package app.cometes.shared.frontend.base.error

import app.cometes.shared.frontend.base.ErrorResult

sealed class CommonError(
    message: String? = null,
    throwable: Throwable? = null
) : ErrorResult(message, throwable) {

    class NetworkError(throwable: Throwable) : CommonError(throwable = throwable)
    object ResourceNotLoaded : CommonError()
}