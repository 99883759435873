@file:Suppress("DuplicatedCode")

package cometes.style

import androidx.compose.runtime.Composable
import androidx.compose.runtime.ReadOnlyComposable
import androidx.compose.runtime.Stable
import app.cometes.shared.frontend.presentation.CometesColor
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.lightened
import com.varabyte.kobweb.silk.style.CssStyleScope
import com.varabyte.kobweb.silk.theme.colors.ColorMode


object CometesTheme {

    val color: ColorScheme
        @Composable
        @ReadOnlyComposable
        get() = if (ColorMode.current.isLight) LightThemeColors else DarkThemeColors
}

val CssStyleScope.cometesColor
    get() = if (colorMode.isLight) LightThemeColors else DarkThemeColors
val CssStyleScope.invertedCometesColor
    get() = if (colorMode.isLight) DarkThemeColors else LightThemeColors

fun Color.emphasize(byPercent: Float, colorMode: ColorMode) = when (colorMode) {
    ColorMode.LIGHT -> darkened(byPercent)
    ColorMode.DARK -> lightened(byPercent)
}


@Stable
class ColorScheme(
    val primary: Color,
    val onPrimary: Color,
    val primaryContainer: Color,
    val onPrimaryContainer: Color,
    val inversePrimary: Color,
    val secondary: Color,
    val onSecondary: Color,
    val secondaryContainer: Color,
    val onSecondaryContainer: Color,
    val tertiary: Color,
    val onTertiary: Color,
    val tertiaryContainer: Color,
    val onTertiaryContainer: Color,
    val background: Color,
    val onBackground: Color,
    val surface: Color,
    val onSurface: Color,
    val surfaceVariant: Color,
    val onSurfaceVariant: Color,
    val inverseSurface: Color,
    val inverseOnSurface: Color,
    val error: Color,
    val onError: Color,
    val errorContainer: Color,
    val onErrorContainer: Color,
    val outline: Color,
    val positive: Color,
    val positiveContainer: Color,
    val onPositiveContainer: Color
)

private val LightThemeColors = ColorScheme(
    primary = Color.argb(CometesColor.light_primary),
    onPrimary = Color.argb(CometesColor.light_onPrimary),
    primaryContainer = Color.argb(CometesColor.light_primaryContainer),
    onPrimaryContainer = Color.argb(CometesColor.light_onPrimaryContainer),
    secondary = Color.argb(CometesColor.light_secondary),
    onSecondary = Color.argb(CometesColor.light_onSecondary),
    secondaryContainer = Color.argb(CometesColor.light_secondaryContainer),
    onSecondaryContainer = Color.argb(CometesColor.light_onSecondaryContainer),
    tertiary = Color.argb(CometesColor.light_tertiary),
    onTertiary = Color.argb(CometesColor.light_onTertiary),
    tertiaryContainer = Color.argb(CometesColor.light_tertiaryContainer),
    onTertiaryContainer = Color.argb(CometesColor.light_onTertiaryContainer),
    error = Color.argb(CometesColor.light_error),
    errorContainer = Color.argb(CometesColor.light_errorContainer),
    onError = Color.argb(CometesColor.light_onError),
    onErrorContainer = Color.argb(CometesColor.light_onErrorContainer),
    background = Color.argb(CometesColor.light_background),
    onBackground = Color.argb(CometesColor.light_onBackground),
    surface = Color.argb(CometesColor.light_surface).darkened(0.05f),
    onSurface = Color.argb(CometesColor.light_onSurface),
    surfaceVariant = Color.argb(CometesColor.light_surfaceVariant),
    onSurfaceVariant = Color.argb(CometesColor.light_onSurfaceVariant),
    outline = Color.argb(CometesColor.light_outline),
    inverseOnSurface = Color.argb(CometesColor.light_inverseOnSurface),
    inverseSurface = Color.argb(CometesColor.light_inverseSurface),
    inversePrimary = Color.argb(CometesColor.light_inversePrimary),
    positive = Color.argb(CometesColor.light_positive),
    positiveContainer = Color.argb(CometesColor.light_positiveContainer),
    onPositiveContainer = Color.argb(CometesColor.light_onPositiveContainer),
)

@Suppress("DuplicatedCode")
private val DarkThemeColors = ColorScheme(
    primary = Color.argb(CometesColor.dark_primary),
    onPrimary = Color.argb(CometesColor.dark_onPrimary),
    primaryContainer = Color.argb(CometesColor.dark_primaryContainer),
    onPrimaryContainer = Color.argb(CometesColor.dark_onPrimaryContainer),
    secondary = Color.argb(CometesColor.dark_secondary),
    onSecondary = Color.argb(CometesColor.dark_onSecondary),
    secondaryContainer = Color.argb(CometesColor.dark_secondaryContainer),
    onSecondaryContainer = Color.argb(CometesColor.dark_onSecondaryContainer),
    tertiary = Color.argb(CometesColor.dark_tertiary),
    onTertiary = Color.argb(CometesColor.dark_onTertiary),
    tertiaryContainer = Color.argb(CometesColor.dark_tertiaryContainer),
    onTertiaryContainer = Color.argb(CometesColor.dark_onTertiaryContainer),
    error = Color.argb(CometesColor.dark_error),
    errorContainer = Color.argb(CometesColor.dark_errorContainer),
    onError = Color.argb(CometesColor.dark_onError),
    onErrorContainer = Color.argb(CometesColor.dark_onErrorContainer),
    background = Color.argb(CometesColor.dark_background),
    onBackground = Color.argb(CometesColor.dark_onBackground),
    surface = Color.argb(CometesColor.dark_surface).lightened(0.05f),
    onSurface = Color.argb(CometesColor.dark_onSurface),
    surfaceVariant = Color.argb(CometesColor.dark_surfaceVariant),
    onSurfaceVariant = Color.argb(CometesColor.dark_onSurfaceVariant),
    outline = Color.argb(CometesColor.dark_outline),
    inverseOnSurface = Color.argb(CometesColor.dark_inverseOnSurface),
    inverseSurface = Color.argb(CometesColor.dark_inverseSurface),
    inversePrimary = Color.argb(CometesColor.dark_inversePrimary),
    positive = Color.argb(CometesColor.dark_positive),
    positiveContainer = Color.argb(CometesColor.dark_positiveContainer),
    onPositiveContainer = Color.argb(CometesColor.dark_onPositiveContainer),
)