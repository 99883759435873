package cometes.components.layout

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextOverflow
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.css.autoLength
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.BoxScope
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.RowScope
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.borderBottom
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flex
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.textOverflow
import com.varabyte.kobweb.compose.ui.modifiers.whiteSpace
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.toModifier
import cometes.components.common.verticalScroll
import cometes.components.layout.menu.SideBar
import cometes.style.BorderSmall
import cometes.style.CometesTheme
import cometes.style.SpaceMedium
import cometes.style.SpaceSmall
import cometes.style.TypographyHeadlineMedium
import cometes.style.system.CometesSurface
import cometes.style.system.SurfaceBackgroundColorVariant
import cometes.util.ZIndex
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.percent

val UtilRowStyle = CssStyle {
    cssRule(" > *") {
        Modifier.margin(left = SpaceMedium)
    }
}

@Composable
fun MainPageLayout(
    modifier: Modifier = Modifier,
    title: String? = null,
    utilRow: @Composable RowScope.() -> Unit = {},
    content: @Composable BoxScope.() -> Unit
) {
    Row(modifier.fillMaxSize()) {
        SideBar(Modifier.fillMaxHeight())
        Column(
            modifier = Modifier.fillMaxHeight().flex(1, 1, 0.percent)
        ) {
            Header(
                title = title,
                utilRow = utilRow,
                modifier = Modifier.fillMaxWidth().zIndex(ZIndex.PageHeader)
            )

            Box(
                modifier = Modifier
                    .verticalScroll()
                    .then(ContainerModifier)
                    .padding(SpaceMedium)
            ) {
                content()
            }
        }
    }
}

@Composable
fun Header(
    modifier: Modifier = Modifier,
    title: String? = null,
    utilRow: @Composable RowScope.() -> Unit = {},
) {
    CometesSurface(
        variant = SurfaceBackgroundColorVariant,
        modifier = modifier
            .padding(topBottom = SpaceSmall)
            .borderBottom(
                width = BorderSmall,
                style = LineStyle.Solid,
                color = CometesTheme.color.outline
            ),
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = ContainerModifier.padding(leftRight = SpaceMedium),
        ) {
            if (title != null) {
                SpanText(
                    text = title,
                    modifier = Modifier
                        .then(TypographyHeadlineMedium)
                        .textOverflow(TextOverflow.Ellipsis)
                        .whiteSpace(WhiteSpace.NoWrap)
                        .overflow(Overflow.Hidden)
                )
            }

            Spacer()

            Row(UtilRowStyle.toModifier()) {
                utilRow()
            }
        }
    }
}


private val ContainerModifier = Modifier
    .fillMaxWidth()
    .maxWidth(72.cssRem)
    .flex(1, 1, 0.percent)
    .margin(leftRight = autoLength)