package cometes.components.layout

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.dom.ref
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.BoxScope
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.RowScope
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import cometes.components.common.verticalScroll
import cometes.components.image.ImageCometes
import cometes.style.ShadowStyle
import cometes.style.SpaceMedium
import cometes.style.SpaceMediumLarge
import cometes.style.SpaceMediumSmall
import cometes.style.SpaceXSmall
import cometes.style.TypographyHeadlineMedium
import cometes.style.system.CometesImageButton
import cometes.style.system.CometesSurface
import cometes.style.system.SurfaceBackgroundColorVariant
import org.jetbrains.compose.web.css.px

val UtilRowStyle by ComponentStyle {
    cssRule(" > *") {
        Modifier.margin(left = SpaceMedium)
    }
}

@Composable
fun MainPageLayout(
    modifier: Modifier = Modifier,
    title: String? = null,
    utilRow: @Composable RowScope.() -> Unit = {},
    content: @Composable BoxScope.() -> Unit
) {
    val context = rememberPageContext()
    var scrollOffset by remember { mutableStateOf(0.0) }
    val isScrolled = scrollOffset > 0.0

    CometesSurface(
        variant = SurfaceBackgroundColorVariant,
        modifier = Modifier.fillMaxSize()
    ) {
        Column(modifier.fillMaxSize()) {
            CometesSurface(
                variant = if (isScrolled) null else SurfaceBackgroundColorVariant,
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(topBottom = SpaceMediumSmall, leftRight = SpaceMediumLarge)
                    .thenIf(isScrolled, ShadowStyle.toModifier())
                    .zIndex(5) // so the shadow is visible over other items
            ) {
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    CometesImageButton(
                        onClick = { context.router.navigateTo("/") },
                        variant = SurfaceBackgroundColorVariant,
                        modifier = Modifier.padding(SpaceXSmall)
                    ) {
                        ImageCometes(Modifier.size(40.px))
                    }

                    Spacer()

                    if (title != null) {
                        SpanText(title, Modifier.then(TypographyHeadlineMedium))
                    }

                    Spacer()

                    Row(UtilRowStyle.toModifier()) {
                        utilRow()
                    }
                }
            }

            Box(
                ref = ref { element ->
                    element.onscroll = {
                        scrollOffset = element.scrollTop; it
                    }
                },
                modifier = Modifier
                    .verticalScroll()
                    .fillMaxSize()
            ) {
                Box(
                    modifier = Modifier.fillMaxWidth().padding(SpaceMedium)
                ) {
                    content()
                }
            }
        }
    }
}