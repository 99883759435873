package cometes.components.feature.organization

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import app.cometes.shared.frontend.feature.organization.presentation.NewOrganizationEvent
import app.cometes.shared.frontend.feature.organization.presentation.NewOrganizationIntent
import app.cometes.shared.frontend.feature.organization.presentation.NewOrganizationPresenter
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.silk.components.text.SpanText
import cometes.components.common.CometesDialog
import cometes.components.common.HorizontalSpacer
import cometes.components.common.VerticalSpacer
import cometes.style.SpaceMedium
import cometes.style.TypographyTitleMedium
import cometes.style.system.CometesButton
import cometes.style.system.SurfaceBackgroundColorVariant
import cometes.style.system.input.CometesTextArea
import cometes.style.system.input.CometesTextField

@Composable
fun NewOrganizationDialog(
    onDismiss: () -> Unit,
    modifier: Modifier = Modifier
) {
    val presenter = remember {
        NewOrganizationPresenter { event ->
            when (event) {
                is NewOrganizationEvent.Error -> {
                    // TODO :(
                }
            }
        }
    }
    val state = presenter.presenterState()

    CometesDialog(
        title = "New organization",
        onDismiss = onDismiss,
        submitButtons = {
            CometesButton(
                enabled = !state.isLoading,
                onClick = onDismiss,
                variant = SurfaceBackgroundColorVariant,
            ) { SpanText("Cancel") }

            HorizontalSpacer(SpaceMedium)

            CometesButton(
                enabled = !state.isLoading,
                onClick = { state.onIntent(NewOrganizationIntent.Confirm) },
            ) { SpanText("Confirm") }
        },
        modifier = modifier
    ) {
        Box(
            contentAlignment = Alignment.Center,
            modifier = Modifier.fillMaxSize()
        ) {
            Column {
                CometesTextField(
                    value = state.organizationName,
                    onValueChange = { state.onIntent(NewOrganizationIntent.SetName(it)) },
                    placeholder = "Name",
                    modifier = Modifier.then(TypographyTitleMedium)
                )

                VerticalSpacer(SpaceMedium)

                CometesTextArea(
                    value = state.organizationDescription,
                    onValueChange = { state.onIntent(NewOrganizationIntent.SetDescription(it)) },
                    placeholder = "Description",
                    modifier = Modifier.then(TypographyTitleMedium)
                )
            }
        }
    }
}