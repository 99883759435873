package infrastructure.source

import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.error.AuthError
import app.cometes.shared.frontend.base.error.CommonError
import app.cometes.shared.frontend.feature.auth.data.AuthListenerHandle
import app.cometes.shared.frontend.feature.auth.data.AuthSsoType
import app.cometes.shared.frontend.feature.auth.data.FirebaseSource
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.FirebaseNetworkException
import dev.gitlive.firebase.FirebaseTooManyRequestsException
import dev.gitlive.firebase.auth.FirebaseAuthEmailException
import dev.gitlive.firebase.auth.FirebaseAuthInvalidCredentialsException
import dev.gitlive.firebase.auth.FirebaseAuthInvalidUserException
import dev.gitlive.firebase.auth.auth
import dev.gitlive.firebase.auth.externals.OAuthProvider
import dev.gitlive.firebase.auth.externals.signInWithPopup
import dev.gitlive.firebase.auth.js
import kotlin.time.Duration.Companion.seconds
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.await
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.launch
import kotlinx.coroutines.withTimeout

class FirebaseSourceImpl() : FirebaseSource {

    override val isLoggedIn: Boolean
        get() = Firebase.auth.currentUser != null
    override val userEmail: String?
        get() = Firebase.auth.currentUser?.email
    override val displayName: String?
        get() = Firebase.auth.currentUser?.displayName

    override suspend fun signInUserUsingEmailAndPassword(
        email: String,
        password: String
    ): Result<Unit> {
        try {
            Firebase.auth.signInWithEmailAndPassword(email, password)
        } catch (e: FirebaseAuthInvalidUserException) {
            return Result.Error(AuthError.InvalidCredentials(e))
        } catch (e: FirebaseAuthEmailException) {
            return Result.Error(AuthError.InvalidCredentials(e))
        } catch (e: FirebaseAuthInvalidCredentialsException) {
            return Result.Error(AuthError.InvalidCredentials(e))
        } catch (e: IllegalArgumentException) {
            return Result.Error(AuthError.InvalidCredentials(e))
        } catch (e: FirebaseTooManyRequestsException) {
            return Result.Error(AuthError.TimedOut(e))
        } catch (e: FirebaseNetworkException) {
            return Result.Error(CommonError.NetworkError(e))
        }

        return Result.Success(Unit)
    }

    override suspend fun signInUsingSso(type: AuthSsoType): Result<Unit> {
        val provider = when (type) {
            AuthSsoType.Google -> {
                dev.gitlive.firebase.auth.externals.GoogleAuthProvider()
                    .apply { addScope("https://www.googleapis.com/auth/userinfo.profile") }
            }

            AuthSsoType.Apple -> {
                OAuthProvider("apple.com")
                    .apply { addScope("email"); addScope("name") }
            }
        }

        try {
            signInWithPopup(Firebase.auth.js, provider).await()
        } catch (t: Throwable) {
            val code: String = t.asDynamic().code
            when (code) {
                "auth/popup-closed-by-user" -> return Result.Error(AuthError.UserCancelledAuthFlow)
                else -> println("Uncaught error $t")
            }
        }

        return Result.Success(Unit)
    }

    override suspend fun getToken(): Result<String> {
        // JS Firebase initialization is slow, so we wait up to two seconds if we get the auth state
        if (!isLoggedIn) withTimeout(2.seconds) { Firebase.auth.authStateChanged.first() }
        val token = Firebase.auth.currentUser?.getIdToken(false)

        return if (token != null) Result.Success(token)
        else Result.Error(AuthError.NoUserLoggedIn(AuthError.NoUserLoggedIn.Cause.FirebaseAuth))
    }

    override suspend fun logOutUser(): Result<Unit> {
        if (!isLoggedIn) return Result.Error(AuthError.NoUserLoggedIn(AuthError.NoUserLoggedIn.Cause.FirebaseAuth))
        Firebase.auth.signOut()
        return Result.Success(Unit)
    }

    override fun addAuthListener(onAuthChange: (isUserLoggedIn: Boolean) -> Unit): AuthListenerHandle {
        onAuthChange(Firebase.auth.currentUser != null)

        val scope = CoroutineScope(Job()).launch {
            Firebase.auth.authStateChanged.collect { user -> onAuthChange(user != null) }
        }
        return AuthListenerHandle { scope.cancel() }
    }

}