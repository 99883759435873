package app.cometes.shared.frontend.feature.auth.data

import androidx.compose.runtime.Composable
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.error.AuthError
import app.cometes.shared.frontend.base.resource.EmptyInputMutation
import app.cometes.shared.frontend.base.resource.EmptyMutation
import app.cometes.shared.frontend.base.resource.alsoOnSuccess
import app.cometes.shared.frontend.base.resource.clearResourceCaches
import app.cometes.shared.frontend.base.resource.rememberInputMutation
import app.cometes.shared.frontend.base.resource.rememberMutation
import app.cometes.shared.frontend.configuration.data.source.AppInfoProvider
import app.cometes.shared.frontend.configuration.domain.Configuration
import app.cometes.shared.frontend.feature.organization.data.source.OrganizationLocalSource
import app.cometes.shared.frontend.feature.person.data.source.PersonLocalSource
import app.cometes.shared.frontend.util.rememberKoinDependency
import app.cometes.shared.frontend.util.withUseCaseContext
import com.russhwolf.settings.Settings


@Composable
fun sendSignInEmailMutation(email: String): EmptyMutation {
    val appInfoProvider = rememberKoinDependency<AppInfoProvider>()
    val firebaseSource = rememberKoinDependency<FirebaseSource>()
    val authLocalSource = rememberKoinDependency<AuthLocalSource>()

    val (androidPackage, iosBundle) = when (appInfoProvider.config) {
        Configuration.Debug -> "app.cometes.android.debug" to "app.cometes.ios"
        Configuration.PreProd -> "app.cometes.android.beta" to "app.cometes.ios"
        Configuration.Release -> "app.cometes.android" to "app.cometes.ios"
    }

    val emailSettings = EmailSignInSettings(
        AuthUrl,
        iosBundle,
        androidPackage
    )

    return rememberMutation(email, emailSettings) {
        withUseCaseContext { firebaseSource.sendSignInEmail(email, emailSettings) }
            .alsoOnSuccess { authLocalSource.loginEmail = email }
    }
}

@Composable
fun confirmLoginLinkMutation(): EmptyInputMutation<String> {
    val firebaseSource = rememberKoinDependency<FirebaseSource>()
    val authLocalSource = rememberKoinDependency<AuthLocalSource>()

    return rememberInputMutation mutation@{ link ->
        val cachedEmail = authLocalSource.loginEmail
            ?: return@mutation Result.Error(AuthError.MagicEmailLoginNotStarted)

        withUseCaseContext { firebaseSource.confirmLogin(cachedEmail, link) }
            .alsoOnSuccess { authLocalSource.clearLoginEmail() }
    }
}

@Composable
fun signInEmailPasswordMutation(email: String, password: String): EmptyMutation {
    val firebaseSource = rememberKoinDependency<FirebaseSource>()

    return rememberMutation(email, password) {
        withUseCaseContext { firebaseSource.signInUserUsingEmailAndPassword(email, password) }
    }
}

@Composable
fun logOutMutation(): EmptyMutation {
    val firebaseSource = rememberKoinDependency<FirebaseSource>()
    val settings = rememberKoinDependency<Settings>()
    val organizationLocalSource = rememberKoinDependency<OrganizationLocalSource>()
    val personLocalSource = rememberKoinDependency<PersonLocalSource>()

    return rememberMutation {
        val firebaseLogoutResult = withUseCaseContext { firebaseSource.logOutUser() }
        if (firebaseLogoutResult is Result.Error)
            return@rememberMutation firebaseLogoutResult

        settings.clear()
        organizationLocalSource.clear()
        personLocalSource.clear()
        clearResourceCaches() // TODO make clear cache mutation

        Result.Success(Unit)
    }
}

private val AuthUrl = "https://cometes.app/auth"