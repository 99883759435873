package app.cometes.shared.frontend.feature.reservation

import app.cometes.shared.frontend.feature.reservation.data.source.ReservationDayLocalSource
import app.cometes.shared.frontend.feature.reservation.data.source.ReservationRemoteSource
import app.cometes.shared.frontend.feature.reservation.infrastructure.source.ReservationDayDayLocalSourceImpl
import app.cometes.shared.frontend.feature.reservation.infrastructure.source.ReservationRemoteSourceImpl
import org.koin.core.module.dsl.singleOf
import org.koin.dsl.bind
import org.koin.dsl.module

val featureReservation = module {
    singleOf(::ReservationRemoteSourceImpl) bind ReservationRemoteSource::class
    singleOf(::ReservationDayDayLocalSourceImpl) bind ReservationDayLocalSource::class
}