package cometes.components.feature.organization.location

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.feature.desk.data.createDeskMutation
import app.cometes.shared.frontend.feature.reservation.domain.model.Location
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.text.DivText
import com.varabyte.kobweb.silk.components.text.SpanText
import cometes.components.common.CometesDialog
import cometes.components.common.CometesDivider
import cometes.components.common.CometesLoadingButton
import cometes.components.common.HorizontalSpacer
import cometes.components.common.LabeledRow
import cometes.components.common.VerticalSpacer
import cometes.style.RadiusPill
import cometes.style.SpaceMedium
import cometes.style.SpaceSmall
import cometes.style.SpaceXXSmall
import cometes.style.TypographyBodyLarge
import cometes.style.system.CometesButton
import cometes.style.system.CometesSurface
import cometes.style.system.SurfaceBackgroundColorVariant
import cometes.style.system.SurfacePrimaryVariant
import cometes.style.system.input.CometesTextField
import kotlinx.coroutines.launch

@Composable
fun DeskCreationDialog(
    locations: List<Location>,
    onDismiss: () -> Unit,
) {
    val coroutineScope = rememberCoroutineScope()
    var isLoading: Boolean by remember { mutableStateOf(false) }

    var deskName: String by remember { mutableStateOf("") }
    var locationSelected: Location by remember { mutableStateOf(locations.first()) }

    val createDeskMutation = createDeskMutation(deskName, locationSelected)
    fun onCreateClick() {
        coroutineScope.launch {
            isLoading = true
            val res = createDeskMutation.execute()
            isLoading = false
            if (res is Result.Success) onDismiss()
        }
    }

    CometesDialog(
        title = "Create new desk",
        onDismiss = onDismiss,
        submitButtons = {
            CometesButton(
                onDismiss,
                variant = SurfaceBackgroundColorVariant
            ) { SpanText("Cancel") }
            HorizontalSpacer(SpaceMedium)
            CometesLoadingButton(
                isLoading = isLoading,
                onClick = { onCreateClick() },
                enabled = deskName.isNotBlank(),
                variant = SurfacePrimaryVariant
            ) {
                SpanText("Create desk")
            }
        }
    ) {
        Column(
            Modifier
                .fillMaxWidth()
                .then(TypographyBodyLarge)
        ) {
            LabeledRow("Desk name:", Modifier.fillMaxWidth()) {
                CometesTextField(
                    value = deskName,
                    onValueChange = { deskName = it }
                )
            }

            VerticalSpacer(SpaceSmall)
            CometesDivider()
            VerticalSpacer(SpaceSmall)

            LabeledRow("Location:", Modifier.fillMaxWidth()) {
                Row {
                    for (location in locations) {
                        HorizontalSpacer(SpaceSmall)
                        CometesSurface(
                            onClick = { locationSelected = location },
                            variant = if (location.id == locationSelected.id) SurfacePrimaryVariant else null,
                            modifier = Modifier
                                .borderRadius(RadiusPill)
                                .margin(topBottom = SpaceXXSmall)
                                .padding(leftRight = SpaceSmall, topBottom = SpaceXXSmall)
                        ) {
                            DivText(location.name)
                        }
                    }
                }
            }
        }
    }
}