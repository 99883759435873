package cometes.components.feature.auth

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import app.cometes.shared.frontend.feature.organization.domain.model.Organization
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationInvitationPreview
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationMember
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationMemberRole
import app.cometes.shared.frontend.feature.organization.presentation.OrganizationSelectionEvent
import app.cometes.shared.frontend.feature.organization.presentation.OrganizationSelectionIntent
import app.cometes.shared.frontend.feature.organization.presentation.OrganizationSelectionPresenter
import app.cometes.shared.frontend.util.DateFormatHelper
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.icons.mdi.IconStyle
import com.varabyte.kobweb.silk.components.icons.mdi.MdiAdd
import com.varabyte.kobweb.silk.components.icons.mdi.MdiSchedule
import com.varabyte.kobweb.silk.components.icons.mdi.MdiWarningAmber
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.ResponsiveValues
import cometes.components.common.CometesCard
import cometes.components.common.CometesDivider
import cometes.components.common.DivText
import cometes.components.common.HorizontalSpacer
import cometes.components.common.ProgressIndicator
import cometes.components.common.RoundedImage
import cometes.components.common.VerticalSpacer
import cometes.components.common.requiredSize
import cometes.components.feature.organization.NewOrganizationDialog
import cometes.components.layout.AuthPageLayout
import cometes.style.RadiusMedium
import cometes.style.RadiusPill
import cometes.style.SpaceLarge
import cometes.style.SpaceMedium
import cometes.style.SpaceMediumLarge
import cometes.style.SpaceMediumSmall
import cometes.style.SpaceSmall
import cometes.style.SpaceXSmall
import cometes.style.TypographyBodyMedium
import cometes.style.TypographyBodySmall
import cometes.style.TypographyHeadlineMedium
import cometes.style.TypographyTitleMedium
import cometes.style.TypographyTitleSmall
import cometes.style.system.ButtonSecondaryVariant
import cometes.style.system.CometesButton
import cometes.style.system.CometesSurface
import cometes.style.system.SurfaceErrorVariant
import kotlinx.datetime.Clock
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div

@Composable
fun OrganizationSelectionContent() {
    val presenter = remember {
        OrganizationSelectionPresenter { event ->
            when (event) {
                is OrganizationSelectionEvent.Error -> {} // TODO
            }
        }
    }
    val state = presenter.presenterState()

    var isNewOrgDialogVisible by remember { mutableStateOf(false) }

    fun logOut() {
        state.onIntent(OrganizationSelectionIntent.LogOut)
    }

    AuthPageLayout(title = "", onLogoutClick = { logOut() }) {
        Box(Modifier.fillMaxSize()) {
            Column(Modifier.fillMaxSize()) {
                VerticalSpacer(SpaceMediumLarge)

                if (state.organizations.isEmpty() || state.isLoading) {
                    Box(
                        contentAlignment = Alignment.Center,
                        modifier = Modifier.fillMaxSize()
                    ) {
                        if (state.isLoading) {
                            ProgressIndicator()
                        } else if (state.organizations.isEmpty()) {
                            Column(horizontalAlignment = Alignment.CenterHorizontally) {
                                MdiWarningAmber(style = IconStyle.ROUNDED)
                                VerticalSpacer(SpaceMedium)
                                DivText("No organizations available", Modifier.then(TypographyHeadlineMedium))
                            }
                        }
                    }

                    return@Column
                }

                if (state.invitations.isNotEmpty()) {
                    SpanText(
                        text = "Pending invitations",
                        modifier = TypographyHeadlineMedium
                    )

                    VerticalSpacer(SpaceMediumLarge)

                    Column(
                        verticalArrangement = Arrangement.spacedBy(SpaceSmall),
                        modifier = Modifier.fillMaxWidth()
                    ) {
                        for (invitation in state.invitations) {
                            InvitationItem(
                                invitation = invitation,
                                onInvitationAccept = {
                                    val token = invitation.invitationToken
                                    state.onIntent(OrganizationSelectionIntent.AcceptInvitation(token))
                                },
                                modifier = Modifier.fillMaxWidth()
                            )
                        }
                    }

                    VerticalSpacer(SpaceLarge)
                    CometesDivider()
                    VerticalSpacer(SpaceLarge)
                }

                Row(Modifier.fillMaxWidth()) {
                    SpanText(
                        "Your organizations",
                        modifier = TypographyHeadlineMedium
                    )
                    Spacer()
                    CometesButton(onClick = { isNewOrgDialogVisible = true }) {
                        MdiAdd(style = IconStyle.ROUNDED)
                        HorizontalSpacer(SpaceSmall)
                        SpanText("Create organization")
                    }
                }

                VerticalSpacer(SpaceMediumLarge)

                SimpleGrid(
                    numColumns = ResponsiveValues(base = 1, sm = 1, md = 2, lg = 2, xl = 2),
                    modifier = Modifier.fillMaxWidth().styleModifier { property("grid-gap", SpaceMediumLarge) }
                ) {
                    for (item in state.organizations) {
                        OrganizationItem(
                            organization = item.organization,
                            membership = item.membership,
                            onClick = { state.onIntent(OrganizationSelectionIntent.SelectOrganization(item)) },
                            modifier = Modifier.fillMaxWidth()
                        )
                    }
                }
            }
        }
    }

    if (isNewOrgDialogVisible) {
        NewOrganizationDialog(onDismiss = { isNewOrgDialogVisible = false })
    }
}

@Composable
private fun InvitationItem(
    invitation: OrganizationInvitationPreview,
    onInvitationAccept: () -> Unit,
    modifier: Modifier = Modifier,
) {
    val expirationDays = remember(invitation.invitationId) {
        (invitation.invitationExpiresAt - Clock.System.now()).inWholeDays
    }

    CometesCard(
        modifier = modifier.borderRadius(RadiusMedium)
    ) {
        Row(
            verticalAlignment = Alignment.Top,
            modifier = Modifier.fillMaxWidth()
        ) {
            OrganizationSummary(
                name = invitation.organizationName,
                description = invitation.organizationDescription,
                imageUrl = invitation.organizationImage,
                modifier = Modifier
            )

            Spacer()

            Column(horizontalAlignment = Alignment.End) {
                CometesSurface(
                    variant = SurfaceErrorVariant,
                    modifier = Modifier.borderRadius(RadiusPill)
                ) {
                    Row(
                        verticalAlignment = Alignment.CenterVertically,
                        modifier = Modifier.margin(topBottom = SpaceXSmall, leftRight = SpaceSmall)
                    ) {
                        MdiSchedule(style = IconStyle.ROUNDED)
                        HorizontalSpacer(SpaceMediumSmall)
                        DivText(
                            text = if (expirationDays > 0) "Expires in $expirationDays days" else "Expires today",
                            modifier = TypographyBodySmall

                        )
                    }
                }

                Spacer()
                VerticalSpacer(SpaceLarge)

                CometesButton(
                    onClick = { onInvitationAccept() },
                    variant = ButtonSecondaryVariant,
                ) {
                    DivText("Accept invitation")
                }
            }
        }
    }
}

@Composable
private fun OrganizationItem(
    organization: Organization,
    membership: OrganizationMember,
    onClick: () -> Unit,
    modifier: Modifier = Modifier
) {
    CometesCard(
        onClick = { onClick() },
        modifier = modifier.borderRadius(RadiusMedium),
    ) {
        Column(Modifier.fillMaxWidth()) {
            OrganizationSummary(
                name = organization.name,
                description = organization.description,
                imageUrl = organization.imageUrl,
                modifier = Modifier.fillMaxWidth()
            )

            VerticalSpacer(SpaceMedium)

            Column(Modifier.fillMaxWidth()) {
                Div {
                    SpanText("Role: ", TypographyTitleSmall)
                    SpanText(
                        when (membership.role) {
                            OrganizationMemberRole.User -> "Member"
                            OrganizationMemberRole.Admin -> "Admin"
                            OrganizationMemberRole.Owner -> "Admin"
                            else -> ""
                        },
                        TypographyBodyMedium
                    )
                }
                VerticalSpacer(SpaceSmall)
                Div {
                    if (membership.role == OrganizationMemberRole.Owner) SpanText("Created at: ", TypographyTitleSmall)
                    else SpanText("Joined at: ", TypographyTitleSmall)

                    val date = remember(membership.joinedAt) {
                        DateFormatHelper.formatAsFullDate(
                            membership.joinedAt.toLocalDateTime(TimeZone.currentSystemDefault()).date
                        )
                    }
                    SpanText(date, TypographyBodyMedium)
                }
            }
        }
    }
}

@Composable
fun OrganizationSummary(
    name: String,
    description: String,
    imageUrl: String?,
    modifier: Modifier = Modifier,
) {
    Row(modifier) {
        RoundedImage(imageUrl, modifier = Modifier.requiredSize(48.px))

        HorizontalSpacer(SpaceMediumLarge)

        Column(Modifier.width(auto)) {
            DivText(
                text = name,
                modifier = TypographyTitleMedium
            )
            DivText(
                text = description,
                modifier = Modifier
                    .then(TypographyBodyMedium)
                    .opacity(0.75)
            )
        }
    }
}