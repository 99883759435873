package cometes.components.feature.organization.member

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationInvitation
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationMemberPerson
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationMemberRole
import app.cometes.shared.frontend.feature.person.data.currentPerson
import com.varabyte.kobweb.compose.dom.ElementTarget
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.silk.components.icons.fa.FaPerson
import com.varabyte.kobweb.silk.components.icons.fa.FaPlus
import com.varabyte.kobweb.silk.components.icons.fa.FaTicket
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.overlay.Popover
import com.varabyte.kobweb.silk.components.text.SpanText
import cometes.components.common.CometesCard
import cometes.components.common.CometesCardHeader
import cometes.components.common.CometesLabelLarge
import cometes.components.common.HorizontalSpacer
import cometes.components.common.ItemList
import cometes.components.common.VerticalSpacer
import cometes.components.feature.organization.invitation.InvitationCreationDialog
import cometes.components.feature.organization.invitation.InvitationItem
import cometes.components.feature.organization.invitation.InvitationRemovalDialog
import cometes.style.SpaceLarge
import cometes.style.SpaceMediumSmall
import cometes.style.TypographyTitleMedium
import cometes.style.system.CometesImageButton
import cometes.style.system.SurfaceErrorVariant
import cometes.style.system.SurfacePrimaryVariant

@Composable
fun OrganizationMembersCard(
    members: List<OrganizationMemberPerson>,
    invitations: List<OrganizationInvitation>,
    memberRole: OrganizationMemberRole,
    modifier: Modifier = Modifier,
    maxMemberCount: Int? = null,
) {
    val currentPerson = currentPerson()
    var isInvitationDialogVisible by remember { mutableStateOf(false) }
    var invitationRemovalTarget: OrganizationInvitation? by remember { mutableStateOf(null) }
    var memberRemovalTarget: OrganizationMemberPerson? by remember { mutableStateOf(null) }

    val maxNumberOfMembersReached = (members.size + invitations.size) >= (maxMemberCount ?: 0)

    CometesCard(modifier = modifier) {
        Column(Modifier.fillMaxWidth()) {
            CometesCardHeader(
                title = "Members",
                leadingItem = { FaPerson(size = IconSize.LG) },
                trailingItem = {
                    if (memberRole.isAdmin) {
                        SpanText(
                            "${members.size}/$maxMemberCount",
                            modifier = TypographyTitleMedium
                        )

                        HorizontalSpacer(SpaceMediumSmall)

                        CometesImageButton(
                            onClick = { isInvitationDialogVisible = true },
                            variant = SurfacePrimaryVariant,
                            enabled = !maxNumberOfMembersReached
                        ) { FaPlus() }

                        if (maxNumberOfMembersReached) {
                            Popover(ElementTarget.PreviousSibling) {
                                CometesLabelLarge(
                                    text = "Maximum number of members reached",
                                    variant = SurfaceErrorVariant
                                )
                            }
                        }
                    }
                }
            )

            ItemList(members) { member ->
                MemberItem(
                    member = member,
                    interactive = memberRole.isAdmin,
                    isCurrentPerson = member.person.id == currentPerson?.id,
                    onRemove = { memberRemovalTarget = member },
                    modifier = Modifier.fillMaxWidth()
                )
            }

            if (memberRole.isAdmin && invitations.isNotEmpty()) {
                VerticalSpacer(SpaceLarge)
                CometesCardHeader(
                    title = "Invitations",
                    leadingItem = { FaTicket(size = IconSize.LG) }
                )
                ItemList(invitations) { invitation ->
                    InvitationItem(
                        invitation = invitation,
                        onRemove = { invitationRemovalTarget = invitation },
                        modifier = Modifier.fillMaxWidth()
                    )
                }
            }
        }
    }

    if (isInvitationDialogVisible) {
        InvitationCreationDialog(
            onDismiss = { isInvitationDialogVisible = false }
        )
    }

    InvitationRemovalDialog(
        invitation = invitationRemovalTarget,
        onDismiss = { invitationRemovalTarget = null }
    )

    MemberRemovalDialog(
        member = memberRemovalTarget,
        onDismiss = { memberRemovalTarget = null }
    )
}