package app.cometes.shared.frontend.feature.person.data

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import app.cometes.shared.feature.person.infrastructure.model.OrganizationWithMembershipDto
import app.cometes.shared.frontend.base.map
import app.cometes.shared.frontend.base.resource.*
import app.cometes.shared.frontend.feature.organization.data.source.OrganizationRemoteSource
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationWithMembership
import app.cometes.shared.frontend.feature.organization.infrastructure.model.toDomain
import app.cometes.shared.frontend.util.withUseCaseContext
import org.koin.compose.koinInject

@Composable
private fun personsOrganizationsCache() =
    resourceCache<List<OrganizationWithMembership>?>(
        key = "List<OrganizationWithMembership>",
        initialValue = null
    )

@Composable
fun personsOrganizationsResource(): Resource<List<OrganizationWithMembership>> {
    val organizationRemoteSource = koinInject<OrganizationRemoteSource>()
    val membershipCache = personsOrganizationsCache()

    val localMemberships by membershipCache.collectAsState()
    val membershipResource = rememberUpdatedResourceState(localMemberships) {
        withUseCaseContext { organizationRemoteSource.getAllPersonsOrganizations() }
            .map { result -> result.map(OrganizationWithMembershipDto::toDomain) }
            .alsoOnSuccess { membershipCache.value = it }
    }

    return wrapEmptyResource { membershipResource.resource }
}