package cometes.style.system.input

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Resize
import com.varabyte.kobweb.compose.css.UserSelect
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.resize
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.compose.ui.modifiers.userSelect
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.style.ComponentKind
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.CssStyleVariant
import com.varabyte.kobweb.silk.style.addVariant
import com.varabyte.kobweb.silk.style.selectors.focus
import com.varabyte.kobweb.silk.style.selectors.hover
import com.varabyte.kobweb.silk.style.selectors.placeholder
import com.varabyte.kobweb.silk.style.toModifier
import cometes.components.common.DivText
import cometes.style.RadiusLarge
import cometes.style.SpaceMediumSmall
import cometes.style.SpaceXSmall
import cometes.style.TypographyBodyLarge
import cometes.style.TypographyBodyMedium
import cometes.style.TypographyLabelMedium
import cometes.style.cometesColor
import cometes.style.system.animation.CometesTransitionOf
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.cols
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.attributes.rows
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.TextArea

private val BorderWidth = 1.8.px
private val BorderRadius = RadiusLarge

interface TextFieldKind : ComponentKind

val CometesTextFieldStyle = CssStyle<TextFieldKind> {
    val inactiveBorderColor = cometesColor.outline.toRgb().copyf(alpha = 0.4f)
    val activeBorderColor = cometesColor.primary
    val backgroundColor = cometesColor.background
    val textColor = cometesColor.onBackground

    base {
        Modifier
            .withoutInputDefaults()
            .border(BorderWidth, style = LineStyle.Solid, color = inactiveBorderColor)
            .borderRadius(BorderRadius)
            .color(textColor)
            .backgroundColor(backgroundColor)
            .then(TypographyBodyMedium)
            .padding(SpaceMediumSmall)
            .transition(
                CometesTransitionOf("background-color"),
                CometesTransitionOf("border-color"),
                CometesTransitionOf("opacity"),
            )
    }

    hover { Modifier.cursor(Cursor.Text) }

    focus { Modifier.border(BorderWidth, style = LineStyle.Solid, color = activeBorderColor) }

    placeholder {
        Modifier
            .then(TypographyBodyLarge)
            .userSelect(UserSelect.None)
            .opacity(0.5f)
    }
}

val CometesTextFieldSurfaceVariant = CometesTextFieldStyle.addVariant {
    base {
        Modifier
            .backgroundColor(cometesColor.surface)
            .color(cometesColor.onSurface)
    }
}

@Composable
fun CometesTextField(
    value: String,
    onValueChange: (String) -> Unit,
    modifier: Modifier = Modifier,
    type: InputType<String> = InputType.Text,
    placeholder: String? = null,
    label: String? = null,
    variant: CssStyleVariant<TextFieldKind>? = null
) {
    Column(
        verticalArrangement = Arrangement.spacedBy(SpaceXSmall),
        modifier = Modifier
            .then(modifier)
    ) {
        if (label != null) {
            DivText(label, TypographyLabelMedium)
        }
        Input(
            type = type,
            attrs = CometesTextFieldStyle
                .toModifier(variant)
                .fillMaxSize()
                .toAttrs {
                    value(value)
                    onInput { onValueChange(it.value) }
                    if (placeholder != null)
                        placeholder(placeholder)
                }
        )
    }
}

@Composable
fun CometesNumberField(
    value: Number,
    onValueChange: (Number) -> Unit,
    modifier: Modifier = Modifier,
    placeholder: String? = null,
    label: String? = null,
    variant: CssStyleVariant<TextFieldKind>? = null
) {
    Column(
        verticalArrangement = Arrangement.spacedBy(SpaceXSmall),
        modifier = Modifier
            .then(modifier)
    ) {
        if (label != null) {
            DivText(label, TypographyLabelMedium)
        }
        Input(
            type = InputType.Number,
            attrs = CometesTextFieldStyle
                .toModifier(variant)
                .fillMaxSize()
                .toAttrs {
                    value(value)
                    onInput { onValueChange(it.value ?: 0) }
                    if (placeholder != null)
                        placeholder(placeholder)
                }
        )
    }
}

@Composable
fun CometesTextArea(
    value: String,
    onValueChange: (String) -> Unit,
    modifier: Modifier = Modifier,
    resizable: Boolean = false,
    rows: Int = 5,
    columns: Int = 50,
    placeholder: String? = null,
    label: String? = null,
    variant: CssStyleVariant<TextFieldKind>? = null
) {
    Column(
        verticalArrangement = Arrangement.spacedBy(SpaceXSmall),
        modifier = Modifier
            .then(modifier)
    ) {
        if (label != null) {
            DivText(label, TypographyLabelMedium)
        }
        TextArea(
            attrs = CometesTextFieldStyle.toModifier(variant)
                .resize(if (resizable) Resize.Both else Resize.None)
                .fillMaxSize()
                .toAttrs {
                    rows(rows)
                    cols(columns)

                    value(value)
                    onInput { onValueChange(it.value) }

                    if (placeholder != null)
                        placeholder(placeholder)
                }
        )
    }
}

private fun Modifier.withoutInputDefaults() = styleModifier {
    property("border", "none")
    property("outline", "none")
}