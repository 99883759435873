package cometes.components.feature.organization.location

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import app.cometes.shared.frontend.feature.organization.data.createOrganizationLocationMutation
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.silk.components.text.SpanText
import cometes.components.common.CometesDialog
import cometes.components.common.CometesLoadingButton
import cometes.components.common.HorizontalSpacer
import cometes.components.common.LabeledRow
import cometes.components.common.VerticalSpacer
import cometes.style.SpaceMedium
import cometes.style.SpaceSmall
import cometes.style.TypographyBodyLarge
import cometes.style.system.CometesButton
import cometes.style.system.SurfaceBackgroundColorVariant
import cometes.style.system.SurfacePrimaryVariant
import cometes.style.system.input.CometesTextField
import kotlinx.coroutines.launch

@Composable
fun LocationCreationDialog(onDismiss: () -> Unit) {
    val coroutineScope = rememberCoroutineScope()
    var isLoading: Boolean by remember { mutableStateOf(false) }

    var locationName: String by remember { mutableStateOf("") }
    var locationAddress: String by remember { mutableStateOf("") }

    val createLocationMutation = createOrganizationLocationMutation(locationName, locationAddress)
    fun onCreateClick() {
        coroutineScope.launch {
            isLoading = true
            val res = createLocationMutation.execute()
            isLoading = false
            onDismiss()
        }
    }

    CometesDialog(
        title = "Create new location",
        onDismiss = onDismiss,
        submitButtons = {
            CometesButton(
                onDismiss,
                variant = SurfaceBackgroundColorVariant
            ) { SpanText("Cancel") }
            HorizontalSpacer(SpaceMedium)
            CometesLoadingButton(
                isLoading = isLoading,
                onClick = { onCreateClick() },
                enabled = locationName.isNotBlank(),
                variant = SurfacePrimaryVariant
            ) {
                SpanText("Create location")
            }
        }
    ) {
        Column(
            Modifier
                .fillMaxWidth()
                .then(TypographyBodyLarge)
        ) {
            LabeledRow("Location name:", Modifier.fillMaxWidth()) {
                CometesTextField(
                    value = locationName,
                    onValueChange = { locationName = it }
                )
            }

            VerticalSpacer(SpaceSmall)

            LabeledRow("Address:", Modifier.fillMaxWidth()) {
                CometesTextField(
                    value = locationAddress,
                    onValueChange = { locationAddress = it }
                )
            }
        }
    }
}