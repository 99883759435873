package app.cometes.shared.frontend.feature.auth.data

import androidx.compose.runtime.*
import org.koin.compose.koinInject

@Composable
internal fun isUserLoggedIn(): Boolean {
    val source = koinInject<FirebaseSource>()
    var loggedIn by remember { mutableStateOf(source.isLoggedIn) }

    DisposableEffect(Unit) {
        val disposable = source.addAuthListener { loggedIn = it }
        onDispose { disposable.dispose() }
    }

    return loggedIn
}

@Composable
fun firebaseUserEmail(): String? {
    val source = koinInject<FirebaseSource>()
    var userEmail by remember { mutableStateOf(source.userEmail) }

    DisposableEffect(Unit) {
        val disposable = source.addAuthListener { userEmail = source.userEmail }
        onDispose { disposable.dispose() }
    }

    return userEmail
}

@Composable
fun firebaseDisplayName(): String? {
    val source = koinInject<FirebaseSource>()
    var displayName by remember { mutableStateOf(source.displayName) }

    DisposableEffect(Unit) {
        val disposable = source.addAuthListener { displayName = source.displayName }
        onDispose { disposable.dispose() }
    }

    return displayName
}