package app.cometes.shared.feature.room.infrastructure.model

import app.cometes.shared.feature.person.infrastructure.model.PersonDto
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class ConferenceRoomReservationDto(
    val id: Long,
    val organizationId: Long,
    val author: PersonDto,
    val name: String,
    val description: String,
    val conferenceRoom: ConferenceRoomDto,
    val startTime: Instant,
    val endTime: Instant,
    val createdAt: Instant,
)