package cometes.components.common

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.setValue
import androidx.compose.web.events.SyntheticMouseEvent
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.RowScope
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.silk.components.icons.mdi.IconStyle
import com.varabyte.kobweb.silk.components.icons.mdi.MdiDarkMode
import com.varabyte.kobweb.silk.components.icons.mdi.MdiLightMode
import com.varabyte.kobweb.silk.style.CssStyleVariant
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import cometes.style.system.ButtonOutlineVariant
import cometes.style.system.ButtonPrimaryVariant
import cometes.style.system.CometesButton
import cometes.style.system.CometesButtonKind
import cometes.style.system.CometesImageButton
import cometes.style.system.animation.fadeInTransition

@Composable
fun ThemeToggleButton(modifier: Modifier = Modifier) {
    var colorMode by ColorMode.currentState

    CometesImageButton(
        onClick = { colorMode = colorMode.opposite },
        modifier = modifier,
        variant = ButtonOutlineVariant,
    ) {
        if (colorMode.isLight) MdiLightMode(style = IconStyle.ROUNDED) else MdiDarkMode(style = IconStyle.ROUNDED)
    }
}

@Composable
fun CometesLoadingButton(
    isLoading: Boolean,
    onClick: (SyntheticMouseEvent) -> Unit,
    modifier: Modifier = Modifier,
    enabled: Boolean = !isLoading,
    variant: CssStyleVariant<CometesButtonKind>? = ButtonPrimaryVariant,
    content: @Composable RowScope.() -> Unit
) {
    CometesButton(onClick, modifier, enabled, variant) {
        Box(
            contentAlignment = Alignment.Center,
            modifier = Modifier.fillMaxWidth()
        ) {
            Row(
                horizontalArrangement = Arrangement.Center,
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.fillMaxWidth(),
                content = content
            )
            if (isLoading) ProgressIndicator(Modifier.fadeInTransition())
        }
    }
}