package cometes.style.system.animation

import com.varabyte.kobweb.compose.css.Transition
import com.varabyte.kobweb.compose.css.TransitionTimingFunction
import org.jetbrains.compose.web.css.CSSNumericValue
import org.jetbrains.compose.web.css.CSSUnitTime
import org.jetbrains.compose.web.css.ms

fun CometesTransitionOf(
    property: String,
    duration: CSSNumericValue<out CSSUnitTime> = 200.ms
) = Transition.of(
    property = property,
    timingFunction = TransitionTimingFunction.cubicBezier(0.4, 0.0, 0.2, 1.0),
    duration = duration
)

fun CometesTransitionOfBackgroundColor() = CometesTransitionOf("background-color")