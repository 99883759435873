package app.cometes.shared.frontend.feature.person.infrastructure.source

import app.cometes.shared.feature.organization.infrastructure.model.OrganizationInvitationPreviewDto
import app.cometes.shared.feature.person.infrastructure.model.PersonDto
import app.cometes.shared.feature.person.infrastructure.model.RegistrationBody
import app.cometes.shared.feature.schedule.infrastructure.model.ScheduleDto
import app.cometes.shared.feature.schedule.infrastructure.model.ScheduleSetBody
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.error.*
import app.cometes.shared.frontend.feature.person.data.source.PersonRemoteSource
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.isoDayNumber

internal class PersonRemoteSourceImpl(
    private val client: HttpClient
) : PersonRemoteSource {

    override suspend fun registerPerson(
        firstName: String,
        lastName: String
    ): Result<PersonDto> = try {
        runCatchingNetworkExceptions {
            val res = client.post("/person/register") {
                setBody(RegistrationBody(firstName = firstName, lastName = lastName))
            }
            Result.Success(res.body())
        }
    } catch (e: BackendException) {
        when (e.error.type) {
            "PersonAlreadyExists" -> Result.Error(PersonError.PersonAlreadyExists(e))
            else -> throw e
        }
    }

    override suspend fun getCurrentPerson(): Result<PersonDto> =
        runCatchingNetworkExceptions {
            val res = client.get("/person/current")
            Result.Success(res.body())
        }

    override suspend fun getCurrentPersonInvitations(): Result<List<OrganizationInvitationPreviewDto>> =
        runCatchingNetworkExceptions {
            val res = client.get("/person/current/invitations")
            Result.Success(res.body())
        }

    override suspend fun getCurrentSchedule(organizationId: Long): Result<ScheduleDto> = try {
        runCatchingNetworkExceptions {
            val res = client.get("/organization/${organizationId}/schedule")
            Result.Success(res.body())
        }
    } catch (e: BackendException) {
        when (e.error.type) {
            "PersonsScheduleNotFound" -> Result.Error(ScheduleError.NotFound)
            else -> throw e
        }
    }

    override suspend fun setSchedule(
        organizationId: Long,
        deskId: Long,
        days: List<DayOfWeek>
    ): Result<ScheduleDto> = try {
        runCatchingNetworkExceptions {
            val res = client.put("/organization/${organizationId}/schedule") {
                setBody(ScheduleSetBody(deskId, days.map(DayOfWeek::isoDayNumber)))
            }

            Result.Success(res.body())
        }
    } catch (e: BackendException) {
        when (e.error.type) {
            "DeskNotFound" -> Result.Error(DeskError.NotFound(deskId))
            "PersonsScheduleNotFound" -> Result.Error(ScheduleError.NotFound)
            "NoValidDay" -> Result.Error(ScheduleError.InvalidDayInput)
            "CreationConflict" -> {
                val day = e.error.params["day"]?.toIntOrNull()
                    ?: error("No day param available")

                Result.Error(ScheduleError.Conflict(deskId, DayOfWeek(day)))
            }

            else -> throw e
        }
    }

    override suspend fun removeSchedule(organizationId: Long): Result<Unit> = try {
        runCatchingNetworkExceptions {
            client.delete("/organization/${organizationId}/schedule")
            Result.Success(Unit)
        }
    } catch (e: BackendException) {
        when (e.error.type) {
            "PersonsScheduleNotFound" -> Result.Error(ScheduleError.NotFound)
            else -> throw e
        }
    }

    override suspend fun removeUserData(): Result<Unit> =
        runCatchingNetworkExceptions {
            client.delete("/person/current")
            Result.Success(Unit)
        }
}