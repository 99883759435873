package cometes.components.common

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.RowScope
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.layout.Divider
import com.varabyte.kobweb.silk.components.style.ComponentVariant
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.DivText
import com.varabyte.kobweb.silk.components.text.SpanText
import cometes.style.CometesTheme
import cometes.style.RadiusLarge
import cometes.style.ShadowStyle
import cometes.style.SpaceMedium
import cometes.style.SpaceSmall
import cometes.style.TypographyBodyLarge
import cometes.style.TypographyHeadlineSmall
import cometes.style.TypographyTitleMedium
import cometes.style.system.CometesSurface

@Composable
fun CometesCard(
    modifier: Modifier = Modifier,
    variant: ComponentVariant? = null,
    content: @Composable () -> Unit
) {
    CometesSurface(
        content = content,
        variant = variant,
        modifier = Modifier
            .borderRadius(RadiusLarge)
            .padding(SpaceMedium)
            .then(ShadowStyle.toModifier())
            .then(modifier),
    )
}

@Composable
fun CometesCardHeader(
    title: String,
    leadingItem: (@Composable RowScope.() -> Unit)? = null,
    trailingItem: (@Composable RowScope.() -> Unit)? = null,
    modifier: Modifier = Modifier,
) {
    Column(Modifier.fillMaxWidth().then(modifier)) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.fillMaxWidth()
        ) {
            if (leadingItem != null) {
                leadingItem()
                HorizontalSpacer(SpaceSmall)
            }

            DivText(
                text = title,
                modifier = Modifier.then(TypographyHeadlineSmall)
            )

            Spacer()

            if (trailingItem != null) trailingItem()
        }

        VerticalSpacer(SpaceMedium)
    }
}

@Composable
fun LabeledTextRow(
    label: String,
    value: String,
    modifier: Modifier = Modifier
) {
    LabeledRow(label, modifier) {
        SpanText(value)
    }
}

@Composable
fun LabeledRow(
    label: String,
    modifier: Modifier = Modifier,
    content: @Composable () -> Unit,
) {
    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = Modifier.then(TypographyTitleMedium).then(modifier)
    ) {
        SpanText(label, TypographyBodyLarge)
        Spacer()
        HorizontalSpacer(SpaceMedium)
        content()
    }
}

@Composable
inline fun <T> ItemList(
    items: List<T>,
    itemContent: @Composable (item: T) -> Unit,
) {
    items.forEachIndexed { index, item ->
        itemContent(item)
        if (index != items.lastIndex) CometesDivider()
    }
}

@Composable
fun CometesDivider(modifier: Modifier = Modifier) {
    Divider(
        Modifier
            .color(CometesTheme.color.outline)
            .opacity(0.2f)
            .fillMaxWidth()
            .then(modifier)
    )
}