package app.cometes.shared.frontend.feature.billing.data

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import app.cometes.shared.feature.person.infrastructure.model.BillingPortalSessionDto
import app.cometes.shared.feature.person.infrastructure.model.SubscriptionStatusDto
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.map
import app.cometes.shared.frontend.base.resource.Mutation
import app.cometes.shared.frontend.base.resource.Resource
import app.cometes.shared.frontend.base.resource.alsoOnSuccess
import app.cometes.shared.frontend.base.resource.rememberMutation
import app.cometes.shared.frontend.base.resource.rememberResourceState
import app.cometes.shared.frontend.base.resource.rememberUpdatedResourceState
import app.cometes.shared.frontend.base.resource.resourceCache
import app.cometes.shared.frontend.base.resource.wrapEmptyResource
import app.cometes.shared.frontend.feature.billing.domain.model.SubscriptionStatus
import app.cometes.shared.frontend.feature.billing.infrasctructure.model.toDomain
import app.cometes.shared.frontend.feature.organization.data.currentOrganization
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationError
import app.cometes.shared.frontend.util.rememberKoinDependency
import app.cometes.shared.frontend.util.withUseCaseContext

@Composable
fun subscriptionCache() = resourceCache<SubscriptionStatus?>("SubscriptionStatus", null)

@Composable
fun subscriptionResource(): Resource<SubscriptionStatus> {
    val remoteSource = rememberKoinDependency<BillingRemoteSource>()
    val subscriptionCache = subscriptionCache()

    val localSubscription by subscriptionCache.collectAsState()

    val subscriptionResource = rememberUpdatedResourceState(localSubscription) {
        withUseCaseContext { remoteSource.getSubscriptionStatus() }
            .map(SubscriptionStatusDto::toDomain)
            .alsoOnSuccess { subscriptionCache.value = it }
    }

    return wrapEmptyResource { subscriptionResource.resource }
}

@Composable
fun customerPortalLinkResource(): Resource<String> {
    val remoteSource = rememberKoinDependency<BillingRemoteSource>()
    val resourceState = rememberResourceState(null) {
        withUseCaseContext { remoteSource.getCustomerPortalLink() }
    }

    return wrapEmptyResource { resourceState.resource }
}

@Composable
fun createSubscriptionCheckoutLinkMutation(quantity: Int): Mutation<String> {
    val remoteSource = rememberKoinDependency<BillingRemoteSource>()
    val organization = currentOrganization()

    return rememberMutation(organization, quantity) {
        val orgId = organization?.id
            ?: return@rememberMutation Result.Error(OrganizationError.NoCurrentOrganization)

        withUseCaseContext { remoteSource.getSubscriptionCheckoutLink(orgId, quantity) }
            .map(BillingPortalSessionDto::link)
    }
}

