package cometes.style.system

import androidx.compose.runtime.Composable
import androidx.compose.web.events.SyntheticMouseEvent
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.dom.ElementRefScope
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.ariaDisabled
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.tabIndex
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.ComponentVariant
import com.varabyte.kobweb.silk.components.style.active
import com.varabyte.kobweb.silk.components.style.addVariant
import com.varabyte.kobweb.silk.components.style.common.DisabledStyle
import com.varabyte.kobweb.silk.components.style.common.ariaDisabled
import com.varabyte.kobweb.silk.components.style.focusVisible
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.components.style.not
import com.varabyte.kobweb.silk.components.style.toModifier
import cometes.style.BorderSmall
import cometes.style.RadiusMedium
import cometes.style.cometesColor
import cometes.style.emphasize
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.s
import org.w3c.dom.HTMLElement

val SurfaceStyle by ComponentStyle {
    val surfaceColor = cometesColor.surface
    val surfaceContentColor = cometesColor.onSurface

    base {
        Modifier
            .backgroundColor(surfaceColor)
            .color(surfaceContentColor)
            .border(BorderSmall, LineStyle.Solid, Color.argb(0x00000000))
            .transition(
                CSSTransition("background-color", duration = 0.3.s),
                CSSTransition("color", duration = 0.3.s),
                CSSTransition("border-color", duration = 0.3.s),
            )
    }

    (hover + not(ariaDisabled)) {
        Modifier
            .backgroundColor(surfaceColor.emphasize(0.1f, colorMode))
            .cursor(Cursor.Pointer)
    }

    (focusVisible + not(ariaDisabled)) {
        Modifier.boxShadow(
            spreadRadius = 3.px,
            color = surfaceColor.emphasize(0.1f, colorMode)
        )
    }

    (active + not(ariaDisabled)) {
        Modifier.backgroundColor(surfaceColor.emphasize(0.18f, colorMode))
    }
}

val SurfaceOutlineVariant by SurfaceStyle.addVariant {
    val surfaceColor = cometesColor.background
    val surfaceContentColor = cometesColor.onBackground
    val outlineColor = cometesColor.outline

    base {
        Modifier
            .backgroundColor(surfaceColor)
            .color(surfaceContentColor)
            .border(1.px, LineStyle.Solid, outlineColor)
            .borderRadius(RadiusMedium)
    }

    (hover + not(ariaDisabled)) {
        Modifier
            .backgroundColor(surfaceColor.emphasize(0.1f, colorMode))
            .cursor(Cursor.Pointer)
    }

    (focusVisible + not(ariaDisabled)) {
        Modifier.boxShadow(
            spreadRadius = 3.px,
            color = surfaceColor.emphasize(0.1f, colorMode)
        )
    }

    (active + not(ariaDisabled)) {
        Modifier.backgroundColor(surfaceColor.emphasize(0.18f, colorMode))
    }
}

val SurfaceBackgroundColorVariant by SurfaceStyle.addVariant {
    val surfaceColor = cometesColor.background
    val surfaceContentColor = cometesColor.onBackground

    base {
        Modifier
            .backgroundColor(surfaceColor)
            .color(surfaceContentColor)
    }

    (hover + not(ariaDisabled)) {
        Modifier
            .backgroundColor(surfaceColor.emphasize(0.1f, colorMode))
            .cursor(Cursor.Pointer)
    }

    (focusVisible + not(ariaDisabled)) {
        Modifier.boxShadow(
            spreadRadius = 3.px,
            color = surfaceColor.emphasize(0.1f, colorMode)
        )
    }

    (active + not(ariaDisabled)) {
        Modifier.backgroundColor(surfaceColor.emphasize(0.18f, colorMode))
    }
}

val SurfacePrimaryVariant by SurfaceStyle.addVariant {
    val surfaceColor = cometesColor.primaryContainer
    val surfaceContentColor = cometesColor.onPrimaryContainer

    base {
        Modifier
            .backgroundColor(surfaceColor)
            .color(surfaceContentColor)
            .border(BorderSmall, LineStyle.Solid, surfaceColor.emphasize(0.05f, colorMode))
    }

    (hover + not(ariaDisabled)) {
        Modifier
            .backgroundColor(surfaceColor.emphasize(0.1f, colorMode))
            .cursor(Cursor.Pointer)
    }

    (focusVisible + not(ariaDisabled)) {
        Modifier.boxShadow(
            spreadRadius = 3.px,
            color = surfaceColor.emphasize(0.1f, colorMode)
        )
    }

    (active + not(ariaDisabled)) {
        Modifier.backgroundColor(surfaceColor.emphasize(0.18f, colorMode))
    }
}

val SurfaceSecondaryVariant by SurfaceStyle.addVariant {
    val surfaceColor = cometesColor.secondaryContainer
    val surfaceContentColor = cometesColor.onSecondaryContainer

    base {
        Modifier
            .backgroundColor(surfaceColor)
            .color(surfaceContentColor)
            .border(BorderSmall, LineStyle.Solid, surfaceColor.emphasize(0.05f, colorMode))
    }

    (hover + not(ariaDisabled)) {
        Modifier
            .backgroundColor(surfaceColor.emphasize(0.1f, colorMode))
            .cursor(Cursor.Pointer)
    }

    (focusVisible + not(ariaDisabled)) {
        Modifier.boxShadow(
            spreadRadius = 3.px,
            color = surfaceColor.emphasize(0.1f, colorMode)
        )
    }

    (active + not(ariaDisabled)) {
        Modifier.backgroundColor(surfaceColor.emphasize(0.18f, colorMode))
    }
}

val SurfaceErrorVariant by SurfaceStyle.addVariant {
    val surfaceColor = cometesColor.errorContainer
    val surfaceContentColor = cometesColor.onErrorContainer

    base {
        Modifier
            .backgroundColor(surfaceColor)
            .color(surfaceContentColor)
            .border(BorderSmall, LineStyle.Solid, surfaceColor.emphasize(0.05f, colorMode))
    }

    (hover + not(ariaDisabled)) {
        Modifier
            .backgroundColor(surfaceColor.emphasize(0.1f, colorMode))
            .cursor(Cursor.Pointer)
    }

    (focusVisible + not(ariaDisabled)) {
        Modifier.boxShadow(
            spreadRadius = 3.px,
            color = surfaceColor.emphasize(0.1f, colorMode)
        )
    }

    (active + not(ariaDisabled)) {
        Modifier.backgroundColor(surfaceColor.emphasize(0.18f, colorMode))
    }
}

val SurfacePositiveVariant by SurfaceStyle.addVariant {
    val surfaceColor = cometesColor.positiveContainer
    val surfaceContentColor = cometesColor.onPositiveContainer

    base {
        Modifier
            .backgroundColor(surfaceColor)
            .color(surfaceContentColor)
            .border(BorderSmall, LineStyle.Solid, surfaceColor.emphasize(0.05f, colorMode))
    }

    (hover + not(ariaDisabled)) {
        Modifier
            .backgroundColor(surfaceColor.emphasize(0.1f, colorMode))
            .cursor(Cursor.Pointer)
    }

    (focusVisible + not(ariaDisabled)) {
        Modifier.boxShadow(
            spreadRadius = 3.px,
            color = surfaceColor.emphasize(0.1f, colorMode)
        )
    }

    (active + not(ariaDisabled)) {
        Modifier.backgroundColor(surfaceColor.emphasize(0.18f, colorMode))
    }
}

val SurfaceVariantColorVariant by SurfaceStyle.addVariant {
    val surfaceColor = cometesColor.surfaceVariant
    val surfaceContentColor = cometesColor.onSurfaceVariant

    base {
        Modifier
            .backgroundColor(surfaceColor)
            .color(surfaceContentColor)
    }

    (hover + not(ariaDisabled)) {
        Modifier
            .backgroundColor(surfaceColor.emphasize(0.1f, colorMode))
            .cursor(Cursor.Pointer)
    }

    (focusVisible + not(ariaDisabled)) {
        Modifier.boxShadow(
            spreadRadius = 3.px,
            color = surfaceColor.emphasize(0.1f, colorMode)
        )
    }

    (active + not(ariaDisabled)) {
        Modifier.backgroundColor(surfaceColor.emphasize(0.18f, colorMode))
    }
}


@Composable
fun CometesSurface(
    modifier: Modifier = Modifier,
    variant: ComponentVariant? = null,
    onClick: ((SyntheticMouseEvent) -> Unit)? = null,
    enabled: Boolean = true,
    ref: ElementRefScope<HTMLElement>? = null,
    content: @Composable () -> Unit
) {
    val isClickable = onClick != null

    Box(
        modifier = SurfaceStyle.toModifier(variant)
            .ariaDisabled(!isClickable) // disable anyway if not clickable
            .thenIf(
                !enabled && isClickable,
                DisabledStyle.toModifier().tabIndex(-1)
            )
            .then(modifier)
            .thenIf(enabled && isClickable) {
                Modifier.onClick {
                    onClick?.invoke(it)
                }
            },
        ref = ref
    ) {
        content()
    }
}

// Non-clickable override
@Composable
fun CometesSurface(
    modifier: Modifier = Modifier,
    variant: ComponentVariant? = null,
    ref: ElementRefScope<HTMLElement>? = null,
    content: @Composable () -> Unit
) {
    CometesSurface(
        onClick = null,
        modifier = modifier,
        variant = variant,
        ref = ref,
        content = content,
    )
}