package cometes.components.image

import androidx.compose.runtime.Composable
import app.cometes.shared.frontend.feature.person.domain.model.Person
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.aspectRatio
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import cometes.components.common.DivText
import cometes.style.RadiusPill
import cometes.style.SpaceXSmall
import cometes.style.TypographyTitleSmall
import cometes.style.system.CometesSurface
import cometes.style.system.SurfaceSecondaryVariant
import org.jetbrains.compose.web.css.cssRem

@Composable
fun UserImage(person: Person, modifier: Modifier = Modifier) {
    CometesSurface(
        variant = SurfaceSecondaryVariant,
        modifier = Modifier
            .borderRadius(RadiusPill)
            .padding(SpaceXSmall)
            .size(2.5.cssRem)
            .aspectRatio(1)
            .then(modifier)
    ) {
        DivText(
            text = person.initials,
            modifier = Modifier.then(TypographyTitleSmall).align(Alignment.Center)
        )
    }
}