package cometes.components.layout

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.icons.mdi.IconStyle
import com.varabyte.kobweb.silk.components.icons.mdi.MdiArrowBack
import com.varabyte.kobweb.silk.components.icons.mdi.MdiBlock
import cometes.components.common.DivText
import cometes.components.common.HorizontalSpacer
import cometes.components.common.ThemeToggleButton
import cometes.components.common.VerticalSpacer
import cometes.style.SpaceMedium
import cometes.style.SpaceSmall
import cometes.style.SpaceXLarge
import cometes.style.TypographyHeadlineLarge
import cometes.style.system.CometesButton
import org.jetbrains.compose.web.css.cssRem

@Composable
fun PageNotFoundContent(modifier: Modifier = Modifier) {
    val pageContext = rememberPageContext()

    BaseContainer(modifier) {
        Row(Modifier.fillMaxWidth().padding(SpaceMedium)) {
            CometesButton(onClick = { pageContext.router.navigateTo("/") }) {
                MdiArrowBack()
                HorizontalSpacer(SpaceSmall)
                DivText("Back to homepage")
            }
            Spacer()
            ThemeToggleButton()
        }

        Column(
            horizontalAlignment = Alignment.CenterHorizontally,
            modifier = Modifier.align(Alignment.Center)
        ) {
            MdiBlock(modifier = Modifier.fontSize(16.cssRem), style = IconStyle.ROUNDED)
            VerticalSpacer(SpaceXLarge)
            DivText(
                text = "Nothing found :(",
                modifier = TypographyHeadlineLarge
            )
        }
    }
}