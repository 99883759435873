package app.cometes.shared.frontend.feature.reservation.presentatuion

import androidx.compose.runtime.*
import app.cash.molecule.RecompositionMode
import app.cash.molecule.moleculeFlow
import app.cometes.shared.frontend.base.ErrorResult
import app.cometes.shared.frontend.base.error.CommonError
import app.cometes.shared.frontend.base.resource.Resource
import app.cometes.shared.frontend.base.resource.isLoading
import app.cometes.shared.frontend.base.resource.loadResourceIfEmpty
import app.cometes.shared.frontend.feature.home.data.reservationDaysResource
import app.cometes.shared.frontend.feature.organization.data.currentOrganizationResource
import app.cometes.shared.frontend.feature.organization.domain.model.Organization
import app.cometes.shared.frontend.feature.person.data.currentPersonResource
import app.cometes.shared.frontend.feature.person.domain.model.Person
import app.cometes.shared.frontend.feature.reservation.domain.model.Location
import app.cometes.shared.frontend.feature.reservation.domain.model.Reservation
import app.cometes.shared.frontend.feature.reservation.domain.model.ReservationDay

@Immutable
data class ReservationOverviewState(
    val person: Person?,
    val organization: Organization?,
    val location: Location?,
    val days: List<ReservationDay>,
    val openedReservation: Reservation?,

    val isLoading: Boolean,
    val error: ErrorResult?,

    val onIntent: (intent: ReservationOverviewIntent) -> Unit,
)

sealed interface ReservationOverviewIntent {
    data object OnAppear : ReservationOverviewIntent
    class OpenReservationInfo(val reservation: Reservation) : ReservationOverviewIntent
    data object CloseReservationInfo : ReservationOverviewIntent
    data object RefreshData : ReservationOverviewIntent
    data object TryAgain : ReservationOverviewIntent
}


object ReservationsOverviewPresenter {

    @Composable
    fun presenter(): ReservationOverviewState {
        var openedReservation: Reservation? by remember { mutableStateOf(null) }

        val person = currentPersonResource()
        val organization = currentOrganizationResource()
        val reservationDays = loadResourceIfEmpty { reservationDaysResource() }

        val error = when {
            person is Resource.Error -> person.error
            reservationDays is Resource.Error -> reservationDays.error
            else -> null
        }

        return ReservationOverviewState(
            person = person.data,
            organization = organization.data,
            location = null, // TODO
            days = reservationDays.data ?: emptyList(),
            openedReservation = openedReservation,
            isLoading = person.isLoading || reservationDays.isLoading,
            error = error.takeIf { it !is CommonError.ResourceNotLoaded },
        ) { intent ->
            when (intent) {
                ReservationOverviewIntent.OnAppear -> reservationDays.reload(silent = true)
                ReservationOverviewIntent.CloseReservationInfo -> openedReservation = null
                is ReservationOverviewIntent.OpenReservationInfo -> openedReservation = intent.reservation
                ReservationOverviewIntent.RefreshData -> {
                    reservationDays.reload()
                }

                ReservationOverviewIntent.TryAgain -> {
                    if (person is Resource.Error) person.reload()
                    if (reservationDays is Resource.Error) reservationDays.reload()
                }
            }
        }
    }

    val state = moleculeFlow(RecompositionMode.Immediate) { presenter() }

    val emptyState = ReservationOverviewState(
        null, null, null, listOf(), null, false, null, {}
    )
}

