package app.cometes.shared.frontend.util

import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.UnhandledExceptionError
import app.cometes.shared.frontend.base.error.AuthError
import app.cometes.shared.frontend.configuration.data.source.AppInfoProvider
import app.cometes.shared.frontend.configuration.domain.Configuration
import app.cometes.shared.frontend.feature.auth.domain.LogOutUseCase
import co.touchlab.kermit.Logger
import kotlin.coroutines.cancellation.CancellationException
import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext
import org.koin.mp.KoinPlatformTools

@PublishedApi
internal val appInfoProvider by lazy {
    val koin = KoinPlatformTools.defaultContext().get()
    koin.get<AppInfoProvider>()
}

suspend inline fun <T> withUseCaseContext(
    dispatcher: CoroutineDispatcher = Dispatchers.Default,
    ignoreUserAuthError: Boolean = false,
    crossinline block: suspend CoroutineScope.() -> Result<T>
): Result<T> = withContext(dispatcher) {
    try {
        val res = block()

        val invalidAuth = res.getErrorOrNull() is AuthError.NoUserLoggedIn
        if (invalidAuth && !ignoreUserAuthError) {
            val logOut = KoinPlatformTools.defaultContext().get().get<LogOutUseCase>()
            logOut()
        }

        res
    } catch (e: Exception) {
        if (e is CancellationException) throw e

        Logger.e(e.message ?: "Unhandled Exception", e)
        if (appInfoProvider.config != Configuration.PreProd) {
            Result.Error(UnhandledExceptionError(e))
        } else {
            throw e
        }
    }
}