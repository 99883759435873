package app.cometes.shared.frontend

import app.cometes.shared.frontend.base.RepositoryScope
import app.cometes.shared.frontend.base.RepositoryScopeImpl
import app.cometes.shared.frontend.configuration.data.source.AppInfoProvider
import app.cometes.shared.frontend.feature.auth.featureAuth
import app.cometes.shared.frontend.feature.billing.featureBilling
import app.cometes.shared.frontend.feature.desk.featureDesk
import app.cometes.shared.frontend.feature.home.featureHome
import app.cometes.shared.frontend.feature.organization.featureOrganization
import app.cometes.shared.frontend.feature.person.featurePerson
import app.cometes.shared.frontend.feature.preferences.featurePreferences
import app.cometes.shared.frontend.feature.reservation.featureReservation
import app.cometes.shared.frontend.network.NetworkClient
import com.russhwolf.settings.Settings
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import org.koin.core.KoinApplication
import org.koin.core.context.startKoin
import org.koin.core.module.Module
import org.koin.dsl.KoinAppDeclaration
import org.koin.dsl.module

fun initKoin(appDeclaration: KoinAppDeclaration = {}): KoinApplication {
    val koinApp = startKoin {
        appDeclaration()
        modules(platformModule, commonModule)
        modules(features)
    }

    return koinApp
}

expect val platformModule: Module

private val commonModule = module {
    single { // Http client
        val infoProvider: AppInfoProvider = get()
        NetworkClient.Ktor.getClient(infoProvider.config, get())
    }

    // Settings
    single { Settings() }
    single<RepositoryScope> { RepositoryScopeImpl(Dispatchers.Default + SupervisorJob()) }
}

private val features = listOf(
    featureAuth,
    featureHome,
    featureReservation,
    featureBilling,
    featureDesk,
    featurePreferences,
    featurePerson,
    featureOrganization
)