package app.cometes.shared.frontend.feature.auth.data

import androidx.compose.runtime.Composable
import app.cometes.shared.frontend.base.resource.EmptyInputMutation
import app.cometes.shared.frontend.base.resource.EmptyMutation
import app.cometes.shared.frontend.base.resource.rememberInputMutation
import app.cometes.shared.frontend.base.resource.rememberMutation
import app.cometes.shared.frontend.feature.auth.domain.LogOutUseCase
import app.cometes.shared.frontend.util.withUseCaseContext
import org.koin.compose.koinInject


enum class AuthSsoType {
    Google, Apple
}

@Composable
fun signInEmailPasswordMutation(email: String, password: String): EmptyMutation {
    val firebaseSource = koinInject<FirebaseSource>()

    return rememberMutation(email, password) {
        withUseCaseContext { firebaseSource.signInUserUsingEmailAndPassword(email, password) }
    }
}

@Composable
fun signInSsoMutation(): EmptyInputMutation<AuthSsoType> {
    val firebaseSource = koinInject<FirebaseSource>()

    return rememberInputMutation { ssoType ->
        withUseCaseContext { firebaseSource.signInUsingSso(ssoType) }
    }
}

@Composable
fun logOutMutation(): EmptyMutation {
    val logOutUseCase = koinInject<LogOutUseCase>()
    return rememberMutation { logOutUseCase() }
}