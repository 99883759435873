package app.cometes.shared.frontend.feature.auth.presentation

import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.runtime.Stable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import app.cash.molecule.RecompositionMode
import app.cash.molecule.moleculeFlow
import app.cometes.shared.frontend.base.ErrorResult
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.resource.alsoOnError
import app.cometes.shared.frontend.feature.auth.data.confirmLoginLinkMutation
import app.cometes.shared.frontend.feature.auth.data.sendSignInEmailMutation
import app.cometes.shared.frontend.feature.auth.data.signInEmailPasswordMutation
import com.rickclephas.kmp.nativecoroutines.NativeCoroutines
import kotlinx.coroutines.launch

@Immutable
data class LoginState(
    val email: String,
    val password: String,

    val isLoading: Boolean,

    val onIntent: (LoginIntent) -> Unit
)

sealed interface LoginIntent {
    class SetEmail(val email: String) : LoginIntent
    class SetPassword(val password: String) : LoginIntent
    object SendEmailSignInLink : LoginIntent
    object SingInUsingEmailAndPassword : LoginIntent
    class ConfirmLink(val link: String) : LoginIntent
}

sealed interface LoginEvent {
    object OnEmailSent : LoginEvent
    class Error(val errorResult: ErrorResult) : LoginEvent
}

@Stable
class LoginPresenter(private val onEvent: (LoginEvent) -> Unit) {

    @Composable
    fun presenterState(): LoginState {
        val scope = rememberCoroutineScope()
        var email by remember { mutableStateOf("") }
        var password by remember { mutableStateOf("") }
        var isLoading by remember { mutableStateOf(false) }

        val sendEmail = sendSignInEmailMutation(email = email)
        val confirmEmail = confirmLoginLinkMutation()
        val signInEmailPassword = signInEmailPasswordMutation(email = email, password = password)

        return LoginState(
            email = email,
            password = password,
            isLoading = isLoading
        ) { intent ->
            when (intent) {
                is LoginIntent.SetEmail -> email = intent.email
                is LoginIntent.SetPassword -> password = intent.password
                LoginIntent.SendEmailSignInLink -> {
                    isLoading = true
                    scope.launch {
                        when (val res = sendEmail.execute()) {
                            is Result.Success -> onEvent(LoginEvent.OnEmailSent)
                            is Result.Error -> onEvent(LoginEvent.Error(res.error))
                        }
                        isLoading = false
                    }
                }

                LoginIntent.SingInUsingEmailAndPassword -> {
                    isLoading = true
                    scope.launch {
                        signInEmailPassword.execute()
                            .alsoOnError { error -> onEvent(LoginEvent.Error(error)) }
                        isLoading = false
                    }
                }

                is LoginIntent.ConfirmLink -> {
                    isLoading = true
                    scope.launch {
                        confirmEmail.execute(intent.link)
                            .alsoOnError { error -> onEvent(LoginEvent.Error(error)) }
                        isLoading = false
                    }
                }
            }
        }
    }

    @NativeCoroutines
    val state = moleculeFlow(RecompositionMode.Immediate) { presenterState() }
    val emptyState = LoginState(email = "", password = "", isLoading = false, onIntent = { })
}