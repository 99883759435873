package app.cometes.shared.frontend.feature.auth.domain

import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.resource.clearResourceCaches
import app.cometes.shared.frontend.base.usecase.UseCaseResultNoParams

interface ClearCacheUseCase : UseCaseResultNoParams<Unit>

internal class ClearCacheUseCaseImpl : ClearCacheUseCase {

    override suspend fun invoke(): Result<Unit> {
        clearResourceCaches()

        return Result.Success(Unit)
    }
}