package app.cometes.shared.frontend.feature.home.data

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import app.cometes.shared.feature.reservation.infrastructure.model.ReservationDayDto
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.map
import app.cometes.shared.frontend.base.resource.Resource
import app.cometes.shared.frontend.base.resource.map
import app.cometes.shared.frontend.base.resource.rememberUpdatedResourceState
import app.cometes.shared.frontend.base.resource.wrapEmptyResource
import app.cometes.shared.frontend.feature.organization.data.currentOrganizationResource
import app.cometes.shared.frontend.feature.person.data.currentPersonResource
import app.cometes.shared.frontend.feature.person.domain.model.Person
import app.cometes.shared.frontend.feature.reservation.data.source.ReservationDayLocalSource
import app.cometes.shared.frontend.feature.reservation.data.source.ReservationRemoteSource
import app.cometes.shared.frontend.feature.reservation.domain.model.ReservationDay
import app.cometes.shared.frontend.feature.reservation.infrastructure.model.toDomain
import app.cometes.shared.frontend.util.rememberKoinDependency
import app.cometes.shared.frontend.util.withUseCaseContext

@Composable
internal fun reservationDaysResource(): Resource<List<ReservationDay>> {
    val reservationDayLocalSource = rememberKoinDependency<ReservationDayLocalSource>()
    val reservationRemoteSource = rememberKoinDependency<ReservationRemoteSource>()

    val cachedReservationDays by remember {
        reservationDayLocalSource.getHomeReservationCacheFlow()
    }.collectAsState()

    val person = when (val res = currentPersonResource()) {
        is Resource.Value -> res.data
        is Resource.Loading -> res.data ?: return Resource.Loading(cachedReservationDays)
        is Resource.Error -> return Resource.Error(res.error, cachedReservationDays, res.onReload)
    }
    val organization = when (val res = currentOrganizationResource()) {
        is Resource.Value -> res.data
        is Resource.Loading -> res.data ?: return Resource.Loading(cachedReservationDays)
        is Resource.Error -> return Resource.Error(res.error, cachedReservationDays, res.onReload)
    }

    val reservationDaysResource = rememberUpdatedResourceState(cachedReservationDays) {
        withUseCaseContext { reservationRemoteSource.fetchHome(organization.id) }
            .map { days -> days.map(ReservationDayDto::toDomain) }
            .also { res ->
                if (res is Result.Success) reservationDayLocalSource.initCache(res.data)
            }
    }

    return wrapEmptyResource { reservationDaysResource.resource }
        .map { days -> days.sortedWithPerson(person) }
}

private fun List<ReservationDay>.sortedWithPerson(person: Person) = map { day ->
    val usersReservation = day.reservations
        .firstOrNull { it.person.id == person.id }

    day.copy(
        reservations = day.reservations.sortedWith { a, b ->
            when {
                a.id == usersReservation?.id -> -1
                b.id == usersReservation?.id -> 1
                else -> (a.desk.id - b.desk.id).toInt()
            }
        }
    )
}