package app.cometes.shared.frontend.feature.auth.data

import app.cometes.shared.frontend.base.RepositoryScope
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.error.AuthError
import app.cometes.shared.frontend.configuration.data.source.AppInfoProvider
import app.cometes.shared.frontend.configuration.domain.Configuration
import app.cometes.shared.frontend.feature.auth.domain.AuthRepository
import app.cometes.shared.frontend.feature.organization.data.source.OrganizationLocalSource
import app.cometes.shared.frontend.feature.person.data.source.PersonLocalSource
import com.russhwolf.settings.Settings
import com.russhwolf.settings.get
import com.russhwolf.settings.set
import kotlinx.coroutines.channels.awaitClose
import kotlinx.coroutines.flow.SharingStarted
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.callbackFlow
import kotlinx.coroutines.flow.stateIn
import org.koin.core.component.KoinComponent
import org.koin.core.component.get

class AuthRepositoryImpl(
    private val source: FirebaseSource,
    private val settings: Settings,
    private val appInfoProvider: AppInfoProvider,
    repositoryScope: RepositoryScope
) : AuthRepository, KoinComponent {
    private val EmailCacheKey = "EMAIL_MAGIC_CACHE"
    override val isUserLoggedInFlow: StateFlow<Boolean> = callbackFlow {
        source.addAuthListener(::trySend)
        awaitClose { }
    }.stateIn(repositoryScope, SharingStarted.Eagerly, source.isLoggedIn)

    override suspend fun signInUsingEmailAndPassword(
        email: String,
        password: String
    ): Result<Unit> =
        source.signInUserUsingEmailAndPassword(email, password)

    override suspend fun sendSignInEmail(email: String): Result<Unit> {
        settings[EmailCacheKey] = email
        val (androidPackage, iosBundle) = when (appInfoProvider.config) {
            Configuration.Debug -> "app.cometes.android.debug" to "app.cometes.ios"
            Configuration.PreProd -> "app.cometes.android.beta" to "app.cometes.ios"
            Configuration.Release -> "app.cometes.android" to "app.cometes.ios"
        }
        val settings = EmailSignInSettings(
            "https://cometes.app/auth",
            iosBundle,
            androidPackage
        )

        return source.sendSignInEmail(email, settings)
    }

    override suspend fun confirmLogin(emailLink: String): Result<Unit> {
        val cachedEmail: String = settings[EmailCacheKey]
            ?: return Result.Error(AuthError.MagicEmailLoginNotStarted)

        return source.confirmLogin(cachedEmail, emailLink)
    }

    override suspend fun logOut(): Result<Unit> {
        val firebaseLogoutResult = source.logOutUser()
        if (firebaseLogoutResult is Result.Error)
            return firebaseLogoutResult

        settings.clear()
        get<OrganizationLocalSource>().clear()
        get<PersonLocalSource>().clear()
        return Result.Success(Unit)
    }
}

data class EmailSignInSettings(
    val url: String,
    val iosBundle: String,
    val androidPackageName: String
)