package cometes.style.system.input

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Resize
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.resize
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.ComponentVariant
import com.varabyte.kobweb.silk.components.style.addVariant
import com.varabyte.kobweb.silk.components.style.focus
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.components.style.toModifier
import cometes.style.RadiusSmall
import cometes.style.SpaceSmall
import cometes.style.TypographyTitleMedium
import cometes.style.cometesColor
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.cols
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.attributes.rows
import org.jetbrains.compose.web.css.AnimationTimingFunction
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.s
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.TextArea

private val BorderWidth = 2.px
private val BorderRadius = RadiusSmall

val CometesTextFieldStyle by ComponentStyle {
    val inactiveBorderColor = cometesColor.outline
    val activeBorderColor = cometesColor.primary
    val backgroundColor = cometesColor.background
    val textColor = cometesColor.onBackground

    base {
        Modifier
            .withoutInputDefaults()
            // Border
            .border(BorderWidth, style = LineStyle.Solid, color = inactiveBorderColor)
            .borderRadius(BorderRadius)
            // Text
            .then(TypographyTitleMedium)
            // Color
            .color(textColor)
            .backgroundColor(backgroundColor)
            // Sizing
            .padding(SpaceSmall)
            // Transition
            .transition(CSSTransition("border-color", 0.25.s, AnimationTimingFunction.Linear))
    }

    hover { Modifier.cursor(Cursor.Text) }

    focus {
        Modifier.border(BorderWidth, style = LineStyle.Solid, color = activeBorderColor)
    }
}

val CometesTextFieldPlainVariant by CometesTextFieldStyle.addVariant {
    base { Modifier.border(0.px) }
    focus { Modifier.border(0.px) }
}

@Composable
fun CometesTextField(
    value: String,
    onValueChange: (String) -> Unit,
    modifier: Modifier = Modifier,
    type: InputType<String> = InputType.Text,
    placeholder: String? = null,
    variant: ComponentVariant? = null
) {
    Input(
        type = type,
        attrs = CometesTextFieldStyle.toModifier(variant)
            .then(modifier)
            .toAttrs {
                value(value)
                onInput { onValueChange(it.value) }
                if (placeholder != null)
                    placeholder(placeholder)
            }
    )
}

@Composable
fun CometesTextArea(
    value: String,
    onValueChange: (String) -> Unit,
    modifier: Modifier = Modifier,
    resizable: Boolean = false,
    rows: Int = 5,
    columns: Int = 50,
    placeholder: String? = null
) {
    TextArea(
        attrs = CometesTextFieldStyle.toModifier()
            .resize(if (resizable) Resize.Both else Resize.None)
            .then(modifier)
            .toAttrs {
                rows(rows)
                cols(columns)
                value(value)
                onInput { onValueChange(it.value) }
                if (placeholder != null)
                    placeholder(placeholder)
            }
    )
}

private fun Modifier.withoutInputDefaults() = styleModifier {
    property("border", "none")
    property("outline", "none")
}