package app.cometes.shared.feature.person.infrastructure.model

import app.cometes.shared.feature.organization.infrastructure.model.OrganizationDto
import app.cometes.shared.feature.organization.infrastructure.model.OrganizationMemberDto
import kotlinx.serialization.Serializable

@Serializable
data class OrganizationWithMembershipDto(
    val organization: OrganizationDto,
    val membership: OrganizationMemberDto,
)