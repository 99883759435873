package app.cometes.shared.frontend.feature.organization.infrastructure.remote

import app.cometes.shared.feature.organization.infrastructure.model.ClaimInvitationBody
import app.cometes.shared.feature.organization.infrastructure.model.CreateInvitationBody
import app.cometes.shared.feature.organization.infrastructure.model.OrganizationDto
import app.cometes.shared.feature.organization.infrastructure.model.OrganizationInvitationDto
import app.cometes.shared.frontend.base.EmptyResult
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.error.BackendException
import app.cometes.shared.frontend.base.error.runCatchingNetworkExceptions
import app.cometes.shared.frontend.feature.organization.data.source.OrganizationInvitationRemoteSource
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationError
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.delete
import io.ktor.client.request.get
import io.ktor.client.request.post
import io.ktor.client.request.setBody

class OrganizationInvitationRemoteSourceImpl(
    private val httpClient: HttpClient
) : OrganizationInvitationRemoteSource {
    override suspend fun createInvitation(
        organizationId: Long,
        inviteeEmail: String
    ): Result<OrganizationInvitationDto> = runCatchingNetworkExceptions {
        val response = httpClient.post("/organization/${organizationId}/invitation") {
            setBody(CreateInvitationBody(targetEmail = inviteeEmail))
        }

        Result.Success(response.body())
    }

    override suspend fun getOrganizationForInvitation(invitationToken: String): Result<OrganizationDto> =
        runCatchingInvitationExceptions {
            val response = httpClient.get("/organization/invitation/info/$invitationToken")

            Result.Success(response.body())
        }

    override suspend fun claimInvitation(token: String): Result<OrganizationDto> =
        runCatchingInvitationExceptions {
            val response = httpClient.post("/organization/invitation") {
                setBody(ClaimInvitationBody(token = token))
            }

            Result.Success(response.body())
        }

    override suspend fun getUnclaimedInvitations(
        organizationId: Long
    ): Result<List<OrganizationInvitationDto>> = runCatchingNetworkExceptions {
        val response = httpClient.get("/organization/${organizationId}/invitation")
        Result.Success(response.body())
    }

    override suspend fun removeInvitation(organizationId: Long, invitationId: Long): EmptyResult =
        runCatchingNetworkExceptions {
            httpClient.delete("/organization/${organizationId}/invitation/$invitationId")
            Result.Success(Unit)
        }

}

private suspend inline fun <T : Any> runCatchingInvitationExceptions(
    body: () -> Result<T>
): Result<T> = try {
    runCatchingNetworkExceptions { body() }
} catch (e: BackendException) {
    when (e.error.type) {
        "InvitationNotFound" -> Result.Error(OrganizationError.InvitationNotFound(e))
        "InvitationAlreadyClaimed" -> Result.Error(OrganizationError.InvitationAlreadyClaimed(e))
        "InvitationExpired" -> Result.Error(OrganizationError.InvitationExpired(e))
        else -> throw e
    }
}