package app.cometes.shared.frontend.feature.reservation.domain.model

import androidx.compose.runtime.Immutable
import app.cometes.shared.frontend.base.Identifiable

@Immutable
data class Desk(
    override val id: Long,
    val name: String,
    val locationId: Long
) : Identifiable