import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobalsLocal
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.silk.components.animation.registerKeyframes
import com.varabyte.kobweb.silk.defer.renderWithDeferred
import kotlin.Unit
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main(): Unit {
    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { cometes.pages.HomePage() }
        ctx.router.register("/account/") { cometes.pages.account.AccountPage() }
        ctx.router.register("/auth") { cometes.pages.AuthPage() }
        ctx.router.register("/privacymobile") { cometes.pages.PrivacymobilePage() }
        ctx.router.register("/support") { cometes.pages.SupportPage() }
        ctx.router.register("/termsconditions") { cometes.pages.TermsconditionsPage() }

    }
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.initSilkHook = { ctx ->
        ctx.theme.registerComponentStyle(cometes.style.ShadowStyle)
        ctx.theme.registerComponentStyle(cometes.style.system.input.CometesTextFieldStyle)
        ctx.theme.registerComponentStyle(cometes.style.system.SurfaceStyle)
        ctx.theme.registerComponentStyle(cometes.components.layout.FlexCardStyle)
        ctx.theme.registerComponentStyle(cometes.components.layout.LegalInfoStyle)
        ctx.theme.registerComponentStyle(cometes.components.layout.UtilRowStyle)
        ctx.theme.registerComponentStyle(cometes.components.common.VerticalScrollableStyle)
        ctx.theme.registerComponentStyle(cometes.components.feature.organization.subscription.ImageUploadStyle)
        ctx.theme.registerComponentVariants(cometes.style.system.input.CometesTextFieldPlainVariant)
        ctx.theme.registerComponentVariants(cometes.style.system.SurfaceOutlineVariant)
        ctx.theme.registerComponentVariants(cometes.style.system.SurfaceBackgroundColorVariant)
        ctx.theme.registerComponentVariants(cometes.style.system.SurfacePrimaryVariant)
        ctx.theme.registerComponentVariants(cometes.style.system.SurfaceSecondaryVariant)
        ctx.theme.registerComponentVariants(cometes.style.system.SurfaceErrorVariant)
        ctx.theme.registerComponentVariants(cometes.style.system.SurfacePositiveVariant)
        ctx.theme.registerComponentVariants(cometes.style.system.SurfaceVariantColorVariant)
        ctx.theme.registerComponentVariants(cometes.components.common.SurfaceVerticalScrollableVariant)
        ctx.stylesheet.registerKeyframes(cometes.style.system.animation.AnimationFadeIn)
        initSiteStyles(ctx)
    }

    router.navigateTo(window.location.href.removePrefix(window.location.origin),
            UpdateHistoryMode.REPLACE)

    // For SEO, we may bake the contents of a page in at build time. However, we will overwrite them
    // the first time we render this page with their composable, dynamic versions. Think of this as
    // poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    val root = document.getElementById("root")!!
    while (root.firstChild != null) {
        root.removeChild(root.firstChild!!)
    }

    renderComposable(rootElementId = "root") {
        CompositionLocalProvider(
            AppGlobalsLocal provides mapOf("title" to "Cometes")
        ) { MyApp {
              router.renderActivePage { renderWithDeferred { it() } }
        } }
    }
}
