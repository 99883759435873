package app.cometes.shared.frontend.feature.billing

import app.cometes.shared.frontend.feature.billing.data.BillingRemoteSource
import app.cometes.shared.frontend.feature.billing.infrasctructure.BillingRemoteSourceImpl
import org.koin.core.module.dsl.singleOf
import org.koin.dsl.bind
import org.koin.dsl.module

val featureBilling = module {
    singleOf(::BillingRemoteSourceImpl) bind BillingRemoteSource::class
}