package cometes.components.common

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.RowScope
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.silk.components.icons.fa.FaMoon
import com.varabyte.kobweb.silk.components.icons.fa.FaSun
import com.varabyte.kobweb.silk.components.style.ComponentVariant
import com.varabyte.kobweb.silk.theme.colors.rememberColorMode
import cometes.style.system.CometesButton
import cometes.style.system.CometesImageButton
import cometes.style.system.SurfaceOutlineVariant
import cometes.style.system.SurfacePrimaryVariant
import cometes.style.system.animation.fadeInTransition

@Composable
fun ThemeToggleButton(modifier: Modifier = Modifier) {
    var colorMode by rememberColorMode()

    CometesImageButton(
        onClick = { colorMode = colorMode.opposite() },
        modifier = modifier,
        variant = SurfaceOutlineVariant,
    ) {
        if (colorMode.isLight()) FaSun() else FaMoon()
    }
}

@Composable
fun CometesLoadingButton(
    isLoading: Boolean,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    enabled: Boolean = !isLoading,
    variant: ComponentVariant? = SurfacePrimaryVariant,
    content: @Composable RowScope.() -> Unit
) {
    CometesButton(onClick, modifier, enabled, variant) {
        Box(contentAlignment = Alignment.Center) {
            Row(
                horizontalArrangement = Arrangement.Center,
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.fillMaxWidth(),
                content = content
            )
            if (isLoading) ProgressIndicator(Modifier.fadeInTransition())
        }
    }
}