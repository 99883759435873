package app.cometes.shared.frontend.feature.organization.presentation

import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.runtime.Stable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import app.cometes.shared.frontend.base.ErrorResult
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.resource.Resource
import app.cometes.shared.frontend.base.resource.alsoOnError
import app.cometes.shared.frontend.base.resource.isLoading
import app.cometes.shared.frontend.base.resource.loadResourceIfEmpty
import app.cometes.shared.frontend.feature.auth.data.logOutMutation
import app.cometes.shared.frontend.feature.organization.data.acceptInvitationMutation
import app.cometes.shared.frontend.feature.organization.data.currentPersonPendingInvitationResource
import app.cometes.shared.frontend.feature.organization.data.setCurrentOrganizationMutation
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationInvitationPreview
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationWithMembership
import app.cometes.shared.frontend.feature.organization.presentation.OrganizationSelectionIntent.AcceptInvitation
import app.cometes.shared.frontend.feature.organization.presentation.OrganizationSelectionIntent.LogOut
import app.cometes.shared.frontend.feature.organization.presentation.OrganizationSelectionIntent.SelectOrganization
import app.cometes.shared.frontend.feature.organization.presentation.OrganizationSelectionIntent.TryAgain
import app.cometes.shared.frontend.feature.person.data.personsOrganizationsResource
import kotlinx.coroutines.launch

@Immutable
data class OrganizationSelectionState(
    val organizations: List<OrganizationWithMembership>,
    val invitations: List<OrganizationInvitationPreview>,

    val acceptationInProgress: Boolean,
    val isLoading: Boolean,
    val error: ErrorResult?,

    val onIntent: (OrganizationSelectionIntent) -> Unit
)

sealed interface OrganizationSelectionIntent {
    class SelectOrganization(val organization: OrganizationWithMembership) : OrganizationSelectionIntent
    data class AcceptInvitation(val invitationToken: String) : OrganizationSelectionIntent
    data object TryAgain : OrganizationSelectionIntent
    data object LogOut : OrganizationSelectionIntent
}

sealed interface OrganizationSelectionEvent {
    class Error(val errorResult: ErrorResult) : OrganizationSelectionEvent
}

@Stable
class OrganizationSelectionPresenter(
    private val onEvent: (OrganizationSelectionEvent) -> Unit
) {

    @Composable
    fun presenterState(): OrganizationSelectionState {
        val scope = rememberCoroutineScope()
        var acceptationInProgress by remember { mutableStateOf(false) }

        // Resources
        val organizationMembershipsResource = loadResourceIfEmpty { personsOrganizationsResource() }
        val pendingInvitationsResource = loadResourceIfEmpty { currentPersonPendingInvitationResource() }

        // Mutations
        val logOutMutation = logOutMutation()
        val setCurrentOrganizationMutation = setCurrentOrganizationMutation()
        val acceptInvitationMutation = acceptInvitationMutation()

        val isLoading = organizationMembershipsResource.isLoading || pendingInvitationsResource.isLoading
        val error = when {
            organizationMembershipsResource is Resource.Error -> organizationMembershipsResource.error
            pendingInvitationsResource is Resource.Error -> pendingInvitationsResource.error
            else -> null
        }

        return OrganizationSelectionState(
            organizations = organizationMembershipsResource.data ?: emptyList(),
            invitations = pendingInvitationsResource.data ?: emptyList(),
            acceptationInProgress = acceptationInProgress,
            isLoading = isLoading,
            error = error
        ) { intent ->
            when (intent) {
                is SelectOrganization -> {
                    scope.launch {
                        setCurrentOrganizationMutation.execute(intent.organization)
                            .alsoOnError { onEvent(OrganizationSelectionEvent.Error(it)) }
                    }
                }

                is AcceptInvitation -> {
                    scope.launch {
                        acceptationInProgress = true
                        val organization = when (val res = acceptInvitationMutation.execute(intent.invitationToken)) {
                            is Result.Success -> res.data
                            is Result.Error -> {
                                onEvent(OrganizationSelectionEvent.Error(res.error))
                                acceptationInProgress = false
                                return@launch
                            }
                        }

                        setCurrentOrganizationMutation.execute(organization)
                            .alsoOnError { onEvent(OrganizationSelectionEvent.Error(it)) }

                        acceptationInProgress = false
                    }
                }

                LogOut -> {
                    scope.launch {
                        logOutMutation.execute()
                            .alsoOnError { error ->
                                onEvent(OrganizationSelectionEvent.Error(error))
                            }
                    }
                }

                TryAgain -> {
                    if (organizationMembershipsResource is Resource.Error) organizationMembershipsResource.reload()
                    if (pendingInvitationsResource is Resource.Error) pendingInvitationsResource.reload()
                }
            }
        }
    }
}