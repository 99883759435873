import app.cometes.shared.frontend.feature.auth.data.FirebaseSource
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.FirebaseApp
import dev.gitlive.firebase.FirebaseOptions
import dev.gitlive.firebase.initialize
import infrastructure.source.FirebaseSourceImpl
import org.koin.core.module.dsl.singleOf
import org.koin.dsl.bind
import org.koin.dsl.module

val webModule = module {
    singleOf(::FirebaseSourceImpl) bind FirebaseSource::class
    single<FirebaseApp> {
        Firebase.initialize(
            null,
            FirebaseOptions(
                apiKey = "AIzaSyD2MgKTA_dtkuP0UjnO94XWrTCZvsIvhrI",
                authDomain = "auth.cometes.app",
                projectId = "cometes-app",
                storageBucket = "cometes-app.appspot.com",
                applicationId = "1:373956404221:web:04c5ee604c8c99b4546f94",
                databaseUrl = null,
                gaTrackingId = "G-0V3TG39FLQ",
                gcmSenderId = "373956404221",
            )
        )
    }
}