package cometes.components.feature.organization.subscription

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import app.cometes.shared.frontend.feature.organization.data.setOrganizationImageMutation
import app.cometes.shared.frontend.network.UploadFile
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.fa.FaSitemap
import com.varabyte.kobweb.silk.components.icons.fa.FaUpload
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.components.style.toModifier
import cometes.components.common.ProgressIndicator
import cometes.components.common.RoundedImage
import cometes.style.cometesColor
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.accept
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.s
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Label

val ImageUploadStyle by ComponentStyle {
    val backgroundColor = cometesColor.surface
    base {
        Modifier
            .backgroundColor(backgroundColor)
            .transition(CSSTransition("opacity", duration = 0.3.s))
            .opacity(0f)
    }
    hover {
        Modifier
            .opacity(0.6f)
            .cursor(Cursor.Pointer)
    }
}

@Composable
fun ImageUploadInput(
    onInput: (UploadFile) -> Unit,
    modifier: Modifier = Modifier,
    content: @Composable () -> Unit,
) {
    Input(type = InputType.File) {
        id("upload")
        hidden()
        accept("image/*")
        onInput {
            val files = it.target.files
            if (files != null && files.length == 1) {
                val file = files.item(0)!!
                onInput(UploadFile(file))
            }
        }
    }

    Label(
        forId = "upload",
        attrs = modifier.toAttrs()
    ) {
        Box(contentAlignment = Alignment.Center) {
            content()
            Box(
                contentAlignment = Alignment.Center,
                modifier = ImageUploadStyle.toModifier().fillMaxSize()
            ) {
                FaUpload()
            }
        }
    }
}

@Composable
fun OrganizationImageWithUpload(
    imageUrl: String?,
    modifier: Modifier = Modifier
) {
    val coroutineScope = rememberCoroutineScope()
    val uploadImage = setOrganizationImageMutation()
    var isLoading by remember { mutableStateOf(false) }

    fun uploadImage(file: UploadFile) {
        coroutineScope.launch {
            try {
                isLoading = true
                uploadImage.execute(file)
            } finally {
                isLoading = false
            }
        }
    }

    ImageUploadInput(
        onInput = { file -> uploadImage(file) },
        modifier = modifier
    ) {
        val size = 48.px
        if (isLoading) {
            Box(
                contentAlignment = Alignment.Center,
                modifier = Modifier.size(size)
            ) {
                ProgressIndicator()
            }
        } else {
            if (imageUrl != null) {
                RoundedImage(
                    imageUrl = imageUrl,
                    size = size
                )
            } else {
                FaSitemap(size = IconSize.LG)
            }
        }
    }
}