package cometes.components.feature.organization.subscription

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.feature.billing.domain.model.SubscriptionStatus
import app.cometes.shared.frontend.feature.organization.data.updateCurrentOrganizationMutation
import app.cometes.shared.frontend.feature.organization.domain.model.Organization
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationMemberRole
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.silk.components.icons.fa.FaCircleCheck
import com.varabyte.kobweb.silk.components.icons.fa.FaCircleExclamation
import com.varabyte.kobweb.silk.components.icons.fa.FaCreditCard
import com.varabyte.kobweb.silk.components.icons.fa.FaPen
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.text.DivText
import com.varabyte.kobweb.silk.components.text.SpanText
import cometes.components.common.CometesCard
import cometes.components.common.CometesCardHeader
import cometes.components.common.CometesDialog
import cometes.components.common.CometesLabelLarge
import cometes.components.common.CometesLoadingButton
import cometes.components.common.HorizontalSpacer
import cometes.components.common.LabeledRow
import cometes.components.common.VerticalSpacer
import cometes.style.CometesTheme
import cometes.style.SpaceLarge
import cometes.style.SpaceMedium
import cometes.style.SpaceSmall
import cometes.style.SpaceXSmall
import cometes.style.TypographyBodyLarge
import cometes.style.TypographyHeadlineSmall
import cometes.style.TypographyTitleMedium
import cometes.style.system.CometesButton
import cometes.style.system.CometesImageButton
import cometes.style.system.SurfaceBackgroundColorVariant
import cometes.style.system.SurfaceErrorVariant
import cometes.style.system.SurfacePrimaryVariant
import cometes.style.system.input.CometesTextArea
import cometes.style.system.input.CometesTextField
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.px

@Composable
fun OrganizationInfoCard(
    organization: Organization,
    memberRole: OrganizationMemberRole,
    subscriptionState: SubscriptionStatus?,
    modifier: Modifier = Modifier
) {
    var isOrganizationEditDialogVisible: Boolean by remember { mutableStateOf(false) }

    CometesCard(modifier) {
        Column(Modifier.fillMaxWidth()) {
            OrganizationInfoColumn(
                organization = organization,
                isOwner = memberRole == OrganizationMemberRole.Owner,
                onEditOrganizationClick = { isOrganizationEditDialogVisible = true },
                modifier = Modifier.fillMaxWidth()
            )

            if (subscriptionState != null) {
                VerticalSpacer(SpaceLarge)
                SubscriptionInfoColumn(
                    organizationName = organization.name,
                    subscriptionState = subscriptionState,
                    modifier = Modifier.fillMaxWidth()
                )
            }
        }
    }

    if (isOrganizationEditDialogVisible) {
        OrganizationEditDialog(
            organization = organization,
            onDismiss = { isOrganizationEditDialogVisible = false }
        )
    }
}

@Composable
private fun OrganizationInfoColumn(
    organization: Organization,
    isOwner: Boolean,
    onEditOrganizationClick: () -> Unit,
    modifier: Modifier = Modifier
) {
    Column(modifier) {
        CometesCardHeader(
            "Info",
            leadingItem = {
                OrganizationImageWithUpload(organization.imageUrl)
            },
            trailingItem = {
                if (isOwner) CometesImageButton(
                    onClick = onEditOrganizationClick,
                    variant = SurfacePrimaryVariant
                ) { FaPen() }
            }
        )

        DivText(organization.name, TypographyTitleMedium)
        VerticalSpacer(SpaceXSmall)
        DivText(
            text = organization.description,
            modifier = Modifier
                .maxWidth(400.px)
                .then(TypographyBodyLarge)
        )
    }
}

@Composable
private fun SubscriptionInfoColumn(
    organizationName: String,
    subscriptionState: SubscriptionStatus,
    modifier: Modifier = Modifier
) {
    Column(modifier) {
        val title = when (subscriptionState.subscriptionStatus) {
            SubscriptionStatus.Status.Active -> "Subscription"
            SubscriptionStatus.Status.Inactive -> "No active subscription"
        }

        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.fillMaxWidth()
        ) {
            FaCreditCard()
            HorizontalSpacer(SpaceSmall)
            DivText(
                text = title,
                modifier = Modifier.then(TypographyHeadlineSmall)
            )

            if (subscriptionState.isInTrial) {
                HorizontalSpacer(SpaceSmall)
                CometesLabelLarge("Trial")
            } else if (subscriptionState.wilCancelAtPeriodEnd) {
                HorizontalSpacer(SpaceSmall)
                CometesLabelLarge("Cancelled", variant = SurfaceErrorVariant)
            }

            Spacer()

            when (subscriptionState.subscriptionStatus) {
                SubscriptionStatus.Status.Active -> FaCircleCheck(
                    Modifier.color(CometesTheme.color.positive),
                    size = IconSize.XL
                )

                SubscriptionStatus.Status.Inactive -> FaCircleExclamation(
                    Modifier.color(CometesTheme.color.error),
                    size = IconSize.XL
                )
            }
        }


        VerticalSpacer(SpaceMedium)

        Column(
            modifier = Modifier.fillMaxWidth(),
        ) {
            when (subscriptionState.subscriptionStatus) {
                SubscriptionStatus.Status.Active -> {
                    ActiveSubscriptionContent(
                        subscriptionState = subscriptionState,
                        modifier = Modifier.fillMaxWidth()
                    )
                }

                SubscriptionStatus.Status.Inactive -> {
                    InactiveSubscriptionContent(
                        organizationName = organizationName,
                        modifier = Modifier.fillMaxWidth()
                    )
                }
            }
        }
    }
}

@Composable
private fun OrganizationEditDialog(
    organization: Organization,
    onDismiss: () -> Unit,
) {
    val coroutineScope = rememberCoroutineScope()
    var isLoading: Boolean by remember { mutableStateOf(false) }

    var organizationName: String by remember { mutableStateOf(organization.name) }
    var organizationDescription: String by remember { mutableStateOf(organization.description) }

    val updateMutation = updateCurrentOrganizationMutation(
        organizationName,
        organizationDescription
    )

    fun onUpdateClick() {
        coroutineScope.launch {
            isLoading = true
            val res = updateMutation.execute()
            isLoading = false
            if (res is Result.Success) onDismiss()
        }
    }

    CometesDialog(
        title = organization.name,
        onDismiss = onDismiss,
        submitButtons = {
            CometesButton(
                onDismiss,
                variant = SurfaceBackgroundColorVariant
            ) { SpanText("Cancel") }
            HorizontalSpacer(SpaceMedium)
            CometesLoadingButton(
                isLoading = isLoading,
                onClick = { onUpdateClick() },
                enabled = organizationName.isNotBlank(),
                variant = SurfacePrimaryVariant
            ) {
                SpanText("Update")
            }
        }
    ) {
        Column(
            Modifier
                .fillMaxWidth()
                .then(TypographyBodyLarge)
        ) {
            LabeledRow("Name", Modifier.fillMaxWidth()) {
                CometesTextField(
                    value = organizationName,
                    onValueChange = { organizationName = it }
                )
            }

            VerticalSpacer(SpaceMedium)

            DivText("Description")
            VerticalSpacer(SpaceXSmall)
            CometesTextArea(
                value = organizationDescription,
                onValueChange = { organizationDescription = it },
                modifier = Modifier
                    .fillMaxWidth()
                    .then(TypographyBodyLarge)
            )
        }
    }
}