package app.cometes.shared.frontend.feature.organization.domain.usecase

import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.usecase.UseCaseResult
import app.cometes.shared.frontend.feature.auth.domain.ClearCacheUseCase
import app.cometes.shared.frontend.feature.organization.domain.model.Organization
import app.cometes.shared.frontend.feature.organization.domain.repository.OrganizationRepository

interface SetCurrentOrganizationUseCase : UseCaseResult<Organization?, Unit>

internal class SetCurrentOrganizationUseCaseImpl(
    private val organizationRepository: OrganizationRepository,
    private val clearCache: ClearCacheUseCase,
) : SetCurrentOrganizationUseCase {
    override suspend fun invoke(params: Organization?): Result<Unit> {
        clearCache()
        organizationRepository.setCurrentOrganization(params)

        return Result.Success(Unit)
    }
}