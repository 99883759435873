package app.cometes.shared.frontend.feature.organization.data

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.rememberUpdatedState
import app.cometes.shared.feature.organization.infrastructure.model.OrganizationDetailDto
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.map
import app.cometes.shared.frontend.base.mapToEmpty
import app.cometes.shared.frontend.base.resource.EmptyInputMutation
import app.cometes.shared.frontend.base.resource.EmptyMutation
import app.cometes.shared.frontend.base.resource.Resource
import app.cometes.shared.frontend.base.resource.alsoOnSuccess
import app.cometes.shared.frontend.base.resource.rememberInputMutation
import app.cometes.shared.frontend.base.resource.rememberMutation
import app.cometes.shared.frontend.base.resource.rememberUpdatedResourceState
import app.cometes.shared.frontend.base.resource.resourceCache
import app.cometes.shared.frontend.base.resource.wrapEmptyResource
import app.cometes.shared.frontend.feature.conferenceRoom.domain.conferenceRoomsCache
import app.cometes.shared.frontend.feature.desk.data.desksCache
import app.cometes.shared.frontend.feature.organization.data.source.OrganizationLocalSource
import app.cometes.shared.frontend.feature.organization.data.source.OrganizationRemoteSource
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationDetail
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationError
import app.cometes.shared.frontend.feature.organization.infrastructure.model.toDomain
import app.cometes.shared.frontend.network.UploadFile
import app.cometes.shared.frontend.util.withUseCaseContext
import org.koin.compose.koinInject

@Composable
fun currentOrganizationDetailCache(organizationId: Long) = resourceCache<OrganizationDetail?>(
    key = "org${organizationId}:OrganizationDetail",
    initialValue = null
)

@Composable
fun currentOrganizationDetailResource(): Resource<OrganizationDetail> {
    val organization = currentOrganization() ?: return noCurrentOrganizationResourceError()
    val organizationId by rememberUpdatedState(organization.id)

    val organizationRemoteSource = koinInject<OrganizationRemoteSource>()
    val organizationLocalSource = koinInject<OrganizationLocalSource>()

    val organizationDetailCache = currentOrganizationDetailCache(organizationId)
    val memberCache = currentOrganizationMembersCache(organizationId)
    val desksCache = desksCache(organizationId)
    val roomsCache = conferenceRoomsCache(organizationId)
    val locationsCache = currentOrganizationLocationListCache(organizationId)
    val invitationsCache = currentOrganizationInvitationsCache(organizationId)

    val localOrganizationDetail by organizationDetailCache.collectAsState()
    val resourceState = rememberUpdatedResourceState(localOrganizationDetail) {
        withUseCaseContext { organizationRemoteSource.getOrganizationDetail(organizationId) }
            .alsoOnSuccess { response ->
                if (organizationId == response.organization.id) {
                    organizationLocalSource.setOrganization(response.organization)
                }
            }
            .map(OrganizationDetailDto::toDomain)
            .alsoOnSuccess { detail ->
                if (organizationId != detail.organization.id) return@alsoOnSuccess
                organizationDetailCache.value = detail
                memberCache.setCache(detail.members)
                desksCache.setCache(detail.desks)
                roomsCache.setCache(detail.rooms)
                locationsCache.setCache(detail.locations)
                invitationsCache.setCache(detail.invitations)
            }
    }

    return wrapEmptyResource { resourceState.resource }
}

@Composable
fun updateCurrentOrganizationMutation(name: String, description: String): EmptyMutation {
    val remoteSource = koinInject<OrganizationRemoteSource>()
    val localSource = koinInject<OrganizationLocalSource>()

    val organization = currentOrganization()
    return rememberMutation(organization?.id, name, description) {
        val organizationId = organization?.id
            ?: return@rememberMutation Result.Error(OrganizationError.NoCurrentOrganization)

        remoteSource.updateOrganization(organizationId, name, description)
            .alsoOnSuccess { updated -> localSource.setOrganization(updated) }
            .mapToEmpty()
    }
}

@Composable
fun setOrganizationImageMutation(): EmptyInputMutation<UploadFile> {
    val remoteSource = koinInject<OrganizationRemoteSource>()
    val organizationResource = currentOrganizationResource()

    return rememberInputMutation { file ->
        val organizationId = organizationResource.data?.id
            ?: return@rememberInputMutation Result.Error(OrganizationError.NoCurrentOrganization)

        remoteSource.uploadOrganizationImage(organizationId, file)
            .alsoOnSuccess { organizationResource.reload() }
    }
}
