package cometes.style.system

import androidx.compose.runtime.Composable
import androidx.compose.web.events.SyntheticMouseEvent
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.dom.ElementRefScope
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.BoxScope
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.ariaDisabled
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.tabIndex
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.style.ComponentKind
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.CssStyleScope
import com.varabyte.kobweb.silk.style.CssStyleVariant
import com.varabyte.kobweb.silk.style.addVariant
import com.varabyte.kobweb.silk.style.common.DisabledStyle
import com.varabyte.kobweb.silk.style.selectors.active
import com.varabyte.kobweb.silk.style.selectors.ariaDisabled
import com.varabyte.kobweb.silk.style.selectors.focusVisible
import com.varabyte.kobweb.silk.style.selectors.hover
import com.varabyte.kobweb.silk.style.selectors.not
import com.varabyte.kobweb.silk.style.toModifier
import cometes.style.RadiusMedium
import cometes.style.cometesColor
import cometes.style.emphasize
import cometes.style.system.animation.CometesTransitionOf
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import org.w3c.dom.HTMLElement

sealed interface CometesSurfaceKind : ComponentKind

val CometesSurfaceStyle = CssStyle<CometesSurfaceKind> {
    val surfaceColor = cometesColor.surface
    val surfaceContentColor = cometesColor.onSurface

    base {
        Modifier
            .backgroundColor(surfaceColor)
            .color(surfaceContentColor)
            .border(
                width = 0.px, // 0 width by default so we can animate it
                style = LineStyle.Solid,
                color = surfaceColor.emphasize(0.05f, colorMode)
            )
            .transition(
                CometesTransitionOf("background-color"),
                CometesTransitionOf("border-color"),
                CometesTransitionOf("border-width"),
                CometesTransitionOf("opacity"),
            )
    }

    (hover + not(ariaDisabled)) {
        Modifier
            .backgroundColor(surfaceColor.emphasize(0.1f, colorMode))
            .cursor(Cursor.Pointer)
    }

    (focusVisible + not(ariaDisabled)) {
        Modifier.boxShadow(
            spreadRadius = 3.px,
            color = surfaceColor.emphasize(0.1f, colorMode)
        )
    }

    (active + not(ariaDisabled)) {
        Modifier.backgroundColor(surfaceColor.emphasize(0.18f, colorMode))
    }
}

val SurfaceOutlineVariant = CometesSurfaceStyle.addSurfaceVariant(
    surfaceColor = { cometesColor.background },
    surfaceContentColor = { cometesColor.onBackground },
    base = { Modifier.border(1.px, LineStyle.Solid, cometesColor.outline).borderRadius(RadiusMedium) },
    onHover = { Modifier.border { color(cometesColor.primary) } },
)

val SurfaceBackgroundColorVariant = CometesSurfaceStyle.addSurfaceVariant(
    surfaceColor = { cometesColor.background },
    surfaceContentColor = { cometesColor.onBackground },
)

val SurfacePrimaryVariant = CometesSurfaceStyle.addSurfaceVariant(
    surfaceColor = { cometesColor.primaryContainer },
    surfaceContentColor = { cometesColor.onPrimaryContainer },
)

val SurfaceSecondaryVariant = CometesSurfaceStyle.addSurfaceVariant(
    surfaceColor = { cometesColor.secondaryContainer },
    surfaceContentColor = { cometesColor.onSecondaryContainer },
)

val SurfaceErrorVariant = CometesSurfaceStyle.addSurfaceVariant(
    surfaceColor = { cometesColor.errorContainer },
    surfaceContentColor = { cometesColor.onErrorContainer },
)

val SurfacePositiveVariant = CometesSurfaceStyle.addSurfaceVariant(
    surfaceColor = { cometesColor.positiveContainer },
    surfaceContentColor = { cometesColor.onPositiveContainer },
)

val SurfaceInvertedVariant = CometesSurfaceStyle.addSurfaceVariant(
    surfaceColor = { cometesColor.background.inverted() },
    surfaceContentColor = { cometesColor.onBackground.inverted() },
)

@Composable
fun CometesSurface(
    modifier: Modifier = Modifier,
    variant: CssStyleVariant<CometesSurfaceKind>? = null,
    onClick: ((SyntheticMouseEvent) -> Unit)? = null,
    enabled: Boolean = true,
    ref: ElementRefScope<HTMLElement>? = null,
    content: @Composable BoxScope.() -> Unit
) {
    val isClickable = onClick != null

    Box(
        modifier = CometesSurfaceStyle.toModifier(variant)
            .ariaDisabled(!isClickable) // disable anyway if not clickable
            .thenIf(
                !enabled && isClickable,
                DisabledStyle.toModifier().tabIndex(-1)
            )
            .then(modifier)
            .thenIf(enabled && isClickable) {
                Modifier.onClick {
                    onClick?.invoke(it)
                }
            },
        ref = ref
    ) {
        content()
    }
}

// Non-clickable override
@Composable
fun CometesSurface(
    modifier: Modifier = Modifier,
    variant: CssStyleVariant<CometesSurfaceKind>? = null,
    ref: ElementRefScope<HTMLElement>? = null,
    content: @Composable BoxScope.() -> Unit
) {
    CometesSurface(
        onClick = null,
        modifier = modifier,
        variant = variant,
        ref = ref,
        content = content,
    )
}

fun <K : CometesSurfaceKind> CssStyle<K>.addSurfaceVariant(
    surfaceColor: CssStyleScope.() -> Color,
    surfaceContentColor: CssStyleScope.() -> Color,
    base: CssStyleScope.() -> Modifier = { Modifier },
    onHover: CssStyleScope.() -> Modifier = { Modifier },
    onFocus: CssStyleScope.() -> Modifier = { Modifier },
    onPressed: CssStyleScope.() -> Modifier = { Modifier },
    init: CssStyleScope.() -> Unit = {}
): CssStyleVariant<K> = addVariant {
    val surfaceColor = surfaceColor()
    val surfaceContentColor = surfaceContentColor()

    base {
        Modifier
            .backgroundColor(surfaceColor)
            .color(surfaceContentColor)
            .border(
                width = 0.px, // 0 width by default so we can animate it
                style = LineStyle.Solid,
                color = surfaceColor.emphasize(0.05f, colorMode)
            )
            .then(base())
    }

    (hover + not(ariaDisabled)) {
        Modifier
            .backgroundColor(surfaceColor.emphasize(0.1f, colorMode))
            .cursor(Cursor.Pointer)
            .then(onHover())
    }

    (focusVisible + not(ariaDisabled)) {
        Modifier
            .boxShadow(spreadRadius = 3.px, color = surfaceColor.emphasize(0.1f, colorMode))
            .then(onFocus())
    }

    (active + not(ariaDisabled)) {
        Modifier
            .backgroundColor(surfaceColor.emphasize(0.18f, colorMode))
            .then(onPressed())
    }

    init()
}