package app.cometes.shared.feature.organization.infrastructure.model

import app.cometes.shared.feature.person.infrastructure.model.PersonDto
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

enum class OrganizationMemberRoleDto { User, Admin, Owner }
enum class OrganizationMemberStatusDto { Active, Suspended }

@Serializable
data class OrganizationMemberDto(
    val organizationId: Long,
    val personId: Long,
    val role: String,
    val joinedAt: Instant,
    val status: String,
) {
    val roleEnum: OrganizationMemberRoleDto?
        get() = when (role) {
            "User" -> OrganizationMemberRoleDto.User
            "Admin" -> OrganizationMemberRoleDto.Admin
            "Owner" -> OrganizationMemberRoleDto.Owner
            else -> null
        }

    val statusEnum: OrganizationMemberStatusDto?
        get() = when (status) {
            "Active" -> OrganizationMemberStatusDto.Active
            "Suspended" -> OrganizationMemberStatusDto.Suspended
            else -> null
        }
}

@Serializable
data class OrganizationMemberPersonDto(
    val organizationId: Long,
    val person: PersonDto,
    val role: String,
    val joinedAt: Instant,
    val status: String,
) {
    val roleEnum: OrganizationMemberRoleDto?
        get() = when (role) {
            "User" -> OrganizationMemberRoleDto.User
            "Admin" -> OrganizationMemberRoleDto.Admin
            "Owner" -> OrganizationMemberRoleDto.Owner
            else -> null
        }

    val statusEnum: OrganizationMemberStatusDto?
        get() = when (status) {
            "Active" -> OrganizationMemberStatusDto.Active
            "Suspended" -> OrganizationMemberStatusDto.Suspended
            else -> null
        }
}