package cometes.components.layout

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.silk.components.icons.fa.FaRightFromBracket
import com.varabyte.kobweb.silk.components.text.DivText
import com.varabyte.kobweb.silk.components.text.SpanText
import cometes.components.common.HorizontalSpacer
import cometes.components.common.ThemeToggleButton
import cometes.components.image.ImageCometes
import cometes.style.SpaceLarge
import cometes.style.SpaceMedium
import cometes.style.SpaceMediumLarge
import cometes.style.SpaceMediumSmall
import cometes.style.TypographyBodyMedium
import cometes.style.TypographyHeadlineMedium
import cometes.style.system.CometesImageButton
import cometes.style.system.CometesSurface
import cometes.style.system.SurfaceBackgroundColorVariant
import cometes.style.system.SurfacePrimaryVariant
import cometes.util.rememberDependency
import dev.bitspittle.firebase.app.FirebaseApp
import org.jetbrains.compose.web.css.px

@Composable
fun AuthPageLayout(
    title: String,
    onLogoutClick: () -> Unit,
    content: @Composable () -> Unit
) {
    CometesSurface(
        modifier = Modifier.fillMaxSize(),
        variant = SurfaceBackgroundColorVariant
    ) {
        Column(Modifier.fillMaxSize()) {
            CometesSurface(
                Modifier
                    .fillMaxWidth()
                    .padding(topBottom = SpaceMediumSmall, leftRight = SpaceMediumLarge)
            ) {
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    ImageCometes(Modifier.size(40.px))
                    HorizontalSpacer(SpaceLarge)
                    SpanText(title, Modifier.then(TypographyHeadlineMedium))

                    Spacer()

                    UserEmailHint()

                    HorizontalSpacer(SpaceMedium)

                    ThemeToggleButton()

                    HorizontalSpacer(SpaceMedium)

                    CometesImageButton(
                        onClick = onLogoutClick,
                        variant = SurfacePrimaryVariant
                    ) {
                        FaRightFromBracket()
                    }
                }
            }

            content()
        }
    }
}

@Composable
private fun UserEmailHint(modifier: Modifier = Modifier) {
    val firebase = rememberDependency<FirebaseApp>()
    val userEmail = remember { firebase.getAuth().currentUser?.email }
    if (userEmail != null) {
        DivText(
            text = userEmail,
            modifier = Modifier
                .then(TypographyBodyMedium)
                .then(modifier)
        )
    }
}