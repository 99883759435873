package cometes.components.feature.auth

import androidx.compose.runtime.Composable
import androidx.compose.runtime.SideEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import app.cometes.shared.frontend.feature.auth.presentation.LoginEvent
import app.cometes.shared.frontend.feature.auth.presentation.LoginIntent
import app.cometes.shared.frontend.feature.auth.presentation.LoginPresenter
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.text.SpanText
import cometes.components.common.AnimatedVisibility
import cometes.components.common.ProgressIndicator
import cometes.components.common.VerticalSpacer
import cometes.components.image.ImageCometes
import cometes.style.SpaceMedium
import cometes.style.SpaceMediumLarge
import cometes.style.SpaceXXXLarge
import cometes.style.TypographyBodyLarge
import cometes.style.TypographyTitleLarge
import cometes.style.system.CometesButton
import cometes.style.system.CometesSurface
import cometes.style.system.SurfaceBackgroundColorVariant
import cometes.style.system.SurfaceOutlineVariant
import cometes.style.system.input.CometesTextField
import cometes.util.rememberDependency
import dev.bitspittle.firebase.app.FirebaseApp
import kotlinx.browser.window
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.px

@Composable
fun LoginContent() {
    val scope = rememberCoroutineScope()
    val auth = rememberDependency<FirebaseApp>().getAuth()
    val presenter = remember {
        LoginPresenter { event ->
            when (event) {
                is LoginEvent.Error -> {} // TODO
                LoginEvent.OnEmailSent -> {} // TODO
            }
        }
    }
    val state = presenter.presenterState()

    var isEmailLinkLogin by remember { mutableStateOf(true) }

    fun login() {
        if (state.email.isBlank()) return
        if (!isEmailLinkLogin && state.password.isBlank()) return

        scope.launch {
            if (isEmailLinkLogin) state.onIntent(LoginIntent.SendEmailSignInLink)
            else state.onIntent(LoginIntent.SingInUsingEmailAndPassword)
        }
    }

    SideEffect {
        val link = window.location.href
        if (auth.isSignInLink(link) && !state.isLoading)
            state.onIntent(LoginIntent.ConfirmLink(link))
    }

    Box(Modifier.fillMaxSize()) {
        CometesSurface(
            variant = SurfaceBackgroundColorVariant,
            modifier = Modifier
                .width(350.px)
                .align(Alignment.Center)
        ) {
            Column(
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.Center,
                modifier = Modifier.fillMaxSize()
            ) {
                VerticalSpacer(SpaceMedium)

                ImageCometes(Modifier.size(128.px))

                VerticalSpacer(SpaceXXXLarge)

                CometesTextField(
                    value = state.email,
                    onValueChange = { state.onIntent(LoginIntent.SetEmail(it)) },
                    type = InputType.Email,
                    placeholder = "Email",
                    modifier = Modifier
                        .then(TypographyTitleLarge)
                        .fillMaxWidth()
                )

                if (!isEmailLinkLogin) {
                    VerticalSpacer(SpaceMedium)
                    CometesTextField(
                        value = state.password,
                        onValueChange = { state.onIntent(LoginIntent.SetPassword(it)) },
                        type = InputType.Password,
                        placeholder = "Password",
                        modifier = Modifier
                            .then(TypographyTitleLarge)
                            .fillMaxWidth()
                    )
                }

                VerticalSpacer(SpaceXXXLarge)

                CometesButton(
                    onClick = { login() },
                    modifier = Modifier.fillMaxWidth()
                ) {
                    Box {
                        SpanText(
                            text = "Login",
                            modifier = Modifier
                                .then(TypographyBodyLarge)
                                .thenIf(state.isLoading, Modifier.opacity(0.3))
                        )

                        AnimatedVisibility(state.isLoading) {
                            ProgressIndicator(Modifier.align(Alignment.Center))
                        }
                    }
                }

                VerticalSpacer(SpaceMediumLarge)

                CometesButton(
                    onClick = { isEmailLinkLogin = !isEmailLinkLogin },
                    variant = SurfaceOutlineVariant,
                    modifier = Modifier.fillMaxWidth()
                ) {
                    val text = if (isEmailLinkLogin) "Sign in with e-mail and password"
                    else "Sign in with email link"

                    SpanText(
                        text = text,
                        modifier = Modifier
                            .then(TypographyBodyLarge)
                    )
                }
            }
        }
    }
}