package app.cometes.shared.frontend.feature.organization.data

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.State
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.produceState
import androidx.compose.runtime.remember
import androidx.compose.runtime.snapshotFlow
import app.cometes.shared.feature.organization.infrastructure.model.OrganizationDto
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.map
import app.cometes.shared.frontend.base.resource.EmptyInputMutation
import app.cometes.shared.frontend.base.resource.EmptyMutation
import app.cometes.shared.frontend.base.resource.Mutation
import app.cometes.shared.frontend.base.resource.Resource
import app.cometes.shared.frontend.base.resource.rememberInputMutation
import app.cometes.shared.frontend.base.resource.rememberMutation
import app.cometes.shared.frontend.base.resource.rememberUpdatedResourceState
import app.cometes.shared.frontend.feature.organization.data.source.OrganizationLocalSource
import app.cometes.shared.frontend.feature.organization.data.source.OrganizationRemoteSource
import app.cometes.shared.frontend.feature.organization.domain.model.Organization
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationError
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationWithMembership
import app.cometes.shared.frontend.feature.organization.infrastructure.model.toDomain
import app.cometes.shared.frontend.feature.organization.infrastructure.model.toDto
import app.cometes.shared.frontend.network.UploadFile
import app.cometes.shared.frontend.util.withUseCaseContext
import kotlinx.coroutines.flow.distinctUntilChangedBy
import kotlinx.coroutines.flow.drop
import org.koin.compose.koinInject

@Composable
fun currentOrganization(): Organization? {
    val organizationLocalSource = koinInject<OrganizationLocalSource>()
    val localDto by organizationLocalSource.currentOrganization.collectAsState()

    return localDto?.toDomain()
}

@Composable
fun currentOrganizationAsState(): State<Organization?> {
    val organizationLocalSource = koinInject<OrganizationLocalSource>()
    return produceState(organizationLocalSource.currentOrganization.value?.toDomain()) {
        organizationLocalSource
            .currentOrganization
            .collect { value = it?.toDomain() }
    }
}

@Composable
fun OrganizationChangeEffect(block: (Organization?) -> Unit) {
    val organization by currentOrganizationAsState()
    LaunchedEffect(Unit) {
        snapshotFlow { organization }
            .distinctUntilChangedBy { it?.id }
            .drop(1)
            .collect { block(organization) }
    }
}

@Composable
internal fun currentOrganizationResource(): Resource<Organization> {
    val organizationRemoteSource = koinInject<OrganizationRemoteSource>()
    val organizationLocalSource = koinInject<OrganizationLocalSource>()

    val localDto by remember { organizationLocalSource.currentOrganization }.collectAsState()
    val organization = localDto?.toDomain()
        ?: return noCurrentOrganizationResourceError()

    val resourceState = rememberUpdatedResourceState(organization) {
        val res = withUseCaseContext { organizationRemoteSource.getOrganization(organization.id) }
        if (res is Result.Success) organizationLocalSource.setOrganization(res.data)
        res.map(OrganizationDto::toDomain)
    }

    return resourceState.resource
}


@Composable
fun setCurrentOrganizationMutation(): EmptyInputMutation<OrganizationWithMembership> {
    val organizationLocalSource = koinInject<OrganizationLocalSource>()

    return rememberInputMutation { (organization, membership) ->
        organizationLocalSource.setOrganization(organization.toDto())
        organizationLocalSource.setOrganizationMembership(membership.toDto())
        Result.Success(Unit)
    }
}

@Composable
fun clearCurrentOrganizationMutation(): EmptyMutation {
    val organizationLocalSource = koinInject<OrganizationLocalSource>()

    // TODO fuuuck, the composable caches might not be such a good idea if not backed by some local source.
    //  How can I clear them now - hmmm, the idea is that they can clean themselves by using the proper key
    return rememberMutation {
        organizationLocalSource.setOrganization(null)
        organizationLocalSource.setOrganizationMembership(null)
        organizationLocalSource.setLocation(null)
        Result.Success(Unit)
    }
}

@Composable
fun createOrganizationMutation(
    name: String,
    description: String,
    image: UploadFile?
): Mutation<OrganizationWithMembership> {
    val organizationSource = koinInject<OrganizationRemoteSource>()

    return rememberMutation(name, description, image) {
        val res = withUseCaseContext { organizationSource.createOrganization(name, description) }
        when (res) {
            is Result.Success -> {
                // upload image if org was created
                if (image != null) withUseCaseContext {
                    organizationSource.uploadOrganizationImage(res.data.organization.id, image)
                }
            }

            is Result.Error -> return@rememberMutation Result.Error(res.error)
        }

        res.map { dto ->
            OrganizationWithMembership(
                organization = dto.organization.toDomain(),
                membership = dto.membership.toDomain()
            )
        }
    }
}

fun <T> noCurrentOrganizationResourceError() =
    Resource.Error<T>(error = OrganizationError.NoCurrentOrganization, data = null, onReload = {})
