package app.cometes.shared.frontend.feature.organization.data

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import app.cometes.shared.feature.reservation.infrastructure.model.LocationDto
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.insertOrUpdate
import app.cometes.shared.frontend.base.map
import app.cometes.shared.frontend.base.mapToEmpty
import app.cometes.shared.frontend.base.resource.EmptyMutation
import app.cometes.shared.frontend.base.resource.Resource
import app.cometes.shared.frontend.base.resource.alsoOnSuccess
import app.cometes.shared.frontend.base.resource.rememberMutation
import app.cometes.shared.frontend.base.resource.rememberUpdatedResourceState
import app.cometes.shared.frontend.base.resource.resourceListCache
import app.cometes.shared.frontend.base.resource.wrapEmptyResource
import app.cometes.shared.frontend.feature.organization.data.source.OrganizationLocationRemoteSource
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationError
import app.cometes.shared.frontend.feature.organization.infrastructure.model.toDomain
import app.cometes.shared.frontend.feature.reservation.domain.model.Location
import app.cometes.shared.frontend.util.rememberKoinDependency
import app.cometes.shared.frontend.util.withUseCaseContext

@Composable
internal fun currentOrganizationLocationListCache(organizationId: Long) =
    resourceListCache<Location>(key = "org${organizationId}:List<Location>")

@Composable
fun currentOrganizationLocationListResource(): Resource<List<Location>> {
    val organization = currentOrganization() ?: return noCurrentOrganizationResourceError()
    val locationRemoteSource = rememberKoinDependency<OrganizationLocationRemoteSource>()
    val locationListCache = currentOrganizationLocationListCache(organization.id)

    val cachedLocationList by locationListCache.items.collectAsState()
    val resourceState = rememberUpdatedResourceState(cachedLocationList) {
        locationRemoteSource.getOrganizationLocations(organization.id)
            .map { locations -> locations.map(LocationDto::toDomain) }
            .alsoOnSuccess { locationListCache.setCache(it) }
    }

    return wrapEmptyResource { resourceState.resource }
}


@Composable
fun createOrganizationLocationMutation(name: String, address: String?): EmptyMutation {
    val locationRemoteSource = rememberKoinDependency<OrganizationLocationRemoteSource>()
    val organization = currentOrganization()
    val locationsCache = currentOrganizationLocationListCache(organization?.id ?: -1)

    return rememberMutation(organization, name, address) {
        val organizationId = organization?.id
            ?: return@rememberMutation Result.Error(OrganizationError.NoCurrentOrganization)

        withUseCaseContext { locationRemoteSource.createLocation(organizationId, name, address) }
            .map(LocationDto::toDomain)
            .alsoOnSuccess { newLocation -> locationsCache.insertOrUpdate(newLocation) }
            .mapToEmpty()
    }
}

@Composable
fun removeOrganizationLocationMutation(location: Location): EmptyMutation {
    val locationRemoteSource = rememberKoinDependency<OrganizationLocationRemoteSource>()
    val locationsCache = currentOrganizationLocationListCache(location.organizationId)

    return rememberMutation(location) {
        withUseCaseContext {
            locationRemoteSource.removeLocation(location.organizationId, location.id)
        }.alsoOnSuccess { locationsCache.remove(location.id) }
    }
}