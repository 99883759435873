package app.cometes.shared.frontend.feature.desk.infrastructure

import app.cometes.shared.feature.reservation.infrastructure.model.DeskDto
import app.cometes.shared.feature.reservation.infrastructure.model.NewDeskBody
import app.cometes.shared.feature.reservation.infrastructure.model.UpdateDeskBody
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.error.runCatchingNetworkExceptions
import app.cometes.shared.frontend.feature.desk.data.DeskRemoteSource
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.delete
import io.ktor.client.request.get
import io.ktor.client.request.patch
import io.ktor.client.request.post
import io.ktor.client.request.setBody

internal class DeskRemoteSourceImpl(
    private val client: HttpClient
) : DeskRemoteSource {
    override suspend fun getDesks(organizationId: Long): Result<List<DeskDto>> =
        runCatchingNetworkExceptions {
            val response = client.get("/organization/${organizationId}/desk")
            Result.Success(response.body())
        }

    override suspend fun addDesk(
        organizationId: Long,
        locationId: Long,
        name: String
    ): Result<DeskDto> = runCatchingNetworkExceptions {
        val response = client.post("/organization/${organizationId}/desk") {
            setBody(NewDeskBody(locationId, name))
        }

        Result.Success(response.body())
    }

    override suspend fun renameDesk(
        organizationId: Long,
        deskId: Long,
        newName: String
    ): Result<DeskDto> = runCatchingNetworkExceptions {
        val response = client.patch("/organization/${organizationId}/desk/$deskId") {
            setBody(UpdateDeskBody(newName))
        }

        Result.Success(response.body())
    }

    override suspend fun removeDesk(organizationId: Long, deskId: Long): Result<Unit> =
        runCatchingNetworkExceptions {
            client.delete("/organization/${organizationId}/desk/$deskId")
            Result.Success(Unit)
        }
}