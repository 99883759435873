package cometes.components.common

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.borderColor
import com.varabyte.kobweb.compose.css.overflowY
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.style.ComponentKind
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.CssStyleVariant
import com.varabyte.kobweb.silk.style.addVariant
import com.varabyte.kobweb.silk.style.toModifier
import cometes.style.RadiusMedium
import cometes.style.SpaceMedium
import cometes.style.SpaceXSmall
import cometes.style.cometesColor
import org.jetbrains.compose.web.css.LineStyle

class VerticalScrollableKind : ComponentKind

val VerticalScrollableStyle = CssStyle<VerticalScrollableKind> {
    val trackColor = cometesColor.background
    val thumbColor = cometesColor.onBackground

    base {
        Modifier
            .styleModifier {
                overflowY(Overflow.Auto)
                property("scrollbar-width", "auto")
                property("scrollbar-color", "${thumbColor.toRgb()} ${trackColor.toRgb()}")
            }
    }

    cssRule("::-webkit-scrollbar") {
        Modifier.width(SpaceMedium)
    }

    cssRule("::-webkit-scrollbar-track") {
        Modifier.backgroundColor(trackColor)
    }

    cssRule("::-webkit-scrollbar-thumb") {
        Modifier
            .backgroundColor(thumbColor)
            .border(SpaceXSmall, LineStyle.Solid, trackColor)
            .borderRadius(RadiusMedium)
    }
}

val SurfaceVerticalScrollableVariant = VerticalScrollableStyle.addVariant {
    val trackColor = cometesColor.surface
    val thumbColor = cometesColor.onSurface

    base {
        Modifier.styleModifier {
            property("scrollbar-color", "${thumbColor.toRgb()} ${trackColor.toRgb()}")
        }
    }

    cssRule("::-webkit-scrollbar-track") {
        Modifier.backgroundColor(trackColor)
    }

    cssRule("::-webkit-scrollbar-thumb") {
        Modifier
            .backgroundColor(thumbColor)
            .styleModifier { borderColor(trackColor) }
    }
}

@Composable
fun Modifier.verticalScroll(variant: CssStyleVariant<VerticalScrollableKind>? = null) =
    Modifier.then(VerticalScrollableStyle.toModifier(variant))