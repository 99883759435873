package app.cometes.shared.frontend.feature.organization.data

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import app.cometes.shared.feature.organization.infrastructure.model.OrganizationMemberPersonDto
import app.cometes.shared.frontend.base.map
import app.cometes.shared.frontend.base.resource.EmptyMutation
import app.cometes.shared.frontend.base.resource.Resource
import app.cometes.shared.frontend.base.resource.alsoOnSuccess
import app.cometes.shared.frontend.base.resource.rememberMutation
import app.cometes.shared.frontend.base.resource.rememberUpdatedResourceState
import app.cometes.shared.frontend.base.resource.resourceListCache
import app.cometes.shared.frontend.base.resource.wrapEmptyResource
import app.cometes.shared.frontend.feature.organization.data.source.OrganizationRemoteSource
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationMemberPerson
import app.cometes.shared.frontend.feature.organization.infrastructure.model.toDomain
import app.cometes.shared.frontend.util.rememberKoinDependency
import app.cometes.shared.frontend.util.withUseCaseContext

@Composable
internal fun currentOrganizationMembersCache(organizationId: Long) =
    resourceListCache<OrganizationMemberPerson>(
        "org${organizationId}:List<OrganizationMemberPerson>"
    )

@Composable
fun currentOrganizationMembersResource(): Resource<List<OrganizationMemberPerson>> {
    val organization = currentOrganization() ?: return noCurrentOrganizationResourceError()
    val organizationRemoteSource = rememberKoinDependency<OrganizationRemoteSource>()
    val memberCache = currentOrganizationMembersCache(organization.id)

    val localMembers by memberCache.items.collectAsState()
    val resourceState = rememberUpdatedResourceState(localMembers) {
        withUseCaseContext { organizationRemoteSource.getOrganizationMembers(organization.id) }
            .map { dtos -> dtos.map(OrganizationMemberPersonDto::toDomain) }
            .alsoOnSuccess { memberCache.setCache(it) }
    }

    return wrapEmptyResource { resourceState.resource }
}

@Composable
fun removeOrganizationMemberMutation(member: OrganizationMemberPerson): EmptyMutation {
    val organizationRemoteSource = rememberKoinDependency<OrganizationRemoteSource>()
    val memberCache = currentOrganizationMembersCache(member.organizationId)

    return rememberMutation(member) {
        withUseCaseContext {
            organizationRemoteSource.removeMember(member.organizationId, member.id)
        }.alsoOnSuccess { memberCache.remove(member.id) }
    }
}