package app.cometes.shared.frontend.feature.reservation.infrastructure.model

import app.cometes.shared.feature.reservation.infrastructure.model.DeskDto
import app.cometes.shared.frontend.feature.organization.infrastructure.model.toDomain
import app.cometes.shared.frontend.feature.reservation.domain.model.Desk

internal fun DeskDto.toDomain() = Desk(
    id = id,
    name = name,
    location = location.toDomain()
)