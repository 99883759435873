package app.cometes.shared.frontend.network

import io.ktor.client.request.HttpRequestBuilder
import io.ktor.client.request.setBody
import io.ktor.http.ContentType
import io.ktor.http.contentType
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine
import org.khronos.webgl.ArrayBuffer
import org.khronos.webgl.Uint8Array
import org.khronos.webgl.get
import org.w3c.files.File
import org.w3c.files.FileReader

actual class UploadFile(val file: File)

actual suspend fun HttpRequestBuilder.setFileBody(uploadFile: UploadFile) {
    val file = uploadFile.file
    val fileBytes = uploadFile.readToBuffer()

    if (fileBytes != null) {
        setBody(fileBytes)
        contentType(ContentType.parse(file.type))
    }
}

suspend fun UploadFile.readAsUrl() = suspendCoroutine<String?> { continuation ->
    with(FileReader()) {
        onloadend = { _ ->
            val byteArray = if (readyState == FileReader.DONE) result as String
            else null

            continuation.resume(byteArray)
        }

        readAsDataURL(file)
    }
}

suspend fun UploadFile.readToBuffer() = suspendCoroutine<ByteArray?> { continuation ->
    with(FileReader()) {
        onloadend = { _ ->
            val byteArray = if (readyState == FileReader.DONE) {
                val array = Uint8Array(buffer = result as ArrayBuffer)
                ByteArray(array.length) { array[it] }
            } else null

            continuation.resume(byteArray)
        }

        readAsArrayBuffer(file)
    }
}