package app.cometes.shared.frontend.feature.desk.data

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import app.cometes.shared.feature.reservation.infrastructure.model.DeskDto
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.insertOrUpdate
import app.cometes.shared.frontend.base.map
import app.cometes.shared.frontend.base.mapToEmpty
import app.cometes.shared.frontend.base.resource.EmptyMutation
import app.cometes.shared.frontend.base.resource.Resource
import app.cometes.shared.frontend.base.resource.alsoOnSuccess
import app.cometes.shared.frontend.base.resource.rememberMutation
import app.cometes.shared.frontend.base.resource.rememberUpdatedResourceState
import app.cometes.shared.frontend.base.resource.resourceListCache
import app.cometes.shared.frontend.base.resource.wrapEmptyResource
import app.cometes.shared.frontend.feature.organization.data.currentOrganization
import app.cometes.shared.frontend.feature.organization.data.currentOrganizationResource
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationError
import app.cometes.shared.frontend.feature.reservation.domain.model.Desk
import app.cometes.shared.frontend.feature.reservation.domain.model.Location
import app.cometes.shared.frontend.feature.reservation.infrastructure.model.toDomain
import app.cometes.shared.frontend.util.rememberKoinDependency
import app.cometes.shared.frontend.util.withUseCaseContext

@Composable
internal fun currentOrganizationDesksCache(organizationId: Long) =
    resourceListCache<Desk>("org${organizationId}:List<Desk>")

@Composable
fun currentOrganizationDesksResource(): Resource<List<Desk>> {
    val deskRemoteSource = rememberKoinDependency<DeskRemoteSource>()

    val organization = when (val res = currentOrganizationResource()) {
        is Resource.Value -> res.data
        is Resource.Loading -> return Resource.Loading(null)
        is Resource.Error -> return Resource.Error(res.error, null, res.onReload)
    }

    val cache = currentOrganizationDesksCache(organization.id)

    val localValue by cache.items.collectAsState()
    val state = rememberUpdatedResourceState(localValue) {
        withUseCaseContext { deskRemoteSource.getDesks(organization.id) }
            .map { desks -> desks.map(DeskDto::toDomain) }
            .alsoOnSuccess { cache.setCache(it) }
    }

    return wrapEmptyResource { state.resource }
}

@Composable
fun createDeskMutation(name: String, location: Location): EmptyMutation {
    val deskRemoteSource = rememberKoinDependency<DeskRemoteSource>()
    val currentOrganization = currentOrganization()
    val organizationCache = currentOrganizationDesksCache(currentOrganization?.id ?: -1)

    return rememberMutation(name, location, currentOrganization) {
        val organization = currentOrganization
            ?: return@rememberMutation Result.Error(OrganizationError.NoCurrentOrganization)

        val result = withUseCaseContext {
            deskRemoteSource.addDesk(organization.id, location.id, name)
        }

        result
            .map(DeskDto::toDomain)
            .alsoOnSuccess { newDesk -> organizationCache.insertOrUpdate(newDesk) }
            .mapToEmpty()
    }
}

@Composable
fun removeDeskMutation(desk: Desk): EmptyMutation {
    val deskRemoteSource = rememberKoinDependency<DeskRemoteSource>()
    val currentOrganization = currentOrganization()
    val organizationCache = currentOrganizationDesksCache(currentOrganization?.id ?: -1)

    return rememberMutation(currentOrganization, desk) {
        val organization = currentOrganization
            ?: return@rememberMutation Result.Error(OrganizationError.NoCurrentOrganization)

        withUseCaseContext {
            deskRemoteSource.removeDesk(
                organizationId = organization.id,
                deskId = desk.id
            )
        }.alsoOnSuccess { organizationCache.remove(desk.id) }
    }
}