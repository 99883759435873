package app.cometes.shared.frontend.feature.auth.data

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import app.cometes.shared.frontend.util.rememberKoinDependency

@Composable
internal fun isUserLoggedIn(): Boolean {
    val source = rememberKoinDependency<FirebaseSource>()
    var loggedIn by remember { mutableStateOf(source.isLoggedIn) }

    DisposableEffect(Unit) {
        val disposable = source.addAuthListener { loggedIn = it }
        onDispose { disposable.dispose() }
    }

    return loggedIn
}