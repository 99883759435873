package cometes.components.feature.organization.member

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.feature.organization.data.removeOrganizationMemberMutation
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationMemberPerson
import app.cometes.shared.frontend.util.withUseCaseContext
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.silk.components.text.DivText
import com.varabyte.kobweb.silk.components.text.SpanText
import cometes.components.common.CometesDialog
import cometes.components.common.CometesLoadingButton
import cometes.components.common.HorizontalSpacer
import cometes.components.common.VerticalSpacer
import cometes.style.SpaceMedium
import cometes.style.SpaceMediumSmall
import cometes.style.TypographyBodyLarge
import cometes.style.TypographyTitleMedium
import cometes.style.system.CometesButton
import cometes.style.system.SurfaceBackgroundColorVariant
import cometes.style.system.SurfaceErrorVariant
import kotlinx.coroutines.launch

@Composable
fun MemberRemovalDialog(
    member: OrganizationMemberPerson?,
    onDismiss: () -> Unit,
) {
    if (member == null) return

    val coroutineScope = rememberCoroutineScope()
    val removeMemberMutation = removeOrganizationMemberMutation(member)
    var isLoading by remember { mutableStateOf(false) }

    fun onRemoveClick() {
        coroutineScope.launch {
            isLoading = true
            val res = withUseCaseContext { removeMemberMutation.execute() }
            isLoading = false
            if (res is Result.Success) onDismiss()
        }
    }

    CometesDialog(
        title = "\"${member.person.fullName}\" will be permanently removed from current organization!",
        onDismiss = onDismiss,
        submitButtons = {
            CometesButton(
                onDismiss,
                variant = SurfaceBackgroundColorVariant
            ) { SpanText("Cancel") }
            HorizontalSpacer(SpaceMedium)
            CometesLoadingButton(
                isLoading = isLoading,
                onClick = ::onRemoveClick,
                variant = SurfaceErrorVariant
            ) {
                SpanText("Remove")
            }
        }
    ) {
        Column(TypographyBodyLarge) {
            DivText("Any other resources associated with this member like reservations or schedules will also be removed.")
            VerticalSpacer(SpaceMediumSmall)
            DivText("This step cannot be reversed!", TypographyTitleMedium)
        }
    }
}