package cometes.components.feature.organization

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import app.cometes.shared.frontend.feature.organization.data.createOrganizationLocationMutation
import app.cometes.shared.frontend.feature.organization.data.removeOrganizationLocationMutation
import app.cometes.shared.frontend.feature.organization.data.updateOrganizationLocationMutation
import app.cometes.shared.frontend.feature.reservation.domain.model.Location
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.silk.components.icons.mdi.MdiDelete
import com.varabyte.kobweb.silk.components.icons.mdi.MdiInfo
import com.varabyte.kobweb.silk.components.text.SpanText
import cometes.components.common.CometesDialog
import cometes.components.common.CometesLoadingButton
import cometes.components.common.VerticalSpacer
import cometes.style.BorderSmall
import cometes.style.SpaceMedium
import cometes.style.SpaceMediumLarge
import cometes.style.TextSecondaryOpacity
import cometes.style.TypographyBodySmall
import cometes.style.system.ButtonAlertVariant
import cometes.style.system.ButtonTextVariant
import cometes.style.system.CometesButton
import cometes.style.system.input.CometesTextField
import cometes.style.system.input.CometesTextFieldSurfaceVariant
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.percent

@Composable
fun EditLocationDialog(
    location: Location?,
    onDismiss: () -> Unit,
) {
    val actionCoroutineScope = rememberCoroutineScope()
    var isLoading by remember { mutableStateOf(false) }
    var locationName by remember(location) { mutableStateOf(location?.name ?: "") }
    var locationAddress by remember(location) { mutableStateOf(location?.address ?: "") }

    val createLocation = if (location == null) {
        createOrganizationLocationMutation(
            name = locationName,
            address = locationAddress.ifBlank { null }
        )
    } else null

    val updateLocation = if (location != null) {
        updateOrganizationLocationMutation(
            originalLocation = location,
            name = locationName,
            address = locationAddress.ifBlank { null }
        )
    } else null

    val removeLocation = if (location != null) {
        removeOrganizationLocationMutation(location)
    } else null

    fun save() {
        isLoading = true
        actionCoroutineScope.launch {
            try {
                if (location == null) {
                    createLocation?.execute()
                } else {
                    updateLocation?.execute()
                }
            } finally {
                isLoading = false
                onDismiss()
            }
        }
    }

    fun removeLocation() {
        isLoading = true
        actionCoroutineScope.launch {
            try {
                removeLocation?.execute()
            } finally {
                isLoading = false
                onDismiss()
            }
        }
    }

    CometesDialog(
        onDismiss = onDismiss,
        title = if (location == null) "Add new location" else "Edit location ${location.name}",
        subTitle = if (location == null) "Add a new location to your organization" else null,
        submitButtons = {
            CometesButton(
                onClick = { onDismiss() },
                variant = ButtonTextVariant,
            ) { SpanText("Cancel") }

            CometesLoadingButton(
                isLoading = isLoading,
                onClick = { save() },
            ) {
                if (location == null) SpanText("Create")
                else SpanText("Save")
            }
        },
        modifier = Modifier
            .fillMaxWidth(80.percent)
            .maxWidth(32.cssRem),
    ) {
        Column(
            modifier = Modifier.fillMaxSize()
        ) {
            CometesTextField(
                value = locationName,
                onValueChange = { locationName = it },
                label = "Location name",
                variant = CometesTextFieldSurfaceVariant,
                modifier = Modifier.fillMaxWidth()
            )

            VerticalSpacer(SpaceMedium)

            CometesTextField(
                value = locationAddress,
                onValueChange = { locationAddress = it },
                label = "Address (optional)",
                variant = CometesTextFieldSurfaceVariant,
                modifier = Modifier.fillMaxWidth()
            )

            if (location != null) {
                VerticalSpacer(SpaceMediumLarge)
                Row(
                    horizontalArrangement = Arrangement.spacedBy(SpaceMedium),
                    modifier = Modifier.fillMaxWidth().border(BorderSmall)
                ) {
                    MdiInfo(Modifier.opacity(TextSecondaryOpacity))
                    SpanText(
                        text = "Removing this location will remove all related resources, including desks, reservations and " +
                                "reservation schedules. This action is permanent and cannot be undone.",
                        modifier = TypographyBodySmall.opacity(TextSecondaryOpacity)
                    )
                    CometesLoadingButton(
                        isLoading = isLoading,
                        onClick = { removeLocation() },
                        variant = ButtonAlertVariant,
                        modifier = Modifier.align(Alignment.CenterVertically)
                    ) {
                        MdiDelete()
                        SpanText("Remove")
                    }
                }
                VerticalSpacer(SpaceMediumLarge)
            }
        }
    }
}
