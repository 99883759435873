package cometes.pages.account

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import app.cometes.shared.frontend.feature.auth.data.logOutMutation
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationMemberRole
import app.cometes.shared.frontend.feature.organization.presentation.OrganizationManagementPresenter
import app.cometes.shared.frontend.feature.organization.presentation.OrganizationManagementState
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.minWidth
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.icons.fa.FaRightFromBracket
import com.varabyte.kobweb.silk.components.text.DivText
import cometes.components.Authenticated
import cometes.components.common.ProgressIndicator
import cometes.components.common.ThemeToggleButton
import cometes.components.feature.organization.location.OrganizationLocationCard
import cometes.components.feature.organization.member.OrganizationMembersCard
import cometes.components.feature.organization.subscription.OrganizationInfoCard
import cometes.components.layout.FlexCardLayout
import cometes.components.layout.MainPageLayout
import cometes.style.TypographyHeadlineMedium
import cometes.style.system.CometesImageButton
import cometes.style.system.SurfacePrimaryVariant
import cometes.style.system.animation.fadeInTransition
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.px

@Page
@Composable
fun AccountPage() {
    Authenticated {
        val scope = rememberCoroutineScope()
        val logOutMutation = logOutMutation()
        val presenter = remember { OrganizationManagementPresenter() }
        val state = presenter.presenterState()

        AccountContent(
            state = state,
            onLogOut = {
                scope.launch { logOutMutation.execute() }
            }
        )
    }
}

@Composable
private fun AccountContent(
    state: OrganizationManagementState,
    onLogOut: () -> Unit,
) {
    MainPageLayout(
        title = state.screenTitle,
        utilRow = {
            ThemeToggleButton()
            CometesImageButton(
                onClick = onLogOut,
                variant = SurfacePrimaryVariant
            ) { FaRightFromBracket() }
        }
    ) {
        when (state) {
            is OrganizationManagementState.OrganizationMember -> {
                FlexCardLayout(Modifier.fadeInTransition()) {
                    OrganizationInfoCard(
                        organization = state.organization.organization,
                        memberRole = state.role,
                        subscriptionState = null,
                        modifier = Modifier.cardSizeConstrains()
                    )

                    OrganizationLocationCard(
                        desks = state.organization.desks,
                        locations = state.organization.locations,
                        memberRole = state.role,
                        modifier = Modifier.cardSizeConstrains()
                    )

                    OrganizationMembersCard(
                        members = state.organization.members,
                        invitations = state.organization.invitations,
                        memberRole = state.role,
                        modifier = Modifier.cardSizeConstrains()
                    )
                }
            }

            is OrganizationManagementState.OrganizationOwner -> {
                FlexCardLayout(Modifier.fadeInTransition()) {
                    OrganizationInfoCard(
                        organization = state.organization.organization,
                        memberRole = OrganizationMemberRole.Owner,
                        subscriptionState = state.billingInfo,
                        modifier = Modifier.cardSizeConstrains()
                    )

                    OrganizationLocationCard(
                        desks = state.organization.desks,
                        locations = state.organization.locations,
                        memberRole = OrganizationMemberRole.Owner,
                        modifier = Modifier.cardSizeConstrains()
                    )

                    OrganizationMembersCard(
                        members = state.organization.members,
                        invitations = state.organization.invitations,
                        memberRole = OrganizationMemberRole.Owner,
                        modifier = Modifier.cardSizeConstrains(),
                        maxMemberCount = state.billingInfo.quantity
                    )
                }
            }

            is OrganizationManagementState.Error -> {
                DivText(
                    text = "Something went wrong :(\n${state.error::class.simpleName}",
                    modifier = Modifier
                        .align(Alignment.Center)
                        .then(TypographyHeadlineMedium)
                        .fadeInTransition()
                )
            }

            OrganizationManagementState.Loading -> {
                ProgressIndicator(Modifier.align(Alignment.Center))
            }
        }
    }
}

private val OrganizationManagementState.screenTitle
    get() = when (this) {
        is OrganizationManagementState.Error, OrganizationManagementState.Loading -> ""
        is OrganizationManagementState.OrganizationMember -> organization.organization.name
        is OrganizationManagementState.OrganizationOwner -> organization.organization.name
    }

@Composable
private fun Modifier.cardSizeConstrains() = minWidth(400.px).maxWidth(400.px)