package app.cometes.shared.frontend.feature.reservation.infrastructure.source

import app.cometes.shared.frontend.feature.reservation.data.source.ReservationDayLocalSource
import app.cometes.shared.frontend.feature.reservation.domain.model.Reservation
import app.cometes.shared.frontend.feature.reservation.domain.model.ReservationDay
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow

class ReservationDayDayLocalSourceImpl : ReservationDayLocalSource {
    private val reservationStateFlow: MutableStateFlow<List<ReservationDay>?> =
        MutableStateFlow(null)

    override fun initCache(days: List<ReservationDay>) {
        reservationStateFlow.value = days
    }

    override fun insertOrUpdate(reservations: List<Reservation>) {
        reservationStateFlow.value = reservationStateFlow.value?.map { day ->
            val newDataForDay = reservations.filter { it.date == day.date }
            val cachedReservations = day.reservations.filterNot { cached ->
                newDataForDay.any(cached::equalContent)
            }

            day.copy(reservations = newDataForDay + cachedReservations)
        }
    }

    override fun remove(reservationId: Long) {
        reservationStateFlow.value = reservationStateFlow.value?.map { day ->
            val clearedReservations = day.reservations.filter { it.id != reservationId }
            day.copy(reservations = clearedReservations)
        }
    }

    override fun getHomeReservationCacheFlow(): StateFlow<List<ReservationDay>?> =
        reservationStateFlow.asStateFlow()

}

private fun Reservation.equalContent(other: Reservation) =
    date == other.date && desk == other.desk && person == other.person