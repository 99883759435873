package app.cometes.shared.frontend.feature.billing.infrasctructure

import app.cometes.shared.feature.person.infrastructure.model.BillingPortalSessionDto
import app.cometes.shared.feature.person.infrastructure.model.CreateSubscriptionBody
import app.cometes.shared.feature.person.infrastructure.model.SubscriptionStatusDto
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.error.runCatchingNetworkExceptions
import app.cometes.shared.frontend.feature.billing.data.BillingRemoteSource
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.get
import io.ktor.client.request.post
import io.ktor.client.request.setBody

class BillingRemoteSourceImpl(
    private val client: HttpClient
) : BillingRemoteSource {

    override suspend fun getSubscriptionStatus(): Result<SubscriptionStatusDto> =
        runCatchingNetworkExceptions {
            val response = client.get("/billing")
            val result = response.body<SubscriptionStatusDto>()

            Result.Success(result)
        }

    override suspend fun getCustomerPortalLink(): Result<String> = runCatchingNetworkExceptions {
        val response = client.get("/billing/portal")
        val result = response.body<BillingPortalSessionDto>()

        Result.Success(result.link)
    }

    override suspend fun getSubscriptionCheckoutLink(
        organizationId: Long,
        userQuantity: Int
    ): Result<BillingPortalSessionDto> = runCatchingNetworkExceptions {
        val response = client.post("/billing") {
            setBody(CreateSubscriptionBody(organizationId, userQuantity))
        }
        val result = response.body<BillingPortalSessionDto>()

        Result.Success(result)
    }
}