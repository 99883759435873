package cometes.components.feature.auth

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.dom.svg.Group
import com.varabyte.kobweb.compose.dom.svg.Path
import com.varabyte.kobweb.compose.dom.svg.SVGStrokeLineJoin
import com.varabyte.kobweb.compose.dom.svg.Svg
import com.varabyte.kobweb.compose.dom.svg.SvgId
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.toAttrs
import cometes.style.CometesTheme

@Composable
fun GoogleLogo(modifier: Modifier = Modifier) {
    Svg(
        attrs = modifier.toAttrs { viewBox(0, 0, 48, 48) }
    ) {
        Path {
            fill(Color.argb(0xFFEA4335))
            attr(
                "d",
                "M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
            )
        }
        Path {
            fill(Color.argb(0xFF4285F4))
            attr(
                "d",
                "M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
            )
        }
        Path {
            fill(Color.argb(0xFFFBBC05))
            attr(
                "d",
                "M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
            )
        }
        Path {
            fill(Color.argb(0xFF34A853))
            attr(
                "d",
                "M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
            )
        }
        Path {
            fill(SvgId("none"))
            attr("d", "M0 0h48v48H0z")
        }
    }
}

@Composable
fun AppleLogo(modifier: Modifier = Modifier) {
    val color = CometesTheme.color.onSurface
    Svg(
        attrs = modifier.toAttrs { viewBox(0, 0, 22.7, 22.7) }
    ) {
        Group(attrs = { strokeLineJoin(SVGStrokeLineJoin.Round) }) {
            Path {
                fill(color)
                attr(
                    "d",
                    "M15.769,0c0.053,0,0.106,0,0.162,0c0.13,1.606-0.483,2.806-1.228,3.675c-0.731,0.863-1.732,1.7-3.351,1.573 c-0.108-1.583,0.506-2.694,1.25-3.561C13.292,0.879,14.557,0.16,15.769,0z"
                )
            }
            Path {
                fill(color)
                attr(
                    "d",
                    "M20.67,16.716c0,0.016,0,0.03,0,0.045c-0.455,1.378-1.104,2.559-1.896,3.655c-0.723,0.995-1.609,2.334-3.191,2.334 c-1.367,0-2.275-0.879-3.676-0.903c-1.482-0.024-2.297,0.735-3.652,0.926c-0.155,0-0.31,0-0.462,0 c-0.995-0.144-1.798-0.932-2.383-1.642c-1.725-2.098-3.058-4.808-3.306-8.276c0-0.34,0-0.679,0-1.019 c0.105-2.482,1.311-4.5,2.914-5.478c0.846-0.52,2.009-0.963,3.304-0.765c0.555,0.086,1.122,0.276,1.619,0.464 c0.471,0.181,1.06,0.502,1.618,0.485c0.378-0.011,0.754-0.208,1.135-0.347c1.116-0.403,2.21-0.865,3.652-0.648 c1.733,0.262,2.963,1.032,3.723,2.22c-1.466,0.933-2.625,2.339-2.427,4.74C17.818,14.688,19.086,15.964,20.67,16.716z"
                )
            }
        }
    }
}