package cometes.components.common

import com.varabyte.kobweb.compose.css.Background
import com.varabyte.kobweb.compose.css.CSSPosition
import com.varabyte.kobweb.compose.css.StyleVariable
import com.varabyte.kobweb.compose.css.functions.RadialGradient
import com.varabyte.kobweb.compose.css.functions.radialGradient
import com.varabyte.kobweb.compose.css.functions.toImage
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.background
import com.varabyte.kobweb.compose.ui.modifiers.setVariable
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.silk.style.CssStyle
import cometes.style.cometesColor
import cometes.style.invertedCometesColor
import cometes.style.system.animation.CometesTransitionOf
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.percent

val GradientBackgroundColorVariable by StyleVariable<CSSColorValue>()
val GradientPrimaryColorVariable by StyleVariable<CSSColorValue>()
val GradientSecondaryColorVariable by StyleVariable<CSSColorValue>()

val CometesGradientStyle = CssStyle {
    val brandColorAlpha = if (colorMode.isLight) 0.33f else 0.2f
    val background = cometesColor.background
    val primary = invertedCometesColor.primary.toRgb().copyf(alpha = brandColorAlpha)
    val secondary = invertedCometesColor.secondary.toRgb().copyf(alpha = brandColorAlpha)

    base {
        Modifier
            .setVariable(GradientBackgroundColorVariable, background)
            .setVariable(GradientPrimaryColorVariable, primary)
            .setVariable(GradientSecondaryColorVariable, secondary)
            .background(
                Background.of(
                    radialGradient(RadialGradient.Shape.Circle, CSSPosition(50.percent, 50.percent)) {
                        add(GradientBackgroundColorVariable.value(), 0.percent)
                        add(GradientBackgroundColorVariable.value(), 100.percent)
                    }.toImage()
                ),
                Background.of(
                    radialGradient(RadialGradient.Shape.Circle, CSSPosition(15.percent, 80.percent)) {
                        add(GradientPrimaryColorVariable.value(), 0.percent)
                        add(Colors.Transparent, 70.percent)
                    }.toImage()
                ),
                Background.of(
                    radialGradient(RadialGradient.Shape.Circle, CSSPosition(85.percent, 15.percent)) {
                        add(GradientSecondaryColorVariable.value(), 0.percent)
                        add(Colors.Transparent, 70.percent)
                    }.toImage()
                ),
            )
            .transition(
                CometesTransitionOf("--${GradientBackgroundColorVariable.name}"),
                CometesTransitionOf("--${GradientPrimaryColorVariable.name}"),
                CometesTransitionOf("--${GradientSecondaryColorVariable.name}"),
            )
    }
}