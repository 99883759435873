package cometes.components.common

import androidx.compose.runtime.Composable
import androidx.compose.web.events.SyntheticMouseEvent
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.BoxScope
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.RowScope
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.borderTop
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.CssStyleVariant
import cometes.style.BorderSmall
import cometes.style.CometesTheme
import cometes.style.RadiusLarge
import cometes.style.SpaceMedium
import cometes.style.SpaceSmall
import cometes.style.TypographyBodyLarge
import cometes.style.TypographyHeadlineSmall
import cometes.style.TypographyTitleMedium
import cometes.style.system.CometesSurface
import cometes.style.system.CometesSurfaceKind
import cometes.style.system.animation.CometesTransitionOf
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.LineStyle

@Composable
fun CometesCard(
    modifier: Modifier = Modifier,
    variant: CssStyleVariant<CometesSurfaceKind>? = null,
    content: @Composable BoxScope.() -> Unit
) {
    CometesCard(
        content = content,
        variant = variant,
        onClick = null,
        modifier = modifier,
    )
}

@Composable
fun CometesCard(
    modifier: Modifier = Modifier,
    variant: CssStyleVariant<CometesSurfaceKind>? = null,
    onClick: ((SyntheticMouseEvent) -> Unit)? = null,
    content: @Composable BoxScope.() -> Unit
) {
    CometesSurface(
        content = content,
        variant = variant,
        onClick = onClick,
        modifier = Modifier
            .borderRadius(RadiusLarge)
            .border { width(BorderSmall) }
            .padding(SpaceMedium)
            .then(modifier),
    )
}

@Composable
fun CometesCardHeader(
    title: String,
    leadingItem: (@Composable RowScope.() -> Unit)? = null,
    trailingItem: (@Composable RowScope.() -> Unit)? = null,
    modifier: Modifier = Modifier,
) {
    Column(Modifier.fillMaxWidth().then(modifier)) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.fillMaxWidth()
        ) {
            if (leadingItem != null) {
                leadingItem()
                HorizontalSpacer(SpaceSmall)
            }

            DivText(text = title, modifier = TypographyHeadlineSmall)

            Spacer()

            if (trailingItem != null) trailingItem()
        }

        VerticalSpacer(SpaceMedium)
    }
}

@Composable
fun LabeledTextRow(
    label: String,
    value: String,
    modifier: Modifier = Modifier
) {
    LabeledRow(label, modifier) {
        SpanText(value)
    }
}

@Composable
fun LabeledRow(
    label: String,
    modifier: Modifier = Modifier,
    content: @Composable () -> Unit,
) {
    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = Modifier.then(TypographyTitleMedium).then(modifier)
    ) {
        SpanText(label, TypographyBodyLarge)
        Spacer()
        HorizontalSpacer(SpaceMedium)
        content()
    }
}

@Composable
inline fun <T> ItemList(
    items: List<T>,
    dividerColor: CSSColorValue = ListDividerColor,
    itemContent: @Composable (item: T) -> Unit,
) {
    items.forEachIndexed { index, item ->
        itemContent(item)
        if (index != items.lastIndex) CometesDivider(color = dividerColor)
    }
}

@Composable
fun CometesDivider(
    modifier: Modifier = Modifier,
    color: CSSColorValue? = null,
) {
    Box(
        Modifier
            .borderTop(
                width = BorderSmall,
                style = LineStyle.Solid,
                color = color ?: CometesTheme.color.outline
            )
            .transition(CometesTransitionOf("border-color"))
            .fillMaxWidth()
            .then(modifier)
    )
}

val ListDividerColor
    @Composable get() = CometesTheme.color.onSurface.toRgb().copyf(alpha = 0.1f)