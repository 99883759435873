package app.cometes.shared.frontend.base.resource

import androidx.compose.runtime.Composable
import androidx.compose.runtime.Stable
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import app.cometes.shared.frontend.base.ErrorResult
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.error.CommonError
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch

@Stable
internal class ResourceState<T>(
    initialState: T,
    private val onReload: suspend () -> Result<T>,
    private val coroutineScope: CoroutineScope
) {
    private var valueState: T by mutableStateOf(initialState)
    private var isLoading: Boolean by mutableStateOf(false)
    private var errorState: ErrorResult? by mutableStateOf(null)

    fun updateValue(value: T) {
        valueState = value
    }

    val resource: Resource<T> by derivedStateOf {
        val error = errorState
        when {
            isLoading -> Resource.Loading(valueState)
            error != null -> Resource.Error(error, valueState, ::reloadCallback)
            else -> Resource.Value(valueState, ::reloadCallback)
        }
    }

    private fun reloadCallback() = reloadWithLoading { onReload() }

    private fun reloadWithLoading(block: suspend () -> Result<T>) {
        // TODO cancel other reloads even though onReload should not be accessible when loading
        coroutineScope.launch {
            isLoading = true
            errorState = null

            try {
                when (val res = block()) {
                    is Result.Success -> valueState = res.data
                    is Result.Error -> errorState = res.error
                }
            } finally {
                isLoading = false
            }
        }
    }
}


@Composable
internal fun <T> rememberResourceState(
    initialValue: T,
    onReload: suspend () -> Result<T>
): ResourceState<T> {
    val scope = rememberCoroutineScope()
    return remember { ResourceState(initialValue, onReload, scope) }
}

@Composable
internal fun <T> rememberUpdatedResourceState(
    value: T,
    onReload: suspend () -> Result<T>
): ResourceState<T> {
    val scope = rememberCoroutineScope()
    val state = remember { ResourceState(value, onReload, scope) }
    state.updateValue(value)

    return state
}

@Composable
inline fun <T> wrapEmptyResource(body: @Composable () -> Resource<T?>): Resource<T> =
    when (val resource = body()) {
        is Resource.Error -> Resource.Error(resource.error, resource.data, resource.onReload)
        is Resource.Loading -> Resource.Loading(resource.data)
        is Resource.Value -> {
            val data = resource.data
            if (data == null) Resource.Error(CommonError.ResourceNotLoaded, data, resource.onReload)
            else Resource.Value(data, resource.onReload)
        }
    }