package app.cometes.shared.frontend.feature.organization.infrastructure.local

import app.cometes.shared.feature.organization.infrastructure.model.OrganizationDto
import app.cometes.shared.feature.organization.infrastructure.model.OrganizationMemberDto
import app.cometes.shared.feature.reservation.infrastructure.model.LocationDto
import app.cometes.shared.frontend.feature.organization.data.source.OrganizationLocalSource
import com.russhwolf.settings.Settings
import com.russhwolf.settings.get
import com.russhwolf.settings.set
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.serialization.json.Json

class OrganizationLocalSourceImpl(
    private val settings: Settings
) : OrganizationLocalSource {

    private companion object {
        const val CurrentOrganizationKey = "CurrentOrganization"
        const val CurrentLocationKey = "CurrentLocation"
        const val CurrentOrganizationMembershipKey = "CurrentOrganizationMembership"
    }

    // JS storage is not observable :(
    private val organizationStateFlow = MutableStateFlow(retrieveCurrentOrganization())
    private val locationStateFlow = MutableStateFlow(retrieveCurrentLocation())
    private val organizationMembershipStateFlow = MutableStateFlow(retrieveCurrentOrganizationMembership())

    // Organization
    override val currentOrganization: StateFlow<OrganizationDto?> =
        organizationStateFlow.asStateFlow()

    override fun setOrganization(organizationDto: OrganizationDto?) {
        settings[CurrentOrganizationKey] = if (organizationDto != null) {
            Json.encodeToString(OrganizationDto.serializer(), organizationDto)
        } else null
        organizationStateFlow.value = organizationDto
    }

    override val currentLocation: StateFlow<LocationDto?> =
        locationStateFlow.asStateFlow()

    override fun setLocation(locationDto: LocationDto?) {
        settings[CurrentLocationKey] = if (locationDto != null) {
            Json.encodeToString(LocationDto.serializer(), locationDto)
        } else null
        locationStateFlow.value = locationDto
    }

    // Organization Membership
    override val currentOrganizationMembership: StateFlow<OrganizationMemberDto?> =
        organizationMembershipStateFlow.asStateFlow()

    override fun setOrganizationMembership(organizationMemberDto: OrganizationMemberDto?) {
        settings[CurrentOrganizationMembershipKey] = if (organizationMemberDto != null) {
            Json.encodeToString(OrganizationMemberDto.serializer(), organizationMemberDto)
        } else null
        organizationMembershipStateFlow.value = organizationMemberDto
    }

    override fun clear() {
        organizationStateFlow.value = null
        locationStateFlow.value = null
        organizationMembershipStateFlow.value = null
    }

    private fun retrieveCurrentOrganization(): OrganizationDto? {
        val jsonString: String? = settings[CurrentOrganizationKey]
        return if (jsonString != null) {
            Json.decodeFromString(OrganizationDto.serializer(), jsonString)
        } else null
    }

    private fun retrieveCurrentLocation(): LocationDto? {
        val jsonString: String? = settings[CurrentLocationKey]
        return if (jsonString != null) {
            Json.decodeFromString(LocationDto.serializer(), jsonString)
        } else null
    }

    private fun retrieveCurrentOrganizationMembership(): OrganizationMemberDto? {
        val jsonString: String? = settings[CurrentOrganizationMembershipKey]
        return if (jsonString != null) {
            Json.decodeFromString(OrganizationMemberDto.serializer(), jsonString)
        } else null
    }
}
