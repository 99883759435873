package app.cometes.shared.frontend.feature.organization

import app.cometes.shared.frontend.feature.organization.data.repository.OrganizationRepositoryImpl
import app.cometes.shared.frontend.feature.organization.data.source.OrganizationInvitationRemoteSource
import app.cometes.shared.frontend.feature.organization.data.source.OrganizationLocalSource
import app.cometes.shared.frontend.feature.organization.data.source.OrganizationLocationRemoteSource
import app.cometes.shared.frontend.feature.organization.data.source.OrganizationRemoteSource
import app.cometes.shared.frontend.feature.organization.domain.repository.OrganizationRepository
import app.cometes.shared.frontend.feature.organization.infrastructure.local.OrganizationLocalSourceImpl
import app.cometes.shared.frontend.feature.organization.infrastructure.local.OrganizationLocationRemoteSourceImpl
import app.cometes.shared.frontend.feature.organization.infrastructure.remote.OrganizationInvitationRemoteSourceImpl
import app.cometes.shared.frontend.feature.organization.infrastructure.remote.OrganizationRemoteSourceImpl
import org.koin.core.module.dsl.singleOf
import org.koin.dsl.bind
import org.koin.dsl.module

val featureOrganization = module {
    // Sources
    singleOf(::OrganizationRemoteSourceImpl) bind OrganizationRemoteSource::class
    singleOf(::OrganizationLocalSourceImpl) bind OrganizationLocalSource::class
    singleOf(::OrganizationInvitationRemoteSourceImpl) bind OrganizationInvitationRemoteSource::class
    singleOf(::OrganizationLocationRemoteSourceImpl) bind OrganizationLocationRemoteSource::class

    // Repositories
    singleOf(::OrganizationRepositoryImpl) bind OrganizationRepository::class
}