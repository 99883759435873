package cometes.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import app.cometes.shared.frontend.feature.auth.domain.AuthState
import app.cometes.shared.frontend.feature.auth.domain.authStateBlock
import app.cometes.shared.frontend.feature.person.data.currentPerson
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.core.rememberPageContext
import cometes.components.common.DelayedProgressIndicator
import cometes.style.system.animation.fadeInTransition

@Composable
fun webAuthenticationState(): AuthState? {
    val currentPerson = currentPerson()
    val authState = authStateBlock()

    return if (currentPerson != null && authState == AuthState.LoggedOut) null
    else authState
}

@Composable
fun Authenticated(
    content: @Composable () -> Unit
) {
    val context = rememberPageContext()
    val authState = webAuthenticationState()

    LaunchedEffect(authState) {
        when (authState) {
            AuthState.LoggedOut -> context.router.navigateTo("/auth?target=${context.route.slug}")
            AuthState.PersonCreation -> context.router.navigateTo("/auth?target=${context.route.slug}")
            AuthState.OrganizationSelection -> context.router.navigateTo("/auth?target=${context.route.slug}")
            AuthState.Valid, null -> {}
        }
    }

    Box(Modifier.fillMaxSize()) {
        if (authState == AuthState.Valid)
            Box(Modifier.fillMaxSize().fadeInTransition()) { content() }
        else
            DelayedProgressIndicator(Modifier.align(Alignment.Center))
    }
}