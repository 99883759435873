package cometes.components.layout.menu

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.remember
import app.cometes.shared.frontend.base.resource.loadResourceIfEmpty
import app.cometes.shared.frontend.feature.organization.data.currentOrganizationMembershipResource
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.borderRight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.icons.mdi.IconStyle
import com.varabyte.kobweb.silk.components.icons.mdi.MdiBusiness
import com.varabyte.kobweb.silk.components.icons.mdi.MdiDashboard
import com.varabyte.kobweb.silk.components.icons.mdi.MdiDesk
import com.varabyte.kobweb.silk.components.icons.mdi.MdiGroup
import com.varabyte.kobweb.silk.components.icons.mdi.MdiLocationPin
import com.varabyte.kobweb.silk.components.icons.mdi.MdiMeetingRoom
import com.varabyte.kobweb.silk.components.text.SpanText
import cometes.components.common.CometesDivider
import cometes.components.common.DivText
import cometes.components.common.HorizontalSpacer
import cometes.components.common.VerticalSpacer
import cometes.style.BorderSmall
import cometes.style.CometesTheme
import cometes.style.SpaceLarge
import cometes.style.SpaceMedium
import cometes.style.SpaceSmall
import cometes.style.SpaceXSmall
import cometes.style.TypographyBodyMedium
import cometes.style.TypographyBodySmall
import cometes.style.system.ButtonPlainVariant
import cometes.style.system.CometesButton
import cometes.style.system.CometesSurface
import cometes.style.system.SurfaceBackgroundColorVariant
import cometes.util.NavigationPath
import kotlinx.browser.document
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px

private val menuItems = listOf(
    NavigationPath.Root,
    NavigationPath.Organization,
    NavigationPath.Locations,
    NavigationPath.Desks,
    NavigationPath.Rooms,
    NavigationPath.Members,
)

@Composable
fun SideBar(
    modifier: Modifier = Modifier
) {
    val pageContext = rememberPageContext()
    val membership = loadResourceIfEmpty { currentOrganizationMembershipResource() }

    DisposableEffect(Unit) {
        val originalCallback = document.onkeydown
        document.onkeydown = { event ->
            val index = event.key.toIntOrNull()
            if (event.metaKey && index != null) {
                val path = menuItems.getOrNull(index - 1)
                path?.let(pageContext.router::navigateTo)
            }
            // listen
            originalCallback?.invoke(event)
        }
        onDispose { document.onkeydown = originalCallback }
    }

    CometesSurface(
        variant = SurfaceBackgroundColorVariant,
        modifier = modifier
            .borderRight(
                width = BorderSmall,
                style = LineStyle.Solid,
                color = CometesTheme.color.outline
            )
    ) {
        Column(modifier = Modifier.fillMaxSize()) {
            Box(
                modifier = Modifier.fillMaxWidth().padding(SpaceSmall)
            ) {
                OrganizationSwitcher(Modifier.fillMaxWidth())
            }

            Column(
                verticalArrangement = Arrangement.spacedBy(SpaceXSmall),
                modifier = Modifier.fillMaxWidth()
            ) {
                VerticalSpacer(SpaceLarge)
                DivText("Navigation", TypographyBodySmall.opacity(0.9f).padding(leftRight = SpaceMedium))
                MenuItem(
                    icon = { MdiDashboard(style = IconStyle.ROUNDED) },
                    label = "Dashboard",
                    path = menuItems[0],
                )

                MenuItem(
                    icon = { MdiBusiness(style = IconStyle.ROUNDED) },
                    label = "Organization",
                    path = menuItems[1],
                )

                VerticalSpacer(SpaceLarge)
                DivText("Resources", TypographyBodySmall.opacity(0.9f).padding(leftRight = SpaceMedium))

                MenuItem(
                    icon = { MdiLocationPin() },
                    label = "Locations",
                    path = menuItems[2],
                )

                MenuItem(
                    icon = { MdiDesk(style = IconStyle.ROUNDED) },
                    label = "Desks",
                    path = menuItems[3],
                )

                MenuItem(
                    icon = { MdiMeetingRoom(style = IconStyle.ROUNDED) },
                    label = "Rooms",
                    path = menuItems[4],
                )

                VerticalSpacer(SpaceLarge)
                DivText("Management", TypographyBodySmall.opacity(0.9f).padding(leftRight = SpaceMedium))

                MenuItem(
                    icon = { MdiGroup(style = IconStyle.ROUNDED) },
                    label = "Members",
                    path = menuItems[5],
                )
            }

            Spacer()

            CometesDivider()
            VerticalSpacer(SpaceSmall)
            LoggedInUserMenuItem(
                Modifier
                    .fillMaxWidth()
                    .align(Alignment.CenterHorizontally)
                    .padding(leftRight = SpaceMedium, topBottom = SpaceSmall)
            )

            VerticalSpacer(SpaceMedium)
        }
    }
}

@Composable
private fun MenuItem(
    icon: @Composable () -> Unit,
    label: String,
    path: String,
    modifier: Modifier = Modifier,
) {
    val pageContext = rememberPageContext()
    val currentPath = remember {
        if (pageContext.route.path == "/") "/"
        else pageContext.route.path.dropLastWhile { it == '/' }
    }
    val isSelected = path == currentPath

    CometesButton(
        variant = ButtonPlainVariant,
        onClick = { pageContext.router.navigateTo(path) },
        modifier = modifier
            .fillMaxWidth()
            .borderRadius(0.px)
            .thenIf(isSelected) { Modifier.backgroundColor(CometesTheme.color.surface.darkened(0.05f)) }
    ) {
        Box(
            contentAlignment = Alignment.Center,
            modifier = Modifier.size(24.px)
        ) {
            icon()
        }
        HorizontalSpacer(SpaceSmall)
        SpanText(
            text = label,
            modifier = Modifier
                .then(TypographyBodyMedium)
        )
        Spacer()
    }
}