package cometes.components.feature.organization.location

import androidx.compose.runtime.Composable
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationMemberRole
import app.cometes.shared.frontend.feature.reservation.domain.model.Location
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.icons.fa.FaBuilding
import com.varabyte.kobweb.silk.components.icons.fa.FaPlus
import com.varabyte.kobweb.silk.components.icons.fa.FaTrashCan
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.text.DivText
import cometes.components.common.CometesCardHeader
import cometes.components.common.ItemList
import cometes.style.CometesTheme
import cometes.style.SpaceSmall
import cometes.style.SpaceXSmall
import cometes.style.TypographyTitleMedium
import cometes.style.system.CometesImageButton
import cometes.style.system.SurfacePrimaryVariant

@Composable
fun LocationListContent(
    memberRole: OrganizationMemberRole,
    locations: List<Location>,
    onAddLocationClick: () -> Unit,
    onRemoveLocationClick: (location: Location) -> Unit,
    modifier: Modifier = Modifier
) {
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .then(modifier)
    ) {
        CometesCardHeader(
            title = "Locations",
            leadingItem = { FaBuilding(size = IconSize.LG) },
            trailingItem = {
                if (memberRole.isAdmin) {
                    CometesImageButton(
                        onClick = onAddLocationClick,
                        variant = SurfacePrimaryVariant
                    ) { FaPlus() }
                }
            }
        )

        ItemList(locations) { location ->
            LocationItem(
                location = location,
                isInteractive = memberRole.isAdmin,
                omRemoveClick = { onRemoveLocationClick(location) },
                modifier = Modifier
                    .padding(topBottom = SpaceXSmall)
                    .padding(left = SpaceSmall)
                    .fillMaxWidth()
            )
        }
    }
}

@Composable
private fun LocationItem(
    location: Location,
    isInteractive: Boolean,
    omRemoveClick: () -> Unit,
    modifier: Modifier = Modifier,
) {
    Column(modifier) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.fillMaxWidth()
        ) {
            DivText(
                text = location.name,
                modifier = Modifier.then(TypographyTitleMedium)
            )
            if (isInteractive) {
                Spacer()
                CometesImageButton(onClick = omRemoveClick, Modifier.padding(SpaceSmall)) {
                    FaTrashCan(Modifier.color(CometesTheme.color.error))
                }
            }
        }

        if (location.address != null) {
            DivText(location.address ?: "")
        }
    }
}