package cometes.components.common

import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.maxHeight
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.minWidth
import org.jetbrains.compose.web.css.CSSNumeric

fun Modifier.requiredSize(size: CSSNumeric) = Modifier
    .minWidth(size)
    .minHeight(size)
    .maxWidth(size)
    .maxHeight(size)