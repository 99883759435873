package app.cometes.shared.frontend.base

import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow

internal interface Identifiable {
    val id: Long
}

internal interface LocalCacheSource<T : Identifiable> {
    val items: StateFlow<List<T>?>

    fun setCache(items: List<T>)
    fun insertOrUpdate(items: List<T>)
    fun remove(itemId: Long)
    fun clearCache()
}

internal fun <T : Identifiable> LocalCacheSource<T>.insertOrUpdate(item: T) =
    insertOrUpdate(listOf(item))

internal class LocalCacheSourceImpl<T : Identifiable>(
    initialValue: List<T>? = null
) : LocalCacheSource<T> {
    private val cache = mutableMapOf<Long, T>()
    private val _items = MutableStateFlow(initialValue)

    override val items: StateFlow<List<T>?>
        get() = _items.asStateFlow()

    override fun setCache(items: List<T>) {
        cache.clear()
        cache.putAll(items.associateBy(Identifiable::id))
        updatePublicValue()
    }

    override fun insertOrUpdate(items: List<T>) {
        items.forEach { item -> cache[item.id] = item }
        updatePublicValue()
    }

    override fun remove(itemId: Long) {
        cache.remove(itemId)
        updatePublicValue()
    }

    override fun clearCache() {
        cache.clear()
        _items.value = emptyList()
    }

    private fun updatePublicValue() {
        _items.value = cache.values.toList().sortedBy(Identifiable::id)
    }
}