package app.cometes.shared.frontend.feature.organization.domain.model

import androidx.compose.runtime.Immutable
import app.cometes.shared.frontend.feature.conferenceRoom.domain.entity.ConferenceRoom
import app.cometes.shared.frontend.feature.reservation.domain.model.Desk
import app.cometes.shared.frontend.feature.reservation.domain.model.Location

@Immutable
data class Organization(
    val id: Long,
    val name: String,
    val description: String,
    val imageUrl: String?,
)

@Immutable
data class OrganizationDetail(
    val organization: Organization,
    val members: List<OrganizationMemberPerson>,
    val desks: List<Desk>,
    val rooms: List<ConferenceRoom>,
    val locations: List<Location>,
    val invitations: List<OrganizationInvitation>
)