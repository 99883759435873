package app.cometes.shared.feature.room.infrastructure.model

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class CreateConferenceRoomBody(
    val locationId: Long,
    val name: String,
    val description: String,
    val capacity: Int,
)

@Serializable
data class CreateConferenceRoomReservationBody(
    val conferenceRoomId: Long,
    val name: String,
    val description: String,
    val startTime: Instant,
    val endTime: Instant,
)

@Serializable
data class UpdateConferenceRoomReservationBody(
    val conferenceRoomId: Long? = null,
    val name: String? = null,
    val description: String? = null,
    val startTime: Instant? = null,
    val endTime: Instant? = null,
)

@Serializable
data class UpdateConferenceRoomBody(
    val name: String? = null,
    val description: String? = null,
    val capacity: Int? = null,
)
