package app.cometes.shared.frontend.feature.organization.infrastructure.model

import app.cometes.shared.feature.organization.infrastructure.model.OrganizationDetailDto
import app.cometes.shared.feature.organization.infrastructure.model.OrganizationDto
import app.cometes.shared.feature.organization.infrastructure.model.OrganizationInvitationDto
import app.cometes.shared.feature.organization.infrastructure.model.OrganizationInvitationPreviewDto
import app.cometes.shared.feature.organization.infrastructure.model.OrganizationMemberDto
import app.cometes.shared.feature.organization.infrastructure.model.OrganizationMemberPersonDto
import app.cometes.shared.feature.organization.infrastructure.model.OrganizationMemberRoleDto
import app.cometes.shared.feature.organization.infrastructure.model.OrganizationMemberStatusDto
import app.cometes.shared.feature.person.infrastructure.model.OrganizationWithMembershipDto
import app.cometes.shared.feature.reservation.infrastructure.model.DeskDto
import app.cometes.shared.feature.reservation.infrastructure.model.LocationDto
import app.cometes.shared.feature.room.infrastructure.model.ConferenceRoomDto
import app.cometes.shared.frontend.feature.conferenceRoom.domain.entity.toDomain
import app.cometes.shared.frontend.feature.organization.domain.model.Organization
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationDetail
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationInvitation
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationInvitationPreview
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationMember
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationMemberPerson
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationMemberRole
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationMemberStatus
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationWithMembership
import app.cometes.shared.frontend.feature.person.infrastructure.model.toDomain
import app.cometes.shared.frontend.feature.reservation.infrastructure.model.toDomain

internal fun OrganizationWithMembershipDto.toDomain() = OrganizationWithMembership(
    organization.toDomain(),
    membership.toDomain()
)

internal fun OrganizationDto.toDomain() = Organization(id, name, description, imageUrl)
internal fun Organization.toDto() = OrganizationDto(id, name, description, imageUrl)

internal fun OrganizationDetailDto.toDomain(): OrganizationDetail {

    return OrganizationDetail(
        organization.toDomain(),
        members = members.map(OrganizationMemberPersonDto::toDomain),
        desks = desks.map(DeskDto::toDomain),
        rooms = rooms.map(ConferenceRoomDto::toDomain),
        locations = locations.map(LocationDto::toDomain),
        invitations = invitations.map(OrganizationInvitationDto::toDomain)
    )
}

internal fun OrganizationMemberDto.toDomain() = OrganizationMember(
    organizationId = organizationId,
    personId = personId,
    role = roleEnum.toDomain(),
    joinedAt = joinedAt,
    status = statusEnum.toDomain(),
)
internal fun OrganizationMember.toDto() = OrganizationMemberDto(
    organizationId = organizationId,
    personId = personId,
    role = role.toDto()?.name ?: "",
    joinedAt = joinedAt,
    status = status.toDto()?.name ?: "",
)

internal fun OrganizationMemberPersonDto.toDomain() = OrganizationMemberPerson(
    organizationId = organizationId,
    person = person.toDomain(),
    role = roleEnum.toDomain(),
    joinedAt = joinedAt,
    status = statusEnum.toDomain(),
)

internal fun OrganizationInvitationDto.toDomain() = OrganizationInvitation(
    id = invitationId,
    organizationId = organizationId,
    email = inviteeEmail,
    link = invitationLink,
    status = invitationStatus.toDomain(),
    expiresAt = invitationExpiresAt,
)

internal fun OrganizationInvitationPreviewDto.toDomain() = OrganizationInvitationPreview(
    invitationId = invitationId,
    invitationToken = invitationToken,
    invitationCreatedAt = invitationCreatedAt,
    invitationExpiresAt = invitationExpiresAt,
    organizationName = organizationName,
    organizationDescription = organizationDescription,
    organizationImage = organizationImage,
)

internal fun OrganizationMemberRoleDto?.toDomain() = when (this) {
    OrganizationMemberRoleDto.User -> OrganizationMemberRole.User
    OrganizationMemberRoleDto.Admin -> OrganizationMemberRole.Admin
    OrganizationMemberRoleDto.Owner -> OrganizationMemberRole.Owner
    else -> OrganizationMemberRole.Unknown
}

internal fun OrganizationMemberRole.toDto() = when (this) {
    OrganizationMemberRole.User -> OrganizationMemberRoleDto.User
    OrganizationMemberRole.Admin -> OrganizationMemberRoleDto.Admin
    OrganizationMemberRole.Owner -> OrganizationMemberRoleDto.Owner
    OrganizationMemberRole.Unknown -> null
}

internal fun OrganizationMemberStatusDto?.toDomain() = when (this) {
    OrganizationMemberStatusDto.Active -> OrganizationMemberStatus.Active
    OrganizationMemberStatusDto.Suspended -> OrganizationMemberStatus.Suspended
    else -> OrganizationMemberStatus.Unknown
}

internal fun OrganizationMemberStatus.toDto() = when (this) {
    OrganizationMemberStatus.Active -> OrganizationMemberStatusDto.Active
    OrganizationMemberStatus.Suspended -> OrganizationMemberStatusDto.Suspended
    OrganizationMemberStatus.Unknown -> null
}

internal fun OrganizationInvitationDto.Status.toDomain() = when (this) {
    OrganizationInvitationDto.Status.Pending -> OrganizationInvitation.Status.Pending
    OrganizationInvitationDto.Status.Accepted -> OrganizationInvitation.Status.Accepted
    OrganizationInvitationDto.Status.Expired -> OrganizationInvitation.Status.Expired
}