package cometes.components.feature.organization.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.silk.components.icons.mdi.IconStyle
import com.varabyte.kobweb.silk.components.icons.mdi.MdiBusiness
import cometes.components.common.RoundedImage
import cometes.style.RadiusPill
import cometes.style.SpaceXSmall
import cometes.style.system.CometesSurface
import cometes.style.system.SurfaceBackgroundColorVariant
import org.jetbrains.compose.web.css.CSSNumericValue
import org.jetbrains.compose.web.css.CSSUnitLengthOrPercentage

@Composable
fun OrganizationImage(
    imageUrl: String?,
    modifier: Modifier = Modifier,
    borderWidth: CSSNumericValue<out CSSUnitLengthOrPercentage> = SpaceXSmall,
) {
    if (imageUrl != null) {
        RoundedImage(
            imageUrl = imageUrl,
            borderWidth = borderWidth,
            modifier = modifier
        )
    } else {
        CometesSurface(
            variant = SurfaceBackgroundColorVariant,
            modifier = Modifier
                .borderRadius(RadiusPill)
                .then(modifier)
        ) {
            MdiBusiness(
                modifier = Modifier.align(Alignment.Center),
                style = IconStyle.ROUNDED
            )
        }
    }
}