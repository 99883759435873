package app.cometes.shared.frontend.feature.preferences

import app.cometes.shared.frontend.feature.preferences.data.source.PersonPreferencesLocalSource
import app.cometes.shared.frontend.feature.preferences.data.source.PersonPreferencesRemoteSource
import app.cometes.shared.frontend.feature.preferences.infrastructure.source.PersonPreferencesLocalSourceImpl
import app.cometes.shared.frontend.feature.preferences.infrastructure.source.PersonPreferencesRemoteSourceImpl
import org.koin.core.module.dsl.singleOf
import org.koin.dsl.bind
import org.koin.dsl.module

val featurePreferences = module {
    singleOf(::PersonPreferencesLocalSourceImpl) bind PersonPreferencesLocalSource::class
    singleOf(::PersonPreferencesRemoteSourceImpl) bind PersonPreferencesRemoteSource::class
}