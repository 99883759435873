package cometes.pages

import androidx.compose.runtime.Composable
import app.cometes.shared.frontend.feature.reservation.domain.model.Reservation
import app.cometes.shared.frontend.feature.reservation.domain.model.ReservationDay
import app.cometes.shared.frontend.feature.reservation.presentatuion.ReservationOverviewState
import app.cometes.shared.frontend.feature.reservation.presentatuion.ReservationsOverviewPresenter
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.text.SpanText
import cometes.components.Authenticated
import cometes.components.common.ThemeToggleButton
import cometes.components.common.VerticalSpacer
import cometes.components.layout.MainPageLayout
import cometes.style.RadiusLarge
import cometes.style.SpaceLarge
import cometes.style.SpaceMedium
import cometes.style.SpaceMediumSmall
import cometes.style.SpaceSmall
import cometes.style.system.CometesSurface
import cometes.style.system.SurfacePrimaryVariant

@Page
@Composable
fun HomePage() {
    Authenticated {
        val state = ReservationsOverviewPresenter.presenter()

        MainPageLayout(
            title = state.organization?.name ?: "",
            utilRow = { ThemeToggleButton() }
        ) {
            HomeContent(
                state = state,
                modifier = Modifier
                    .fillMaxSize()
                    .padding(SpaceLarge)
            )
        }
    }
}

@Composable
fun HomeContent(
    state: ReservationOverviewState,
    modifier: Modifier = Modifier
) {
    Row(modifier) {
        for (day in state.days.take(5)) {
            DayColumn(day)
            Box(Modifier.width(SpaceLarge))
        }
    }
}

@Composable
fun DayColumn(
    day: ReservationDay,
    modifier: Modifier = Modifier
) {
    Column(
        modifier = modifier
    ) {
        SpanText(day.date.dayOfWeek.toString())
        VerticalSpacer(SpaceLarge)
        for (reservation in day.reservations) {
            ReservationListItem(
                reservation = reservation,
                modifier = Modifier.fillMaxWidth()
            )

            Box(Modifier.height(SpaceMedium))
        }
    }
}

@Composable
fun ReservationListItem(
    reservation: Reservation,
    modifier: Modifier = Modifier
) {
    CometesSurface(
        variant = SurfacePrimaryVariant,
        modifier = modifier
            .borderRadius(RadiusLarge)
            .padding(SpaceMediumSmall)
    ) {
        Column {
            SpanText(reservation.person.fullName)
            VerticalSpacer(SpaceSmall)
            SpanText(reservation.date.toString())
            VerticalSpacer(SpaceSmall)
            SpanText(reservation.desk.name)
        }
    }
}

