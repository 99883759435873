package cometes.style

import org.jetbrains.compose.web.css.px

/**
 * Radius of 4 px
 */
val RadiusSmall = 4.px

/**
 * Radius of 8 px
 */
val RadiusMedium = 8.px

/**
 * Radius of 16 px
 */
val RadiusLarge = 16.px

/**
 * Radius of 24 px
 */
val RadiusXLarge = 24.px

val RadiusPill = 999.px


/**
 * Spacing of 2 px
 */
val SpaceXXSmall = 2.px

/**
 * Spacing of 4 px
 */
val SpaceXSmall = 4.px

/**
 * Spacing of 8 px
 */
val SpaceSmall = 8.px

/**
 * Spacing of 12 px
 */
val SpaceMediumSmall = 12.px

/**
 * Spacing of 16 px
 */
val SpaceMedium = 16.px

/**
 * Spacing of 20 px
 */
val SpaceMediumLarge = 20.px

/**
 * Spacing of 24 px
 */
val SpaceLarge = 24.px

/**
 * Spacing of 32 px
 */
val SpaceXLarge = 32.px

/**
 * Spacing of 44 px
 */
val SpaceXXLarge = 44.px

/**
 * Spacing of 64 px
 */
val SpaceXXXLarge = 64.px

// === BORDER ===
val BorderSmall = 1.px
val BorderMedium = 2.px
val BorderLarge = 3.px