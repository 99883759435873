package app.cometes.shared.frontend.feature.reservation.infrastructure.model

import app.cometes.shared.feature.reservation.infrastructure.model.ReservationDto
import app.cometes.shared.feature.reservation.infrastructure.model.ReservationOriginDto
import app.cometes.shared.frontend.feature.person.infrastructure.model.toDomain
import app.cometes.shared.frontend.feature.reservation.domain.model.Reservation

internal fun ReservationDto.toDomain() = Reservation(
    id,
    date,
    desk.toDomain(),
    person.toDomain(),
    originEnum?.toDomain()
)

fun ReservationOriginDto.toDomain() = when (this) {
    ReservationOriginDto.Manual -> Reservation.Origin.Manual
    ReservationOriginDto.Schedule -> Reservation.Origin.Schedule
    else -> null
}