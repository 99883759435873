package cometes.pages.organization

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import app.cometes.shared.frontend.base.resource.Resource
import app.cometes.shared.frontend.base.resource.combine
import app.cometes.shared.frontend.base.resource.loadResourceIfEmpty
import app.cometes.shared.frontend.feature.conferenceRoom.domain.conferenceRoomsResource
import app.cometes.shared.frontend.feature.conferenceRoom.domain.entity.ConferenceRoom
import app.cometes.shared.frontend.feature.organization.data.currentOrganizationLocationListResource
import app.cometes.shared.frontend.feature.organization.data.currentOrganizationMembership
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationMember
import app.cometes.shared.frontend.feature.reservation.domain.model.Location
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.icons.mdi.MdiAdd
import com.varabyte.kobweb.silk.components.icons.mdi.MdiEdit
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import cometes.components.Authenticated
import cometes.components.common.DivText
import cometes.components.common.HorizontalSpacer
import cometes.components.common.ProgressIndicator
import cometes.components.common.ThemeToggleButton
import cometes.components.common.VerticalSpacer
import cometes.components.feature.organization.EditRoomDialog
import cometes.components.feature.organization.component.ResourceList
import cometes.components.layout.MainPageLayout
import cometes.style.SpaceLarge
import cometes.style.SpaceSmall
import cometes.style.TypographyBodyMedium
import cometes.style.TypographyBodySmall
import cometes.style.system.CometesButton
import cometes.style.system.CometesImageButton

@Page
@Composable
fun RoomsPage() {
    Authenticated {
        val membership = currentOrganizationMembership() ?: return@Authenticated
        val roomsResource = loadResourceIfEmpty { conferenceRoomsResource() }
        val locationsResource = loadResourceIfEmpty { currentOrganizationLocationListResource() }

        val dataResource = roomsResource.combine(locationsResource)

        MainPageLayout(
            title = "Conference Rooms",
            utilRow = { ThemeToggleButton() },
            modifier = Modifier.fillMaxSize()
        ) {
            when (dataResource) {
                is Resource.Value -> {
                    val (rooms, locations) = dataResource.data
                    ConferenceRoomPageContent(
                        userMembership = membership,
                        conferenceRooms = rooms,
                        locations = locations,
                        modifier = Modifier.fillMaxWidth(),
                    )
                }

                is Resource.Loading -> ProgressIndicator(Modifier.align(Alignment.Center))
                is Resource.Error -> DivText("Error :(")
            }
        }
    }
}

@Composable
private fun ConferenceRoomPageContent(
    userMembership: OrganizationMember,
    conferenceRooms: List<ConferenceRoom>,
    locations: List<Location>,
    modifier: Modifier = Modifier
) {
    var newRoomDialogVisible by remember { mutableStateOf(false) }
    var editedRoom by remember { mutableStateOf<ConferenceRoom?>(null) }

    Column(
        modifier = Modifier.fillMaxSize()
    ) {
        if (userMembership.isAdmin) {
            CometesButton(
                onClick = { newRoomDialogVisible = true },
                modifier = Modifier.align(Alignment.End)
            ) {
                MdiAdd()
                HorizontalSpacer(SpaceSmall)
                DivText("New conference room")
            }
            VerticalSpacer(SpaceLarge)
        }

        ResourceList(
            items = conferenceRooms,
            modifier = modifier,
            gridBreakpoint = Breakpoint.MD,
            gridColumns = 3
        ) { room ->
            RoomItem(
                room = room,
                isEditable = userMembership.isAdmin,
                onEditClick = { editedRoom = room },
                modifier = modifier.fillMaxSize()
            )
        }
    }

    if (editedRoom != null || newRoomDialogVisible) {
        EditRoomDialog(
            room = editedRoom,
            locations = locations,
            onDismiss = {
                editedRoom = null
                newRoomDialogVisible = false
            }
        )
    }
}

@Composable
private fun RoomItem(
    room: ConferenceRoom,
    isEditable: Boolean,
    onEditClick: () -> Unit,
    modifier: Modifier = Modifier
) {
    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = modifier
    ) {
        Column {
            DivText(room.name, TypographyBodyMedium)
            DivText("Capacity: ${room.capacity}", TypographyBodySmall.opacity(0.6))
        }
        Spacer()

        if (isEditable) {
            CometesImageButton(
                onClick = { onEditClick() },
                modifier = Modifier.align(Alignment.Top)
            ) { MdiEdit() }
        }
    }
}
