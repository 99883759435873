package app.cometes.shared.frontend.feature.billing.infrasctructure.model

import app.cometes.shared.feature.person.infrastructure.model.SubscriptionStatusDto
import app.cometes.shared.frontend.feature.billing.domain.model.SubscriptionStatus

fun SubscriptionStatusDto.toDomain() = SubscriptionStatus(
    subscriptionStatus = subscriptionStatus.toDomain(),
    quantity = quantity,
    renewAt = renewAt,
    wilCancelAtPeriodEnd = wilCancelAtPeriodEnd,
    isInTrial = isTrial
)

fun SubscriptionStatusDto.Status.toDomain() = when (this) {
    SubscriptionStatusDto.Status.Active -> SubscriptionStatus.Status.Active
    SubscriptionStatusDto.Status.Inactive -> SubscriptionStatus.Status.Inactive
}