package app.cometes.shared.frontend.feature.organization.domain.model

import androidx.compose.runtime.Immutable
import app.cometes.shared.frontend.base.Identifiable
import kotlinx.datetime.Instant

@Immutable
data class OrganizationInvitation(
    override val id: Long,
    val organizationId: Long,
    val email: String,
    val link: String,
    val status: Status,
    val expiresAt: Instant,
) : Identifiable {

    enum class Status { Pending, Accepted, Expired }
}

@Immutable
data class OrganizationInvitationPreview(
    val invitationId: Long,
    val invitationToken: String,
    val invitationCreatedAt: Instant,
    val invitationExpiresAt: Instant,
    val organizationName: String,
    val organizationDescription: String,
    val organizationImage: String?,
)