package cometes.components.feature.organization.invitation

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import app.cometes.shared.frontend.base.ErrorResult
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.feature.organization.data.createInvitationMutation
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.silk.components.text.DivText
import com.varabyte.kobweb.silk.components.text.SpanText
import cometes.components.common.CometesDialog
import cometes.components.common.CometesLoadingButton
import cometes.components.common.HorizontalSpacer
import cometes.components.common.LabeledRow
import cometes.components.common.VerticalSpacer
import cometes.style.CometesTheme
import cometes.style.SpaceMedium
import cometes.style.TypographyBodyLarge
import cometes.style.system.CometesButton
import cometes.style.system.SurfaceBackgroundColorVariant
import cometes.style.system.SurfacePrimaryVariant
import cometes.style.system.input.CometesTextField
import kotlinx.coroutines.launch

@Composable
fun InvitationCreationDialog(
    onDismiss: () -> Unit,
) {
    val coroutineScope = rememberCoroutineScope()
    var isLoading: Boolean by remember { mutableStateOf(false) }

    var email: String by remember { mutableStateOf("") }
    var error: ErrorResult? by remember { mutableStateOf(null) }

    val createInvitationMutation = createInvitationMutation(email)
    fun onCreateClick() {
        coroutineScope.launch {
            error = null
            isLoading = true
            when (val res = createInvitationMutation.execute()) {
                is Result.Success -> onDismiss()
                is Result.Error -> error = res.error
            }
            isLoading = false
        }
    }

    CometesDialog(
        title = "Invite colleague",
        onDismiss = onDismiss,
        submitButtons = {
            CometesButton(
                onDismiss,
                variant = SurfaceBackgroundColorVariant
            ) { SpanText("Cancel") }
            HorizontalSpacer(SpaceMedium)
            CometesLoadingButton(
                isLoading = isLoading,
                onClick = { onCreateClick() },
                enabled = email.isNotBlank(),
                variant = SurfacePrimaryVariant
            ) {
                SpanText("Create invitation")
            }
        }
    ) {
        Column(
            Modifier
                .fillMaxWidth()
                .then(TypographyBodyLarge)
        ) {
            LabeledRow("Email:", Modifier.fillMaxWidth()) {
                CometesTextField(
                    value = email,
                    onValueChange = { email = it }
                )
            }

            if (error != null) {
                VerticalSpacer(SpaceMedium)
                DivText(
                    text = error?.message ?: "",
                    modifier = Modifier.color(CometesTheme.color.error)
                )
            }
        }
    }
}