package app.cometes.shared.frontend.base.error

import app.cometes.shared.frontend.base.ErrorResult

sealed class PersonError(
    message: String?,
    innerException: Exception?
) : ErrorResult(message, innerException) {

    class NotFound(exception: BackendException) : PersonError(
        "Person not found.",
        exception
    )

    class PersonAlreadyExists(exception: BackendException) : PersonError(
        message = "Person already exists",
        innerException = exception
    )
}
