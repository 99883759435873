package app.cometes.shared.frontend.feature.organization.data.repository

import app.cometes.shared.frontend.feature.organization.data.source.OrganizationLocalSource
import app.cometes.shared.frontend.feature.organization.domain.repository.OrganizationInvitationRepository

class OrganizationInvitationRepositoryImpl(
    private val localOrganizationSource: OrganizationLocalSource,
) : OrganizationInvitationRepository {

    override fun setPendingInvitation(token: String) {
        localOrganizationSource.setPendingInvitationToken(token)
    }

}