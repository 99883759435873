package cometes.components.layout

import androidx.compose.runtime.Composable
import app.cometes.shared.frontend.feature.auth.data.firebaseUserEmail
import com.varabyte.kobweb.compose.css.autoLength
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flex
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.silk.components.icons.mdi.IconStyle
import com.varabyte.kobweb.silk.components.icons.mdi.MdiLogout
import com.varabyte.kobweb.silk.components.text.SpanText
import cometes.components.common.DivText
import cometes.components.common.HorizontalSpacer
import cometes.components.common.ThemeToggleButton
import cometes.components.image.ImageCometes
import cometes.style.SpaceLarge
import cometes.style.SpaceMedium
import cometes.style.SpaceMediumLarge
import cometes.style.SpaceMediumSmall
import cometes.style.TypographyBodyMedium
import cometes.style.TypographyHeadlineMedium
import cometes.style.system.ButtonSecondaryVariant
import cometes.style.system.CometesImageButton
import cometes.style.system.CometesSurface
import cometes.style.system.SurfaceBackgroundColorVariant
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun AuthPageLayout(
    title: String,
    onLogoutClick: () -> Unit,
    content: @Composable () -> Unit
) {
    CometesSurface(
        variant = SurfaceBackgroundColorVariant,
        modifier = Modifier.fillMaxSize(),
    ) {
        Column(Modifier.fillMaxSize()) {
            CometesSurface(
                Modifier
                    .fillMaxWidth()
                    .padding(topBottom = SpaceMediumSmall)
            ) {
                Row(
                    verticalAlignment = Alignment.CenterVertically,
                    modifier = containerModifier,
                ) {
                    ImageCometes(Modifier.size(40.px))
                    HorizontalSpacer(SpaceLarge)
                    SpanText(title, Modifier.then(TypographyHeadlineMedium))

                    Spacer()

                    UserEmailHint()

                    HorizontalSpacer(SpaceMedium)

                    ThemeToggleButton()

                    HorizontalSpacer(SpaceMedium)

                    CometesImageButton(
                        onClick = { onLogoutClick() },
                        variant = ButtonSecondaryVariant
                    ) {
                        MdiLogout(style = IconStyle.ROUNDED)
                    }
                }
            }

            Box(containerModifier) {
                content()
            }
        }
    }
}

private val containerModifier = Modifier
    .fillMaxWidth()
    .maxWidth(64.cssRem)
    .flex(1, 1, 0.percent)
    .margin(leftRight = autoLength)
    .padding(leftRight = SpaceMediumLarge)

@Composable
private fun UserEmailHint(modifier: Modifier = Modifier) {
    val userEmail = firebaseUserEmail()
    if (userEmail != null) {
        DivText(
            text = userEmail,
            modifier = Modifier
                .then(TypographyBodyMedium)
                .then(modifier)
        )
    }
}