package app.cometes.shared.frontend.feature.organization.data

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import app.cometes.shared.feature.organization.infrastructure.model.OrganizationDto
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.map
import app.cometes.shared.frontend.base.resource.EmptyInputMutation
import app.cometes.shared.frontend.base.resource.EmptyMutation
import app.cometes.shared.frontend.base.resource.Mutation
import app.cometes.shared.frontend.base.resource.Resource
import app.cometes.shared.frontend.base.resource.ResourceState
import app.cometes.shared.frontend.base.resource.rememberInputMutation
import app.cometes.shared.frontend.base.resource.rememberMutation
import app.cometes.shared.frontend.base.resource.rememberUpdatedResourceState
import app.cometes.shared.frontend.feature.organization.data.source.OrganizationLocalSource
import app.cometes.shared.frontend.feature.organization.data.source.OrganizationRemoteSource
import app.cometes.shared.frontend.feature.organization.domain.model.Organization
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationError
import app.cometes.shared.frontend.feature.organization.infrastructure.model.toDomain
import app.cometes.shared.frontend.feature.organization.infrastructure.model.toDto
import app.cometes.shared.frontend.util.rememberKoinDependency
import app.cometes.shared.frontend.util.withUseCaseContext

@Composable
internal fun currentOrganization(): Organization? {
    val organizationLocalSource = rememberKoinDependency<OrganizationLocalSource>()
    val localDto by organizationLocalSource.currentOrganization.collectAsState()

    return localDto?.toDomain()
}

@Composable
internal fun currentOrganizationResource(): Resource<Organization> {
    val organizationRemoteSource = rememberKoinDependency<OrganizationRemoteSource>()
    val organizationLocalSource = rememberKoinDependency<OrganizationLocalSource>()

    val localDto by remember { organizationLocalSource.currentOrganization }.collectAsState()
    val organization = localDto?.toDomain()
        ?: return noCurrentOrganizationResourceError()

    val resourceState: ResourceState<Organization> = rememberUpdatedResourceState(organization) {
        val res = withUseCaseContext { organizationRemoteSource.getOrganization(organization.id) }
        if (res is Result.Success) organizationLocalSource.setOrganization(res.data)
        res.map(OrganizationDto::toDomain)
    }

    return resourceState.resource
}


@Composable
fun setCurrentOrganizationMutation(): EmptyInputMutation<Organization> {
    val organizationLocalSource = rememberKoinDependency<OrganizationLocalSource>()

    return rememberInputMutation { organization ->
        organizationLocalSource.setOrganization(organization.toDto())
        Result.Success(Unit)
    }
}

@Composable
fun clearCurrentOrganizationMutation(): EmptyMutation {
    val organizationLocalSource = rememberKoinDependency<OrganizationLocalSource>()

    return rememberMutation {
        organizationLocalSource.setOrganization(null)
        Result.Success(Unit)
    }
}

@Composable
fun createOrganizationMutation(name: String, description: String): Mutation<Organization> {
    val organizationSource = rememberKoinDependency<OrganizationRemoteSource>()

    return rememberMutation(name, description) {
        withUseCaseContext { organizationSource.createOrganization(name, description) }
            .map(OrganizationDto::toDomain)
    }
}

fun <T> noCurrentOrganizationResourceError() =
    Resource.Error<T>(error = OrganizationError.NoCurrentOrganization, data = null, onReload = {})
