package app.cometes.shared.frontend.feature.auth.infrasctructure

import app.cometes.shared.frontend.feature.auth.data.AuthLocalSource
import com.russhwolf.settings.Settings
import com.russhwolf.settings.get
import com.russhwolf.settings.set

class AuthLocalSourceImpl(
    private val settings: Settings
) : AuthLocalSource {
    private val EmailCacheKey = "EMAIL_MAGIC_CACHE"

    override var loginEmail: String?
        get() = settings[EmailCacheKey]
        set(value) {
            settings[EmailCacheKey] = value
        }

    override fun clearLoginEmail() {
        settings.remove(EmailCacheKey)
    }
}