package app.cometes.shared.feature.reservation.infrastructure.model

import app.cometes.shared.feature.person.infrastructure.model.PersonDto
import kotlinx.datetime.LocalDate
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient

enum class ReservationOriginDto {
    Manual, Schedule
}

@Serializable
data class ReservationDto(
    val id: Long,
    val date: LocalDate,
    val desk: DeskDto,
    val person: PersonDto,
    val origin: String? = null,
) {
    @Transient
    val originEnum: ReservationOriginDto? = when (origin) {
        "Manual" -> ReservationOriginDto.Manual
        "Schedule" -> ReservationOriginDto.Schedule
        else -> null
    }
}