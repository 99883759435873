package cometes.components.common

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.util.withUseCaseContext
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.ColumnScope
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.silk.components.text.SpanText
import cometes.style.SpaceMedium
import cometes.style.TypographyBodyLarge
import cometes.style.system.CometesButton
import cometes.style.system.SurfaceBackgroundColorVariant
import cometes.style.system.SurfaceErrorVariant
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.px

@Composable
fun RemovalDialog(
    title: String,
    onDismiss: () -> Unit,
    onRemoveClick: suspend () -> Result<Any>,
    content: @Composable ColumnScope.() -> Unit
) {
    val coroutineScope = rememberCoroutineScope()
    var isLoading by remember { mutableStateOf(false) }

    fun remove() {
        coroutineScope.launch {
            isLoading = true
            val res = withUseCaseContext { onRemoveClick() }
            isLoading = false
            if (res is Result.Success) onDismiss()
        }
    }

    CometesDialog(
        title = title,
        onDismiss = onDismiss,
        submitButtons = {
            CometesButton(
                onDismiss,
                variant = SurfaceBackgroundColorVariant
            ) { SpanText("Cancel") }
            HorizontalSpacer(SpaceMedium)
            CometesLoadingButton(
                isLoading = isLoading,
                onClick = ::remove,
                variant = SurfaceErrorVariant
            ) {
                SpanText("Remove")
            }
        },
        modifier = Modifier.maxWidth(500.px)
    ) {
        Column(Modifier.then(TypographyBodyLarge)) {
            content()
        }
    }
}