package app.cometes.shared.frontend.feature.person.data

import androidx.compose.runtime.Composable
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.mapToEmpty
import app.cometes.shared.frontend.base.resource.EmptyMutation
import app.cometes.shared.frontend.base.resource.alsoOnSuccess
import app.cometes.shared.frontend.base.resource.rememberMutation
import app.cometes.shared.frontend.feature.auth.data.logOutMutation
import app.cometes.shared.frontend.feature.person.data.source.PersonLocalSource
import app.cometes.shared.frontend.feature.person.data.source.PersonRemoteSource
import app.cometes.shared.frontend.util.withUseCaseContext
import org.koin.compose.koinInject

@Composable
fun registerMutation(firstName: String, lastName: String): EmptyMutation {
    val personRemoteSource = koinInject<PersonRemoteSource>()
    val personLocalSource = koinInject<PersonLocalSource>()

    return rememberMutation(firstName, lastName) {
        withUseCaseContext { personRemoteSource.registerPerson(firstName, lastName) }
            .alsoOnSuccess { personDto -> personLocalSource.person = personDto }
            .mapToEmpty()
    }
}

@Composable
fun removeUserDataAndLogOutMutation(): EmptyMutation {
    val personRemoteSource = koinInject<PersonRemoteSource>()
    val logOut = logOutMutation()

    return rememberMutation {
        val result = withUseCaseContext { personRemoteSource.removeUserData() }

        when (result) {
            is Result.Success -> {
                logOut.execute()
                Result.Success(Unit)
            }

            is Result.Error -> Result.Error(result.error)
        }
    }
}