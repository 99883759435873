package app.cometes.shared.frontend.feature.preferences.infrastructure.source

import app.cometes.shared.frontend.feature.preferences.data.source.PersonPreferencesLocalSource
import com.russhwolf.settings.Settings
import com.russhwolf.settings.get
import com.russhwolf.settings.set
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow

private object PrefKey {
    const val MaterialYou = "MaterialYouEnabled"
}

private object PrefDefaultValue {
    const val MaterialYou = false
}

class PersonPreferencesLocalSourceImpl(
    private val settings: Settings,
) : PersonPreferencesLocalSource {

    private val materialEnabledStateFlow = MutableStateFlow(androidMaterialYouEnabled)

    override var androidMaterialYouEnabled: Boolean
        get() = settings[PrefKey.MaterialYou]
            ?: PrefDefaultValue.MaterialYou
        set(value) {
            settings[PrefKey.MaterialYou] = value
            materialEnabledStateFlow.value = value
        }

    override val androidMaterialYouEnabledFlow: StateFlow<Boolean>
        get() = materialEnabledStateFlow.asStateFlow()
}