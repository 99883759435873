package cometes.components.common

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.produceState
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.rotate
import com.varabyte.kobweb.silk.components.icons.fa.FaSpinner
import cometes.style.system.animation.fadeInTransition
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.css.deg
import org.jetbrains.compose.web.css.plus

@Composable
fun ProgressIndicator(modifier: Modifier = Modifier) {
    val rotation by produceState(0.deg) {
        while (true) {
            value += 45.deg
            delay(100)
        }
    }

    FaSpinner(modifier.rotate(rotation))
}

@Composable
fun DelayedProgressIndicator(modifier: Modifier = Modifier) {
    var isProgressVisible by remember { mutableStateOf(false) }
    LaunchedEffect(Unit) {
        // delay the progress indicator so it only shows up when long load is happening
        delay(ProgressDelay)
        isProgressVisible = true
    }

    if (isProgressVisible) {
        ProgressIndicator(
            Modifier
                .fadeInTransition()
                .then(modifier)
        )
    }
}

private const val ProgressDelay = 400L