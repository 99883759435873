package cometes.components.layout.menu

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import app.cometes.shared.frontend.feature.auth.data.logOutMutation
import app.cometes.shared.frontend.feature.person.data.currentPerson
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.silk.components.icons.mdi.IconStyle
import com.varabyte.kobweb.silk.components.icons.mdi.MdiAccountCircle
import com.varabyte.kobweb.silk.components.icons.mdi.MdiExpandLess
import com.varabyte.kobweb.silk.components.icons.mdi.MdiExpandMore
import com.varabyte.kobweb.silk.components.icons.mdi.MdiLogout
import com.varabyte.kobweb.silk.components.overlay.PopupPlacement
import com.varabyte.kobweb.silk.components.overlay.PopupPlacementStrategy
import cometes.components.common.DivText
import cometes.components.common.HorizontalSpacer
import cometes.components.common.PopoverMenuContent
import cometes.components.common.PopoverMenuItem
import cometes.components.common.PopoverMenuLayout
import cometes.style.SpaceSmall
import cometes.style.SpaceXSmall
import cometes.style.TypographyBodySmall
import cometes.style.TypographyTitleSmall
import cometes.style.system.ButtonPlainVariant
import cometes.style.system.CometesImageButton
import kotlinx.browser.window
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.cssRem

@Composable
fun LoggedInUserMenuItem(modifier: Modifier = Modifier) {
    val person = currentPerson() ?: return
    val logOut = logOutMutation()
    val actionScope = rememberCoroutineScope()

    var isPopupOpen by remember { mutableStateOf(false) }

    PopoverMenuLayout(
        isVisible = isPopupOpen,
        onDismiss = { isPopupOpen = false },
        popoverContent = {
            PopoverMenuContent(modifier = Modifier.fillMaxWidth()) {
                PopoverMenuItem(
                    icon = { MdiAccountCircle(style = IconStyle.ROUNDED, modifier = Modifier.fontSize(1.2.cssRem)) },
                    title = "Profile",
                    subtitle = person.fullName,
                    onClick = { window.alert("Not yet implemented :)") },
                )
                PopoverMenuItem(
                    icon = { MdiLogout(style = IconStyle.ROUNDED, modifier = Modifier.fontSize(1.2.cssRem)) },
                    title = "Log out",
                    onClick = { actionScope.launch { logOut.execute() } },
                )
            }
        },
        placementStrategy = PopupPlacementStrategy.of(PopupPlacement.Top, SpaceXSmall.value),
        modifier = modifier,
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceAround,
            modifier = Modifier.fillMaxWidth()
        ) {
            Column {
                DivText(person.fullName, TypographyTitleSmall)
                DivText(person.email, TypographyBodySmall)
            }
            HorizontalSpacer(SpaceSmall)
            CometesImageButton(
                onClick = { event -> isPopupOpen = !isPopupOpen },
                variant = ButtonPlainVariant,
            ) {
                if (isPopupOpen) MdiExpandMore() else MdiExpandLess()
            }
        }
    }
}