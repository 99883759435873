package app.cometes.shared.frontend.base.resource

import androidx.compose.runtime.*
import app.cometes.shared.frontend.base.ErrorResult
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.error.CommonError
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch

@Stable
internal class ResourceState<T>(
    initialState: T,
    private val coroutineScope: CoroutineScope,
    private val onReload: suspend (silent: Boolean) -> Result<T>
) {
    private var loadJob: Job? = null
    private var valueState: T by mutableStateOf(initialState)
    private var isLoading: Boolean by mutableStateOf(false)
    private var errorState: ErrorResult? by mutableStateOf(null)

    fun updateValue(value: T) {
        valueState = value
    }

    val resource: Resource<T> by derivedStateOf {
        val error = errorState
        when {
            isLoading -> Resource.Loading(valueState)
            error != null -> Resource.Error(error, valueState, ::reloadCallback)
            else -> Resource.Value(valueState, ::reloadCallback)
        }
    }

    private fun reloadCallback(silent: Boolean) {
        loadJob?.cancel()
        loadJob = coroutineScope.launch {
            isLoading = !silent
            errorState = null

            try {
                when (val res = onReload(silent)) {
                    is Result.Success -> valueState = res.data
                    is Result.Error -> errorState = res.error
                }
            } finally {
                isLoading = false
            }
        }
    }
}


@Composable
internal fun <T> rememberResourceState(
    initialValue: T,
    onReload: suspend (silent: Boolean) -> Result<T>
): ResourceState<T> {
    val reloadCallback by rememberUpdatedState(onReload)
    val scope = rememberCoroutineScope()
    return remember {
        ResourceState(
            initialState = initialValue,
            coroutineScope = scope,
            onReload = { silent -> reloadCallback(silent) }
        )
    }
}

@Composable
internal fun <T> rememberUpdatedResourceState(
    value: T,
    onReload: suspend (silent: Boolean) -> Result<T>
): ResourceState<T> {
    val reloadCallback by rememberUpdatedState(onReload)
    val scope = rememberCoroutineScope()
    val state = remember {
        ResourceState(
            initialState = value,
            coroutineScope = scope,
            onReload = { silent -> reloadCallback(silent) }
        )
    }
    state.updateValue(value)

    return state
}

@Composable
inline fun <T> wrapEmptyResource(body: @Composable () -> Resource<T?>): Resource<T> =
    when (val resource = body()) {
        is Resource.Error -> Resource.Error(resource.error, resource.data, resource.onReload)
        is Resource.Loading -> Resource.Loading(resource.data)
        is Resource.Value -> {
            val data = resource.data
            if (data == null) Resource.Error(CommonError.ResourceNotLoaded, null, resource.onReload)
            else Resource.Value(data, resource.onReload)
        }
    }