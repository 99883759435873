package cometes.components.feature.organization.location

import androidx.compose.runtime.Composable
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationMemberRole
import app.cometes.shared.frontend.feature.reservation.domain.model.Desk
import app.cometes.shared.frontend.feature.reservation.domain.model.Location
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.icons.fa.FaCube
import com.varabyte.kobweb.silk.components.icons.fa.FaPlus
import com.varabyte.kobweb.silk.components.icons.fa.FaTrashCan
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.text.DivText
import cometes.components.common.CometesCardHeader
import cometes.components.common.ItemList
import cometes.style.CometesTheme
import cometes.style.SpaceSmall
import cometes.style.SpaceXSmall
import cometes.style.TypographyTitleMedium
import cometes.style.TypographyTitleSmall
import cometes.style.system.CometesImageButton
import cometes.style.system.SurfacePrimaryVariant

@Composable
fun DeskListContent(
    memberRole: OrganizationMemberRole,
    desks: List<Desk>,
    locations: List<Location>,
    onAddDeskClick: () -> Unit,
    onRemoveDeskClick: (desk: Desk) -> Unit,
    modifier: Modifier = Modifier
) {
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .then(modifier)
    ) {
        CometesCardHeader(
            title = "Desks",
            leadingItem = { FaCube(size = IconSize.LG) },
            trailingItem = {
                if (memberRole.isAdmin) {
                    CometesImageButton(
                        onClick = onAddDeskClick,
                        variant = SurfacePrimaryVariant
                    ) { FaPlus() }
                }
            }
        )

        ItemList(desks) { desk ->
            DeskItem(
                desk,
                location = locations.first { it.id == desk.locationId },
                interactive = memberRole.isAdmin,
                onRemove = { onRemoveDeskClick(desk) },
                modifier = Modifier
                    .padding(topBottom = SpaceXSmall)
                    .padding(left = SpaceSmall)
                    .fillMaxWidth()
            )
        }
    }
}

@Composable
private fun DeskItem(
    desk: Desk,
    location: Location,
    interactive: Boolean,
    onRemove: () -> Unit,
    modifier: Modifier = Modifier
) {
    Column(modifier = modifier) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.fillMaxWidth()
        ) {
            DivText(
                text = desk.name,
                modifier = Modifier.then(TypographyTitleMedium)
            )
            if (interactive) {
                Spacer()
                CometesImageButton(onClick = onRemove, Modifier.padding(SpaceSmall)) {
                    FaTrashCan(Modifier.color(CometesTheme.color.error))
                }
            }
        }
        DivText(location.name, TypographyTitleSmall)
    }
}