package cometes.components.common

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.ColumnScope
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.RowScope
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.silk.components.icons.mdi.MdiExpandLess
import com.varabyte.kobweb.silk.components.icons.mdi.MdiExpandMore
import com.varabyte.kobweb.silk.components.overlay.PopupPlacement
import com.varabyte.kobweb.silk.components.overlay.PopupPlacementStrategy
import com.varabyte.kobweb.silk.style.toModifier
import cometes.style.SpaceXSmall
import cometes.style.TypographyLabelMedium
import cometes.style.system.CometesSurface
import cometes.style.system.input.CometesTextFieldStyle
import cometes.style.system.input.CometesTextFieldSurfaceVariant
import org.jetbrains.compose.web.css.px

@Composable
fun CometesDropDown(
    mainContent: @Composable RowScope.() -> Unit,
    modifier: Modifier = Modifier,
    label: String? = null,
    enabled: Boolean = true,
    placementStrategy: PopupPlacementStrategy = PopupPlacementStrategy.of(PopupPlacement.Bottom, SpaceXSmall.value),
    dropDownContent: @Composable ColumnScope.() -> Unit,
) {
    var isVisible by remember { mutableStateOf(false) }

    PopoverMenuLayout(
        isVisible = isVisible,
        onDismiss = { isVisible = false },
        popoverContent = {
            PopoverMenuContent(Modifier.fillMaxWidth()) {
                Column(Modifier.fillMaxWidth()) {
                    dropDownContent()
                }
            }
        },
        modifier = modifier,
        placementStrategy = placementStrategy,
        popoverSpacing = 0.px,
    ) {
        Column(
            verticalArrangement = Arrangement.spacedBy(SpaceXSmall),
            modifier = Modifier
                .then(modifier)
        ) {
            if (label != null) {
                DivText(label, TypographyLabelMedium)
            }
            CometesSurface(
                onClick = { isVisible = !isVisible },
                enabled = enabled,
                modifier = Modifier
                    .then(CometesTextFieldStyle.toModifier(CometesTextFieldSurfaceVariant))
                    .fillMaxWidth()
            ) {
                Row(
                    verticalAlignment = Alignment.CenterVertically,
                    modifier = Modifier.fillMaxWidth()
                ) {
                    mainContent()
                    Spacer()
                    if (isVisible) MdiExpandLess() else MdiExpandMore()
                }
            }
        }
    }
}