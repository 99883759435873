package app.cometes.shared.frontend.feature.organization.presentation

import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.runtime.Stable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import app.cash.molecule.RecompositionMode
import app.cash.molecule.moleculeFlow
import app.cometes.shared.frontend.base.ErrorResult
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.resource.Resource
import app.cometes.shared.frontend.base.resource.alsoOnError
import app.cometes.shared.frontend.base.resource.isLoading
import app.cometes.shared.frontend.base.resource.loadResourceIfEmpty
import app.cometes.shared.frontend.feature.auth.data.logOutMutation
import app.cometes.shared.frontend.feature.organization.data.acceptInvitationMutation
import app.cometes.shared.frontend.feature.organization.data.clearPendingInvitationMutation
import app.cometes.shared.frontend.feature.organization.data.invitationOrganizationResource
import app.cometes.shared.frontend.feature.organization.data.pendingInvitationValue
import app.cometes.shared.frontend.feature.organization.data.setCurrentOrganizationMutation
import app.cometes.shared.frontend.feature.organization.domain.model.Organization
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationError
import app.cometes.shared.frontend.feature.organization.presentation.OrganizationSelectionIntent.AcceptInvitation
import app.cometes.shared.frontend.feature.organization.presentation.OrganizationSelectionIntent.DeclineInvitation
import app.cometes.shared.frontend.feature.organization.presentation.OrganizationSelectionIntent.LogOut
import app.cometes.shared.frontend.feature.organization.presentation.OrganizationSelectionIntent.SelectOrganization
import app.cometes.shared.frontend.feature.organization.presentation.OrganizationSelectionIntent.TryAgain
import app.cometes.shared.frontend.feature.person.data.personsOrganizationsResource
import com.rickclephas.kmp.nativecoroutines.NativeCoroutines
import kotlinx.coroutines.launch

@Immutable
data class OrganizationSelectionState(
    val organizations: List<Organization>,
    val invitationOrganization: Organization?,

    val acceptationInProgress: Boolean,
    val isLoading: Boolean,
    val error: ErrorResult?,

    val onIntent: (OrganizationSelectionIntent) -> Unit
)

sealed interface OrganizationSelectionIntent {
    class SelectOrganization(val organization: Organization) : OrganizationSelectionIntent
    object AcceptInvitation : OrganizationSelectionIntent
    object DeclineInvitation : OrganizationSelectionIntent
    object TryAgain : OrganizationSelectionIntent
    object LogOut : OrganizationSelectionIntent
}

sealed interface OrganizationSelectionEvent {
    class Error(val errorResult: ErrorResult) : OrganizationSelectionEvent
}

@Stable
class OrganizationSelectionPresenter(
    private val onEvent: (OrganizationSelectionEvent) -> Unit
) {

    @Composable
    fun presenterState(): OrganizationSelectionState {
        val scope = rememberCoroutineScope()
        var acceptationInProgress by remember { mutableStateOf(false) }

        // Resources
        val organizationMemberships = loadResourceIfEmpty { personsOrganizationsResource() }
        val pendingInvitation = pendingInvitationValue()
        val invitationOrganization = if (pendingInvitation != null) {
            loadResourceIfEmpty { invitationOrganizationResource(pendingInvitation) }
        } else null

        // Mutations
        val logOutMutation = logOutMutation()
        val setCurrentOrganizationMutation = setCurrentOrganizationMutation()
        val acceptInvitationMutation =
            if (pendingInvitation != null) acceptInvitationMutation(pendingInvitation)
            else null

        val clearInvitationMutation = clearPendingInvitationMutation()

        val isLoading = organizationMemberships.isLoading ||
                (invitationOrganization?.isLoading ?: false)
        val error = when {
            organizationMemberships is Resource.Error -> organizationMemberships.error
            invitationOrganization is Resource.Error -> {
                if (invitationOrganization.error::class in OrganizationError.invitationErrors) {
                    null // TODO
                } else invitationOrganization.error
            }

            else -> null
        }

        return OrganizationSelectionState(
            organizations = organizationMemberships.data
                ?.map { membership -> membership.organization }
                ?: emptyList(),
            invitationOrganization = invitationOrganization?.data,
            acceptationInProgress = acceptationInProgress,
            isLoading = isLoading,
            error = error
        ) { intent ->
            when (intent) {
                is SelectOrganization -> {
                    scope.launch {
                        setCurrentOrganizationMutation.execute(intent.organization)
                            .alsoOnError { onEvent(OrganizationSelectionEvent.Error(it)) }
                    }
                }

                AcceptInvitation -> {
                    val acceptMutation = acceptInvitationMutation
                        ?: return@OrganizationSelectionState

                    scope.launch {
                        acceptationInProgress = true
                        val organization = when (val res = acceptMutation.execute()) {
                            is Result.Success -> res.data
                            is Result.Error -> {
                                onEvent(OrganizationSelectionEvent.Error(res.error))
                                acceptationInProgress = false
                                return@launch
                            }
                        }

                        setCurrentOrganizationMutation.execute(organization)
                            .alsoOnError { onEvent(OrganizationSelectionEvent.Error(it)) }

                        acceptationInProgress = false
                    }
                }

                DeclineInvitation -> {
                    scope.launch {
                        clearInvitationMutation.execute()
                            .alsoOnError { onEvent(OrganizationSelectionEvent.Error(it)) }
                    }
                }

                LogOut -> {
                    scope.launch {
                        logOutMutation.execute()
                            .alsoOnError { error ->
                                onEvent(OrganizationSelectionEvent.Error(error))
                            }
                    }
                }

                TryAgain -> {
                    if (organizationMemberships is Resource.Error) organizationMemberships.reload()
                    if (invitationOrganization is Resource.Error) invitationOrganization.reload()
                }
            }
        }
    }


    @NativeCoroutines
    val state = moleculeFlow(RecompositionMode.Immediate) { presenterState() }
    val emptyState = OrganizationSelectionState(
        listOf(), null, false, false, null, {}
    )
}