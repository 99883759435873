package cometes.components.common

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.style.ComponentVariant
import com.varabyte.kobweb.silk.components.text.SpanText
import cometes.style.SpaceSmall
import cometes.style.SpaceXXSmall
import cometes.style.TypographyLabelLarge
import cometes.style.TypographyLabelMedium
import cometes.style.system.CometesSurface
import cometes.style.system.SurfaceSecondaryVariant
import org.jetbrains.compose.web.css.cssRem

@Composable
fun CometesLabel(
    text: String,
    modifier: Modifier = Modifier,
    variant: ComponentVariant? = SurfaceSecondaryVariant
) {
    CometesSurface(
        variant = variant,
        modifier = Modifier
            .borderRadius(1.cssRem)
            .padding(leftRight = SpaceSmall, topBottom = SpaceXXSmall)
            .then(modifier)
    ) {
        SpanText(text, TypographyLabelMedium)
    }
}

@Composable
fun CometesLabelLarge(
    text: String,
    modifier: Modifier = Modifier,
    variant: ComponentVariant? = SurfaceSecondaryVariant
) {
    CometesSurface(
        variant = variant,
        modifier = Modifier
            .borderRadius(1.cssRem)
            .padding(leftRight = SpaceSmall, topBottom = SpaceXXSmall)
            .then(modifier)
    ) {
        SpanText(text, TypographyLabelLarge)
    }
}