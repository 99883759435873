package app.cometes.shared.feature.reservation.infrastructure.model

import kotlinx.serialization.Serializable

@Serializable
data class LocationDto(
    val id: Long,
    val organizationId: Long,
    val name: String,
    val address: String?
)
