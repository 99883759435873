package cometes.style

import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.silk.style.ComponentKind
import com.varabyte.kobweb.silk.style.CssStyle
import org.jetbrains.compose.web.css.px

// rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
// rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

private val shadowColor = Color.rgb(0x60, 0x64, 0x67)

interface ShadowKind : ComponentKind

val ShadowStyle = CssStyle<ShadowKind> {
    val shadowColor = Color.rgb(0x60, 0x64, 0x67)

    base {
        Modifier
            .boxShadow(0.px, 1.px, 2.px, 0.px, shadowColor.copyf(alpha = 0.3f))
            .boxShadow(0.px, 1.px, 3.px, 1.px, shadowColor.copyf(alpha = 0.15f))
    }
}
//val InteractiveShadow by ShadowStyle.addVariant {
//
//    hover {
//        Modifier
//            .boxShadow(0.px, 1.px, 2.px, 0.px, shadowColor.copyf(alpha = 0.3f))
//    }
//}
