package app.cometes.shared.frontend.feature.organization.data

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.resource.EmptyInputMutation
import app.cometes.shared.frontend.base.resource.EmptyMutation
import app.cometes.shared.frontend.base.resource.rememberInputMutation
import app.cometes.shared.frontend.base.resource.rememberMutation
import app.cometes.shared.frontend.feature.organization.data.source.OrganizationLocalSource
import app.cometes.shared.frontend.util.rememberKoinDependency

@Composable
fun pendingInvitationValue(): String? {
    val localOrganizationSource = rememberKoinDependency<OrganizationLocalSource>()
    return localOrganizationSource.pendingInvitationToken.collectAsState().value
}

@Composable
fun setPendingInvitationMutation(): EmptyInputMutation<String> {
    val localOrganizationSource = rememberKoinDependency<OrganizationLocalSource>()
    return rememberInputMutation { newValue ->
        localOrganizationSource.setPendingInvitationToken(newValue)
        Result.Success(Unit)
    }
}

@Composable
fun clearPendingInvitationMutation(): EmptyMutation {
    val localOrganizationSource = rememberKoinDependency<OrganizationLocalSource>()
    return rememberMutation {
        localOrganizationSource.setPendingInvitationToken(null)
        Result.Success(Unit)
    }
}