package app.cometes.shared.frontend.feature.auth.domain

import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.usecase.UseCaseResultNoParams

interface LogOutUseCase : UseCaseResultNoParams<Unit>

internal class LogOutUseCaseImpl(
    private val authRepository: AuthRepository,
    private val clearCacheUseCase: ClearCacheUseCase,
) : LogOutUseCase {

    override suspend fun invoke(): Result<Unit> {
        val res = authRepository.logOut()
        if (res is Result.Success) clearCacheUseCase()
        return res
    }
}