package app.cometes.shared.feature.person.infrastructure.model

import app.cometes.shared.feature.organization.infrastructure.model.OrganizationMemberRoleDto
import app.cometes.shared.feature.organization.infrastructure.model.OrganizationMemberStatusDto
import kotlinx.serialization.Serializable

@Serializable
data class NewMemberBody(val personId: Long)

@Serializable
data class UpdateMemberRoleBody(
    val role: OrganizationMemberRoleDto?,
    val status: OrganizationMemberStatusDto?,
)