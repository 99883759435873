import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobals
import com.varabyte.kobweb.core.`init`.InitKobwebContext
import com.varabyte.kobweb.navigation.BasePath
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.navigation.remove
import com.varabyte.kobweb.silk.defer.DeferringHost
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main() {
    AppGlobals.initialize(mapOf("title" to "Cometes"))
    BasePath.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { cometes.pages.HomePage() }
        ctx.router.register("/404") { cometes.pages.NotFoundPage() }
        ctx.router.register("/auth") { cometes.pages.AuthPage() }
        ctx.router.register("/organization/") { cometes.pages.organization.OrganizationDetailPage()
                }
        ctx.router.register("/organization/desks") { cometes.pages.organization.DesksPage() }
        ctx.router.register("/organization/locations") { cometes.pages.organization.LocationsPage()
                }
        ctx.router.register("/organization/members") { cometes.pages.organization.MembersPage() }
        ctx.router.register("/organization/rooms") { cometes.pages.organization.RoomsPage() }
        ctx.router.register("/privacymobile") { cometes.pages.PrivacymobilePage() }
        ctx.router.register("/support") { cometes.pages.SupportPage() }
        ctx.router.register("/termsconditions") { cometes.pages.TermsconditionsPage() }

        initKobweb(ctx)
    }
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerStyle("cometes-gradient", cometes.components.common.CometesGradientStyle)
        ctx.theme.registerStyle("vertical-scrollable",
                cometes.components.common.VerticalScrollableStyle)
        ctx.theme.registerStyle("image-upload",
                cometes.components.feature.organization.component.ImageUploadStyle)
        ctx.theme.registerStyle("flex-card", cometes.components.layout.FlexCardStyle)
        ctx.theme.registerStyle("legal-info", cometes.components.layout.LegalInfoStyle)
        ctx.theme.registerStyle("util-row", cometes.components.layout.UtilRowStyle)
        ctx.theme.registerStyle("shadow", cometes.style.ShadowStyle)
        ctx.theme.registerStyle("cometes-button", cometes.style.system.CometesButtonStyle)
        ctx.theme.registerStyle("cometes-surface", cometes.style.system.CometesSurfaceStyle)
        ctx.theme.registerStyle("cometes-text-field",
                cometes.style.system.input.CometesTextFieldStyle)
        ctx.theme.registerVariant("-surface",
                cometes.components.common.SurfaceVerticalScrollableVariant
        )
        ctx.theme.registerVariant("-button-primary", cometes.style.system.ButtonPrimaryVariant
        )
        ctx.theme.registerVariant("-button-secondary", cometes.style.system.ButtonSecondaryVariant
        )
        ctx.theme.registerVariant("-button-outline", cometes.style.system.ButtonOutlineVariant
        )
        ctx.theme.registerVariant("-button-text", cometes.style.system.ButtonTextVariant
        )
        ctx.theme.registerVariant("-button-primary-text",
                cometes.style.system.ButtonPrimaryTextVariant
        )
        ctx.theme.registerVariant("-button-plain", cometes.style.system.ButtonPlainVariant
        )
        ctx.theme.registerVariant("-button-alert", cometes.style.system.ButtonAlertVariant
        )
        ctx.theme.registerVariant("-surface-outline", cometes.style.system.SurfaceOutlineVariant
        )
        ctx.theme.registerVariant("-surface-background-color",
                cometes.style.system.SurfaceBackgroundColorVariant
        )
        ctx.theme.registerVariant("-surface-primary", cometes.style.system.SurfacePrimaryVariant
        )
        ctx.theme.registerVariant("-surface-secondary", cometes.style.system.SurfaceSecondaryVariant
        )
        ctx.theme.registerVariant("-surface-error", cometes.style.system.SurfaceErrorVariant
        )
        ctx.theme.registerVariant("-surface-positive", cometes.style.system.SurfacePositiveVariant
        )
        ctx.theme.registerVariant("-surface-inverted", cometes.style.system.SurfaceInvertedVariant
        )
        ctx.theme.registerVariant("-cometes-text-field-surface",
                cometes.style.system.input.CometesTextFieldSurfaceVariant
        )
        ctx.theme.registerKeyframes("animation-fade-in",
                cometes.style.system.animation.AnimationFadeIn)
        initSiteStyles(ctx)
    }

    router.tryRoutingTo(BasePath.remove(window.location.href.removePrefix(window.origin)),
            UpdateHistoryMode.REPLACE)

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    val root = document.getElementById("_kobweb-root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    renderComposable(rootElementId = "_kobweb-root") {
        CometesApp {
            router.renderActivePage { DeferringHost { it() } }
        }
    }
}
