package app.cometes.shared.frontend.feature.auth.domain

import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.resource.clearResourceCaches
import app.cometes.shared.frontend.feature.auth.data.FirebaseSource
import app.cometes.shared.frontend.feature.organization.data.source.OrganizationLocalSource
import app.cometes.shared.frontend.feature.person.data.source.PersonLocalSource
import app.cometes.shared.frontend.util.withUseCaseContext
import com.russhwolf.settings.Settings

class LogOutUseCase internal constructor(
    private val firebaseSource: FirebaseSource,
    private val settings: Settings,
    private val organizationLocalSource: OrganizationLocalSource,
    private val personLocalSource: PersonLocalSource
) {
    @Throws(Exception::class)
    suspend operator fun invoke(): Result<Unit> {
        val firebaseLogoutResult = withUseCaseContext { firebaseSource.logOutUser() }
        if (firebaseLogoutResult is Result.Error)
            return firebaseLogoutResult

        settings.clear()
        organizationLocalSource.clear()
        personLocalSource.clear()
        clearResourceCaches()

        return Result.Success(Unit)
    }
}