package cometes.components.common

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.web.events.SyntheticMouseEvent
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.RowScope
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.silk.components.overlay.Overlay
import com.varabyte.kobweb.silk.components.text.SpanText
import cometes.style.SpaceXLarge
import cometes.style.SpaceXSmall
import cometes.style.SpaceXXSmall
import cometes.style.TypographyBodyMedium
import cometes.style.TypographyHeadlineSmall
import cometes.style.system.ButtonPlainVariant
import cometes.style.system.CometesButton
import cometes.style.system.SurfaceBackgroundColorVariant
import cometes.style.system.animation.fadeInTransition
import cometes.util.ZIndex
import kotlinx.browser.document

@Composable
fun CometesDialog(
    onDismiss: () -> Unit,
    modifier: Modifier = Modifier,
    title: String? = null,
    subTitle: String? = null,
    submitButtons: @Composable RowScope.() -> Unit = { DefaultOkButton { onDismiss() } },
    content: @Composable () -> Unit
) {
    DisposableEffect(Unit) {
        val originalCallback = document.onkeyup
        document.onkeyup = { event ->
            if (event.key == "Escape") onDismiss()
            originalCallback?.invoke(event)
        }
        onDispose { document.onkeyup = originalCallback }
    }

    val titleText: (String, String?) -> (@Composable RowScope.() -> Unit) = { title, subTitle ->
        {
            if (subTitle == null) {
                SpanText(
                    text = title,
                    modifier = Modifier.then(TypographyHeadlineSmall)
                )
            } else {
                Column(modifier = Modifier.fillMaxWidth()) {
                    SpanText(
                        text = title,
                        modifier = Modifier.then(TypographyHeadlineSmall)
                    )
                    VerticalSpacer(SpaceXXSmall)
                    SpanText(
                        text = subTitle,
                        modifier = Modifier.then(TypographyBodyMedium).opacity(0.6)
                    )
                }
            }
        }
    }

    CometesDialog(
        onDismiss = { onDismiss() },
        modifier = modifier,
        title = title?.let { titleText(it, subTitle) },
        submitButtons = submitButtons,
        content = content
    )
}

@Composable
fun CometesDialog(
    onDismiss: () -> Unit,
    modifier: Modifier = Modifier,
    title: (@Composable RowScope.() -> Unit)? = null,
    submitButtons: @Composable RowScope.() -> Unit = { DefaultOkButton { onDismiss() } },
    content: @Composable () -> Unit,
) {
    Overlay(
        contentAlignment = Alignment.Center,
        modifier = Modifier
            .fadeInTransition()
            .zIndex(ZIndex.Dialog)
            .onClick { onDismiss() }
    ) {
        CometesCard(
            variant = SurfaceBackgroundColorVariant,
            modifier = Modifier
                .then(modifier)
                .onClick { event ->
                    // so that click on dialog does not close it
                    event.stopPropagation()
                }
        ) {
            Column(modifier = Modifier.fillMaxSize()) {
                if (title != null) {
                    Row(Modifier.fillMaxWidth()) {
                        title()
                        Spacer()
                    }
                }

                VerticalSpacer(SpaceXLarge)

                Box(
                    modifier = Modifier.fillMaxWidth()
                ) {
                    content()
                }

                VerticalSpacer(SpaceXLarge)

                Row(
                    horizontalArrangement = Arrangement.spacedBy(SpaceXSmall),
                    modifier = Modifier.align(Alignment.End),
                ) {
                    submitButtons()
                }
            }
        }
    }
}

@Composable
private fun DefaultOkButton(onClick: (SyntheticMouseEvent) -> Unit) {
    CometesButton(
        onClick = onClick,
        variant = ButtonPlainVariant,
    ) { SpanText("Ok") }
}