package app.cometes.shared.frontend.feature.home.data

import app.cometes.shared.feature.reservation.infrastructure.model.HomeOverviewDto
import app.cometes.shared.feature.reservation.infrastructure.model.LocationDto
import app.cometes.shared.feature.reservation.infrastructure.model.ReservationDto
import app.cometes.shared.feature.room.infrastructure.model.ConferenceRoomReservationDto
import app.cometes.shared.frontend.feature.conferenceRoom.domain.entity.ConferenceRoomReservation
import app.cometes.shared.frontend.feature.conferenceRoom.domain.entity.toDomain
import app.cometes.shared.frontend.feature.organization.domain.model.Organization
import app.cometes.shared.frontend.feature.organization.infrastructure.model.toDomain
import app.cometes.shared.frontend.feature.reservation.domain.model.Location
import app.cometes.shared.frontend.feature.reservation.domain.model.Reservation
import app.cometes.shared.frontend.feature.reservation.infrastructure.model.toDomain

data class HomeOverview(
    val organization: Organization,
    val locations: List<Location>,
    val deskReservations: List<Reservation>,
    val personsConferenceRoomReservations: List<ConferenceRoomReservation>,
)

fun HomeOverviewDto.toDomain() = HomeOverview(
    organization = organization.toDomain(),
    locations = locations.map(LocationDto::toDomain),
    deskReservations = deskReservations.map(ReservationDto::toDomain),
    personsConferenceRoomReservations = personsConferenceRoomReservations.map(ConferenceRoomReservationDto::toDomain),
)