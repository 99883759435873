package app.cometes.shared.frontend.feature.person.infrastructure.source

import app.cometes.shared.feature.person.infrastructure.model.PersonDto
import app.cometes.shared.frontend.feature.person.data.source.PersonLocalSource
import com.russhwolf.settings.Settings
import com.russhwolf.settings.get
import com.russhwolf.settings.set
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.serialization.json.Json

internal class PersonLocalSourceImpl(
    private val settings: Settings
) : PersonLocalSource {
    private companion object {
        const val PersonKey = "PersonIdKey"
    }

    private val personStateFlow = MutableStateFlow(person) // JS storage is not observable :(

    override var person: PersonDto?
        get() {
            val jsonString: String? = settings[PersonKey]
            return if (jsonString != null) {
                Json.decodeFromString(PersonDto.serializer(), jsonString)
            } else null
        }
        set(value) {
            settings[PersonKey] = if (value != null) {
                Json.encodeToString(PersonDto.serializer(), value)
            } else null
            personStateFlow.value = value
        }

    override val personFlow: StateFlow<PersonDto?> = personStateFlow.asStateFlow()

    override fun clear() {
        personStateFlow.value = null
    }
}