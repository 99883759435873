package app.cometes.shared.frontend.feature.organization.data.repository

import app.cometes.shared.feature.organization.infrastructure.model.OrganizationDto
import app.cometes.shared.frontend.feature.organization.data.source.OrganizationLocalSource
import app.cometes.shared.frontend.feature.organization.domain.model.Organization
import app.cometes.shared.frontend.feature.organization.domain.repository.OrganizationRepository

internal class OrganizationRepositoryImpl(
    private val organizationLocalSource: OrganizationLocalSource,
) : OrganizationRepository {

    override suspend fun setCurrentOrganization(organization: Organization?) {
        if (organization == null) organizationLocalSource.setOrganization(null)
        else organizationLocalSource.setOrganization(
            OrganizationDto(
                id = organization.id,
                name = organization.name,
                description = organization.description,
                imageUrl = organization.imageUrl
            )
        )
    }
}