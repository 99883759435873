package cometes.components.feature.organization.invitation

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationInvitation
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.dom.ElementTarget
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.icons.fa.FaCheck
import com.varabyte.kobweb.silk.components.icons.fa.FaCircleCheck
import com.varabyte.kobweb.silk.components.icons.fa.FaHourglassHalf
import com.varabyte.kobweb.silk.components.icons.fa.FaLink
import com.varabyte.kobweb.silk.components.icons.fa.FaTrashCan
import com.varabyte.kobweb.silk.components.icons.fa.FaTriangleExclamation
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.overlay.Popover
import com.varabyte.kobweb.silk.components.text.SpanText
import cometes.components.common.CometesLabelLarge
import cometes.components.common.HorizontalSpacer
import cometes.components.common.VerticalSpacer
import cometes.style.CometesTheme
import cometes.style.RadiusLarge
import cometes.style.RadiusPill
import cometes.style.SpaceMedium
import cometes.style.SpaceMediumSmall
import cometes.style.SpaceSmall
import cometes.style.SpaceXSmall
import cometes.style.SpaceXXSmall
import cometes.style.TypographyBodyLarge
import cometes.style.TypographyBodyMedium
import cometes.style.TypographyBodySmall
import cometes.style.system.CometesImageButton
import cometes.style.system.CometesSurface
import cometes.style.system.SurfaceErrorVariant
import cometes.style.system.SurfaceOutlineVariant
import cometes.style.system.SurfacePositiveVariant
import cometes.style.system.SurfaceSecondaryVariant
import cometes.style.system.animation.fadeInTransition
import cometes.style.system.input.CometesTextField
import cometes.style.system.input.CometesTextFieldPlainVariant
import kotlinx.browser.window
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.css.px
import kotlin.time.Duration.Companion.seconds

@Composable
fun InvitationItem(
    invitation: OrganizationInvitation,
    onRemove: () -> Unit,
    modifier: Modifier = Modifier
) {
    CometesSurface(
        modifier = Modifier
            .borderRadius(RadiusLarge)
            .padding(
                topBottom = (SpaceXSmall.value + SpaceXXSmall.value).px,
                leftRight = SpaceMediumSmall
            )
            .then(modifier)
    ) {
        Column(Modifier.fillMaxWidth()) {
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.fillMaxWidth()
            ) {
                SpanText(
                    text = invitation.email,
                    modifier = Modifier
                        .styleModifier { property("text-overflow", "ellipsis") }
                        .overflow(Overflow.Hidden)
                        .maxWidth(250.px)
                        .then(TypographyBodyLarge)
                )

                if (invitation.email.length > 40) {
                    Popover(ElementTarget.PreviousSibling) {
                        CometesLabelLarge(
                            invitation.email,
                            Modifier.fadeInTransition(),
                            SurfaceOutlineVariant
                        )
                    }
                }

                HorizontalSpacer(SpaceSmall)
                InvitationLabel(invitation.status)

                Spacer()
                HorizontalSpacer(SpaceXSmall)

                CometesImageButton(onClick = onRemove, Modifier.padding(SpaceSmall)) {
                    FaTrashCan(Modifier.color(CometesTheme.color.error))
                }
            }

            if (invitation.status == OrganizationInvitation.Status.Pending) {
                VerticalSpacer(SpaceMedium)

                var checkDisplayed by remember { mutableStateOf(false) }
                LaunchedEffect(checkDisplayed) {
                    if (checkDisplayed) {
                        delay(2.seconds)
                        checkDisplayed = false
                    }
                }

                Row(
                    modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically,
                ) {
                    CometesTextField(
                        value = invitation.link,
                        onValueChange = {},
                        variant = CometesTextFieldPlainVariant,
                        modifier = Modifier
                            .fillMaxWidth()
                            .then(TypographyBodyMedium)

                    )
                    HorizontalSpacer(SpaceSmall)
                    CometesImageButton(
                        onClick = {
                            window.navigator.clipboard.writeText(invitation.link)
                            checkDisplayed = true
                        },
                        modifier = Modifier.padding(SpaceSmall)
                    ) {
                        if (checkDisplayed) {
                            FaCheck(Modifier.color(CometesTheme.color.positive))
                            Popover(ElementTarget.Parent) {
                                CometesLabelLarge(
                                    "Copied",
                                    Modifier.fadeInTransition(),
                                    SurfacePositiveVariant
                                )
                            }
                        } else FaLink()
                    }
                }
            }
        }
    }
}

@Composable
private fun InvitationLabel(
    status: OrganizationInvitation.Status,
    modifier: Modifier = Modifier
) {
    CometesSurface(
        variant = when (status) {
            OrganizationInvitation.Status.Pending -> SurfaceSecondaryVariant
            OrganizationInvitation.Status.Accepted -> SurfacePositiveVariant
            OrganizationInvitation.Status.Expired -> SurfaceErrorVariant
        },
        modifier = Modifier
            .borderRadius(RadiusPill)
            .padding(leftRight = SpaceSmall, topBottom = SpaceXXSmall)
            .then(modifier)
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
        ) {
            SpanText(status.name, TypographyBodySmall)
            HorizontalSpacer(SpaceSmall)
            when (status) {
                OrganizationInvitation.Status.Pending -> FaHourglassHalf(size = IconSize.SM)
                OrganizationInvitation.Status.Accepted -> FaCircleCheck(size = IconSize.SM)
                OrganizationInvitation.Status.Expired -> FaTriangleExclamation(size = IconSize.SM)
            }
        }
    }
}
