package cometes.components.image

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.silk.components.graphics.Image
import org.jetbrains.compose.web.css.px

@Composable
fun ImageCometes(modifier: Modifier = Modifier.size(32.px)) {
    Image(
        src = "/img/comet.svg",
        modifier = modifier
    )
}