package app.cometes.shared.frontend.feature.reservation.domain.model

import androidx.compose.runtime.Immutable
import kotlinx.datetime.LocalDate

@Immutable
data class ReservationDay(
    val date: LocalDate,
    val reservations: List<Reservation>,
    val maxReservations: Int
) {
    val openForReservation: Boolean get() = reservations.size < maxReservations
}
