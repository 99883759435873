package cometes.components.feature.organization.invitation

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.feature.organization.data.removeInvitationMutation
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationInvitation
import app.cometes.shared.frontend.util.withUseCaseContext
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.silk.components.text.DivText
import com.varabyte.kobweb.silk.components.text.SpanText
import cometes.components.common.CometesDialog
import cometes.components.common.CometesLoadingButton
import cometes.components.common.HorizontalSpacer
import cometes.style.SpaceMedium
import cometes.style.TypographyBodyLarge
import cometes.style.system.CometesButton
import cometes.style.system.SurfaceBackgroundColorVariant
import cometes.style.system.SurfaceErrorVariant
import kotlinx.coroutines.launch

@Composable
fun InvitationRemovalDialog(
    invitation: OrganizationInvitation?,
    onDismiss: () -> Unit,
) {
    if (invitation == null) return

    val coroutineScope = rememberCoroutineScope()
    val removeInvitationMutation = removeInvitationMutation(invitation)
    var isLoading by remember { mutableStateOf(false) }

    fun onRemoveClick() {
        coroutineScope.launch {
            isLoading = true
            val res = withUseCaseContext { removeInvitationMutation.execute() }
            isLoading = false
            if (res is Result.Success) onDismiss()
        }
    }

    CometesDialog(
        title = "Invitation for email \"${invitation.email}\" will be permanently removed!",
        onDismiss = onDismiss,
        submitButtons = {
            CometesButton(
                onDismiss,
                variant = SurfaceBackgroundColorVariant
            ) { SpanText("Cancel") }
            HorizontalSpacer(SpaceMedium)
            CometesLoadingButton(
                isLoading = isLoading,
                onClick = { onRemoveClick() },
                variant = SurfaceErrorVariant
            ) {
                SpanText("Remove")
            }
        }
    ) {
        Column(TypographyBodyLarge) {
            DivText("No one will be able to use this invitation.")
            DivText("This step cannot be reversed!")
        }
    }
}