package app.cometes.shared.frontend.feature.conferenceRoom.source

import app.cometes.shared.feature.room.infrastructure.model.ConferenceRoomAvailabilityDto
import app.cometes.shared.feature.room.infrastructure.model.ConferenceRoomDto
import app.cometes.shared.feature.room.infrastructure.model.ConferenceRoomReservationDto
import app.cometes.shared.feature.room.infrastructure.model.ConferenceRoomReservationTipDto
import app.cometes.shared.feature.room.infrastructure.model.CreateConferenceRoomBody
import app.cometes.shared.feature.room.infrastructure.model.CreateConferenceRoomReservationBody
import app.cometes.shared.feature.room.infrastructure.model.UpdateConferenceRoomBody
import app.cometes.shared.feature.room.infrastructure.model.UpdateConferenceRoomReservationBody
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.error.BackendException
import app.cometes.shared.frontend.base.error.runCatchingNetworkExceptions
import app.cometes.shared.frontend.feature.conferenceRoom.domain.ConferenceRoomReservationError
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.delete
import io.ktor.client.request.get
import io.ktor.client.request.parameter
import io.ktor.client.request.patch
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDate
import kotlinx.datetime.TimeZone

internal class ConferenceRoomRemoteSourceImpl(
    private val client: HttpClient,
) : ConferenceRoomRemoteSource {
    override suspend fun getRoom(
        organizationId: Long,
        roomId: Long
    ): Result<ConferenceRoomDto> = runCatchingNetworkExceptions {
        val response = client.get("/organization/${organizationId}/conference-room/${roomId}")
        Result.Success(response.body())
    }

    override suspend fun getRooms(
        organizationId: Long,
        locationId: Long?
    ): Result<List<ConferenceRoomDto>> = runCatchingNetworkExceptions {
        val response = client.get("/organization/${organizationId}/conference-room")
        Result.Success(response.body())
    }

    override suspend fun createRoom(
        organizationId: Long,
        locationId: Long,
        name: String,
        description: String,
        capacity: Int
    ): Result<ConferenceRoomDto> = runCatchingNetworkExceptions {
        val response = client.post("/organization/${organizationId}/conference-room") {
            setBody(
                CreateConferenceRoomBody(
                    locationId = locationId,
                    name = name,
                    description = description,
                    capacity = capacity
                )
            )
        }

        Result.Success(response.body())
    }

    override suspend fun updateRoom(
        organizationId: Long,
        roomId: Long,
        name: String?,
        description: String?,
        capacity: Int?
    ): Result<ConferenceRoomDto> = runCatchingNetworkExceptions {
        val body = UpdateConferenceRoomBody(
            name = name,
            description = description,
            capacity = capacity
        )

        val response = client.patch("/organization/${organizationId}/conference-room/$roomId") {
            setBody(body)
        }

        Result.Success(response.body())
    }

    override suspend fun deleteRoom(
        organizationId: Long,
        roomId: Long
    ): Result<Unit> = runCatchingNetworkExceptions {
        client.delete("/organization/${organizationId}/conference-room/$roomId")
        Result.Success(Unit)
    }

}

internal class ConferenceRoomReservationRemoteSourceImpl(
    private val client: HttpClient,
) : ConferenceRoomReservationRemoteSource {
    override suspend fun getReservation(
        organizationId: Long,
        reservationId: Long
    ): Result<ConferenceRoomReservationDto> = runCatchingNetworkExceptions {
        val response = client.get(
            "/organization/${organizationId}/conference-room/reservation/$reservationId"
        )

        Result.Success(response.body())
    }

    override suspend fun getReservations(
        organizationId: Long,
        personId: Long?,
        locationId: Long?,
        afterInstant: Instant?,
        beforeInstant: Instant?,
        offset: Int,
        limit: Int,
    ): Result<List<ConferenceRoomReservationDto>> = runCatchingNetworkExceptions {
        val response = client.get("/organization/${organizationId}/conference-room/reservation") {
            if (personId != null) parameter("personId", personId)
            if (locationId != null) parameter("locationId", locationId)
            if (afterInstant != null) parameter("after", afterInstant)
            if (beforeInstant != null) parameter("before", beforeInstant)

            parameter("offset", offset)
            parameter("limit", limit)
        }

        Result.Success(response.body())
    }

    override suspend fun getReservationTips(
        organizationId: Long,
        locationId: Long?
    ): Result<List<ConferenceRoomReservationTipDto>> = runCatchingNetworkExceptions {
        val response = client.get(
            "/organization/${organizationId}/conference-room/reservation/tips"
        ) {
            parameter("locationId", locationId)
        }

        Result.Success(response.body())
    }

    override suspend fun getReservationAvailability(
        organizationId: Long,
        locationId: Long?,
        date: LocalDate,
    ): Result<List<ConferenceRoomAvailabilityDto>> = runCatchingNetworkExceptions {
        val response = client.get(
            "/organization/${organizationId}/conference-room/reservation/availability"
        ) {
            parameter("date", date)
            parameter("locationId", locationId)
            parameter("timeZoneId", TimeZone.currentSystemDefault().id)
        }

        Result.Success(response.body())
    }

    override suspend fun createReservation(
        organizationId: Long,
        conferenceRoomId: Long,
        name: String,
        description: String,
        startTime: Instant,
        endTime: Instant
    ): Result<ConferenceRoomReservationDto> = try {
        runCatchingNetworkExceptions {
            val body = CreateConferenceRoomReservationBody(
                conferenceRoomId = conferenceRoomId,
                name = name,
                description = description,
                startTime = startTime,
                endTime = endTime
            )

            val response = client.post(
                urlString = "/organization/${organizationId}/conference-room/reservation"
            ) {
                setBody(body)
            }

            Result.Success(response.body())
        }
    } catch (e: BackendException) {
        when (e.error.type) {
            "ConflictingReservation" -> Result.Error(ConferenceRoomReservationError.Conflict(e))
            else -> throw e
        }
    }


    override suspend fun updateReservation(
        organizationId: Long,
        reservationId: Long,
        conferenceRoomId: Long?,
        name: String?,
        description: String?,
        startTime: Instant?,
        endTime: Instant?
    ): Result<ConferenceRoomReservationDto> = runCatchingNetworkExceptions {
        val body = UpdateConferenceRoomReservationBody(
            conferenceRoomId = conferenceRoomId,
            name = name,
            description = description,
            startTime = startTime,
            endTime = endTime
        )

        val response = client.patch(
            "/organization/$organizationId/conference-room/reservation/$reservationId"
        ) {
            setBody(body)
        }

        Result.Success(response.body())
    }

    override suspend fun deleteReservation(
        organizationId: Long,
        reservationId: Long
    ): Result<Unit> = runCatchingNetworkExceptions {
        client.delete("/organization/$organizationId/conference-room/reservation/$reservationId")
        Result.Success(Unit)
    }
}
