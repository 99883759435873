package app.cometes.shared.frontend.feature.reservation.domain.model

import androidx.compose.runtime.Immutable
import app.cometes.shared.frontend.base.Identifiable

@Immutable
data class Location(
    override val id: Long,
    val organizationId: Long,
    val name: String,
    val address: String?
) : Identifiable
