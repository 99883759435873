package cometes.components.common

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.objectFit
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.silk.components.graphics.Image
import cometes.style.CometesTheme
import cometes.style.SpaceXSmall
import cometes.style.system.animation.CometesTransitionOfBackgroundColor
import org.jetbrains.compose.web.css.CSSNumericValue
import org.jetbrains.compose.web.css.CSSUnitLengthOrPercentage
import org.jetbrains.compose.web.css.percent

@Composable
fun RoundedImage(
    imageUrl: String?,
    modifier: Modifier = Modifier,
    borderWidth: CSSNumericValue<out CSSUnitLengthOrPercentage> = SpaceXSmall,
) {
    val imgSizeModifier = Modifier
        .objectFit(ObjectFit.Contain)
        .padding(borderWidth)
        .borderRadius(50.percent)
        .backgroundColor(CometesTheme.color.background)
        .transition(CometesTransitionOfBackgroundColor())
        .then(modifier)

    if (imageUrl != null) {
        Image(imageUrl, modifier = imgSizeModifier)
    } else {
        Box(imgSizeModifier)
    }
}