package cometes.components.layout

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.toModifier
import cometes.style.SpaceLarge
import org.jetbrains.compose.web.css.px

val LegalInfoStyle by ComponentStyle {
    base {
        Modifier
            .padding(SpaceLarge)
            .maxWidth(800.px)
            .styleModifier {
                property("margin-left", "auto")
                property("margin-right", "auto")
            }
    }
}

@Suppress("unused")
@Composable
fun LegalLayout(content: @Composable () -> Unit) {
    Surface(Modifier.fillMaxSize()) {
        Column(LegalInfoStyle.toModifier()) {
            content()
        }
    }
}