package app.cometes.shared.frontend.network

import app.cometes.shared.frontend.configuration.data.source.AppInfoProvider
import app.cometes.shared.frontend.configuration.domain.Configuration
import app.cometes.shared.frontend.feature.auth.data.FirebaseSource
import io.ktor.client.HttpClient
import io.ktor.client.plugins.HttpTimeout
import io.ktor.client.plugins.contentnegotiation.ContentNegotiation
import io.ktor.client.plugins.defaultRequest
import io.ktor.client.plugins.logging.Logger
import io.ktor.client.plugins.logging.Logging
import io.ktor.client.request.HttpSendPipeline
import io.ktor.client.request.header
import io.ktor.http.ContentType
import io.ktor.http.HttpHeaders
import io.ktor.http.URLProtocol
import io.ktor.http.contentType
import io.ktor.serialization.kotlinx.json.json
import kotlin.native.concurrent.ThreadLocal
import kotlinx.serialization.json.Json
import co.touchlab.kermit.Logger.Companion as KermitLogger

@ThreadLocal
private val globalJson = Json {
    useAlternativeNames = false
    ignoreUnknownKeys = true
}


object NetworkClient {
    object Ktor {
        fun getClient(
            config: Configuration,
            appInfoProvider: AppInfoProvider,
            tokenSource: FirebaseSource,
        ) = HttpClient {
            followRedirects = false
            expectSuccess = true // throw exception when response is not 2xx

            install(ContentNegotiation) {
                json(globalJson)
            }

            install(HttpTimeout) {
                socketTimeoutMillis = 20_000L
                requestTimeoutMillis = 20_000L
                connectTimeoutMillis = 20_000L
            }

            defaultRequest {
                contentType(ContentType.Application.Json)

                url(
                    scheme = URLProtocol.HTTPS.name,
                    host = config.host
                )

                if (appInfoProvider.config == Configuration.Release) {
                    header("X-Cometes-App-Id", appInfoProvider.appIdentification)
                    header("X-Cometes-App-Version", appInfoProvider.version)
                }
            }

            install("AuthInterceptor") {
                sendPipeline.intercept(HttpSendPipeline.State) {
                    val token = tokenSource.getToken().getOrNull()
                    if (token != null) {
                        context.header(HttpHeaders.Authorization, "Bearer $token")
                    }
                }
            }

            install(Logging) {
                logger = object : Logger {
                    override fun log(message: String) {
                        KermitLogger.d("Ktor") {
                            message.lines().filter { line -> "Authorization" !in line }.joinToString("\n")
                        }
                    }
                }
            }
        }
    }
}