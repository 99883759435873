package cometes.components.layout.menu

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import app.cometes.shared.frontend.base.resource.loadResourceIfEmpty
import app.cometes.shared.frontend.feature.organization.data.clearCurrentOrganizationMutation
import app.cometes.shared.frontend.feature.organization.data.currentOrganization
import app.cometes.shared.frontend.feature.organization.data.setCurrentOrganizationMutation
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationWithMembership
import app.cometes.shared.frontend.feature.person.data.personsOrganizationsResource
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextOverflow
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.aspectRatio
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.textOverflow
import com.varabyte.kobweb.compose.ui.modifiers.whiteSpace
import com.varabyte.kobweb.silk.components.icons.mdi.MdiSettings
import com.varabyte.kobweb.silk.components.overlay.PopupPlacement
import com.varabyte.kobweb.silk.components.overlay.PopupPlacementStrategy
import cometes.components.common.DivText
import cometes.components.common.HorizontalSpacer
import cometes.components.common.PopoverMenuContent
import cometes.components.common.PopoverMenuItem
import cometes.components.common.PopoverMenuLayout
import cometes.components.feature.organization.component.OrganizationImage
import cometes.style.RadiusLarge
import cometes.style.SpaceSmall
import cometes.style.SpaceXSmall
import cometes.style.SpaceXXSmall
import cometes.style.TypographyTitleSmall
import cometes.style.system.CometesSurface
import cometes.style.system.SurfaceBackgroundColorVariant
import cometes.util.localized
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px

@Composable
fun OrganizationSwitcher(modifier: Modifier) {
    val actionScope = rememberCoroutineScope()
    val currentOrganization = currentOrganization()
    val organizationsResource = loadResourceIfEmpty { personsOrganizationsResource() }

    val setOrganization = setCurrentOrganizationMutation()
    val clearOrganization = clearCurrentOrganizationMutation()

    var isPopupOpen by remember { mutableStateOf(false) }

    PopoverMenuLayout(
        isVisible = isPopupOpen,
        onDismiss = { isPopupOpen = false },
        popoverContent = {
            OrganizationList(
                organizations = organizationsResource.data.orEmpty(),
                onOrganizationClick = { item -> actionScope.launch { setOrganization.execute(item) } },
                onOrganizationSettingsClick = { actionScope.launch { clearOrganization.execute() } },
                modifier = Modifier.fillMaxWidth(),
            )
        },
        placementStrategy = PopupPlacementStrategy.of(PopupPlacement.Bottom, SpaceSmall.value),
        modifier = modifier,
    ) {
        CometesSurface(
            variant = SurfaceBackgroundColorVariant,
            onClick = { event -> isPopupOpen = !isPopupOpen },
            modifier = Modifier
                .fillMaxWidth()
                .padding(leftRight = SpaceSmall, topBottom = SpaceXSmall)
                .borderRadius(RadiusLarge),
        ) {
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.fillMaxWidth()
            ) {
                OrganizationImage(
                    imageUrl = currentOrganization?.imageUrl,
                    borderWidth = SpaceXXSmall,
                    modifier = Modifier
                        .size(40.px)
                        .aspectRatio(1)
                )
                HorizontalSpacer(SpaceSmall)
                DivText(
                    currentOrganization?.name ?: "",
                    TypographyTitleSmall
                        .whiteSpace(WhiteSpace.NoWrap)
                        .overflow(Overflow.Hidden)
                        .textOverflow(TextOverflow.Ellipsis)
                        .fillMaxWidth()
                )
            }
        }
    }
}

@Composable
private fun OrganizationList(
    organizations: List<OrganizationWithMembership>,
    onOrganizationClick: (OrganizationWithMembership) -> Unit,
    onOrganizationSettingsClick: () -> Unit,
    modifier: Modifier = Modifier
) {
    val currentOrganization = currentOrganization()
    val organizationList = organizations
        .sortedWith { first, second -> if (first.organization.id == currentOrganization?.id) 1 else -1 }


    PopoverMenuContent(modifier = modifier) {
        for (item in organizationList) {
            val (organization, membership) = item
            PopoverMenuItem(
                icon = {
                    OrganizationImage(
                        imageUrl = organization.imageUrl,
                        borderWidth = 0.px,
                        modifier = Modifier.fillMaxSize()
                    )
                },
                title = organization.name,
                subtitle = membership.role.localized(),
                enabled = organization.id != currentOrganization?.id,
                onClick = { onOrganizationClick(item) },
            )
        }

        PopoverMenuItem(
            icon = { MdiSettings(Modifier.fontSize(1.cssRem)) },
            title = "All organizations",
            onClick = { onOrganizationSettingsClick() },
        )
    }
}