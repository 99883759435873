package app.cometes.shared.frontend.presentation

object CometesColor {
    const val light_primary = 0xFF775a00
    const val light_onPrimary = 0xFFffffff
    const val light_primaryContainer = 0xFFffdf8c
    const val light_onPrimaryContainer = 0xFF251a00
    const val light_secondary = 0xFF5355ab
    const val light_onSecondary = 0xFFffffff
    const val light_secondaryContainer = 0xFFe0e0ff
    const val light_onSecondaryContainer = 0xFF090467
    const val light_tertiary = 0xFF5355ab
    const val light_onTertiary = 0xFFffffff
    const val light_tertiaryContainer = 0xFFe0e0ff
    const val light_onTertiaryContainer = 0xFF090467
    const val light_error = 0xFFba1b1b
    const val light_errorContainer = 0xFFffdad4
    const val light_onError = 0xFFffffff
    const val light_onErrorContainer = 0xFF410001
    const val light_background = 0xFFfbfdfd
    const val light_onBackground = 0xFF191c1d
    const val light_surface = 0xFFfbfdfd
    const val light_onSurface = 0xFF191c1d
    const val light_surfaceVariant = 0xFFece1cf
    const val light_onSurfaceVariant = 0xFF4c4639
    const val light_outline = 0xFF7e7667
    const val light_inverseOnSurface = 0xFFeff1f1
    const val light_inverseSurface = 0xFF2d3132
    const val light_inversePrimary = 0xFFf2c02d
    const val light_positive = 0xFF6a994e
    const val light_positiveContainer = 0xFFC8DABE
    const val light_onPositiveContainer = 0xFF2F4323

    const val dark_primary = 0xFFf2c02d
    const val dark_onPrimary = 0xFF3f2e00
    const val dark_primaryContainer = 0xFF5a4400
    const val dark_onPrimaryContainer = 0xFFffdf8c
    const val dark_secondary = 0xFFbfc1ff
    const val dark_onSecondary = 0xFF23247a
    const val dark_secondaryContainer = 0xFF3b3d91
    const val dark_onSecondaryContainer = 0xFFe0e0ff
    const val dark_tertiary = 0xFFbfc1ff
    const val dark_onTertiary = 0xFF23247a
    const val dark_tertiaryContainer = 0xFF3b3d91
    const val dark_onTertiaryContainer = 0xFFe0e0ff
    const val dark_error = 0xFFffb4a9
    const val dark_errorContainer = 0xFF930006
    const val dark_onError = 0xFF680003
    const val dark_onErrorContainer = 0xFFffdad4
    const val dark_background = 0xFF191c1d
    const val dark_onBackground = 0xFFe0e3e3
    const val dark_surface = 0xFF191c1d
    const val dark_onSurface = 0xFFe0e3e3
    const val dark_surfaceVariant = 0xFF4c4639
    const val dark_onSurfaceVariant = 0xFFcfc5b4
    const val dark_outline = 0xFF999080
    const val dark_inverseOnSurface = 0xFF191c1d
    const val dark_inverseSurface = 0xFFe0e3e3
    const val dark_inversePrimary = 0xFF775a00
    const val dark_positive = 0xFFa7c957
    const val dark_positiveContainer = 0xFF6D8B2D
    const val dark_onPositiveContainer = 0xFF171D0C

    internal const val seed = 0xFFf3c12e
    internal const val error = 0xFFba1b1b
}