package app.cometes.shared.frontend.feature.home.presentation

import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import app.cash.molecule.RecompositionMode
import app.cash.molecule.moleculeFlow
import app.cometes.shared.frontend.base.ErrorResult
import app.cometes.shared.frontend.base.error.CommonError
import app.cometes.shared.frontend.base.resource.Resource
import app.cometes.shared.frontend.base.resource.isLoading
import app.cometes.shared.frontend.feature.home.data.reservationDaysResource
import app.cometes.shared.frontend.feature.organization.data.currentOrganizationResource
import app.cometes.shared.frontend.feature.organization.domain.model.Organization
import app.cometes.shared.frontend.feature.person.data.currentPersonResource
import app.cometes.shared.frontend.feature.person.domain.model.Person
import app.cometes.shared.frontend.feature.reservation.domain.model.Location
import app.cometes.shared.frontend.feature.reservation.domain.model.Reservation
import app.cometes.shared.frontend.feature.reservation.domain.model.ReservationDay
import com.rickclephas.kmp.nativecoroutines.NativeCoroutines

@Immutable
data class HomeState(
    val person: Person?,
    val organization: Organization?,
    val location: Location?,
    val days: List<ReservationDay>,
    val userNextReservation: Reservation?,
    val openedReservation: Reservation?,

    val isLoading: Boolean,
    val error: ErrorResult?,

    val onIntent: (intent: HomeIntent) -> Unit,
)

sealed interface HomeIntent {
    class OpenReservationInfo(val reservation: Reservation) : HomeIntent
    object CloseReservationInfo : HomeIntent
    object RefreshData : HomeIntent
    object TryAgain : HomeIntent
}


object HomePresenter {

    @Composable
    fun presenter(): HomeState {
        var manualLoading: Boolean by remember { mutableStateOf(false) }
        var openedReservation: Reservation? by remember { mutableStateOf(null) }

        val person = currentPersonResource()
        val organization = currentOrganizationResource()
        val reservationDays = reservationDaysResource()

        val userNextReservation: Reservation? = reservationDays.data
            ?.flatMap { day -> day.reservations }
            ?.firstOrNull { it.person.id == person.data?.id }

        val isLoading = when {
            person.isLoading -> true
            reservationDays.isLoading -> (reservationDays.data?.isEmpty() ?: true) || manualLoading
            else -> false
        }

        val error = when {
            person is Resource.Error -> person.error
            reservationDays is Resource.Error -> reservationDays.error
            else -> null
        }

        LaunchedEffect(Unit) { organization.reload() }
        LaunchedEffect(Unit) { reservationDays.reload() }

        return HomeState(
            person = person.data,
            organization = organization.data,
            location = null, // TODO
            days = reservationDays.data ?: emptyList(),
            userNextReservation = userNextReservation,
            openedReservation = openedReservation,
            isLoading = isLoading,
            error = error.takeIf { it !is CommonError.ResourceNotLoaded },
        ) { intent ->
            when (intent) {
                HomeIntent.CloseReservationInfo -> openedReservation = null
                is HomeIntent.OpenReservationInfo -> openedReservation = intent.reservation
                HomeIntent.RefreshData -> {
                    manualLoading = true
                    reservationDays.reload()
                }

                HomeIntent.TryAgain -> {
                    if (person is Resource.Error) person.reload()
                    if (reservationDays is Resource.Error) reservationDays.reload()
                }
            }
        }
    }

    @NativeCoroutines
    val state = moleculeFlow(RecompositionMode.Immediate) { presenter() }

    val emptyState = HomeState(
        null, null, null, listOf(), null, null, false, null, {}
    )
}

