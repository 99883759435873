package app.cometes.shared.frontend.util

import kotlinx.datetime.Clock
import kotlinx.datetime.DatePeriod
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.LocalTime
import kotlinx.datetime.TimeZone
import kotlinx.datetime.atDate
import kotlinx.datetime.atStartOfDayIn
import kotlinx.datetime.minus
import kotlinx.datetime.plus
import kotlinx.datetime.toInstant
import kotlinx.datetime.todayIn

enum class RelativeDay { Today, Tomorrow, InWeek, MoreThanWeek }
enum class RelativeWeek { ThisWeek, NextWeek, LaterThanNextWeek }

object DateFormatHelper {
    const val baseDateFormat = "d.M"
    const val fullDateFormat = "dd.MM yyyy"
    const val dayDescriptionFormat = "E d.M"
    const val fullDayDescriptionFormat = "EEEE d.M"
    const val weekDayFormat = "EEEE"
    const val timeFormat = "HH:mm"

    fun getRelativeDayFromNow(date: LocalDate): RelativeDay {
        val today = Clock.System.todayIn(TimeZone.currentSystemDefault())
        val days = (date - today).days

        return when {
            date == today -> RelativeDay.Today
            days == 1 -> RelativeDay.Tomorrow
            days < 7 -> RelativeDay.InWeek
            else -> RelativeDay.MoreThanWeek
        }
    }

    fun getRelativeWeekFromNow(date: LocalDate): RelativeWeek {
        val today = Clock.System.todayIn(TimeZone.currentSystemDefault())
        val thisMonday = getMondayForWeek(today)
        val thisSunday = getSundayForWeek(today)

        val thisWeek = thisMonday..thisSunday
        val nextWeek = (thisMonday + DatePeriod(days = 7))..(thisSunday + DatePeriod(days = 7))

        return when (date) {
            in thisWeek -> RelativeWeek.ThisWeek
            in nextWeek -> RelativeWeek.NextWeek
            else -> RelativeWeek.LaterThanNextWeek
        }
    }

    fun getMondayForWeek(date: LocalDate): LocalDate =
        date - DatePeriod(days = date.dayOfWeek.ordinal)

    fun getSundayForWeek(date: LocalDate): LocalDate =
        getMondayForWeek(date) + DatePeriod(days = 6)

    fun getNearestMondayForWorkWeek(date: LocalDate) = when (date.dayOfWeek) {
        DayOfWeek.SATURDAY -> date + DatePeriod(days = 2)
        DayOfWeek.SUNDAY -> date + DatePeriod(days = 1)
        else -> {
            date - DatePeriod(days = date.dayOfWeek.ordinal)
        }
    }

    fun getNearestWeekday(date: LocalDate): LocalDate = when (date.dayOfWeek) {
        DayOfWeek.SATURDAY -> date + DatePeriod(days = 2)
        DayOfWeek.SUNDAY -> date + DatePeriod(days = 1)
        else -> date
    }

    fun formatAsSmallDate(date: LocalDate) = date
        .atStartOfDayIn(TimeZone.currentSystemDefault())
        .format(baseDateFormat)

    fun formatAsFullDate(date: LocalDate) = date
        .atStartOfDayIn(TimeZone.currentSystemDefault())
        .format(fullDateFormat)

    fun formatDayDescription(date: LocalDate) = date
        .atStartOfDayIn(TimeZone.currentSystemDefault())
        .format(dayDescriptionFormat)
        .replaceFirstChar { it.uppercase() }

    fun formatFullDayDescription(date: LocalDate) = date
        .atStartOfDayIn(TimeZone.currentSystemDefault())
        .format(fullDayDescriptionFormat)
        .replaceFirstChar { it.uppercase() }

    fun formatAsTime(dateTime: LocalDateTime) = dateTime
        .toInstant(TimeZone.currentSystemDefault())
        .format(timeFormat)

    fun formatAsTime(time: LocalTime): String {
        val today = Clock.System.todayIn(TimeZone.currentSystemDefault())
        return time.atDate(today)
            .toInstant(TimeZone.currentSystemDefault())
            .format(timeFormat)
    }

    fun weekDayFromDate(date: LocalDate) = date
        .atStartOfDayIn(TimeZone.currentSystemDefault())
        .format(weekDayFormat)
}

internal expect fun Instant.format(format: String): String