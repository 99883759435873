package cometes.components.layout

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.AlignItems
import com.varabyte.kobweb.compose.css.JustifyContent
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.alignContent
import com.varabyte.kobweb.compose.ui.modifiers.alignItems
import com.varabyte.kobweb.compose.ui.modifiers.display
import com.varabyte.kobweb.compose.ui.modifiers.flexDirection
import com.varabyte.kobweb.compose.ui.modifiers.flexWrap
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.compose.ui.modifiers.justifyContent
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.toModifier
import cometes.style.SpaceMedium
import org.jetbrains.compose.web.css.AlignContent
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.dom.Div

val FlexCardStyle = CssStyle {
    base {
        Modifier
            .display(DisplayStyle.Flex)
            .flexDirection(FlexDirection.Row)
            .flexWrap(FlexWrap.Wrap)
            .justifyContent(JustifyContent.FlexStart)
            .alignItems(AlignItems.FlexStart)
            .alignContent(AlignContent.FlexStart)
            .gap(rowGap = SpaceMedium, columnGap = SpaceMedium)
    }

    cssRule(" > *") {
        Modifier//.flexGrow(1)
    }
}

@Composable
inline fun FlexCardLayout(
    modifier: Modifier = Modifier,
    crossinline content: @Composable () -> Unit
) {
    Div(FlexCardStyle.toModifier().then(modifier).toAttrs()) {
        content()
    }
}