package app.cometes.shared.frontend.feature.person.domain.model

import androidx.compose.runtime.Immutable
import app.cometes.shared.frontend.base.Identifiable

@Immutable
data class Person(
    override val id: Long,
    val firstName: String,
    val lastName: String,
    val email: String
) : Identifiable {
    val fullName = "$firstName $lastName"
    val initials = "${firstName.first()}${lastName.first()}".uppercase()
}