package cometes.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import app.cometes.shared.frontend.feature.auth.domain.AuthState
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import cometes.components.feature.auth.AuthUserInfoContent
import cometes.components.feature.auth.LoginContent
import cometes.components.feature.auth.OrganizationSelectionContent
import cometes.components.webAuthenticationState

@Page
@Composable
fun AuthPage() {
    val context = rememberPageContext()
    val target = context.route.params["target"]

    val authState = webAuthenticationState()

    LaunchedEffect(authState) {
        if (authState == AuthState.Valid) {
            context.router.navigateTo(if (target != null) "/$target" else "/")
        }
    }

    when (authState) {
        AuthState.LoggedOut -> LoginContent()
        AuthState.PersonCreation -> AuthUserInfoContent()
        AuthState.OrganizationSelection -> OrganizationSelectionContent()
        AuthState.Valid, null -> {}
    }
}
