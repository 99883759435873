package cometes.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.produceState
import app.cometes.shared.frontend.feature.auth.domain.AuthState
import app.cometes.shared.frontend.feature.organization.data.currentOrganization
import app.cometes.shared.frontend.feature.person.data.currentPerson
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.core.isExporting
import com.varabyte.kobweb.core.rememberPageContext
import cometes.components.common.DelayedProgressIndicator
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.auth.auth

@Composable
fun webAuthenticationState(): AuthState? {
    val person = currentPerson()
    // Firebase initialization is slow, so we consider only the local auth state (current person) for init state
    val isLoggedIn by produceState(person != null) {
        Firebase.auth.authStateChanged.collect { currentUser -> value = currentUser != null }
    }
    val organization = currentOrganization()

    return when {
        !isLoggedIn -> AuthState.LoggedOut
        person == null -> AuthState.PersonCreation
        organization == null -> AuthState.OrganizationSelection
        else -> AuthState.Valid
    }
}

@Composable
inline fun Authenticated(
    modifier: Modifier = Modifier.fillMaxSize(),
    content: @Composable () -> Unit
) {
    val context = rememberPageContext()
    if (context.isExporting) {
        content()
        return
    }

    val authState = webAuthenticationState()

    LaunchedEffect(authState) {
        when (authState) {
            AuthState.LoggedOut -> context.router.navigateTo("/auth?target=${context.route.slug}")
            AuthState.PersonCreation -> context.router.navigateTo("/auth?target=${context.route.slug}")
            AuthState.OrganizationSelection -> context.router.navigateTo("/auth?target=${context.route.slug}")
            AuthState.Valid, null -> {}
        }
    }

    if (authState != AuthState.Valid) {
        Box(modifier) {
            DelayedProgressIndicator(Modifier.align(Alignment.Center))
        }

        return
    }

    content()
}