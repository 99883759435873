package app.cometes.shared.frontend.feature.organization.infrastructure.remote

import app.cometes.shared.feature.organization.infrastructure.model.NewOrganizationBody
import app.cometes.shared.feature.organization.infrastructure.model.NewOrganizationDto
import app.cometes.shared.feature.organization.infrastructure.model.OrganizationDetailDto
import app.cometes.shared.feature.organization.infrastructure.model.OrganizationDto
import app.cometes.shared.feature.organization.infrastructure.model.OrganizationMemberPersonDto
import app.cometes.shared.feature.organization.infrastructure.model.OrganizationMemberRoleDto
import app.cometes.shared.feature.organization.infrastructure.model.OrganizationMemberStatusDto
import app.cometes.shared.feature.organization.infrastructure.model.UpdateOrganizationBody
import app.cometes.shared.feature.person.infrastructure.model.OrganizationWithMembershipDto
import app.cometes.shared.feature.person.infrastructure.model.UpdateMemberRoleBody
import app.cometes.shared.frontend.base.EmptyResult
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.error.runCatchingNetworkExceptions
import app.cometes.shared.frontend.feature.organization.data.source.OrganizationRemoteSource
import app.cometes.shared.frontend.network.UploadFile
import app.cometes.shared.frontend.network.setFileBody
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.delete
import io.ktor.client.request.get
import io.ktor.client.request.patch
import io.ktor.client.request.post
import io.ktor.client.request.setBody

internal class OrganizationRemoteSourceImpl(
    private val client: HttpClient
) : OrganizationRemoteSource {

    override suspend fun createOrganization(
        name: String,
        description: String
    ): Result<NewOrganizationDto> = runCatchingNetworkExceptions {
        val res = client.post("/organization") {
            setBody(
                NewOrganizationBody(
                    name = name,
                    description = description
                )
            )
        }

        val resBody = res.body<NewOrganizationDto>()
        Result.Success(resBody)
    }

    override suspend fun getOrganization(organizationId: Long): Result<OrganizationDto> =
        runCatchingNetworkExceptions {
            val res = client.get("/organization/${organizationId}")
            val resBody = res.body<OrganizationDto>()
            Result.Success(resBody)
        }

    override suspend fun updateOrganization(
        organizationId: Long,
        name: String,
        description: String
    ): Result<OrganizationDto> = runCatchingNetworkExceptions {
        val res = client.patch("/organization/${organizationId}") {
            setBody(UpdateOrganizationBody(name, description))
        }
        val resBody = res.body<OrganizationDto>()
        Result.Success(resBody)
    }
    override suspend fun uploadOrganizationImage(
        organizationId: Long,
        file: UploadFile
    ): Result<Unit> =
        runCatchingNetworkExceptions {
            client.post("/organization/${organizationId}/image") {
                setFileBody(file)
            }

            return Result.Success(Unit)
        }

    override suspend fun getOrganizationDetail(
        organizationId: Long
    ): Result<OrganizationDetailDto> = runCatchingNetworkExceptions {
        val res = client.get("/organization/${organizationId}/detail")
        val resBody = res.body<OrganizationDetailDto>()
        Result.Success(resBody)
    }


    // === MEMBERS ===

    override suspend fun getCurrentPersonMembership(organizationId: Long): Result<OrganizationMemberPersonDto> =
        runCatchingNetworkExceptions {
            val res = client.get("/organization/${organizationId}/member/current")
            val resBody = res.body<OrganizationMemberPersonDto>()
            Result.Success(resBody)
        }

    override suspend fun getOrganizationMembers(organizationId: Long): Result<List<OrganizationMemberPersonDto>> =
        runCatchingNetworkExceptions {
            val res = client.get("/organization/${organizationId}/member")
            val resBody = res.body<List<OrganizationMemberPersonDto>>()
            Result.Success(resBody)
        }

    override suspend fun updateMember(
        organizationId: Long,
        personId: Long,
        role: OrganizationMemberRoleDto?,
        status: OrganizationMemberStatusDto?,
    ): Result<OrganizationMemberPersonDto> = runCatchingNetworkExceptions {
        val body = UpdateMemberRoleBody(role = role, status = status)
        val response = client.patch("/organization/${organizationId}/member/${personId}") {
            setBody(body)
        }

        Result.Success(response.body())
    }

    override suspend fun removeMember(organizationId: Long, personId: Long): EmptyResult =
        runCatchingNetworkExceptions {
            client.delete("/organization/${organizationId}/member/${personId}")
            Result.Success(Unit)
        }


    // === PERSON ===

    override suspend fun getAllPersonsOrganizations(): Result<List<OrganizationWithMembershipDto>> =
        runCatchingNetworkExceptions {
            val res = client.get("/person/current/organizations")
            val resBody = res.body<List<OrganizationWithMembershipDto>>()
            return Result.Success(resBody)
        }
}
