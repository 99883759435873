
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import app.cometes.shared.frontend.initKoin
import app.cometes.shared.frontend.presentation.CometesColor
import com.varabyte.kobweb.compose.css.BoxSizing
import com.varabyte.kobweb.compose.foundation.layout.BoxScope
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.graphics.lightened
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.App
import com.varabyte.kobweb.silk.SilkApp
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.init.InitSilk
import com.varabyte.kobweb.silk.init.InitSilkContext
import com.varabyte.kobweb.silk.init.registerBaseStyle
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.colors.MutableSilkPalette
import com.varabyte.kobweb.silk.theme.colors.MutableSilkPalettes
import com.varabyte.kobweb.silk.theme.colors.getColorMode
import cometes.style.TypographyBodyMedium
import cometes.util.rememberDependency
import dev.bitspittle.firebase.app.FirebaseApp
import kotlinx.browser.localStorage
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.number
import org.jetbrains.compose.web.css.px
import org.w3c.dom.get

@Suppress("unused")
@App
@Composable
fun MyApp(content: @Composable BoxScope.() -> Unit) {
    initKoin { modules(webModule) }
    rememberDependency<FirebaseApp>()

    SilkApp {
        val colorMode = getColorMode()
        LaunchedEffect(colorMode) { // Relaunched every time the color mode changes
            localStorage.setItem(ColorModeKey, colorMode.name)
        }

        Surface(
            content = content,
            modifier = Modifier
                .then(TypographyBodyMedium)
                .fillMaxSize(),
        )
    }
}


@Suppress("unused")
@InitSilk
fun initSiteStyles(ctx: InitSilkContext) {
    val colorMode = ColorMode.valueOf(
        localStorage[ColorModeKey] ?: ColorMode.DARK.name
    )
    val backgroundColor = if (colorMode.isLight) LightPalette.background else DarkPalette.background

    ctx.config.initialColorMode = colorMode

    with(ctx.stylesheet) {
        registerBaseStyle("html, body") {
            Modifier.margin(0.number).padding(0.number).background(backgroundColor)
        }
        registerBaseStyle("*") { Modifier.boxSizing(BoxSizing.BorderBox) }
        registerBaseStyle("#root") {
            Modifier
                .position(Position.Absolute)
                .top(0.px).bottom(0.px).left(0.px).right(0.px)
        }

        registerBaseStyle("body") {
            Modifier.fontFamily(
                "Inter var", "sans-serif"
            )
        }
    }
    ctx.theme.palettes = MutableSilkPalettes(
        light = LightPalette,
        dark = DarkPalette
    )
}

private val LightPalette = MutableSilkPalette(
    background = Color.argb(CometesColor.light_background),
    color = Color.argb(CometesColor.light_onBackground),
    link = MutableSilkPalette.Link(
        default = Color.argb(CometesColor.light_secondary),
        visited = Color.argb(CometesColor.light_secondary).darkened(0.4f)
    ),
    button = MutableSilkPalette.Button(
        default = Color.argb(CometesColor.light_primaryContainer),
        hover = Color.argb(CometesColor.light_primaryContainer).darkened(0.2f),
        focus = Color.argb(CometesColor.light_primaryContainer).darkened(0.2f),
        pressed = Color.argb(CometesColor.light_primaryContainer).darkened(0.35f)
    ),
    overlay = Color
        .argb(CometesColor.light_onBackground)
        .copyf(alpha = 0.3f),
    switch = MutableSilkPalette.Switch(
        backgroundOn = Color.argb(CometesColor.light_secondary),
        backgroundOff = Color.argb(CometesColor.light_surface)
    ),
    tab = MutableSilkPalette.Tab(
        // Not used
        color = Colors.Black,
        background = Colors.White,
        selectedColor = Colors.CornflowerBlue,
        hover = Colors.LightGray,
        pressed = Colors.WhiteSmoke,
        disabled = Colors.White,
    ),
    border = Color.argb(CometesColor.light_onBackground),
    tooltip = MutableSilkPalette.Tooltip(
        background = Color.argb(CometesColor.light_surface),
        color = Color.argb(CometesColor.light_onSurface),
    ),
    input = MutableSilkPalette.Input(
        colorMode = ColorMode.LIGHT,
        filled = Color.argb(CometesColor.light_onSurface)
    )
)
private val DarkPalette = MutableSilkPalette(
    background = Color.argb(CometesColor.dark_background),
    color = Color.argb(CometesColor.dark_onBackground),
    link = MutableSilkPalette.Link(
        default = Color.argb(CometesColor.dark_secondary),
        visited = Color.argb(CometesColor.dark_secondary).lightened(0.4f)
    ),
    button = MutableSilkPalette.Button(
        default = Color.argb(CometesColor.dark_primaryContainer),
        hover = Color.argb(CometesColor.dark_primaryContainer).darkened(0.2f),
        focus = Color.argb(CometesColor.dark_primaryContainer).darkened(0.2f),
        pressed = Color.argb(CometesColor.dark_primaryContainer).darkened(0.35f)
    ),
    overlay = Color
        .argb(CometesColor.dark_onBackground)
        .copyf(alpha = 0.2f),
    switch = MutableSilkPalette.Switch(
        backgroundOn = Color.argb(CometesColor.dark_secondary),
        backgroundOff = Color.argb(CometesColor.dark_surface)
    ),
    tab = MutableSilkPalette.Tab(
        // Not used
        color = Colors.Black,
        background = Colors.White,
        selectedColor = Colors.CornflowerBlue,
        hover = Colors.LightGray,
        pressed = Colors.WhiteSmoke,
        disabled = Colors.White,
    ),
    border = Color.argb(CometesColor.dark_onBackground),
    tooltip = MutableSilkPalette.Tooltip(
        background = Color.argb(CometesColor.dark_surface),
        color = Color.argb(CometesColor.dark_onSurface),
    ),
    input = MutableSilkPalette.Input(
        colorMode = ColorMode.DARK,
        filled = Color.argb(CometesColor.dark_onSurface)
    )
)


private const val ColorModeKey = "ColorModeKey"