package cometes.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.text.SpanText
import cometes.components.common.VerticalSpacer
import cometes.components.layout.MainPageLayout
import cometes.style.CometesTheme
import cometes.style.SpaceMedium
import cometes.style.TypographyHeadlineSmall

@Page
@Composable
fun SupportPage() {
    MainPageLayout(title = "Support") {
        Column(
            horizontalAlignment = Alignment.CenterHorizontally,
            modifier = Modifier.fillMaxSize()
        ) {
            VerticalSpacer(SpaceMedium)

            Row {
                SpanText(
                    text = "Do you need help with Cometes? Please contact us at ",
                    modifier = TypographyHeadlineSmall
                )
                Link(
                    text = "info@cometes.app",
                    path = "mailto:info@cometes.app",
                    modifier = TypographyHeadlineSmall
                        .color(CometesTheme.color.primary)
                )
            }
        }
    }
}