package app.cometes.shared.frontend.feature.home.infrastructure

import app.cometes.shared.feature.reservation.infrastructure.model.HomeOverviewDto
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.error.runCatchingNetworkExceptions
import app.cometes.shared.frontend.feature.home.data.HomeRemoteSource
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*
import kotlinx.datetime.LocalDate
import kotlinx.datetime.TimeZone

internal class HomeRemoteSourceImpl(
    private val client: HttpClient
) : HomeRemoteSource {

    override suspend fun getOverview(organizationId: Long, date: LocalDate): Result<HomeOverviewDto> =
        runCatchingNetworkExceptions {
            val response = client.get("/organization/${organizationId}/overview") {
                parameter("date", date)
                parameter("timeZoneId", TimeZone.currentSystemDefault().id)
            }

            Result.Success(response.body())
        }
}