package cometes.components.common

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.RowScope
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.minWidth
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.silk.components.overlay.Overlay
import com.varabyte.kobweb.silk.components.text.SpanText
import cometes.style.RadiusLarge
import cometes.style.SpaceLarge
import cometes.style.SpaceXLarge
import cometes.style.TypographyHeadlineSmall
import cometes.style.system.CometesButton
import cometes.style.system.CometesSurface
import cometes.style.system.SurfaceBackgroundColorVariant
import cometes.style.system.animation.fadeInTransition
import org.jetbrains.compose.web.css.percent

@Composable
fun CometesDialog(
    onDismiss: () -> Unit,
    modifier: Modifier = Modifier,
    title: String? = null,
    submitButtons: @Composable RowScope.() -> Unit = { DefaultOkButton(onDismiss) },
    content: @Composable () -> Unit
) {
    val titleText: (String) -> (@Composable RowScope.() -> Unit) = { text ->
        {
            SpanText(
                text = text,
                modifier = Modifier.then(TypographyHeadlineSmall)
            )
        }
    }

    CometesDialog(
        onDismiss = onDismiss,
        modifier = modifier,
        title = title?.let(titleText),
        submitButtons = submitButtons,
        content = content
    )
}

@Composable
fun CometesDialog(
    onDismiss: () -> Unit,
    modifier: Modifier = Modifier,
    title: (@Composable RowScope.() -> Unit)? = null,
    submitButtons: @Composable RowScope.() -> Unit = { DefaultOkButton(onDismiss) },
    content: @Composable () -> Unit,
) {
    Overlay(
        contentAlignment = Alignment.Center,
        modifier = Modifier
            .fadeInTransition()
            .zIndex(6)
            .onClick { onDismiss() }
    ) {
        CometesSurface(
            variant = SurfaceBackgroundColorVariant,
            modifier = Modifier
                .borderRadius(RadiusLarge)
                .padding(SpaceLarge)
                .minWidth(40.percent)
                .then(modifier)
                .onClick { event ->
                    event.preventDefault()
                    event.stopPropagation()
                }
        ) {
            Column(Modifier.fillMaxSize()) {
                if (title != null) {
                    Row(Modifier.fillMaxWidth()) {
                        title()
                        Spacer()
                    }
                }

                VerticalSpacer(SpaceXLarge)

                Box(Modifier.fillMaxSize()) {
                    content()
                }

                VerticalSpacer(SpaceXLarge)

                Row(Modifier.fillMaxWidth()) {
                    Spacer()
                    submitButtons()
                }
            }
        }
    }
}

@Composable
private fun DefaultOkButton(onClick: () -> Unit) {
    CometesButton(
        onClick = onClick,
        variant = SurfaceBackgroundColorVariant,
    ) { SpanText("Ok") }
}