package cometes.components.feature.reservation

import androidx.compose.runtime.Composable
import app.cometes.shared.frontend.feature.reservation.domain.model.Reservation
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.text.SpanText
import cometes.components.common.VerticalSpacer
import cometes.style.RadiusLarge
import cometes.style.SpaceMediumSmall
import cometes.style.SpaceSmall
import cometes.style.system.CometesSurface
import cometes.style.system.SurfacePrimaryVariant

@Composable
fun ReservationListItem(
    reservation: Reservation,
    onClick: () -> Unit,
    modifier: Modifier = Modifier
) {
    CometesSurface(
        onClick = { onClick() },
        variant = SurfacePrimaryVariant,
        modifier = modifier
            .borderRadius(RadiusLarge)
            .padding(SpaceMediumSmall)
    ) {
        Column {
            SpanText(reservation.person.fullName)
            VerticalSpacer(SpaceSmall)
            SpanText(reservation.desk.name)
        }
    }
}