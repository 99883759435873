package cometes.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.core.Page
import cometes.components.layout.PageNotFoundContent

@Page
@Composable
fun NotFoundPage() {
    PageNotFoundContent(Modifier.fillMaxSize())
}