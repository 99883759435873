package cometes.components.feature.auth

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.autoLength
import com.varabyte.kobweb.compose.css.functions.blur
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.ColumnScope
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backdropFilter
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flex
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.styleModifier
import cometes.components.common.CometesCard
import cometes.components.common.VerticalSpacer
import cometes.components.image.ImageCometes
import cometes.style.CometesTheme
import cometes.style.SpaceLarge
import cometes.style.SpaceMedium
import cometes.style.SpaceXXLarge
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun LoginLayout(
    modifier: Modifier = Modifier,
    content: @Composable ColumnScope.() -> Unit,
) {
    Column(modifier = modifier) {
        VerticalSpacer(SpaceMedium)

        ImageCometes(Modifier.size(162.px).align(Alignment.CenterHorizontally))

        VerticalSpacer(SpaceXXLarge)

        CometesCard(
            modifier = Modifier
                .maxWidth(28.cssRem)
                .fillMaxWidth()
                .flex(1, 1, 0.percent)
                .margin(leftRight = autoLength)
                .padding(SpaceLarge)
                .cardBlur()
        ) {
            Column(
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.Center,
                modifier = Modifier.fillMaxSize(),
                content = content
            )
        }
    }
}

@Composable
private fun Modifier.cardBlur() =
    backgroundColor(CometesTheme.color.surface.toRgb().copyf(alpha = 0.5f))
        .backdropFilter(blur(20.px))
        .styleModifier { property("-webkit-backdrop-filter", "blur(20px)") }