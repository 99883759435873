package app.cometes.shared.frontend.feature.person

import app.cometes.shared.frontend.feature.person.data.source.PersonLocalSource
import app.cometes.shared.frontend.feature.person.data.source.PersonRemoteSource
import app.cometes.shared.frontend.feature.person.infrastructure.source.PersonLocalSourceImpl
import app.cometes.shared.frontend.feature.person.infrastructure.source.PersonRemoteSourceImpl
import org.koin.core.module.dsl.singleOf
import org.koin.dsl.bind
import org.koin.dsl.module

val featurePerson = module {
    singleOf(::PersonRemoteSourceImpl) bind PersonRemoteSource::class
    singleOf(::PersonLocalSourceImpl) bind PersonLocalSource::class
}