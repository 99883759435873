package app.cometes.shared.frontend.feature.organization.infrastructure.local

import app.cometes.shared.feature.organization.infrastructure.model.NewLocationBody
import app.cometes.shared.feature.organization.infrastructure.model.UpdateLocationBody
import app.cometes.shared.feature.reservation.infrastructure.model.LocationDto
import app.cometes.shared.frontend.base.EmptyResult
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.error.runCatchingNetworkExceptions
import app.cometes.shared.frontend.feature.organization.data.source.OrganizationLocationRemoteSource
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.delete
import io.ktor.client.request.get
import io.ktor.client.request.patch
import io.ktor.client.request.post
import io.ktor.client.request.setBody

internal class OrganizationLocationRemoteSourceImpl(
    private val httpClient: HttpClient
) : OrganizationLocationRemoteSource {
    override suspend fun createLocation(
        organizationId: Long,
        name: String,
        address: String?
    ): Result<LocationDto> = runCatchingNetworkExceptions {
        val response = httpClient.post("/organization/${organizationId}/location") {
            setBody(NewLocationBody(name, address))
        }

        Result.Success(response.body())
    }

    override suspend fun getOrganizationLocations(organizationId: Long): Result<List<LocationDto>> =
        runCatchingNetworkExceptions {
            val response = httpClient.get("/organization/${organizationId}/location")

            Result.Success(response.body())
        }

    override suspend fun getLocation(organizationId: Long, locationId: Long): Result<LocationDto> =
        runCatchingNetworkExceptions {
            val response = httpClient.get("/organization/${organizationId}/location/${locationId}")

            Result.Success(response.body())
        }

    override suspend fun updateLocation(
        organizationId: Long,
        locationId: Long,
        name: String?,
        address: String?
    ): Result<LocationDto> = runCatchingNetworkExceptions {
        val body = UpdateLocationBody(name = name, address = address)
        val response = httpClient.patch("/organization/${organizationId}/location/${locationId}") {
            setBody(body)
        }

        Result.Success(response.body())
    }

    override suspend fun removeLocation(
        organizationId: Long,
        locationId: Long
    ): EmptyResult = runCatchingNetworkExceptions {
        httpClient.delete("/organization/${organizationId}/location/${locationId}")
        Result.Success(Unit)
    }

}