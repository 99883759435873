package cometes.components.feature.organization.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import cometes.components.common.CometesCard
import cometes.components.common.ItemList
import cometes.style.SpaceMediumSmall
import cometes.style.SpaceSmall
import org.jetbrains.compose.web.css.CSSSizeValue

@Composable
fun <T> ResourceList(
    items: List<T>,
    modifier: Modifier = Modifier,
    itemSpacing: CSSSizeValue<*> = SpaceMediumSmall,
    gridBreakpoint: Breakpoint = Breakpoint.LG,
    gridColumns: Int = 2,
    itemContent: @Composable (T) -> Unit
) {
    val breakpoint = rememberBreakpoint()
    when {
        breakpoint < gridBreakpoint -> {
            CometesCard(modifier) {
                Column(
                    verticalArrangement = Arrangement.spacedBy(SpaceSmall),
                    modifier = Modifier.fillMaxSize()
                ) {
                    ItemList(items) { member ->
                        itemContent(member)
                    }
                }
            }
        }

        else -> {
            SimpleGrid(
                numColumns = numColumns(gridColumns),
                modifier = modifier.styleModifier { property("grid-gap", itemSpacing) }
            ) {
                for (member in items) {
                    CometesCard(Modifier.fillMaxSize()) {
                        itemContent(member)
                    }
                }
            }
        }
    }
}