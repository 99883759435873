package app.cometes.shared.feature.person.infrastructure.model

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient

@Serializable
data class PersonDto(
    val id: Long,
    val email: String,
    val firstName: String,
    val lastName: String,
    val createdAt: Instant,

    @Transient
    val paymentCustomerId: String? = null
)