package cometes.pages

import androidx.compose.runtime.Composable
import app.cometes.shared.frontend.feature.home.presentation.HomePresenter
import app.cometes.shared.frontend.feature.home.presentation.HomeState
import app.cometes.shared.frontend.feature.reservation.domain.model.ReservationDay
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.icons.fa.FaUser
import com.varabyte.kobweb.silk.components.text.DivText
import cometes.components.Authenticated
import cometes.components.common.ThemeToggleButton
import cometes.components.common.VerticalSpacer
import cometes.components.feature.reservation.ReservationListItem
import cometes.components.layout.MainPageLayout
import cometes.style.SpaceLarge
import cometes.style.SpaceMedium
import cometes.style.TypographyHeadlineMedium
import cometes.style.system.CometesImageButton
import cometes.style.system.SurfacePrimaryVariant

@Page
@Composable
fun HomePage() {
    val context = rememberPageContext()

    Authenticated {
        val state = HomePresenter.presenter()

        HomeContent(
            state = state,
            onAccountClick = { context.router.navigateTo("/account") },
            modifier = Modifier
                .fillMaxSize()
                .padding(SpaceLarge)
        )
    }
}

@Composable
fun HomeContent(
    state: HomeState,
    onAccountClick: () -> Unit,
    modifier: Modifier = Modifier
) {
    MainPageLayout(
        utilRow = {
            ThemeToggleButton()
            CometesImageButton(
                onClick = onAccountClick,
                variant = SurfacePrimaryVariant,
            ) { FaUser() }
        }
    ) {
        Column(modifier) {
            DivText(
                text = "Reservations",
                modifier = Modifier
                    .align(Alignment.CenterHorizontally)
                    .then(TypographyHeadlineMedium)
            )

            VerticalSpacer(SpaceLarge)

            Row(Modifier.align(Alignment.CenterHorizontally)) {
                for (day in state.days.take(5)) {
                    DayColumn(day)
                    Box(Modifier.width(SpaceLarge))
                }
            }
        }
    }
}

@Composable
fun DayColumn(
    day: ReservationDay,
    modifier: Modifier = Modifier
) {
    Column(
        modifier = modifier
    ) {
        for (reservation in day.reservations) {
            ReservationListItem(
                reservation = reservation,
                onClick = {},
                modifier = Modifier.fillMaxWidth()
            )

            Box(Modifier.height(SpaceMedium))
        }
    }
}

