package app.cometes.shared.feature.person.infrastructure.model

import kotlinx.serialization.Serializable

@Serializable
data class RegistrationBody(
    val firstName: String,
    val lastName: String,
    val email: String? = null,

    /**
     * Atomically join organization as an User if specified
     */
    val organizationId: Long? = null
)