package app.cometes.shared.frontend.feature.conferenceRoom.domain.entity

import androidx.compose.runtime.Immutable
import app.cometes.shared.feature.room.infrastructure.model.ConferenceRoomDto
import app.cometes.shared.feature.room.infrastructure.model.ConferenceRoomReservationDto
import app.cometes.shared.frontend.base.Identifiable
import app.cometes.shared.frontend.feature.organization.infrastructure.model.toDomain
import app.cometes.shared.frontend.feature.person.domain.model.Person
import app.cometes.shared.frontend.feature.person.infrastructure.model.toDomain
import app.cometes.shared.frontend.feature.reservation.domain.model.Location
import kotlinx.datetime.Instant

// Room

@Immutable
data class ConferenceRoom(
    override val id: Long,
    val location: Location,
    val name: String,
    val description: String,
    val capacity: Int,
) : Identifiable

fun ConferenceRoomDto.toDomain() = ConferenceRoom(
    id = id,
    location = location.toDomain(),
    name = conferenceRoomName,
    description = conferenceRoomDescription,
    capacity = conferenceRoomCapacity,
)

// Reservation
@Immutable
data class ConferenceRoomReservation(
    override val id: Long,
    val name: String,
    val description: String,
    val conferenceRoom: ConferenceRoom,
    val startTime: Instant,
    val endTime: Instant,
    val author: Person,
    val createdAt: Instant,
) : Identifiable {
    val reservationRange get() = startTime..endTime
}

fun ConferenceRoomReservationDto.toDomain() = ConferenceRoomReservation(
    id = id,
    name = name,
    description = description,
    conferenceRoom = conferenceRoom.toDomain(),
    startTime = startTime,
    endTime = endTime,
    author = author.toDomain(),
    createdAt = createdAt,
)