package cometes.components.feature.organization.member

import androidx.compose.runtime.Composable
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationMemberPerson
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationMemberStatus
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.borderWidth
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.icons.fa.FaTrashCan
import com.varabyte.kobweb.silk.components.icons.fa.FaWrench
import com.varabyte.kobweb.silk.components.text.SpanText
import cometes.components.common.CometesLabel
import cometes.components.common.HorizontalSpacer
import cometes.components.common.VerticalSpacer
import cometes.style.CometesTheme
import cometes.style.RadiusLarge
import cometes.style.SpaceMediumSmall
import cometes.style.SpaceSmall
import cometes.style.SpaceXSmall
import cometes.style.SpaceXXSmall
import cometes.style.TypographyBodyLarge
import cometes.style.system.CometesImageButton
import cometes.style.system.CometesSurface
import cometes.style.system.SurfaceErrorVariant
import cometes.style.system.SurfaceSecondaryVariant
import org.jetbrains.compose.web.css.px

@Composable
fun MemberItem(
    member: OrganizationMemberPerson,
    interactive: Boolean,
    isCurrentPerson: Boolean,
    onRemove: () -> Unit,
    modifier: Modifier = Modifier
) {

    CometesSurface(
        variant = if (isCurrentPerson) SurfaceSecondaryVariant else null,
        modifier = Modifier
            .borderRadius(RadiusLarge)
            .padding(
                topBottom = (SpaceXSmall.value + SpaceXXSmall.value).px,
                leftRight = SpaceMediumSmall
            )
            .then(modifier)
    ) {
        Row(Modifier.fillMaxWidth()) {
            Column {
                Row(verticalAlignment = Alignment.CenterVertically) {
                    SpanText(
                        text = member.person.fullName,
                        modifier = Modifier.then(TypographyBodyLarge)
                    )

                    if (member.role.isAdmin) {
                        HorizontalSpacer(SpaceSmall)
                        FaWrench()
                    }
                }

                SpanText(member.person.email)

                if (member.status == OrganizationMemberStatus.Suspended) {
                    VerticalSpacer(SpaceSmall)
                    CometesLabel(
                        text = member.status.name,
                        variant = SurfaceErrorVariant,
                    )
                }
            }

            if (interactive && !isCurrentPerson) {
                Spacer()

                CometesImageButton(
                    onClick = onRemove,
                    modifier = Modifier.padding(SpaceSmall).borderWidth(0.px)
                ) {
                    FaTrashCan(Modifier.color(CometesTheme.color.error))
                }
            }
        }
    }
}