package app.cometes.shared.feature.reservation.infrastructure.model

import kotlinx.datetime.LocalDate
import kotlinx.serialization.Serializable

@Serializable
data class ReservationDayDto(
    val date: LocalDate,
    val reservations: List<ReservationDto>,
    val maxReservations: Int
)
