package app.cometes.shared.frontend.feature.organization.presentation

import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.runtime.Stable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import app.cometes.shared.frontend.base.ErrorResult
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.feature.organization.data.createOrganizationMutation
import app.cometes.shared.frontend.feature.organization.data.setCurrentOrganizationMutation
import app.cometes.shared.frontend.network.UploadFile
import kotlinx.coroutines.launch

@Immutable
data class NewOrganizationState(
    val organizationName: String,
    val organizationDescription: String,
    val organizationImage: UploadFile?,
    val isLoading: Boolean,

    val onIntent: (NewOrganizationIntent) -> Unit
)

sealed interface NewOrganizationIntent {
    class SetName(val name: String) : NewOrganizationIntent
    class SetDescription(val description: String) : NewOrganizationIntent
    class SetFile(val file: UploadFile) : NewOrganizationIntent
    object Confirm : NewOrganizationIntent
}

sealed interface NewOrganizationEvent {
    class Error(val errorResult: ErrorResult) : NewOrganizationEvent
}

@Stable
class NewOrganizationPresenter(
    private val onEvent: (NewOrganizationEvent) -> Unit,
) {

    @Composable
    fun presenterState(): NewOrganizationState {
        val scope = rememberCoroutineScope()
        var name by remember { mutableStateOf("") }
        var description by remember { mutableStateOf("") }
        var file by remember { mutableStateOf<UploadFile?>(null) }
        var isLoading by remember { mutableStateOf(false) }

        val createOrganizationMutation = createOrganizationMutation(name, description, file)
        val setOrganization = setCurrentOrganizationMutation()

        return NewOrganizationState(
            organizationName = name,
            organizationDescription = description,
            organizationImage = file,
            isLoading = isLoading,
        ) { intent ->
            when (intent) {
                is NewOrganizationIntent.SetName -> name = intent.name
                is NewOrganizationIntent.SetDescription -> description = intent.description
                is NewOrganizationIntent.SetFile -> file = intent.file
                NewOrganizationIntent.Confirm -> {
                    isLoading = true
                    scope.launch {
                        try {
                            when (val res = createOrganizationMutation.execute()) {
                                is Result.Success -> setOrganization.execute(res.data)
                                is Result.Error -> onEvent(NewOrganizationEvent.Error(res.error))
                            }
                        } finally {
                            isLoading = false
                        }
                    }
                }
            }
        }
    }
}