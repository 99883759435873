package cometes.components.feature.auth

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import app.cometes.shared.frontend.feature.auth.presentation.UserInfoEvent
import app.cometes.shared.frontend.feature.auth.presentation.UserInfoIntent
import app.cometes.shared.frontend.feature.auth.presentation.UserInfoPresenter
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.text.SpanText
import cometes.components.common.AnimatedVisibility
import cometes.components.common.DelayedProgressIndicator
import cometes.components.common.DivText
import cometes.components.common.ProgressIndicator
import cometes.components.common.VerticalSpacer
import cometes.components.layout.AuthPageLayout
import cometes.style.CometesTheme
import cometes.style.SpaceLarge
import cometes.style.SpaceMedium
import cometes.style.SpaceXLarge
import cometes.style.SpaceXSmall
import cometes.style.TypographyBodyLarge
import cometes.style.TypographyBodyMedium
import cometes.style.TypographyHeadlineSmall
import cometes.style.TypographyTitleMedium
import cometes.style.system.CometesButton
import cometes.style.system.input.CometesTextField

@Composable
fun AuthUserInfoContent() {
    val presenter = remember {
        UserInfoPresenter { event ->
            when (event) {
                is UserInfoEvent.Error -> {} // TODO snackbar
            }
        }
    }
    val state = presenter.presenterState()


    AuthPageLayout(
        title = "",
        onLogoutClick = { state.onIntent(UserInfoIntent.LogOut) }
    ) {
        Box(Modifier.fillMaxSize()) {
            if (state.isLoading) {
                DelayedProgressIndicator(Modifier.align(Alignment.Center))
            } else {
                LoginLayout(
                    Modifier
                        .align(Alignment.Center)
                        .fillMaxWidth()
                        .padding(leftRight = SpaceMedium)
                ) {
                    DivText(
                        text = "Welcome!",
                        modifier = Modifier
                            .then(TypographyHeadlineSmall)
                            .align(Alignment.Start)
                    )

                    VerticalSpacer(SpaceXSmall)

                    DivText(
                        text = "Please tell us a bit about yourself to complete your account setup.",
                        modifier = Modifier
                            .then(TypographyBodyMedium)
                            .opacity(0.75)
                            .align(Alignment.Start)
                    )

                    VerticalSpacer(SpaceXLarge)

                    CometesTextField(
                        value = state.firstName,
                        onValueChange = { state.onIntent(UserInfoIntent.SetFirstName(it)) },
                        label = "First name",
                        modifier = Modifier
                            .then(TypographyTitleMedium)
                            .fillMaxWidth()
                    )

                    VerticalSpacer(SpaceMedium)

                    CometesTextField(
                        value = state.lastName,
                        onValueChange = { state.onIntent(UserInfoIntent.SetLastName(it)) },
                        label = "Last name",
                        modifier = Modifier
                            .then(TypographyTitleMedium)
                            .fillMaxWidth()
                    )

                    val error = state.error
                    if (error != null) {
                        VerticalSpacer(SpaceMedium)
                        DivText(
                            text = error.message ?: "Unknown error",
                            modifier = Modifier
                                .color(CometesTheme.color.error)
                                .align(Alignment.Start)
                        )
                    }

                    VerticalSpacer(SpaceXLarge)

                    DivText(
                        text = "Your information is securely stored and you can update it anytime from your profile settings.",
                        modifier = Modifier
                            .then(TypographyBodyMedium)
                            .opacity(0.75)
                            .align(Alignment.Start)
                    )

                    VerticalSpacer(SpaceLarge)

                    CometesButton(
                        onClick = { state.onIntent(UserInfoIntent.Register) },
                        enabled = !state.isLoading,
                        modifier = Modifier.fillMaxWidth()
                    ) {
                        Box {
                            SpanText(
                                text = "Save",
                                modifier = Modifier
                                    .then(TypographyBodyLarge)
                                    .thenIf(state.isLoading, Modifier.opacity(0.3))
                            )

                            AnimatedVisibility(state.isLoading, Modifier.align(Alignment.Center)) {
                                ProgressIndicator()
                            }
                        }
                    }
                }
            }
        }
    }
}