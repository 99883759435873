package app.cometes.shared.frontend.base

import androidx.compose.runtime.Composable
import androidx.compose.runtime.State
import androidx.compose.runtime.produceState
import androidx.compose.runtime.remember
import io.github.reactivecircus.cache4k.Cache
import kotlinx.coroutines.channels.BufferOverflow
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.flow

class ReactiveCache<Key : Any, Value : Any>(
    maximumSize: Long? = null,
) {
    private val cache: Cache<Key, Value> = Cache.Builder<Key, Value>().apply {
        if (maximumSize != null) maximumCacheSize(maximumSize)
    }.build()

    private val propagationFlow = MutableSharedFlow<Collection<Value>?>(
        extraBufferCapacity = 1,
        onBufferOverflow = BufferOverflow.DROP_OLDEST
    )

//    fun test(predicate: (Value) -> Boolean) = callbackFlow {
//        val listener = object : CacheEventListener<Key, Value> {
//            override fun onEvent(event: CacheEvent<Key, Value>) {
//                when (event) {
//                    is CacheEvent.Created -> {}
//                    is CacheEvent.Updated -> {}
//
//                    is CacheEvent.Removed -> {}
//                    is CacheEvent.Evicted -> {}
//                    is CacheEvent.Expired -> {}
//                }
//            }
//        }
//    }

    fun stream(predicate: (Value) -> Boolean) = flow {
        suspend fun emitAllMatching() {
            val values = cache.asMap().values.filter(predicate)
            emit(values)
        }

        emitAllMatching()
        propagationFlow.collect { changedValues ->
            when {
                changedValues == null -> emitAllMatching()
                changedValues.any(predicate) -> emitAllMatching()
            }
        }
    }

    fun get(key: Key): Value? = cache.get(key)

    fun getAll(predicate: (Value) -> Boolean) = cache.asMap().values.filter(predicate)

    fun put(key: Key, value: Value) {
        cache.put(key, value)
        propagationFlow.tryEmit(listOf(value))
    }

    fun putAll(map: Map<Key, Value>) {
        for ((key, value) in map) cache.put(key, value)
        propagationFlow.tryEmit(map.values)
    }

    fun invalidate(key: Key) {
        val value = cache.get(key)
        if (value != null) {
            cache.invalidate(key)
            propagationFlow.tryEmit(listOf(value))
        }
    }

    fun invalidateAll() {
        cache.invalidateAll()
        propagationFlow.tryEmit(null)
    }
}

@Composable
fun <Key : Any, Value : Any> ReactiveCache<Key, Value>.collectAsState(
    predicate: (Value) -> Boolean = { true }
): State<List<Value>> {
    val initialValue = remember(this, predicate) { getAll(predicate) }
    return produceState(initialValue, predicate, this) {
        stream(predicate)
            .collect { value = it }
    }
}
