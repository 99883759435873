package cometes.components.feature.organization.subscription

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.feature.billing.data.createSubscriptionCheckoutLinkMutation
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.dom.ElementTarget
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.alignContent
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.icons.fa.FaCartShopping
import com.varabyte.kobweb.silk.components.icons.fa.FaCircleInfo
import com.varabyte.kobweb.silk.components.overlay.Popover
import com.varabyte.kobweb.silk.components.overlay.PopupPlacement
import com.varabyte.kobweb.silk.components.text.DivText
import com.varabyte.kobweb.silk.components.text.SpanText
import cometes.components.common.CometesCard
import cometes.components.common.CometesLoadingButton
import cometes.components.common.HorizontalSpacer
import cometes.components.common.VerticalSpacer
import cometes.style.SpaceSmall
import cometes.style.SpaceXLarge
import cometes.style.SpaceXSmall
import cometes.style.TypographyBodyLarge
import cometes.style.TypographyBodyMedium
import cometes.style.system.CometesImageButton
import cometes.style.system.SurfaceSecondaryVariant
import cometes.style.system.animation.fadeInTransition
import cometes.style.system.input.CometesTextField
import cometes.style.system.input.CometesTextFieldPlainVariant
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.AlignContent
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun InactiveSubscriptionContent(
    organizationName: String,
    modifier: Modifier = Modifier
) {
    Column(modifier) {
        DivText(
            text = "To invite other users to join ${organizationName}, please set up a subscription. You'll be billed on a per-user basis, and you can modify your subscription at any time. If you reduce the number of users, the payment difference will be credited towards your next billing cycle. If you increase the number of users, the additional cost will be charged immediately.",
            modifier = Modifier.then(TypographyBodyLarge)
        )
        DivText(
            text = "Changing user quantity during trial period will end the trial.",
            modifier = Modifier.then(TypographyBodyLarge)
        )
        VerticalSpacer(SpaceXLarge)
        CreateSubscriptionRow(
            organizationName = organizationName,
            modifier = Modifier.fillMaxWidth()
        )
    }
}

@Composable
private fun CreateSubscriptionRow(
    organizationName: String,
    modifier: Modifier = Modifier
) {
    val pageContext = rememberPageContext()
    val coroutineScope = rememberCoroutineScope()

    var userCount by remember { mutableStateOf(5) }
    var isLoading by remember { mutableStateOf(false) }

    val createSubscriptionCheckoutLinkMutation = createSubscriptionCheckoutLinkMutation(userCount)
    fun navigateToSubscriptionCheckout() {
        coroutineScope.launch {
            isLoading = true
            when (val res = createSubscriptionCheckoutLinkMutation.execute()) {
                is Result.Success -> {
                    pageContext.router.navigateTo(
                        res.data,
                        openExternalLinksStrategy = OpenLinkStrategy.IN_PLACE
                    )
                }

                is Result.Error -> {}
            }
            isLoading = false
        }
    }

    Column(modifier) {
        Row(verticalAlignment = Alignment.CenterVertically) {
            SpanText(
                text = "Enter the number of people in $organizationName to set up your subscription.",
                modifier = Modifier
                    .then(TypographyBodyMedium)
                    .maxWidth(60.percent)
            )
            HorizontalSpacer(SpaceSmall)

            CometesImageButton(onClick = {}, Modifier.align(Alignment.Top), variant = null) {
                FaCircleInfo()
            }
            Popover(
                ElementTarget.PreviousSibling,
                placement = PopupPlacement.Top,
                offsetPixels = SpaceXSmall.value
            ) {
                CometesCard(
                    variant = SurfaceSecondaryVariant,
                    modifier = Modifier
                        .width(200.px)
                        .alignContent(AlignContent.Center)
                        .fadeInTransition()
                ) {
                    DivText("You can change the number at the checkout")
                }
            }

            Spacer()
            CometesTextField(
                userCount.toString(),
                onValueChange = {
                    if (it.isEmpty()) userCount = 0
                    else {
                        val newValue = it.filter(Char::isDigit).toIntOrNull()
                        if (newValue != null) userCount = newValue
                    }
                },
                variant = CometesTextFieldPlainVariant,
                modifier = Modifier
                    .width(3.em)
                    .textAlign(TextAlign.Center)
                    .margin(leftRight = SpaceSmall)
            )
            Spacer()
        }

        VerticalSpacer(SpaceXLarge)

        CometesLoadingButton(
            isLoading = isLoading,
            onClick = { navigateToSubscriptionCheckout() },
            enabled = !isLoading && userCount > 0,
            modifier = Modifier.align(Alignment.End)
        ) {
            SpanText("Proceed to Checkout")
            HorizontalSpacer(SpaceSmall)
            FaCartShopping()
        }
    }
}