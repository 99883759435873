package cometes.style.system

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.UserSelect
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.RowScope
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.minWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.userSelect
import com.varabyte.kobweb.silk.components.style.ComponentVariant
import cometes.style.RadiusLarge
import cometes.style.SpaceMedium
import cometes.style.SpaceMediumSmall
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun CometesButton(
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    enabled: Boolean = true,
    variant: ComponentVariant? = SurfacePrimaryVariant,
    content: @Composable RowScope.() -> Unit
) {
    CometesSurface(
        variant = variant,
        onClick = { event ->
            event.preventDefault()
            event.stopPropagation()
            onClick()
        },
        enabled = enabled,
        modifier = Modifier
            .userSelect(UserSelect.None)
            .borderRadius(RadiusLarge)
            .padding(topBottom = SpaceMediumSmall, leftRight = SpaceMedium)
            .then(modifier),
    ) {
        Row(
            horizontalArrangement = Arrangement.Center,
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.fillMaxWidth()
        ) {
            content()
        }
    }
}

@Composable
fun CometesImageButton(
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    enabled: Boolean = true,
    variant: ComponentVariant? = null,
    content: @Composable () -> Unit
) {
    CometesSurface(
        modifier = Modifier
            .padding(ImageButtonPadding)
            .borderRadius(50.percent)
            .then(modifier),
        variant = variant,
        onClick = { onClick() },
        enabled = enabled
    ) {
        Box(
            contentAlignment = Alignment.Center,
            modifier = Modifier.minWidth(18.px).minHeight(18.px)
        ) { content() }
    }
}

private val ImageButtonPadding = 10.px