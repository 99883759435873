package app.cometes.shared.frontend.feature.person.data

import androidx.compose.runtime.*
import app.cometes.shared.feature.person.infrastructure.model.PersonDto
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.error.NoUserLoggedInPersonError
import app.cometes.shared.frontend.base.map
import app.cometes.shared.frontend.base.resource.Resource
import app.cometes.shared.frontend.base.resource.rememberUpdatedResourceState
import app.cometes.shared.frontend.base.resource.wrapEmptyResource
import app.cometes.shared.frontend.feature.person.data.source.PersonLocalSource
import app.cometes.shared.frontend.feature.person.data.source.PersonRemoteSource
import app.cometes.shared.frontend.feature.person.domain.model.Person
import app.cometes.shared.frontend.feature.person.infrastructure.model.toDomain
import app.cometes.shared.frontend.util.withUseCaseContext
import org.koin.compose.koinInject

@Composable
fun currentPerson(): Person? {
    val personLocalSource = koinInject<PersonLocalSource>()
    val localDto by personLocalSource.personFlow.collectAsState()

    return localDto?.toDomain()
}

@Composable
fun currentPersonAsState(): State<Person?> {
    val personLocalSource = koinInject<PersonLocalSource>()
    val personState = remember { mutableStateOf(personLocalSource.personFlow.value?.toDomain()) }

    LaunchedEffect(Unit) {
        personLocalSource.personFlow.collect { person ->
            personState.value = person?.toDomain()
        }
    }

    return personState
}

@Composable
internal fun currentPersonResource(ignoreUserAuthError: Boolean = false): Resource<Person> {
    val personRemoteSource = koinInject<PersonRemoteSource>()
    val personLocalSource = koinInject<PersonLocalSource>()

    val localDto by personLocalSource.personFlow.collectAsState()
    val resourceState = rememberUpdatedResourceState(localDto?.toDomain()) {
        val res = withUseCaseContext(ignoreUserAuthError = ignoreUserAuthError) {
            personRemoteSource.getCurrentPerson()
        }
        if (res is Result.Success) personLocalSource.person = res.data
        res.map(PersonDto::toDomain)
    }

    LaunchedEffect(Unit) {
        if (localDto == null) resourceState.resource.reload()
    }

    return wrapEmptyResource { resourceState.resource }
}

fun <T> noCurrentPersonResourceError() =
    Resource.Error<T>(error = NoUserLoggedInPersonError(), data = null, onReload = {})