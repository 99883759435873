package app.cometes.shared.frontend.feature.conferenceRoom.domain

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.rememberUpdatedState
import app.cometes.shared.feature.room.infrastructure.model.ConferenceRoomDto
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.insertOrUpdate
import app.cometes.shared.frontend.base.map
import app.cometes.shared.frontend.base.mapToEmpty
import app.cometes.shared.frontend.base.resource.EmptyMutation
import app.cometes.shared.frontend.base.resource.Resource
import app.cometes.shared.frontend.base.resource.alsoOnSuccess
import app.cometes.shared.frontend.base.resource.rememberMutation
import app.cometes.shared.frontend.base.resource.rememberUpdatedResourceState
import app.cometes.shared.frontend.base.resource.resourceListCache
import app.cometes.shared.frontend.base.resource.wrapEmptyResource
import app.cometes.shared.frontend.feature.conferenceRoom.domain.entity.ConferenceRoom
import app.cometes.shared.frontend.feature.conferenceRoom.domain.entity.toDomain
import app.cometes.shared.frontend.feature.conferenceRoom.source.ConferenceRoomRemoteSource
import app.cometes.shared.frontend.feature.organization.data.currentOrganization
import app.cometes.shared.frontend.feature.organization.data.currentOrganizationResource
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationError
import app.cometes.shared.frontend.util.withUseCaseContext
import org.koin.compose.koinInject

@Composable
internal fun conferenceRoomsCache(organizationId: Long) =
    resourceListCache<ConferenceRoom>("org${organizationId}:List<ConferenceRoom>")

@Composable
fun conferenceRoomResource(roomId: Long): Resource<ConferenceRoom> {
    val conferenceRoomRemoteSource = koinInject<ConferenceRoomRemoteSource>()

    val organization = when (val res = currentOrganizationResource()) {
        is Resource.Value -> res.data
        is Resource.Loading -> return Resource.Loading(null)
        is Resource.Error -> return Resource.Error(res.error, null, res.onReload)
    }

    val cache = conferenceRoomsCache(organization.id)
    val cachedRooms by cache.items.collectAsState()
    val localValue = cachedRooms?.firstOrNull { it.id == roomId }

    val roomParam by rememberUpdatedState(roomId)
    val state = rememberUpdatedResourceState(localValue) {
        withUseCaseContext { conferenceRoomRemoteSource.getRoom(organization.id, roomParam) }
            .map { room -> room.toDomain() }
            .alsoOnSuccess(cache::insertOrUpdate)
    }

    LaunchedEffect(roomId) {
        state.resource.reload()
    }

    return wrapEmptyResource { state.resource }
}

@Composable
fun conferenceRoomsResource(): Resource<List<ConferenceRoom>> {
    val conferenceRoomRemoteSource = koinInject<ConferenceRoomRemoteSource>()

    val organization = when (val res = currentOrganizationResource()) {
        is Resource.Value -> res.data
        is Resource.Loading -> return Resource.Loading(null)
        is Resource.Error -> return Resource.Error(res.error, null, res.onReload)
    }

    val cache = conferenceRoomsCache(organization.id)
    val localValue by cache.items.collectAsState()
    val state = rememberUpdatedResourceState(localValue) {
        withUseCaseContext { conferenceRoomRemoteSource.getRooms(organization.id, null) }
            .map { rooms -> rooms.map(ConferenceRoomDto::toDomain) }
            .alsoOnSuccess(cache::setCache)
    }

    return wrapEmptyResource { state.resource }
}

@Composable
fun createConferenceRoomMutation(
    locationId: Long,
    name: String,
    description: String,
    capacity: Int,
): EmptyMutation {
    val conferenceRoomRemoteSource = koinInject<ConferenceRoomRemoteSource>()
    val currentOrganization = currentOrganization()
    val conferenceRoomSource = conferenceRoomsCache(currentOrganization?.id ?: -1)

    return rememberMutation(currentOrganization?.id, locationId, name, description, capacity) {
        val organization = currentOrganization
            ?: return@rememberMutation Result.Error(OrganizationError.NoCurrentOrganization)

        val result = withUseCaseContext {
            conferenceRoomRemoteSource.createRoom(
                organizationId = organization.id,
                locationId = locationId,
                name = name,
                description = description,
                capacity = capacity
            )
        }

        result
            .map(ConferenceRoomDto::toDomain)
            .alsoOnSuccess { newRoom -> conferenceRoomSource.insertOrUpdate(newRoom) }
            .mapToEmpty()
    }
}

@Composable
fun removeConferenceRoomMutation(roomId: Long): EmptyMutation {
    val conferenceRoomRemoteSource = koinInject<ConferenceRoomRemoteSource>()
    val currentOrganization = currentOrganization()
    val conferenceRoomCache = conferenceRoomsCache(currentOrganization?.id ?: -1)

    return rememberMutation(currentOrganization, roomId) {
        val organization = currentOrganization
            ?: return@rememberMutation Result.Error(OrganizationError.NoCurrentOrganization)

        withUseCaseContext {
            conferenceRoomRemoteSource.deleteRoom(organization.id, roomId)
        }.alsoOnSuccess { conferenceRoomCache.remove(roomId) }
    }
}

@Composable
fun updateConferenceRoomMutation(
    originalRoom: ConferenceRoom,
    name: String,
    description: String,
    capacity: Int,
): EmptyMutation {
    val conferenceRoomRemoteSource = koinInject<ConferenceRoomRemoteSource>()
    val currentOrganization = currentOrganization()
    val conferenceRoomCache = conferenceRoomsCache(currentOrganization?.id ?: -1)

    return rememberMutation(originalRoom, name, description, capacity) {
        val organization = currentOrganization
            ?: return@rememberMutation Result.Error(OrganizationError.NoCurrentOrganization)

        val result = withUseCaseContext {
            conferenceRoomRemoteSource.updateRoom(
                organizationId = organization.id,
                roomId = originalRoom.id,
                name = name.takeIf { it != originalRoom.name },
                description = description.takeIf { it != originalRoom.description },
                capacity = capacity.takeIf { it != originalRoom.capacity }
            )
        }

        result
            .map(ConferenceRoomDto::toDomain)
            .alsoOnSuccess { updatedRoom -> conferenceRoomCache.insertOrUpdate(updatedRoom) }
            .mapToEmpty()
    }
}
