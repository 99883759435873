package app.cometes.shared.frontend.feature.conferenceRoom.domain

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import app.cometes.shared.feature.room.infrastructure.model.ConferenceRoomReservationDto
import app.cometes.shared.frontend.base.*
import app.cometes.shared.frontend.base.resource.*
import app.cometes.shared.frontend.feature.conferenceRoom.domain.entity.ConferenceRoomReservation
import app.cometes.shared.frontend.feature.conferenceRoom.domain.entity.toDomain
import app.cometes.shared.frontend.feature.conferenceRoom.source.ConferenceRoomReservationRemoteSource
import app.cometes.shared.frontend.feature.organization.data.currentOrganization
import app.cometes.shared.frontend.feature.organization.data.currentOrganizationResource
import app.cometes.shared.frontend.feature.organization.data.noCurrentOrganizationResourceError
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationError
import app.cometes.shared.frontend.feature.person.data.currentPerson
import app.cometes.shared.frontend.feature.person.data.noCurrentPersonResourceError
import app.cometes.shared.frontend.util.withUseCaseContext
import kotlinx.datetime.Instant
import org.koin.compose.koinInject

internal val conferenceRoomReservationsCache = ReactiveCache<Long, ConferenceRoomReservation>()

@Composable
fun conferenceRoomReservationResource(reservationId: Long): Resource<ConferenceRoomReservation> {
    val reservationRemoteSource = koinInject<ConferenceRoomReservationRemoteSource>()

    val organization = when (val res = currentOrganizationResource()) {
        is Resource.Value -> res.data
        is Resource.Loading -> return Resource.Loading(null)
        is Resource.Error -> return Resource.Error(res.error, null, res.onReload)
    }

    val localValue = conferenceRoomReservationsCache
        .collectAsState { it.id == reservationId }
        .value
        .firstOrNull()

    val state = rememberUpdatedResourceState(localValue) {
        val result = withUseCaseContext {
            reservationRemoteSource.getReservation(
                organizationId = organization.id,
                reservationId = reservationId
            )
        }

        result
            .map(ConferenceRoomReservationDto::toDomain)
            .alsoOnSuccess { reservation -> conferenceRoomReservationsCache.put(reservation.id, reservation) }
    }

    return wrapEmptyResource { state.resource }
}

@Composable
fun roomReservationsForPeriod(
    start: Instant,
    end: Instant,
    showOnlyUserReservations: Boolean = true
): Resource<List<ConferenceRoomReservation>> {
    val reservationRemoteSource = koinInject<ConferenceRoomReservationRemoteSource>()
    val organization = currentOrganization() ?: return noCurrentOrganizationResourceError()
    val person = currentPerson() ?: return noCurrentPersonResourceError()

    val localValue by conferenceRoomReservationsCache.collectAsState { reservation ->
        val timeInRange = reservation.startTime in reservation.reservationRange
                || reservation.endTime in reservation.reservationRange

        if (showOnlyUserReservations) {
            timeInRange && reservation.author.id == person.id
        } else {
            timeInRange
        }
    }

    val state = rememberUpdatedResourceState(localValue) {
        val result = withUseCaseContext {
            reservationRemoteSource.getReservations(
                organizationId = organization.id,
                personId = if (showOnlyUserReservations) person.id else null,
                locationId = null,
                afterInstant = start,
                beforeInstant = end,
                offset = 0,
                limit = Int.MAX_VALUE,
            )
        }

        result
            .map { it.map(ConferenceRoomReservationDto::toDomain) }
            .alsoOnSuccess { conferenceRoomReservationsCache.putAll(it.associateBy { it.id }) }
    }

    return state.resource
}

@Composable
fun createConferenceRoomReservationMutation(
    conferenceRoomId: Long,
    name: String,
    description: String,
    startTime: Instant,
    endTime: Instant
): EmptyMutation {
    val reservationRemoteSource = koinInject<ConferenceRoomReservationRemoteSource>()
    val organization = currentOrganization()

    return rememberMutation(conferenceRoomId, name, description, startTime, endTime) {
        val organizationId = organization?.id
            ?: return@rememberMutation Result.Error(OrganizationError.NoCurrentOrganization)

        val result = reservationRemoteSource.createReservation(
            organizationId = organizationId,
            conferenceRoomId = conferenceRoomId,
            name = name,
            description = description,
            startTime = startTime,
            endTime = endTime
        )

        result
            .alsoOnSuccess { reservation ->
                conferenceRoomReservationsCache.put(reservation.id, reservation.toDomain())
            }
            .mapToEmpty()
    }
}

@Composable
fun cancelConferenceRoomReservationMutation(reservationId: Long): EmptyMutation {
    val reservationRemoteSource = koinInject<ConferenceRoomReservationRemoteSource>()
    val organization = currentOrganization()

    return rememberMutation(reservationId) {
        val organizationId = organization?.id
            ?: return@rememberMutation Result.Error(OrganizationError.NoCurrentOrganization)

        reservationRemoteSource.deleteReservation(
            organizationId = organizationId,
            reservationId = reservationId
        ).alsoOnSuccess { conferenceRoomReservationsCache.invalidate(reservationId) }
    }
}
