package app.cometes.shared.frontend.base.resource

import androidx.compose.runtime.Immutable
import app.cometes.shared.frontend.base.ErrorResult

@Immutable
sealed interface Resource<T> {
    val data: T?

    fun reload(silent: Boolean = false)

    @Immutable
    data class Value<T>(
        override val data: T,
        val onReload: (silent: Boolean) -> Unit
    ) : Resource<T> {
        override fun reload(silent: Boolean) = onReload(silent)
    }

    @Immutable
    data class Loading<T>(override val data: T? = null) : Resource<T> {
        override fun reload(silent: Boolean) {} // already loading
    }

    @Immutable
    data class Error<T>(
        val error: ErrorResult,
        override val data: T? = null,
        val onReload: (silent: Boolean) -> Unit,
    ) : Resource<T> {
        override fun reload(silent: Boolean) = onReload(silent)
    }
}

val Resource<*>.isLoading get() = this is Resource.Loading
val Resource<*>.errorOrNull get() = (this as? Resource.Error)?.error

inline fun <T, R> Resource<T>.map(transform: (T) -> R): Resource<R> =
    when (this) {
        is Resource.Value -> Resource.Value(transform(data), onReload)
        is Resource.Loading -> Resource.Loading(data?.let(transform))
        is Resource.Error -> Resource.Error(error, data?.let(transform), onReload)
    }
