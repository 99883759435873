package cometes.components.feature.auth

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import app.cometes.shared.frontend.feature.organization.domain.model.Organization
import app.cometes.shared.frontend.feature.organization.presentation.OrganizationSelectionEvent
import app.cometes.shared.frontend.feature.organization.presentation.OrganizationSelectionIntent
import app.cometes.shared.frontend.feature.organization.presentation.OrganizationSelectionPresenter
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.minWidth
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.icons.fa.FaBuildingCircleExclamation
import com.varabyte.kobweb.silk.components.icons.fa.FaCirclePlus
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.layout.Divider
import com.varabyte.kobweb.silk.components.text.DivText
import com.varabyte.kobweb.silk.components.text.SpanText
import cometes.components.common.HorizontalSpacer
import cometes.components.common.ProgressIndicator
import cometes.components.common.RoundedImage
import cometes.components.common.VerticalSpacer
import cometes.components.feature.organization.NewOrganizationDialog
import cometes.components.layout.AuthPageLayout
import cometes.style.CometesTheme
import cometes.style.RadiusMedium
import cometes.style.RadiusXLarge
import cometes.style.SpaceLarge
import cometes.style.SpaceMedium
import cometes.style.SpaceMediumLarge
import cometes.style.SpaceMediumSmall
import cometes.style.SpaceSmall
import cometes.style.SpaceXSmall
import cometes.style.SpaceXXLarge
import cometes.style.TypographyBodyMedium
import cometes.style.TypographyHeadlineMedium
import cometes.style.TypographyTitleLarge
import cometes.style.TypographyTitleMedium
import cometes.style.system.CometesButton
import cometes.style.system.CometesSurface
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.percent

@Composable
fun OrganizationSelectionContent() {
    val presenter = remember {
        OrganizationSelectionPresenter { event ->
            when (event) {
                is OrganizationSelectionEvent.Error -> {} // TODO
            }
        }
    }
    val state = presenter.presenterState()

    var isNewOrgDialogVisible by remember { mutableStateOf(false) }

    fun onOrganizationClick(organization: Organization) {
        state.onIntent(OrganizationSelectionIntent.SelectOrganization(organization))
    }

    fun logOut() {
        state.onIntent(OrganizationSelectionIntent.LogOut)
    }

    AuthPageLayout(
        title = "Your organizations",
        onLogoutClick = { logOut() }
    ) {
        Box(Modifier.fillMaxSize()) {
            Column(
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.Center,
                modifier = Modifier
                    .fillMaxHeight()
                    .minWidth(30.percent)
                    .margin(leftRight = SpaceLarge)
                    .align(Alignment.Center)
            ) {
                Column(
                    horizontalAlignment = Alignment.CenterHorizontally,
                    modifier = Modifier
                        .fillMaxWidth()
                        .backgroundColor(CometesTheme.color.surface)
                        .color(CometesTheme.color.onSurface)
                        .padding(leftRight = SpaceXXLarge, topBottom = SpaceLarge)
                        .borderRadius(topLeft = RadiusXLarge, topRight = RadiusXLarge)
                ) {

                    if (state.isLoading) ProgressIndicator()

                    state.organizations.forEachIndexed { index, organization ->
                        OrganizationItem(
                            organization = organization,
                            onClick = { onOrganizationClick(organization) },
                            modifier = Modifier.fillMaxWidth()
                        )
                        if (index != state.organizations.lastIndex) {
                            VerticalSpacer(SpaceXSmall)
                            Divider(Modifier.opacity(0.5))
                            VerticalSpacer(SpaceXSmall)
                        }
                    }

                    if (state.organizations.isEmpty() && !state.isLoading) {

                        FaBuildingCircleExclamation(size = IconSize.X4)

                        VerticalSpacer(SpaceMedium)

                        DivText(
                            text = "No organizations available",
                            modifier = Modifier.then(TypographyHeadlineMedium)
                        )
                    }
                }

                VerticalSpacer(SpaceMediumSmall)

                CometesButton(
                    onClick = { isNewOrgDialogVisible = true },
                    modifier = Modifier
                        .padding(topBottom = SpaceMediumLarge)
                        .borderRadius(bottomLeft = RadiusXLarge, bottomRight = RadiusXLarge)
                        .fillMaxWidth()
                ) {
                    FaCirclePlus(size = IconSize.XL)
                    HorizontalSpacer(SpaceSmall)
                    SpanText(
                        "Create new organization",
                        modifier = Modifier.then(TypographyTitleLarge)
                    )
                }
            }
        }
    }

    if (isNewOrgDialogVisible) {
        NewOrganizationDialog(onDismiss = { isNewOrgDialogVisible = false })
    }
}

@Composable
private fun OrganizationItem(
    organization: Organization,
    onClick: () -> Unit,
    modifier: Modifier = Modifier
) {
    val imageUrl = organization.imageUrl
    CometesSurface(
        onClick = { onClick() },
        modifier = modifier.borderRadius(RadiusMedium),
    ) {
        Row(
            Modifier
                .fillMaxWidth()
                .padding(SpaceMedium)
        ) {
            RoundedImage(imageUrl)

            HorizontalSpacer(SpaceMedium)

            Column(Modifier.width(auto)) {
                DivText(organization.name, TypographyTitleMedium)
                DivText(
                    organization.description,
                    Modifier
                        .then(TypographyBodyMedium)
                        .opacity(0.75)
                )
            }
        }
    }
}