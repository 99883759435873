package app.cometes.shared.frontend.feature.home.infrastructure

import app.cometes.shared.frontend.base.ReactiveCache
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.feature.conferenceRoom.domain.conferenceRoomReservationsCache
import app.cometes.shared.frontend.feature.conferenceRoom.domain.entity.ConferenceRoomReservation
import app.cometes.shared.frontend.feature.home.data.HomeOverview
import app.cometes.shared.frontend.feature.home.data.HomeRemoteSource
import app.cometes.shared.frontend.feature.home.data.toDomain
import app.cometes.shared.frontend.feature.reservation.data.source.deskReservationCache
import app.cometes.shared.frontend.feature.reservation.domain.model.Reservation
import kotlinx.datetime.LocalDate
import org.mobilenativefoundation.store.store5.Fetcher
import org.mobilenativefoundation.store.store5.FetcherResult
import org.mobilenativefoundation.store.store5.Store
import org.mobilenativefoundation.store.store5.StoreBuilder

internal typealias HomeOverviewStore = Store<HomeOverviewKey, HomeOverview>

data class HomeOverviewKey(val organisationId: Long, val date: LocalDate, val personId: Long)

internal fun createHomeOverviewStore(remote: HomeRemoteSource): HomeOverviewStore {
    val fetcher = Fetcher.ofResult<HomeOverviewKey, HomeOverview> { key ->
        when (val res = remote.getOverview(key.organisationId, key.date)) {
            is Result.Success -> {
                val data = res.data.toDomain()
                homeOverviewCache.put(key, data)
                deskReservationCache.putAll(data.deskReservations.associateBy(Reservation::id))
                conferenceRoomReservationsCache.putAll(
                    data.personsConferenceRoomReservations.associateBy(ConferenceRoomReservation::id)
                )
                FetcherResult.Data(data)
            }
            is Result.Error -> FetcherResult.Error.Custom(res.error)
        }
    }

    return StoreBuilder.from(fetcher).build()
}

internal fun HomeOverviewStore.getCached(key: HomeOverviewKey): HomeOverview? = homeOverviewCache.get(key)

private val homeOverviewCache = ReactiveCache<HomeOverviewKey, HomeOverview>()