package app.cometes.shared.feature.person.infrastructure.model

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class BillingPortalSessionDto(
    val link: String
)

@Serializable
data class SubscriptionStatusDto(
    val organizationId: Long,
    val subscriptionStatus: Status,
    val quantity: Int,
    val renewAt: Instant?,
    val wilCancelAtPeriodEnd: Boolean,
    val isTrial: Boolean
) {
    companion object {
        fun empty(organizationId: Long) = SubscriptionStatusDto(
            organizationId = organizationId,
            subscriptionStatus = Status.Inactive,
            quantity = 0,
            renewAt = null,
            wilCancelAtPeriodEnd = false,
            isTrial = false
        )
    }

    // Stripe status values: incomplete, incomplete_expired, trialing, active, past_due, canceled, or unpaid.
    enum class Status { Active, Inactive }
}

@Serializable
data class CreateSubscriptionBody(
    val organizationId: Long,
    val userQuantity: Int
)