package app.cometes.shared.frontend.feature.auth.presentation

import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.runtime.Stable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import app.cometes.shared.frontend.base.ErrorResult
import app.cometes.shared.frontend.base.error.AuthError
import app.cometes.shared.frontend.base.resource.alsoOnError
import app.cometes.shared.frontend.feature.auth.data.AuthSsoType
import app.cometes.shared.frontend.feature.auth.data.signInEmailPasswordMutation
import app.cometes.shared.frontend.feature.auth.data.signInSsoMutation
import app.cometes.shared.frontend.feature.auth.presentation.LoginEvent.Error
import co.touchlab.kermit.Logger
import kotlinx.coroutines.launch

@Immutable
data class LoginState(
    val email: String,
    val password: String,
    val isEmailPasswordSignIn: Boolean,
    val isLoading: Boolean,

    val onIntent: (LoginIntent) -> Unit
)

sealed interface LoginIntent {
    data object SwitchLoginFlow : LoginIntent
    class SingInUsingSso(val type: AuthSsoType) : LoginIntent
    class SetEmail(val email: String) : LoginIntent
    class SetPassword(val password: String) : LoginIntent
    data object SingInUsingEmailAndPassword : LoginIntent
}

sealed interface LoginEvent {
    class Error(val errorResult: ErrorResult) : LoginEvent
}

@Stable
class LoginPresenter(private val onEvent: (LoginEvent) -> Unit) {

    @Composable
    fun presenterState(): LoginState {
        val scope = rememberCoroutineScope()
        var isEmailPasswordSignIn by remember { mutableStateOf(false) }
        var email by remember { mutableStateOf("") }
        var password by remember { mutableStateOf("") }
        var isLoading by remember { mutableStateOf(false) }

        val signInEmailPassword = signInEmailPasswordMutation(email = email, password = password)
        val signInSso = signInSsoMutation()

        return LoginState(
            email = email,
            password = password,
            isLoading = isLoading,
            isEmailPasswordSignIn = isEmailPasswordSignIn,
        ) { intent ->
            when (intent) {
                LoginIntent.SwitchLoginFlow -> isEmailPasswordSignIn = !isEmailPasswordSignIn

                is LoginIntent.SingInUsingSso -> {
                    isLoading = true
                    scope.launch {
                        signInSso.execute(intent.type)
                            .alsoOnError { error ->
                                if (error == AuthError.UserCancelledAuthFlow) return@alsoOnError // ignore
                                Logger.e("LoginPresenter") { error.message ?: "Unknown error" }
                                onEvent(Error(error))
                            }
                        isLoading = false
                    }
                }

                is LoginIntent.SetEmail -> email = intent.email
                is LoginIntent.SetPassword -> password = intent.password

                LoginIntent.SingInUsingEmailAndPassword -> {
                    isLoading = true
                    scope.launch {
                        signInEmailPassword
                            .execute()
                            .alsoOnError { error -> onEvent(Error(error)) }
                        isLoading = false
                    }
                }
            }
        }
    }
}