package app.cometes.shared.feature.organization.infrastructure.model

import app.cometes.shared.feature.reservation.infrastructure.model.DeskDto
import app.cometes.shared.feature.reservation.infrastructure.model.LocationDto
import kotlinx.serialization.Serializable

@Serializable
data class OrganizationDto(
    val id: Long,
    val name: String,
    val description: String,
    val imageUrl: String?,
)

@Serializable
data class OrganizationDetailDto(
    val organization: OrganizationDto,
    val members: List<OrganizationMemberPersonDto>,
    val desks: List<DeskDto>,
    val locations: List<LocationDto>,
    val invitations: List<OrganizationInvitationDto>
)