package app.cometes.shared.frontend.feature.home

import app.cometes.shared.frontend.feature.home.data.HomeRemoteSource
import app.cometes.shared.frontend.feature.home.infrastructure.HomeRemoteSourceImpl
import app.cometes.shared.frontend.feature.home.infrastructure.createHomeOverviewStore
import org.koin.core.module.dsl.singleOf
import org.koin.dsl.bind
import org.koin.dsl.module

val featureHome = module {
    singleOf(::HomeRemoteSourceImpl) bind HomeRemoteSource::class
    singleOf(::createHomeOverviewStore)
}