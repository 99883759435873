package app.cometes.shared.frontend.feature.organization.domain.model

import androidx.compose.runtime.Immutable
import app.cometes.shared.frontend.base.Identifiable
import app.cometes.shared.frontend.feature.person.domain.model.Person
import kotlinx.datetime.Instant

enum class OrganizationMemberRole {
    User, Admin, Owner, Unknown;

    val isAdmin
        get() = when (this) {
            User -> false
            Admin, Owner -> true
            Unknown -> false
        }
}

enum class OrganizationMemberStatus { Active, Suspended, Unknown }

@Immutable
data class OrganizationMember(
    val organizationId: Long,
    val personId: Long,
    val role: OrganizationMemberRole,
    val joinedAt: Instant,
    val status: OrganizationMemberStatus,
)

@Immutable
data class OrganizationMemberPerson(
    val organizationId: Long,
    val person: Person,
    val role: OrganizationMemberRole,
    val joinedAt: Instant,
    val status: OrganizationMemberStatus,
) : Identifiable {
    override val id: Long = person.id
}