package app.cometes.shared.frontend.feature.conferenceRoom

import app.cometes.shared.frontend.feature.conferenceRoom.source.ConferenceRoomRemoteSource
import app.cometes.shared.frontend.feature.conferenceRoom.source.ConferenceRoomRemoteSourceImpl
import app.cometes.shared.frontend.feature.conferenceRoom.source.ConferenceRoomReservationRemoteSource
import app.cometes.shared.frontend.feature.conferenceRoom.source.ConferenceRoomReservationRemoteSourceImpl
import org.koin.core.module.dsl.singleOf
import org.koin.dsl.bind
import org.koin.dsl.module

val featureConferenceRoom = module {
    singleOf(::ConferenceRoomRemoteSourceImpl) bind ConferenceRoomRemoteSource::class
    singleOf(::ConferenceRoomReservationRemoteSourceImpl) bind ConferenceRoomReservationRemoteSource::class
}