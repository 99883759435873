package app.cometes.shared.frontend.feature.person.infrastructure.model

import app.cometes.shared.feature.person.infrastructure.model.PersonDto
import app.cometes.shared.frontend.feature.person.domain.model.Person

fun PersonDto.toDomain() = Person(
    id = id,
    firstName = firstName,
    lastName = lastName,
    email = email
)