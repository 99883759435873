package app.cometes.shared.feature.organization.infrastructure.model

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class OrganizationInvitationDto(
    val invitationId: Long,
    val organizationId: Long,
    val inviterPersonId: Long?,
    val inviteeEmail: String,
    val invitationLink: String,
    val invitationStatus: Status,
    val invitationCreatedAt: Instant,
    val invitationExpiresAt: Instant,
) {
    @Serializable
    enum class Status { Pending, Accepted, Expired }
}