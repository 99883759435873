package cometes.pages.organization

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import app.cometes.shared.frontend.base.resource.Resource
import app.cometes.shared.frontend.base.resource.loadResourceIfEmpty
import app.cometes.shared.frontend.feature.organization.data.currentOrganizationMembersResource
import app.cometes.shared.frontend.feature.organization.data.currentOrganizationMembership
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationMemberPerson
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationMemberRole
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationMemberStatus
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.icons.mdi.MdiEdit
import cometes.components.Authenticated
import cometes.components.common.CometesLabel
import cometes.components.common.DivText
import cometes.components.common.HorizontalSpacer
import cometes.components.common.ProgressIndicator
import cometes.components.common.ThemeToggleButton
import cometes.components.feature.organization.EditMemberDialog
import cometes.components.feature.organization.component.ResourceList
import cometes.components.image.UserImage
import cometes.components.layout.MainPageLayout
import cometes.style.SpaceMedium
import cometes.style.SpaceMediumSmall
import cometes.style.SpaceSmall
import cometes.style.TypographyBodyMedium
import cometes.style.TypographyBodySmall
import cometes.style.system.CometesImageButton
import cometes.style.system.SurfaceErrorVariant
import cometes.style.system.SurfacePrimaryVariant
import cometes.style.system.SurfaceSecondaryVariant
import cometes.util.localized

@Page
@Composable
fun MembersPage() {
    Authenticated {
        val userMembership = currentOrganizationMembership() ?: return@Authenticated
        val membersResource = loadResourceIfEmpty { currentOrganizationMembersResource() }
        var editedMember by remember { mutableStateOf<OrganizationMemberPerson?>(null) }

        MainPageLayout(
            title = "Members",
            utilRow = { ThemeToggleButton() },
            modifier = Modifier.fillMaxSize()
        ) {
            when (membersResource) {
                is Resource.Value -> {
                    ResourceList(
                        items = membersResource.data,
                        modifier = Modifier.fillMaxWidth(),
                    ) { member ->
                        MemberItem(
                            member = member,
                            isCurrentUserAdmin = userMembership.isAdmin,
                            onEditClick = { editedMember = member },
                            modifier = Modifier.fillMaxSize()
                        )
                    }
                }

                is Resource.Loading -> ProgressIndicator(Modifier.align(Alignment.Center))
                is Resource.Error -> DivText("Error :(")
            }
        }

        if (editedMember != null) {
            EditMemberDialog(
                member = editedMember!!,
                isCurrentUserAdmin = userMembership.isAdmin,
                onDismiss = { editedMember = null }
            )
        }
    }
}

@Composable
private fun MemberItem(
    member: OrganizationMemberPerson,
    isCurrentUserAdmin: Boolean,
    onEditClick: () -> Unit,
    modifier: Modifier = Modifier
) {
    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = modifier
            .opacity(if (member.status == OrganizationMemberStatus.Active) 1f else 0.4f)
    ) {
        UserImage(member.person)

        HorizontalSpacer(SpaceMediumSmall)

        Column {
            DivText(member.person.fullName, TypographyBodyMedium)
            DivText(member.person.email, TypographyBodySmall.opacity(0.6))
        }

        Spacer()
        HorizontalSpacer(SpaceMedium)

        Column(
            verticalArrangement = Arrangement.spacedBy(SpaceSmall),
            horizontalAlignment = Alignment.End,
            modifier = Modifier.align(Alignment.Top)
        ) {
            if (member.role.isAdmin) {
                CometesLabel(
                    text = member.role.localized(),
                    variant = if (member.role == OrganizationMemberRole.Owner) {
                        SurfacePrimaryVariant
                    } else {
                        SurfaceSecondaryVariant
                    },
                )
            }

            if (member.status == OrganizationMemberStatus.Suspended) {
                CometesLabel(
                    text = "Suspended",
                    variant = SurfaceErrorVariant
                )
            }

            if (isCurrentUserAdmin) {
                CometesImageButton(
                    onClick = { onEditClick() },
                    modifier = Modifier.align(Alignment.End)
                ) { MdiEdit() }
            }
        }
    }
}
