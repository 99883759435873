package cometes.components.feature.organization.component

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import app.cometes.shared.frontend.feature.organization.data.setOrganizationImageMutation
import app.cometes.shared.frontend.network.UploadFile
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.mdi.IconStyle
import com.varabyte.kobweb.silk.components.icons.mdi.MdiBusiness
import com.varabyte.kobweb.silk.components.icons.mdi.MdiUpload
import com.varabyte.kobweb.silk.style.ComponentKind
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.selectors.hover
import com.varabyte.kobweb.silk.style.toModifier
import cometes.components.common.ProgressIndicator
import cometes.components.common.RoundedImage
import cometes.components.common.requiredSize
import cometes.style.RadiusPill
import cometes.style.cometesColor
import cometes.style.system.CometesSurface
import cometes.style.system.SurfaceBackgroundColorVariant
import cometes.style.system.animation.CometesTransitionOf
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.accept
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Label

interface ImageKind : ComponentKind

val ImageUploadStyle = CssStyle<ImageKind> {
    val backgroundColor = cometesColor.surface
    base {
        Modifier
            .backgroundColor(backgroundColor)
            .transition(CometesTransitionOf("opacity"))
            .opacity(0f)
    }
    hover {
        Modifier
            .opacity(0.75f)
            .cursor(Cursor.Pointer)
    }
}

@Composable
fun ImageUploadInput(
    onInput: (UploadFile) -> Unit,
    modifier: Modifier = Modifier,
    accept: String = "image/*",
    content: @Composable () -> Unit,
) {
    Input(type = InputType.File) {
        id("upload")
        hidden()
        accept(accept)
        onInput {
            val files = it.target.files
            if (files != null && files.length == 1) {
                val file = files.item(0)!!
                onInput(UploadFile(file))
            }
        }
    }

    Label(
        forId = "upload",
        attrs = modifier.toAttrs()
    ) {
        Box(contentAlignment = Alignment.Center) {
            content()
            Box(
                contentAlignment = Alignment.Center,
                modifier = ImageUploadStyle.toModifier().fillMaxSize()
            ) {
                MdiUpload(
                    modifier = Modifier
                        .fontSize(28.px)
                        .align(Alignment.Center),
                    style = IconStyle.ROUNDED
                )
            }
        }
    }
}

@Composable
fun OrganizationImageWithUpload(
    imageUrl: String?,
    modifier: Modifier = Modifier
) {
    val coroutineScope = rememberCoroutineScope()
    val uploadImage = setOrganizationImageMutation()
    var isLoading by remember { mutableStateOf(false) }

    fun uploadImage(file: UploadFile) {
        coroutineScope.launch {
            try {
                isLoading = true
                uploadImage.execute(file)
            } finally {
                isLoading = false
            }
        }
    }

    ImageUploadInput(
        onInput = { file -> uploadImage(file) },
        modifier = modifier
    ) {
        val size = 48.px
        if (isLoading) {
            Box(
                contentAlignment = Alignment.Center,
                modifier = Modifier.size(size)
            ) {
                ProgressIndicator()
            }
        } else {
            if (imageUrl != null) {
                RoundedImage(
                    imageUrl = imageUrl,
                    modifier = Modifier
                        .requiredSize(size)
                )
            } else {
                CometesSurface(
                    variant = SurfaceBackgroundColorVariant,
                    modifier = Modifier
                        .borderRadius(RadiusPill)
                        .requiredSize(size)
                ) {
                    MdiBusiness(
                        modifier = Modifier.align(Alignment.Center),
                        style = IconStyle.ROUNDED
                    )
                }
            }
        }
    }
}