package app.cometes.shared.feature.reservation.infrastructure.model

import kotlinx.serialization.Serializable

@Serializable
data class DeskDto(
    val id: Long,
    val name: String,
    val location: LocationDto
)

@Serializable
data class NewDeskBody(
    val locationId: Long,
    val deskName: String
)

@Serializable
data class UpdateDeskBody(
    val deskName: String? = null,
    val locationId: Long? = null
)