package cometes.components.feature.auth

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import app.cometes.shared.frontend.feature.auth.data.AuthSsoType
import app.cometes.shared.frontend.feature.auth.presentation.LoginEvent
import app.cometes.shared.frontend.feature.auth.presentation.LoginIntent
import app.cometes.shared.frontend.feature.auth.presentation.LoginPresenter
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.toModifier
import cometes.components.common.AnimatedVisibility
import cometes.components.common.CometesDivider
import cometes.components.common.CometesGradientStyle
import cometes.components.common.DivText
import cometes.components.common.ProgressIndicator
import cometes.components.common.ThemeToggleButton
import cometes.components.common.VerticalSpacer
import cometes.style.SpaceLarge
import cometes.style.SpaceMedium
import cometes.style.SpaceSmall
import cometes.style.SpaceXSmall
import cometes.style.TypographyBodyLarge
import cometes.style.TypographyBodySmall
import cometes.style.TypographyTitleLarge
import cometes.style.TypographyTitleMedium
import cometes.style.system.ButtonPlainVariant
import cometes.style.system.CometesButton
import cometes.style.system.input.CometesTextField
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.px

@Composable
fun LoginContent() {
    val presenter = remember {
        LoginPresenter { event ->
            when (event) {
                is LoginEvent.Error -> {} // TODO
            }
        }
    }
    val state = presenter.presenterState()

    fun login() {
        if (state.email.isBlank()) return
        if (state.password.isBlank()) return
        state.onIntent(LoginIntent.SingInUsingEmailAndPassword)
    }

    Box(Modifier.fillMaxSize().then(CometesGradientStyle.toModifier())) {
        ThemeToggleButton(Modifier.align(Alignment.TopEnd).margin(SpaceMedium))

        LoginLayout(
            Modifier
                .align(Alignment.Center)
                .fillMaxWidth()
                .padding(leftRight = SpaceMedium)
        ) {
            DivText(
                text = "Welcome back",
                modifier = Modifier.then(TypographyTitleLarge).align(Alignment.Start)
            )
            VerticalSpacer(SpaceXSmall)
            DivText(
                text = "Sign in to your account to continue",
                modifier = Modifier.then(TypographyBodySmall).align(Alignment.Start)
            )

            VerticalSpacer(SpaceLarge)

            CometesTextField(
                value = state.email,
                onValueChange = { state.onIntent(LoginIntent.SetEmail(it)) },
                type = InputType.Email,
                label = "Email",
                placeholder = "email@example.com",
                modifier = Modifier
                    .then(TypographyTitleMedium)
                    .fillMaxWidth()
            )

            VerticalSpacer(SpaceMedium)

            CometesTextField(
                value = state.password,
                onValueChange = { state.onIntent(LoginIntent.SetPassword(it)) },
                type = InputType.Password,
                label = "Password",
                placeholder = "•••••",
                modifier = Modifier
                    .then(TypographyTitleMedium)
                    .fillMaxWidth()
            )

            VerticalSpacer(SpaceMedium)

            CometesButton(
                onClick = { login() },
                enabled = !state.isLoading,
                modifier = Modifier.fillMaxWidth()
            ) {
                Box {
                    SpanText(
                        text = "Sign in",
                        modifier = Modifier
                            .then(TypographyBodyLarge)
                            .thenIf(state.isLoading, Modifier.opacity(0.3))
                    )

                    AnimatedVisibility(state.isLoading, Modifier.align(Alignment.Center)) {
                        ProgressIndicator()
                    }
                }
            }

            VerticalSpacer(SpaceLarge)
            CometesDivider()
            VerticalSpacer(SpaceLarge)

            CometesButton(
                onClick = { state.onIntent(LoginIntent.SingInUsingSso(AuthSsoType.Google)) },
                enabled = !state.isLoading,
                variant = ButtonPlainVariant,
                modifier = Modifier.fillMaxWidth()
            ) {
                Row(horizontalArrangement = Arrangement.spacedBy(SpaceSmall)) {
                    GoogleLogo(Modifier.size(20.px))
                    SpanText(
                        text = "Sign in with Google",
                        modifier = Modifier
                            .then(TypographyBodyLarge)
                            .thenIf(state.isLoading, Modifier.opacity(0.3))
                    )
                }
            }

            VerticalSpacer(SpaceMedium)

            CometesButton(
                onClick = { state.onIntent(LoginIntent.SingInUsingSso(AuthSsoType.Apple)) },
                enabled = !state.isLoading,
                variant = ButtonPlainVariant,
                modifier = Modifier.fillMaxWidth()
            ) {
                Row(horizontalArrangement = Arrangement.spacedBy(SpaceSmall)) {
                    AppleLogo(Modifier.size(20.px))
                    SpanText(
                        text = "Sign in with Apple",
                        modifier = Modifier
                            .then(TypographyBodyLarge)
                            .thenIf(state.isLoading, Modifier.opacity(0.3))
                    )
                }
            }
        }
    }
}