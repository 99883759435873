package app.cometes.shared.frontend.feature.preferences.infrastructure.source

import app.cometes.shared.feature.person.infrastructure.model.PersonPreferencesDto
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.error.runCatchingNetworkExceptions
import app.cometes.shared.frontend.feature.preferences.data.source.PersonPreferencesRemoteSource
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.get
import io.ktor.client.request.patch
import io.ktor.client.request.setBody

class PersonPreferencesRemoteSourceImpl(
    private val client: HttpClient
) : PersonPreferencesRemoteSource {

    override suspend fun getCurrentPersonPreferences(organizationId: Long) =
        runCatchingNetworkExceptions<PersonPreferencesDto> {
            val res = client.get("/organization/${organizationId}/preferences")
            Result.Success(res.body())
        }

    override suspend fun savePersonPreferences(
        organizationId: Long,
        preferencesDto: PersonPreferencesDto
    ) = runCatchingNetworkExceptions<PersonPreferencesDto> {
        val res = client.patch("/organization/${organizationId}/preferences") {
            setBody(preferencesDto)
        }
        Result.Success(res.body())
    }
}