package app.cometes.shared.frontend.feature.auth.presentation

import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.runtime.Stable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import app.cash.molecule.RecompositionMode
import app.cash.molecule.moleculeFlow
import app.cometes.shared.frontend.base.ErrorResult
import app.cometes.shared.frontend.base.error.AuthError
import app.cometes.shared.frontend.base.error.AuthError.NoUserLoggedIn.Cause
import app.cometes.shared.frontend.base.resource.Resource
import app.cometes.shared.frontend.base.resource.alsoOnError
import app.cometes.shared.frontend.base.resource.isLoading
import app.cometes.shared.frontend.base.resource.loadResourceIfEmpty
import app.cometes.shared.frontend.feature.auth.data.logOutMutation
import app.cometes.shared.frontend.feature.person.data.currentPersonResource
import app.cometes.shared.frontend.feature.person.data.registerMutation
import com.rickclephas.kmp.nativecoroutines.NativeCoroutines
import kotlinx.coroutines.launch

@Immutable
enum class UserInfoPhase { InitialPersonLoad, InfoInput }

@Immutable
data class UserInfoState(
    val firstName: String,
    val lastName: String,
    val phase: UserInfoPhase,

    val registrationInProgress: Boolean,
    val isLoading: Boolean,
    val error: ErrorResult?,

    val onIntent: (UserInfoIntent) -> Unit
)

sealed interface UserInfoIntent {
    class SetFirstName(val firstName: String) : UserInfoIntent
    class SetLastName(val lastName: String) : UserInfoIntent
    object Register : UserInfoIntent
    object TryAgain : UserInfoIntent
    object LogOut : UserInfoIntent
}

sealed interface UserInfoEvent {
    class Error(val errorResult: ErrorResult) : UserInfoEvent
}

@Stable
class UserInfoPresenter(
    private val onEvent: (UserInfoEvent) -> Unit,
) {

    @Composable
    fun presenterState(): UserInfoState {
        val scope = rememberCoroutineScope()
        var firstNameInput: String by remember { mutableStateOf("") }
        var lastNameInput: String by remember { mutableStateOf("") }
        var registrationInProgress by remember { mutableStateOf(false) }

        val personResource = loadResourceIfEmpty { currentPersonResource(true) }

        val personError = (personResource as? Resource.Error)?.error
        val userDoesNotExist = personError is AuthError.NoUserLoggedIn &&
                personError.cause == Cause.PersonDoesNotExit

        val phase = if (userDoesNotExist)
            UserInfoPhase.InfoInput
        else UserInfoPhase.InitialPersonLoad

        val registerMutation = registerMutation(firstNameInput, lastNameInput)
        val logOutMutation = logOutMutation()

        return UserInfoState(
            firstName = firstNameInput,
            lastName = lastNameInput,
            isLoading = personResource.isLoading,
            registrationInProgress = registrationInProgress,
            phase = phase,
            error = when (phase) {
                UserInfoPhase.InitialPersonLoad -> personError
                UserInfoPhase.InfoInput -> null
            }
        ) { intent ->
            when (intent) {
                is UserInfoIntent.SetFirstName -> firstNameInput = intent.firstName
                is UserInfoIntent.SetLastName -> lastNameInput = intent.lastName
                UserInfoIntent.Register -> scope.launch {
                    registrationInProgress = true
                    registerMutation.execute()
                        .alsoOnError { error -> onEvent(UserInfoEvent.Error(error)) }
                    registrationInProgress = false
                }

                UserInfoIntent.LogOut -> scope.launch {
                    logOutMutation.execute()
                        .alsoOnError { error -> onEvent(UserInfoEvent.Error(error)) }
                }

                UserInfoIntent.TryAgain -> {
                    if (personResource is Resource.Error) personResource.reload()
                }
            }
        }
    }

    @NativeCoroutines
    val state = moleculeFlow(RecompositionMode.Immediate) { presenterState() }
    val emptyState = UserInfoState(
        "", "", UserInfoPhase.InitialPersonLoad, false, false, null, {}
    )
}