package app.cometes.shared.frontend.feature.billing.domain.model

import kotlinx.datetime.Instant

data class SubscriptionStatus(
    val subscriptionStatus: Status,
    val quantity: Int,
    val renewAt: Instant?,
    val wilCancelAtPeriodEnd: Boolean,
    val isInTrial: Boolean
) {
    enum class Status { Active, Inactive }
}
