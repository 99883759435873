package app.cometes.shared.frontend.feature.reservation.domain.model

import androidx.compose.runtime.Immutable
import app.cometes.shared.frontend.feature.person.domain.model.Person
import kotlinx.datetime.LocalDate

@Immutable
data class Reservation(
    val id: Long,
    val date: LocalDate,
    val desk: Desk,
    val person: Person,
    val origin: Origin?
) {
    enum class Origin { Manual, Schedule }
}