package app.cometes.shared.frontend.feature.organization.domain.model

import app.cometes.shared.frontend.base.ErrorResult
import app.cometes.shared.frontend.base.error.BackendException

sealed class OrganizationError(
    message: String?,
    exception: BackendException?
) : ErrorResult(
    message = message ?: exception?.error?.message,
    throwable = exception
) {
    object NoPendingInvitation : OrganizationError("No pending invitation", null)
    object NoCurrentOrganization : OrganizationError("No organization set", null)
    object InvalidCurrentMembership : OrganizationError("Current membership does not match current organization", null)

    class InvitationNotFound(
        backendException: BackendException
    ) : OrganizationError("Invitation could not be found", backendException)

    class InvitationAlreadyClaimed(
        backendException: BackendException
    ) : OrganizationError("Invitation has already been claimed", backendException)

    class InvitationExpired(
        backendException: BackendException
    ) : OrganizationError("Invitation expired", backendException)

    companion object {
        val invitationErrors = listOf(
            InvitationNotFound::class,
            InvitationAlreadyClaimed::class,
            InvitationExpired::class
        )
    }
}