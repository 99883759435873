package app.cometes.shared.frontend.feature.reservation.infrastructure.model

import app.cometes.shared.feature.reservation.infrastructure.model.ReservationDayDto
import app.cometes.shared.feature.reservation.infrastructure.model.ReservationDto
import app.cometes.shared.frontend.feature.reservation.domain.model.ReservationDay

internal fun ReservationDayDto.toDomain() = ReservationDay(
    date,
    reservations.map(ReservationDto::toDomain),
    maxReservations
)