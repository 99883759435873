package app.cometes.shared.frontend.feature.reservation.infrastructure.model

import app.cometes.shared.feature.reservation.infrastructure.model.ReservationDto
import app.cometes.shared.feature.reservation.infrastructure.model.ReservationOriginDto
import app.cometes.shared.frontend.feature.organization.infrastructure.model.toDomain
import app.cometes.shared.frontend.feature.person.infrastructure.model.toDomain
import app.cometes.shared.frontend.feature.reservation.domain.model.Desk
import app.cometes.shared.frontend.feature.reservation.domain.model.Reservation

internal fun ReservationDto.toDomain() = Reservation(
    id = id,
    date = date,
    desk = Desk(
        id = desk.id,
        name = desk.name,
        location = desk.location.toDomain()
    ),
    person = person.toDomain(),
    origin = originEnum?.toDomain()
)

fun ReservationOriginDto.toDomain() = when (this) {
    ReservationOriginDto.Manual -> Reservation.Origin.Manual
    ReservationOriginDto.Schedule -> Reservation.Origin.Schedule
}