package app.cometes.shared.frontend.feature.organization.data

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import app.cometes.shared.feature.organization.infrastructure.model.OrganizationMemberDto
import app.cometes.shared.feature.organization.infrastructure.model.OrganizationMemberPersonDto
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.insertOrUpdate
import app.cometes.shared.frontend.base.map
import app.cometes.shared.frontend.base.mapToEmpty
import app.cometes.shared.frontend.base.resource.EmptyMutation
import app.cometes.shared.frontend.base.resource.Resource
import app.cometes.shared.frontend.base.resource.ResourceState
import app.cometes.shared.frontend.base.resource.alsoOnSuccess
import app.cometes.shared.frontend.base.resource.rememberMutation
import app.cometes.shared.frontend.base.resource.rememberUpdatedResourceState
import app.cometes.shared.frontend.base.resource.resourceListCache
import app.cometes.shared.frontend.base.resource.wrapEmptyResource
import app.cometes.shared.frontend.feature.organization.data.source.OrganizationLocalSource
import app.cometes.shared.frontend.feature.organization.data.source.OrganizationRemoteSource
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationError
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationMember
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationMemberPerson
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationMemberRole
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationMemberStatus
import app.cometes.shared.frontend.feature.organization.infrastructure.model.toDomain
import app.cometes.shared.frontend.feature.organization.infrastructure.model.toDto
import app.cometes.shared.frontend.util.withUseCaseContext
import org.koin.compose.koinInject

// Person member

@Composable
fun currentOrganizationMembership(): OrganizationMember? {
    val organization = currentOrganization() ?: return null
    val organizationLocalSource = koinInject<OrganizationLocalSource>()
    val localDto by organizationLocalSource.currentOrganizationMembership.collectAsState()

    if (localDto?.organizationId != organization.id) {
        // make sure the membership is not for the wrong organization
        return null
    }

    return localDto?.toDomain()
}

@Composable
fun currentOrganizationMembershipResource(): Resource<OrganizationMember> {
    val organization = currentOrganization() ?: return noCurrentOrganizationResourceError()
    val organizationLocalSource = koinInject<OrganizationLocalSource>()
    val organizationRemoteSource = koinInject<OrganizationRemoteSource>()

    val localDto by organizationLocalSource.currentOrganizationMembership.collectAsState()
    if (localDto?.organizationId != organization.id) {
        // make sure the membership is not for the wrong organization
        return Resource.Error(error = OrganizationError.InvalidCurrentMembership, data = null, onReload = {})
    }

    val resourceState: ResourceState<OrganizationMember?> = rememberUpdatedResourceState(localDto?.toDomain()) {
        val res = withUseCaseContext {
            organizationRemoteSource.getCurrentPersonMembership(organization.id)
        }
        if (res is Result.Success) organizationLocalSource.setOrganizationMembership(res.data.toMemberDto())
        res.map { it.toMemberDto().toDomain() }
    }

    LaunchedEffect(Unit) {
        if (localDto == null) resourceState.resource.reload()
    }

    return wrapEmptyResource { resourceState.resource }
}

// Organization members

@Composable
internal fun currentOrganizationMembersCache(organizationId: Long) =
    resourceListCache<OrganizationMemberPerson>(
        "org${organizationId}:List<OrganizationMemberPerson>"
    )

@Composable
fun currentOrganizationMembersResource(): Resource<List<OrganizationMemberPerson>> {
    val organization = currentOrganization() ?: return noCurrentOrganizationResourceError()
    val organizationRemoteSource = koinInject<OrganizationRemoteSource>()
    val memberCache = currentOrganizationMembersCache(organization.id)

    val localMembers by memberCache.items.collectAsState()
    val resourceState = rememberUpdatedResourceState(localMembers) {
        withUseCaseContext { organizationRemoteSource.getOrganizationMembers(organization.id) }
            .map { dtos -> dtos.map(OrganizationMemberPersonDto::toDomain) }
            .alsoOnSuccess { memberCache.setCache(it) }
    }

    return wrapEmptyResource { resourceState.resource }
}

@Composable
fun removeOrganizationMemberMutation(member: OrganizationMemberPerson): EmptyMutation {
    val organizationRemoteSource = koinInject<OrganizationRemoteSource>()
    val memberCache = currentOrganizationMembersCache(member.organizationId)

    return rememberMutation(member) {
        withUseCaseContext {
            organizationRemoteSource.removeMember(member.organizationId, member.id)
        }.alsoOnSuccess { memberCache.remove(member.id) }
    }
}

@Composable
fun updateOrganizationMemberMutation(
    member: OrganizationMemberPerson,
    role: OrganizationMemberRole,
    status: OrganizationMemberStatus
): EmptyMutation {
    val organizationRemoteSource = koinInject<OrganizationRemoteSource>()
    val memberCache = currentOrganizationMembersCache(member.organizationId)

    return rememberMutation(member, role, status) {
        withUseCaseContext {
            organizationRemoteSource.updateMember(
                organizationId = member.organizationId,
                personId = member.id,
                role = role.toDto(),
                status = status.toDto(),
            )
        }
            .map(OrganizationMemberPersonDto::toDomain)
            .alsoOnSuccess { updatedMember -> memberCache.insertOrUpdate(member) }
            .mapToEmpty()
    }
}

private fun OrganizationMemberPersonDto.toMemberDto() = OrganizationMemberDto(
    organizationId = organizationId,
    personId = person.id,
    role = role,
    joinedAt = joinedAt,
    status = status,
)
