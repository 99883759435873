package cometes.style.system.animation

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.animation
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.silk.style.animation.Keyframes
import com.varabyte.kobweb.silk.style.animation.toAnimation
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.s

private val DefaultAnimationDuration = 0.3.s

val AnimationFadeIn = Keyframes {
    from { Modifier.opacity(0f) }
    to { Modifier.opacity(1f) }
}

@Composable
fun Modifier.fadeInTransition(duration: CSSSizeValue<CSSUnit.s> = DefaultAnimationDuration) =
    animation(AnimationFadeIn.toAnimation(duration = duration))