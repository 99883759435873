package app.cometes.shared.frontend.feature.organization.infrastructure.local

import app.cometes.shared.feature.organization.infrastructure.model.OrganizationDto
import app.cometes.shared.frontend.feature.organization.data.source.OrganizationLocalSource
import com.russhwolf.settings.Settings
import com.russhwolf.settings.get
import com.russhwolf.settings.set
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.serialization.json.Json

class OrganizationLocalSourceImpl(
    private val settings: Settings
) : OrganizationLocalSource {

    private companion object {
        const val CurrentOrganizationKey = "CurrentOrganization"
        const val InvitationPendingKey = "InvitationPending"
    }

    // JS storage is not observable :(
    private val organizationStateFlow = MutableStateFlow(retrieveCurrentOrganization())
    private val invitationStateFlow = MutableStateFlow(retrieveInvitationToken())

    // Organization
    override val currentOrganization: StateFlow<OrganizationDto?> =
        organizationStateFlow.asStateFlow()

    override fun setOrganization(organizationDto: OrganizationDto?) {
        settings[CurrentOrganizationKey] = if (organizationDto != null) {
            Json.encodeToString(OrganizationDto.serializer(), organizationDto)
        } else null
        organizationStateFlow.value = organizationDto
    }

    // Invitation
    override val pendingInvitationToken: StateFlow<String?> = invitationStateFlow.asStateFlow()

    override fun setPendingInvitationToken(token: String?) {
        settings[InvitationPendingKey] = token
        invitationStateFlow.value = token
    }

    override fun clear() {
        organizationStateFlow.value = null
        invitationStateFlow.value = null
    }

    private fun retrieveCurrentOrganization(): OrganizationDto? {
        val jsonString: String? = settings[CurrentOrganizationKey]
        return if (jsonString != null) {
            Json.decodeFromString(OrganizationDto.serializer(), jsonString)
        } else null
    }

    private fun retrieveInvitationToken(): String? = settings[InvitationPendingKey]
}