package app.cometes.shared.frontend.feature.organization.data

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import app.cometes.shared.feature.organization.infrastructure.model.OrganizationDto
import app.cometes.shared.feature.organization.infrastructure.model.OrganizationInvitationDto
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.insertOrUpdate
import app.cometes.shared.frontend.base.map
import app.cometes.shared.frontend.base.mapToEmpty
import app.cometes.shared.frontend.base.resource.EmptyMutation
import app.cometes.shared.frontend.base.resource.Mutation
import app.cometes.shared.frontend.base.resource.Resource
import app.cometes.shared.frontend.base.resource.alsoOnSuccess
import app.cometes.shared.frontend.base.resource.rememberMutation
import app.cometes.shared.frontend.base.resource.rememberResourceState
import app.cometes.shared.frontend.base.resource.rememberUpdatedResourceState
import app.cometes.shared.frontend.base.resource.resourceListCache
import app.cometes.shared.frontend.base.resource.wrapEmptyResource
import app.cometes.shared.frontend.feature.organization.data.source.OrganizationInvitationRemoteSource
import app.cometes.shared.frontend.feature.organization.domain.model.Organization
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationError
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationInvitation
import app.cometes.shared.frontend.feature.organization.infrastructure.model.toDomain
import app.cometes.shared.frontend.util.rememberKoinDependency
import app.cometes.shared.frontend.util.withUseCaseContext

// === Organization for invitation
@Composable
fun invitationOrganizationResource(invitationId: String): Resource<Organization> {
    val invitationRemoteSource = rememberKoinDependency<OrganizationInvitationRemoteSource>()

    val resourceState = rememberResourceState(null) {
        withUseCaseContext { invitationRemoteSource.getOrganizationForInvitation(invitationId) }
            .map(OrganizationDto::toDomain)
    }

    return wrapEmptyResource { resourceState.resource }
}

@Composable
fun acceptInvitationMutation(invitation: String): Mutation<Organization> {
    val invitationRemoteSource = rememberKoinDependency<OrganizationInvitationRemoteSource>()

    return rememberMutation(invitation) {
        withUseCaseContext { invitationRemoteSource.claimInvitation(invitation) }
            .map(OrganizationDto::toDomain)
    }
}

// === Invitation management

@Composable
internal fun currentOrganizationInvitationsCache(organizationId: Long) =
    resourceListCache<OrganizationInvitation>(
        key = "org${organizationId}:List<OrganizationInvitation>"
    )

@Composable
fun currentOrganizationInvitationsListResource(): Resource<List<OrganizationInvitation>> {
    val organization = currentOrganization() ?: return noCurrentOrganizationResourceError()
    val invitationRemoteSource = rememberKoinDependency<OrganizationInvitationRemoteSource>()
    val invitationListCache = currentOrganizationInvitationsCache(organization.id)

    val cachedInvitationList by invitationListCache.items.collectAsState()
    val resourceState = rememberUpdatedResourceState(cachedInvitationList) {
        invitationRemoteSource.getUnclaimedInvitations(organization.id)
            .map { invitations -> invitations.map(OrganizationInvitationDto::toDomain) }
            .alsoOnSuccess { invitationListCache.setCache(it) }
    }

    return wrapEmptyResource { resourceState.resource }
}

@Composable
fun createInvitationMutation(email: String): EmptyMutation {
    val invitationRemoteSource = rememberKoinDependency<OrganizationInvitationRemoteSource>()

    val organization = currentOrganization()
    val invitationListCache = currentOrganizationInvitationsCache(organization?.id ?: -1)

    return rememberMutation(email, organization) {
        val organizationId = organization?.id
            ?: return@rememberMutation Result.Error(OrganizationError.NoCurrentOrganization)

        withUseCaseContext {
            invitationRemoteSource.createInvitation(organizationId, email)
        }.map(OrganizationInvitationDto::toDomain)
            .alsoOnSuccess { newInvitation -> invitationListCache.insertOrUpdate(newInvitation) }
            .mapToEmpty()
    }
}

@Composable
fun removeInvitationMutation(invitation: OrganizationInvitation): EmptyMutation {
    val invitationRemoteSource = rememberKoinDependency<OrganizationInvitationRemoteSource>()
    val invitationListCache = currentOrganizationInvitationsCache(invitation.organizationId)

    return rememberMutation(invitation) {
        withUseCaseContext {
            withUseCaseContext {
                invitationRemoteSource.removeInvitation(invitation.organizationId, invitation.id)
            }.alsoOnSuccess { invitationListCache.remove(invitation.id) }
        }
    }
}