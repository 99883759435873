package cometes.components.common

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.objectFit
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.graphics.Image
import cometes.style.CometesTheme
import cometes.style.SpaceXSmall
import org.jetbrains.compose.web.css.CSSNumeric
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun RoundedImage(
    imageUrl: String?,
    modifier: Modifier = Modifier,
    size: CSSNumeric = 48.px,
) {
    val imgSizeModifier = Modifier
        .requiredSize(size)
        .objectFit(ObjectFit.Contain)
        .backgroundColor(CometesTheme.color.background)
        .padding(SpaceXSmall)
        .borderRadius(50.percent)
        .then(modifier)

    if (imageUrl != null) {
        Image(imageUrl, modifier = imgSizeModifier)
    } else {
        Box(imgSizeModifier)
    }
}