package app.cometes.shared.frontend.feature.organization.presentation

import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.runtime.Stable
import androidx.compose.runtime.rememberCoroutineScope
import app.cometes.shared.frontend.base.ErrorResult
import app.cometes.shared.frontend.base.error.NoUserLoggedInPersonError
import app.cometes.shared.frontend.base.resource.Resource
import app.cometes.shared.frontend.base.resource.loadResourceIfEmpty
import app.cometes.shared.frontend.feature.billing.data.subscriptionResource
import app.cometes.shared.frontend.feature.billing.domain.model.SubscriptionStatus
import app.cometes.shared.frontend.feature.desk.data.currentOrganizationDesksResource
import app.cometes.shared.frontend.feature.organization.data.clearCurrentOrganizationMutation
import app.cometes.shared.frontend.feature.organization.data.currentOrganization
import app.cometes.shared.frontend.feature.organization.data.currentOrganizationDetailResource
import app.cometes.shared.frontend.feature.organization.data.currentOrganizationInvitationsListResource
import app.cometes.shared.frontend.feature.organization.data.currentOrganizationLocationListResource
import app.cometes.shared.frontend.feature.organization.data.currentOrganizationMembersResource
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationDetail
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationError
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationMemberRole
import app.cometes.shared.frontend.feature.organization.presentation.OrganizationManagementState.Error
import app.cometes.shared.frontend.feature.organization.presentation.OrganizationManagementState.Loading
import app.cometes.shared.frontend.feature.organization.presentation.OrganizationManagementState.OrganizationMember
import app.cometes.shared.frontend.feature.organization.presentation.OrganizationManagementState.OrganizationOwner
import app.cometes.shared.frontend.feature.person.data.currentPerson
import kotlinx.coroutines.launch

@Immutable
sealed interface OrganizationManagementState {
    object Loading : OrganizationManagementState

    @Immutable
    class OrganizationMember(
        val role: OrganizationMemberRole,
        val organization: OrganizationDetail
    ) : OrganizationManagementState

    @Immutable
    class OrganizationOwner(
        val billingInfo: SubscriptionStatus,
        val organization: OrganizationDetail,
    ) : OrganizationManagementState

    @Immutable
    class Error(val error: ErrorResult) : OrganizationManagementState
}

@Stable
class OrganizationManagementPresenter() {

    @Composable
    fun presenterState(): OrganizationManagementState {
        val scope = rememberCoroutineScope()

        val person = currentPerson() ?: return Error(NoUserLoggedInPersonError())
        val organization = currentOrganization()
            ?: return Error(OrganizationError.NoCurrentOrganization)

        val organizationDetail = loadResourceIfEmpty { currentOrganizationDetailResource() }
        val subscriptionStatus = loadResourceIfEmpty { subscriptionResource() }
        val locations = currentOrganizationLocationListResource() // will be loaded by org detail
        val desks = currentOrganizationDesksResource() // will be loaded
        val members = currentOrganizationMembersResource() // will be loaded
        val invitations = currentOrganizationInvitationsListResource() // will be loaded

        val detail = when (organizationDetail) {
            is Resource.Value -> organizationDetail.data
            is Resource.Error -> return Error(organizationDetail.error)
            is Resource.Loading -> return Loading
        }

        val member = detail.members.firstOrNull { it.person.id == person.id }
        if (member == null) { // Current member is not part of this organization
            val clearOrganizationMutation = clearCurrentOrganizationMutation()
            scope.launch { clearOrganizationMutation.execute() }
            return Error(InvalidStateError)
        }

        val billing = if (member.role == OrganizationMemberRole.Owner) {
            when (subscriptionStatus) {
                is Resource.Value -> subscriptionStatus.data
                is Resource.Error -> return Error(subscriptionStatus.error)
                is Resource.Loading -> return Loading
            }
        } else null

        val cachedLocations = locations.data ?: return Loading
        val cachedDesks = desks.data ?: return Loading
        val cachedMembers = members.data ?: return Loading
        val cachedInvitations = invitations.data ?: return Loading
        return when (member.role) {
            OrganizationMemberRole.Owner -> {
                OrganizationOwner(
                    billing ?: return Error(InvalidStateError),
                    OrganizationDetail(
                        organization,
                        cachedMembers,
                        cachedDesks,
                        cachedLocations,
                        cachedInvitations
                    )
                )
            }

            else -> {
                OrganizationMember(
                    member.role,
                    OrganizationDetail(
                        organization,
                        cachedMembers,
                        cachedDesks,
                        cachedLocations,
                        cachedInvitations
                    )
                )
            }
        }
    }
}

object InvalidStateError : ErrorResult("Invalid OrganizationManagementPresenter state")