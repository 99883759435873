package cometes.components.feature.organization.location

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import app.cometes.shared.frontend.feature.desk.data.removeDeskMutation
import app.cometes.shared.frontend.feature.organization.data.removeOrganizationLocationMutation
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationMemberRole
import app.cometes.shared.frontend.feature.reservation.domain.model.Desk
import app.cometes.shared.frontend.feature.reservation.domain.model.Location
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.silk.components.text.DivText
import cometes.components.common.CometesCard
import cometes.components.common.RemovalDialog
import cometes.components.common.VerticalSpacer
import cometes.style.SpaceLarge
import cometes.style.SpaceSmall

@Composable
fun OrganizationLocationCard(
    desks: List<Desk>,
    locations: List<Location>,
    memberRole: OrganizationMemberRole,
    modifier: Modifier = Modifier,
) {
    var isLocationCreationDialogVisible by remember { mutableStateOf(false) }
    var isDeskCreationDialogVisible by remember { mutableStateOf(false) }

    var deskRemovalTarget: Desk? by remember { mutableStateOf(null) }
    var locationRemovalTarget: Location? by remember { mutableStateOf(null) }

    CometesCard(modifier = modifier) {
        Column(Modifier.fillMaxWidth()) {
            LocationListContent(
                memberRole = memberRole,
                locations = locations,
                onAddLocationClick = { isLocationCreationDialogVisible = true },
                onRemoveLocationClick = { locationRemovalTarget = it }
            )

            VerticalSpacer(SpaceLarge)

            DeskListContent(
                memberRole = memberRole,
                desks = desks,
                locations = locations,
                onAddDeskClick = { isDeskCreationDialogVisible = true },
                onRemoveDeskClick = { deskRemovalTarget = it })
        }
    }

    if (isDeskCreationDialogVisible) {
        DeskCreationDialog(locations, onDismiss = { isDeskCreationDialogVisible = false })
    }

    if (isLocationCreationDialogVisible) {
        LocationCreationDialog(onDismiss = { isLocationCreationDialogVisible = false })
    }

    LocationRemovalDialog(
        locationRemovalTarget,
        onDismiss = { locationRemovalTarget = null }
    )

    DeskRemovalDialog(
        deskRemovalTarget,
        onDismiss = { deskRemovalTarget = null }
    )
}

@Composable
private fun LocationRemovalDialog(
    location: Location?,
    onDismiss: () -> Unit,
) {
    if (location == null) return
    val removeLocationMutation = removeOrganizationLocationMutation(location)

    RemovalDialog(
        "Location \"${location.name}\" will be permanently removed!",
        onDismiss = onDismiss,
        onRemoveClick = { removeLocationMutation.execute() }
    ) {
        DivText("Any other resources associated with this location like reservations, desks or schedules will also be removed.")
        VerticalSpacer(SpaceSmall)
        DivText("This step cannot be reversed!", Modifier.fontWeight(FontWeight.Bold))
    }
}

@Composable
private fun DeskRemovalDialog(
    desk: Desk?,
    onDismiss: () -> Unit,
) {
    if (desk == null) return
    val removeDeskMutation = removeDeskMutation(desk)

    RemovalDialog(
        "Desk \"${desk.name}\" will be permanently removed!",
        onDismiss = onDismiss,
        onRemoveClick = { removeDeskMutation.execute() }
    ) {
        DivText("Any other resources associated with this desk like reservations or schedules will also be removed.")
        VerticalSpacer(SpaceSmall)
        DivText("This step cannot be reversed!", Modifier.fontWeight(FontWeight.Bold))
    }
}