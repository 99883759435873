package app.cometes.shared.frontend.base.error

import app.cometes.shared.base.error.ErrorDto
import app.cometes.shared.frontend.base.ErrorResult
import app.cometes.shared.frontend.base.Result
import io.ktor.client.call.body
import io.ktor.client.plugins.ResponseException

internal suspend inline fun <T : Any> runCatchingNetworkExceptions(body: () -> Result<T>): Result<T> =
    try {
        body()
    } catch (e: ResponseException) {
        val errorBody = e.response.body<ErrorDto>()
        when (errorBody.type) {
            "Unauthorized" -> Result.Error(
                AuthError.NoUserLoggedIn(AuthError.NoUserLoggedIn.Cause.FirebaseAuth)
            )

            "NoUserRegistered" -> Result.Error(
                AuthError.NoUserLoggedIn(AuthError.NoUserLoggedIn.Cause.PersonDoesNotExit)
            )

            else -> throw BackendException(errorBody)
        }
    } catch (t: Throwable) {
        val error = when (t::class.simpleName) {
            "SocketTimeoutException",
            "HttpRequestTimeoutException",
            "UnknownHostException" ->
                CommonError.NetworkError(t)

            else -> handlePlatformError(t)
        }

        Result.Error(error)
    }

internal expect fun handlePlatformError(throwable: Throwable): ErrorResult

data class BackendException(val error: ErrorDto) : Exception(error.message)