package cometes.style.system

import androidx.compose.runtime.Composable
import androidx.compose.web.events.SyntheticMouseEvent
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.UserSelect
import com.varabyte.kobweb.compose.dom.ElementRefScope
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.RowScope
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.aspectRatio
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flexShrink
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.minWidth
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.tabIndex
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.compose.ui.modifiers.userSelect
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.CssStyleScope
import com.varabyte.kobweb.silk.style.CssStyleVariant
import com.varabyte.kobweb.silk.style.common.DisabledStyle
import com.varabyte.kobweb.silk.style.selectors.active
import com.varabyte.kobweb.silk.style.selectors.ariaDisabled
import com.varabyte.kobweb.silk.style.selectors.focusVisible
import com.varabyte.kobweb.silk.style.selectors.hover
import com.varabyte.kobweb.silk.style.selectors.not
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import cometes.style.BorderSmall
import cometes.style.RadiusLarge
import cometes.style.RadiusMedium
import cometes.style.SpaceMediumSmall
import cometes.style.SpaceSmall
import cometes.style.cometesColor
import cometes.style.emphasize
import cometes.style.system.animation.CometesTransitionOf
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.w3c.dom.HTMLElement

sealed interface CometesButtonKind : CometesSurfaceKind

val CometesButtonStyle = CssStyle<CometesButtonKind> {
    val surfaceColor = cometesColor.surface
    val surfaceContentColor = cometesColor.onSurface

    base {
        Modifier
            .backgroundColor(surfaceColor)
            .color(surfaceContentColor)
            .border(BorderSmall, LineStyle.Solid, Color.argb(0x00000000))
            .borderRadius(RadiusLarge)
            .padding(topBottom = SpaceSmall, leftRight = SpaceMediumSmall)
            .userSelect(UserSelect.None)
            .flexShrink(0)
            .transition(
                CometesTransitionOf("background-color"),
                CometesTransitionOf("border-color"),
                CometesTransitionOf("opacity"),
            )
    }

    (hover + not(ariaDisabled)) {
        Modifier
            .backgroundColor(surfaceColor.emphasize(0.1f, colorMode))
            .cursor(Cursor.Pointer)
    }

    (focusVisible + not(ariaDisabled)) {
        Modifier.boxShadow(
            spreadRadius = 3.px,
            color = surfaceColor.emphasize(0.1f, colorMode)
        )
    }

    (active + not(ariaDisabled)) {
        Modifier.backgroundColor(surfaceColor.emphasize(0.18f, colorMode))
    }
}

fun CssStyle<CometesButtonKind>.addButtonVariant(
    backgroundColor: CssStyleScope.() -> Color,
    contentColor: CssStyleScope.() -> Color,
    base: CssStyleScope.() -> Modifier = { Modifier },
    onHover: CssStyleScope.() -> Modifier = { Modifier },
    onFocus: CssStyleScope.() -> Modifier = { Modifier },
    onPressed: CssStyleScope.() -> Modifier = { Modifier },
    init: CssStyleScope.() -> Unit = {}
): CssStyleVariant<CometesButtonKind> = addSurfaceVariant(
    surfaceColor = backgroundColor,
    surfaceContentColor = contentColor,
    base = base,
    onHover = onHover,
    onFocus = onFocus,
    onPressed = onPressed,
    init = init
)

val ButtonPrimaryVariant = CometesButtonStyle.addButtonVariant(
    backgroundColor = { cometesColor.primary },
    contentColor = { cometesColor.onPrimary },
    onHover = { Modifier.backgroundColor(cometesColor.primary.emphasize(0.2f, ColorMode.LIGHT)) },
    onFocus = { Modifier.backgroundColor(cometesColor.primary.emphasize(0.25f, ColorMode.LIGHT)) },
    onPressed = { Modifier.backgroundColor(cometesColor.primary.emphasize(0.3f, ColorMode.LIGHT)) }
)

val ButtonSecondaryVariant = CometesButtonStyle.addButtonVariant(
    backgroundColor = { cometesColor.secondary },
    contentColor = { cometesColor.onSecondary },
)

val ButtonOutlineVariant = CometesButtonStyle.addButtonVariant(
    backgroundColor = { Colors.Transparent },
    contentColor = { cometesColor.onBackground },
    base = { Modifier.border(1.px, LineStyle.Solid, cometesColor.outline).borderRadius(RadiusMedium) },
    onHover = { Modifier.border { color(cometesColor.primary) } },
)

val ButtonTextVariant = CometesButtonStyle.addButtonVariant(
    backgroundColor = { Colors.Transparent },
    contentColor = { cometesColor.onBackground },
    onHover = { Modifier.backgroundColor(cometesColor.primary.toRgb().copyf(alpha = 0.1f)) }
)

val ButtonPrimaryTextVariant = CometesButtonStyle.addButtonVariant(
    backgroundColor = { Colors.Transparent },
    contentColor = { cometesColor.primary },
    onHover = { Modifier.backgroundColor(cometesColor.primary.toRgb().copyf(alpha = 0.1f)) }
)

val ButtonPlainVariant = CometesButtonStyle.addButtonVariant(
    backgroundColor = { cometesColor.background },
    contentColor = { cometesColor.onBackground },
)

val ButtonAlertVariant = CometesButtonStyle.addButtonVariant(
    backgroundColor = { cometesColor.error },
    contentColor = { cometesColor.onError },
)

@Composable
fun CometesButton(
    onClick: (SyntheticMouseEvent) -> Unit,
    modifier: Modifier = Modifier,
    enabled: Boolean = true,
    variant: CssStyleVariant<CometesButtonKind>? = ButtonPrimaryVariant,
    ref: ElementRefScope<HTMLElement>? = null,
    content: @Composable RowScope.() -> Unit
) {
    Box(
        ref = ref,
        modifier = CometesButtonStyle.toModifier(variant)
            .thenIf(!enabled, DisabledStyle.toModifier().tabIndex(-1))
            .then(modifier)
            .thenIf(enabled) {
                Modifier.onClick { event ->
                    event.preventDefault()
                    event.stopPropagation()
                    onClick(event)
                }
            },
    ) {
        Row(
            horizontalArrangement = Arrangement.Center,
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.fillMaxWidth()
        ) {
            content()
        }
    }
}

@Composable
fun CometesImageButton(
    onClick: (SyntheticMouseEvent) -> Unit,
    modifier: Modifier = Modifier,
    enabled: Boolean = true,
    variant: CssStyleVariant<CometesButtonKind>? = null,
    content: @Composable () -> Unit
) {
    CometesButton(
        variant = variant,
        onClick = onClick,
        enabled = enabled,
        modifier = Modifier
            .padding(ImageButtonPadding)
            .borderRadius(50.percent)
            .aspectRatio(1)
            .then(modifier),
    ) {
        Box(
            contentAlignment = Alignment.Center,
            modifier = Modifier
                .minWidth(ImageButtonSize)
                .minHeight(ImageButtonSize)
        ) { content() }
    }
}

private val ImageButtonPadding = 10.px
private val ImageButtonSize = 18.px