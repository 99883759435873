package app.cometes.shared.frontend.base.resource

import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.runtime.remember
import app.cometes.shared.frontend.base.ErrorResult
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.util.withUseCaseContext

typealias EmptyMutation = Mutation<Unit>

// === Mutation

@Immutable
class Mutation<T>(private val body: suspend () -> Result<T>) {
    suspend fun execute(): Result<T> = withUseCaseContext { body() }
}

@Composable
fun <T> rememberMutation(body: suspend () -> Result<T>): Mutation<T> = remember { Mutation(body) }

@Composable
fun <T> rememberMutation(key1: Any?, body: suspend () -> Result<T>): Mutation<T> =
    remember(key1) { Mutation(body) }

@Composable
fun <T> rememberMutation(key1: Any?, key2: Any?, body: suspend () -> Result<T>): Mutation<T> =
    remember(key1, key2) { Mutation(body) }

@Composable
fun <T> rememberMutation(
    key1: Any?,
    key2: Any?,
    key3: Any?,
    body: suspend () -> Result<T>
): Mutation<T> = remember(key1, key2, key3) { Mutation(body) }

@Composable
fun <T> rememberMutation(
    key1: Any?,
    key2: Any?,
    key3: Any?,
    key4: Any?,
    body: suspend () -> Result<T>
): Mutation<T> = remember(key1, key2, key3, key4) { Mutation(body) }

@Composable
fun <T> rememberMutation(
    key1: Any?,
    key2: Any?,
    key3: Any?,
    key4: Any?,
    key5: Any?,
    body: suspend () -> Result<T>
): Mutation<T> = remember(key1, key2, key3, key4, key5) { Mutation(body) }

// === Input Mutation - discouraged

typealias EmptyInputMutation<Input> = InputMutation<Input, Unit>

@Immutable
class InputMutation<Input, T>(private val body: suspend (Input) -> Result<T>) {
    suspend fun execute(input: Input): Result<T> = withUseCaseContext { body(input) }
}

@Composable
fun <Input, T> rememberInputMutation(body: suspend (Input) -> Result<T>): InputMutation<Input, T> =
    remember { InputMutation(body) }

@Composable
fun <Input, T> rememberInputMutation(
    key1: Any?,
    body: suspend (Input) -> Result<T>
): InputMutation<Input, T> = remember(key1) { InputMutation(body) }

@Composable
fun <Input, T> rememberInputMutation(
    key1: Any?,
    key2: Any?,
    body: suspend (Input) -> Result<T>
): InputMutation<Input, T> = remember(key1, key2) { InputMutation(body) }

@Composable
fun <Input, T> rememberInputMutation(
    key1: Any?,
    key2: Any?,
    key3: Any?,
    body: suspend (Input) -> Result<T>
): InputMutation<Input, T> = remember(key1, key2, key3) { InputMutation(body) }

// === Util
fun <T> Result<T>.alsoOnSuccess(block: (T) -> Unit) =
    also { res -> if (res is Result.Success) block(res.data) }

fun <T> Result<T>.alsoOnError(block: (ErrorResult) -> Unit) =
    also { res -> if (res is Result.Error) block(res.error) }