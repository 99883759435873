package cometes.components.feature.organization

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import app.cometes.shared.frontend.feature.organization.data.removeOrganizationMemberMutation
import app.cometes.shared.frontend.feature.organization.data.updateOrganizationMemberMutation
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationMemberPerson
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationMemberRole
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationMemberStatus
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.silk.components.icons.mdi.MdiDelete
import com.varabyte.kobweb.silk.components.icons.mdi.MdiInfo
import com.varabyte.kobweb.silk.components.text.SpanText
import cometes.components.common.CometesDialog
import cometes.components.common.CometesDropDown
import cometes.components.common.CometesLoadingButton
import cometes.components.common.DivText
import cometes.components.common.PopoverMenuItem
import cometes.components.common.VerticalSpacer
import cometes.style.BorderSmall
import cometes.style.SpaceMedium
import cometes.style.SpaceMediumLarge
import cometes.style.TextSecondaryOpacity
import cometes.style.TypographyBodySmall
import cometes.style.TypographyTitleSmall
import cometes.style.system.ButtonAlertVariant
import cometes.style.system.ButtonTextVariant
import cometes.style.system.CometesButton
import cometes.util.localized
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.percent

@Composable
fun EditMemberDialog(
    member: OrganizationMemberPerson,
    isCurrentUserAdmin: Boolean,
    onDismiss: () -> Unit,
) {
    val actionCoroutineScope = rememberCoroutineScope()
    var isLoading by remember { mutableStateOf(false) }
    var selectedRole by remember(member) { mutableStateOf(member.role) }
    var selectedStatus by remember(member) { mutableStateOf(member.status) }

    val updateMember = updateOrganizationMemberMutation(
        member = member,
        role = selectedRole,
        status = selectedStatus
    )

    val removeMember = removeOrganizationMemberMutation(member)

    fun save() {
        isLoading = true
        actionCoroutineScope.launch {
            try {
                updateMember.execute()
            } finally {
                isLoading = false
                onDismiss()
            }
        }
    }

    fun removeMember() {
        isLoading = true
        actionCoroutineScope.launch {
            try {
                removeMember.execute()
            } finally {
                isLoading = false
                onDismiss()
            }
        }
    }

    CometesDialog(
        onDismiss = onDismiss,
        title = "Edit member ${member.person.fullName}",
        submitButtons = {
            CometesButton(
                onClick = { onDismiss() },
                variant = ButtonTextVariant,
            ) { SpanText("Cancel") }

            CometesLoadingButton(
                isLoading = isLoading,
                onClick = { save() },
            ) { SpanText("Save") }
        },
        modifier = Modifier
            .fillMaxWidth(80.percent)
            .maxWidth(32.cssRem),
    ) {
        Column(
            modifier = Modifier.fillMaxSize()
        ) {
            // Role dropdown
            CometesDropDown(
                mainContent = { DivText(text = selectedRole.localized(), TypographyTitleSmall) },
                label = "Role",
                enabled = isCurrentUserAdmin && member.role != OrganizationMemberRole.Owner,
                modifier = Modifier.fillMaxWidth(),
            ) {
                // Only show roles that the user can change to
                // Owner can't be changed, and we don't want to show Unknown
                val availableRoles = listOf(OrganizationMemberRole.User, OrganizationMemberRole.Admin)
                for (role in availableRoles) {
                    PopoverMenuItem(
                        title = role.localized(),
                        onClick = { selectedRole = role }
                    )
                }
            }

            VerticalSpacer(SpaceMedium)

            // Status dropdown
            CometesDropDown(
                mainContent = {
                    DivText(
                        text = if (selectedStatus == OrganizationMemberStatus.Active) "Active" else "Suspended",
                        TypographyTitleSmall
                    )
                },
                label = "Status",
                enabled = isCurrentUserAdmin && member.role != OrganizationMemberRole.Owner,
                modifier = Modifier.fillMaxWidth(),
            ) {
                // Only show statuses that the user can change to
                // We don't want to show Unknown
                val availableStatuses = listOf(OrganizationMemberStatus.Active, OrganizationMemberStatus.Suspended)
                for (status in availableStatuses) {
                    PopoverMenuItem(
                        title = if (status == OrganizationMemberStatus.Active) "Active" else "Suspended",
                        onClick = { selectedStatus = status }
                    )
                }
            }

            VerticalSpacer(SpaceMediumLarge)
            Row(
                horizontalArrangement = Arrangement.spacedBy(SpaceMedium),
                modifier = Modifier.fillMaxWidth().border(BorderSmall)
            ) {
                MdiInfo(Modifier.opacity(TextSecondaryOpacity))
                SpanText(
                    text = "Removing this member will remove their access to the organization. " +
                            "This action is permanent and cannot be undone.",
                    modifier = TypographyBodySmall.opacity(TextSecondaryOpacity)
                )
                CometesLoadingButton(
                    isLoading = isLoading,
                    onClick = { removeMember() },
                    variant = ButtonAlertVariant,
                    enabled = isCurrentUserAdmin && member.role != OrganizationMemberRole.Owner,
                    modifier = Modifier.align(Alignment.CenterVertically)
                ) {
                    MdiDelete()
                    SpanText("Remove")
                }
            }
            VerticalSpacer(SpaceMediumLarge)
        }
    }
}