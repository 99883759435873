package cometes.components.feature.organization

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import app.cometes.shared.frontend.feature.desk.data.createDeskMutation
import app.cometes.shared.frontend.feature.desk.data.removeDeskMutation
import app.cometes.shared.frontend.feature.desk.data.updateDeskMutation
import app.cometes.shared.frontend.feature.reservation.domain.model.Desk
import app.cometes.shared.frontend.feature.reservation.domain.model.Location
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.silk.components.icons.mdi.MdiDelete
import com.varabyte.kobweb.silk.components.icons.mdi.MdiInfo
import com.varabyte.kobweb.silk.components.text.SpanText
import cometes.components.common.CometesDialog
import cometes.components.common.CometesDropDown
import cometes.components.common.CometesLoadingButton
import cometes.components.common.DivText
import cometes.components.common.PopoverMenuItem
import cometes.components.common.VerticalSpacer
import cometes.style.BorderSmall
import cometes.style.SpaceMedium
import cometes.style.SpaceMediumLarge
import cometes.style.TextSecondaryOpacity
import cometes.style.TypographyBodySmall
import cometes.style.TypographyTitleSmall
import cometes.style.system.ButtonAlertVariant
import cometes.style.system.ButtonTextVariant
import cometes.style.system.CometesButton
import cometes.style.system.input.CometesTextField
import cometes.style.system.input.CometesTextFieldSurfaceVariant
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.percent

@Composable
fun EditDeskDialog(
    desk: Desk?,
    locations: List<Location>,
    onDismiss: () -> Unit,
) {
    val actionCoroutineScope = rememberCoroutineScope()
    var isLoading by remember { mutableStateOf(false) }
    var deskName by remember(desk) { mutableStateOf(desk?.name ?: "") }
    var selectedLocation by remember(desk) { mutableStateOf(desk?.location ?: locations.first()) }

    val createDesk = if (desk == null) {
        createDeskMutation(name = deskName, location = selectedLocation)
    } else null
    val updateDesk = if (desk != null) {
        updateDeskMutation(originalDesk = desk, name = deskName)
    } else null
    val removeDesk = if (desk != null) {
        removeDeskMutation(desk)
    } else null

    fun save() {
        isLoading = true
        actionCoroutineScope.launch {
            try {
                createDesk?.execute()
                updateDesk?.execute()
            } finally {
                isLoading = false
                onDismiss()
            }
        }
    }

    fun removeDesk() {
        isLoading = true
        actionCoroutineScope.launch {
            try {
                removeDesk?.execute()
            } finally {
                isLoading = false
                onDismiss()
            }
        }
    }

    CometesDialog(
        onDismiss = onDismiss,
        title = if (desk == null) "Add new desk" else "Edit desk ${desk.name}",
        subTitle = if (desk == null) "Add a new desk to a location" else null,
        submitButtons = {
            CometesButton(
                onClick = { onDismiss() },
                variant = ButtonTextVariant,
            ) { SpanText("Cancel") }

            CometesLoadingButton(
                isLoading = isLoading,
                onClick = { save() },
            ) {
                if (desk == null) SpanText("Create")
                else SpanText("Save")
            }
        },
        modifier = Modifier
            .fillMaxWidth(80.percent)
            .maxWidth(32.cssRem),
    ) {
        Column(
            modifier = Modifier.fillMaxSize()
        ) {
            CometesTextField(
                value = deskName,
                onValueChange = { deskName = it },
                label = "Desk name",
                variant = CometesTextFieldSurfaceVariant,
                modifier = Modifier.fillMaxWidth()
            )

            if (desk == null) {
                VerticalSpacer(SpaceMedium)
                CometesDropDown(
                    mainContent = { DivText(text = selectedLocation.name, TypographyTitleSmall) },
                    label = "Location",
                    enabled = locations.size > 1,
                    modifier = Modifier.fillMaxWidth(),
                ) {
                    for (location in locations)
                        PopoverMenuItem(
                            title = location.name,
                            onClick = { selectedLocation = location }
                        )
                }
            }

            if (desk != null) {
                VerticalSpacer(SpaceMediumLarge)
                Row(
                    horizontalArrangement = Arrangement.spacedBy(SpaceMedium),
                    modifier = Modifier.fillMaxWidth().border(BorderSmall)
                ) {
                    MdiInfo(Modifier.opacity(TextSecondaryOpacity))
                    SpanText(
                        text = "Removing this desk will remove all related resources, including reservations and " +
                                "reservation schedules. This action is permanent and cannot be undone.",
                        modifier = TypographyBodySmall.opacity(TextSecondaryOpacity)
                    )
                    CometesLoadingButton(
                        isLoading = isLoading,
                        onClick = { removeDesk() },
                        variant = ButtonAlertVariant,
                        modifier = Modifier.align(Alignment.CenterVertically)
                    ) {
                        MdiDelete()
                        SpanText("Remove")
                    }
                }
                VerticalSpacer(SpaceMediumLarge)
            }
        }
    }
}