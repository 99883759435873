package app.cometes.shared.frontend.base.resource

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisallowComposableCalls
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.remember
import app.cometes.shared.frontend.base.Identifiable
import app.cometes.shared.frontend.base.LocalCacheSource
import app.cometes.shared.frontend.base.LocalCacheSourceImpl
import app.cometes.shared.frontend.base.error.CommonError
import kotlinx.coroutines.flow.MutableStateFlow

@Composable
inline fun <T> loadResourceIfEmpty(body: @Composable () -> Resource<T>): Resource<T> {
    val resource = body()
    val notLoaded = resource is Resource.Error && resource.error == CommonError.ResourceNotLoaded

    LaunchedEffect(notLoaded) { if (notLoaded) resource.reload() }

    return if (notLoaded) Resource.Loading(resource.data)
    else resource
}

@Composable
fun <A, B> Resource<A>.combine(other: Resource<B>): Resource<Pair<A, B>> {
    fun reloadBoth(silent: Boolean) {
        this.reload(silent)
        other.reload(silent)
    }

    return when {
        this is Resource.Value && other is Resource.Value -> {
            Resource.Value(
                data = this.data to other.data,
                onReload = { silent -> reloadBoth(silent) }
            )
        }

        this is Resource.Loading || other is Resource.Loading -> {
            Resource.Loading(null)
        }

        this is Resource.Error -> {
            Resource.Error(
                error = this.error,
                onReload = { silent ->
                    this.reload(silent)
                    if (other is Resource.Error) other.reload(silent)
                }
            )
        }

        other is Resource.Error -> {
            Resource.Error(
                error = other.error,
                onReload = { silent ->
                    other.reload(silent)
                    if (this is Resource.Error) this.reload(silent)
                }
            )
        }

        else -> Resource.Loading(null)
    }
}

private val rememberCacheableStorage = mutableMapOf<String, Any?>()
fun clearResourceCaches() = rememberCacheableStorage.clear()

@Composable
private inline fun <T> rememberCacheable(
    name: String,
    crossinline calculation: @DisallowComposableCalls () -> T
): T = remember(name) {
    val cachedValue = if (rememberCacheableStorage.contains(name)) {
        @Suppress("UNCHECKED_CAST")
        rememberCacheableStorage[name] as? T
    } else null

    if (cachedValue != null) cachedValue
    else {
        val newValue = calculation()
        rememberCacheableStorage[name] = newValue
        newValue
    }
}

@Suppress("NOTHING_TO_INLINE")
@Composable
internal inline fun <T : Identifiable> resourceListCache(
    key: String,
    initialValue: List<T>? = null
): LocalCacheSource<T> = rememberCacheable(key) { LocalCacheSourceImpl(initialValue) }

@Suppress("NOTHING_TO_INLINE")
@Composable
internal inline fun <T> resourceCache(key: String, initialValue: T) =
    rememberCacheable(key) { MutableStateFlow(initialValue) }