package cometes.components.common

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.width
import org.jetbrains.compose.web.css.CSSNumeric

@Composable
fun VerticalSpacer(spacing: CSSNumeric) {
    Box(com.varabyte.kobweb.compose.ui.Modifier.height(spacing))
}

@Composable
fun HorizontalSpacer(spacing: CSSNumeric) {
    Box(com.varabyte.kobweb.compose.ui.Modifier.width(spacing))
}