package app.cometes.shared.frontend.feature.person.data

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import app.cometes.shared.feature.person.infrastructure.model.PersonDto
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.map
import app.cometes.shared.frontend.base.resource.Resource
import app.cometes.shared.frontend.base.resource.rememberUpdatedResourceState
import app.cometes.shared.frontend.base.resource.wrapEmptyResource
import app.cometes.shared.frontend.feature.person.data.source.PersonLocalSource
import app.cometes.shared.frontend.feature.person.data.source.PersonRemoteSource
import app.cometes.shared.frontend.feature.person.domain.model.Person
import app.cometes.shared.frontend.feature.person.infrastructure.model.toDomain
import app.cometes.shared.frontend.util.rememberKoinDependency
import app.cometes.shared.frontend.util.withUseCaseContext

@Composable
fun currentPerson(): Person? {
    val personLocalSource = rememberKoinDependency<PersonLocalSource>()
    val localDto by personLocalSource.personFlow.collectAsState()

    return localDto?.toDomain()
}

@Composable
internal fun currentPersonResource(ignoreUserAuthError: Boolean = false): Resource<Person> {
    val personRemoteSource = rememberKoinDependency<PersonRemoteSource>()
    val personLocalSource = rememberKoinDependency<PersonLocalSource>()

    val localDto by personLocalSource.personFlow.collectAsState()
    val resourceState = rememberUpdatedResourceState(localDto?.toDomain()) {
        val res = withUseCaseContext(ignoreUserAuthError = ignoreUserAuthError) {
            personRemoteSource.getCurrentPerson()
        }
        if (res is Result.Success) personLocalSource.person = res.data
        res.map(PersonDto::toDomain)
    }

    LaunchedEffect(Unit) {
        if (localDto == null) resourceState.resource.reload()
    }

    return wrapEmptyResource { resourceState.resource }
}