package cometes.components.common

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.SideEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.web.events.SyntheticMouseEvent
import com.varabyte.kobweb.browser.dom.ElementTarget
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextOverflow
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.dom.disposableRef
import com.varabyte.kobweb.compose.dom.ref
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.BoxScope
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.ColumnScope
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.aspectRatio
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.textOverflow
import com.varabyte.kobweb.compose.ui.modifiers.whiteSpace
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.overlay.AdvancedPopover
import com.varabyte.kobweb.silk.components.overlay.KeepPopupOpenStrategy
import com.varabyte.kobweb.silk.components.overlay.OpenClosePopupStrategy
import com.varabyte.kobweb.silk.components.overlay.PopupPlacement
import com.varabyte.kobweb.silk.components.overlay.PopupPlacementStrategy
import com.varabyte.kobweb.silk.components.overlay.PopupScope
import com.varabyte.kobweb.silk.components.overlay.manual
import com.varabyte.kobweb.silk.components.overlay.never
import com.varabyte.kobweb.silk.style.CssStyleVariant
import cometes.style.BorderSmall
import cometes.style.RadiusLarge
import cometes.style.SpaceSmall
import cometes.style.SpaceXSmall
import cometes.style.SpaceXXSmall
import cometes.style.TypographyBodySmall
import cometes.style.TypographyTitleSmall
import cometes.style.system.CometesSurface
import cometes.style.system.CometesSurfaceKind
import cometes.util.ZIndex
import kotlinx.browser.document
import org.jetbrains.compose.web.css.CSSNumericValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.minus
import org.jetbrains.compose.web.css.px
import org.w3c.dom.HTMLElement
import org.w3c.dom.events.Event

@Composable
fun PopoverMenuLayout(
    isVisible: Boolean,
    onDismiss: () -> Unit,
    popoverContent: @Composable BoxScope.() -> Unit,
    modifier: Modifier = Modifier,
    placementStrategy: PopupPlacementStrategy = PopupPlacementStrategy.of(PopupPlacement.Bottom),
    popoverSpacing: CSSNumericValue<CSSUnit.px> = SpaceSmall,
    content: @Composable BoxScope.() -> Unit,
) {
    var contentElement: HTMLElement? by remember { mutableStateOf(null) }

    Box(
        ref = ref { element -> contentElement = element },
        modifier = modifier.onClick { it.stopPropagation() },
        content = content,
    )

    CometesPopover(
        isVisible = isVisible,
        onDismiss = onDismiss,
        placementStrategy = placementStrategy
    ) {
        Box(
            modifier = Modifier.thenIf(contentElement != null) {
                Modifier.width((contentElement?.offsetWidth?.px ?: 0.px) - popoverSpacing)
            },
            content = popoverContent,
        )
    }
}

@Composable
fun CometesPopover(
    isVisible: Boolean,
    onDismiss: () -> Unit,
    target: ElementTarget = ElementTarget.PreviousSibling,
    placementStrategy: PopupPlacementStrategy = PopupPlacementStrategy.of(PopupPlacement.Bottom),
    content: @Composable PopupScope.() -> Unit,
) {
    val popoverStrategy = remember { OpenClosePopupStrategy.manual() }
    SideEffect { popoverStrategy.isOpen = isVisible }

    DisposableEffect(Unit) {
        val callback: (Event) -> Unit = { onDismiss() }
        document.addEventListener("click", callback)
        onDispose { document.removeEventListener("click", callback) }
    }

    AdvancedPopover(
        target = target,
        placementStrategy = placementStrategy,
        openCloseStrategy = popoverStrategy,
        keepOpenStrategy = remember { KeepPopupOpenStrategy.never() },
        ref = disposableRef { onDispose { onDismiss() } },
        content = content,
        modifier = Modifier.zIndex(ZIndex.PopOver)
    )
}

@Composable
fun PopoverMenuContent(
    modifier: Modifier = Modifier,
    variant: CssStyleVariant<CometesSurfaceKind>? = null,
    items: @Composable ColumnScope.() -> Unit,
) {
    CometesSurface(
        variant = variant,
        modifier = Modifier
            .borderRadius(RadiusLarge)
            .padding(SpaceXXSmall)
            .border { width(BorderSmall) }
            .then(modifier),
    ) {
        Column(
            verticalArrangement = Arrangement.spacedBy(SpaceXSmall),
            modifier = Modifier.fillMaxSize()
        ) {
            items()
        }
    }
}

@Composable
fun PopoverMenuItem(
    title: String,
    onClick: (SyntheticMouseEvent) -> Unit,
    modifier: Modifier = Modifier,
    icon: (@Composable () -> Unit)? = null,
    subtitle: String? = null,
    enabled: Boolean = true,
) {
    CometesSurface(
        onClick = onClick,
        enabled = enabled,
        modifier = Modifier
            .fillMaxWidth()
            .borderRadius(RadiusLarge - SpaceXXSmall)
            .padding(SpaceSmall)
            .then(modifier),
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.fillMaxWidth()
        ) {
            if (icon != null) {
                Box(
                    contentAlignment = Alignment.Center,
                    modifier = Modifier
                        .requiredSize(24.px)
                        .aspectRatio(1f)
                ) {
                    icon()
                }
                HorizontalSpacer(SpaceSmall)
            }
            Column {
                DivText(text = title, TypographyTitleSmall)
                if (subtitle != null) {
                    DivText(
                        text = subtitle,
                        modifier = Modifier
                            .then(TypographyBodySmall)
                            .opacity(0.6)
                            .whiteSpace(WhiteSpace.NoWrap)
                            .overflow(Overflow.Hidden)
                            .textOverflow(TextOverflow.Ellipsis)
                            .fillMaxWidth()
                    )
                }
            }
        }
    }
}