package app.cometes.shared.frontend.feature.auth

import app.cometes.shared.frontend.feature.auth.data.AuthLocalSource
import app.cometes.shared.frontend.feature.auth.data.AuthRepositoryImpl
import app.cometes.shared.frontend.feature.auth.domain.AuthRepository
import app.cometes.shared.frontend.feature.auth.domain.ClearCacheUseCase
import app.cometes.shared.frontend.feature.auth.domain.ClearCacheUseCaseImpl
import app.cometes.shared.frontend.feature.auth.domain.LogOutUseCase
import app.cometes.shared.frontend.feature.auth.domain.LogOutUseCaseImpl
import app.cometes.shared.frontend.feature.auth.infrasctructure.AuthLocalSourceImpl
import org.koin.core.module.dsl.factoryOf
import org.koin.core.module.dsl.singleOf
import org.koin.dsl.bind
import org.koin.dsl.module

val featureAuth = module {
    singleOf(::AuthLocalSourceImpl) bind AuthLocalSource::class

    singleOf(::AuthRepositoryImpl) bind AuthRepository::class

    factoryOf(::LogOutUseCaseImpl) bind LogOutUseCase::class
    factoryOf(::ClearCacheUseCaseImpl) bind ClearCacheUseCase::class
}