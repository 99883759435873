package cometes.style

import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.letterSpacing
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import org.jetbrains.compose.web.css.cssRem

val TypographyDisplayLarge =
    Modifier
        .fontWeight(FontWeight.Normal)
        .fontSize(3.56.cssRem)
        .lineHeight(4.cssRem)
        .letterSpacing((-0.015).cssRem)

val TypographyDisplayMedium =
    Modifier
        .fontWeight(FontWeight.Normal)
        .fontSize(2.81.cssRem)
        .lineHeight(3.25.cssRem)
        .letterSpacing(0.cssRem)

val TypographyDisplaySmall =
    Modifier
        .fontWeight(FontWeight.Normal)
        .fontSize(2.25.cssRem)
        .lineHeight(2.75.cssRem)
        .letterSpacing(0.cssRem)

val TypographyHeadlineLarge =
    Modifier
        .fontWeight(FontWeight.Normal)
        .fontSize(2.cssRem)
        .lineHeight(2.5.cssRem)
        .letterSpacing(0.cssRem)

val TypographyHeadlineMedium =
    Modifier
        .fontWeight(FontWeight.Normal)
        .fontSize(1.75.cssRem)
        .lineHeight(2.25.cssRem)
        .letterSpacing(0.cssRem)

val TypographyHeadlineSmall =
    Modifier
        .fontWeight(FontWeight.Normal)
        .fontSize(1.5.cssRem)
        .lineHeight(2.cssRem)
        .letterSpacing(0.cssRem)

val TypographyTitleLarge =
    Modifier
        .fontWeight(FontWeight.Normal)
        .fontSize(1.38.cssRem)
        .lineHeight(1.75.cssRem)
        .letterSpacing(0.cssRem)

val TypographyTitleMedium =
    Modifier
        .fontWeight(FontWeight.Medium)
        .fontSize(1.1.cssRem)
        .lineHeight(1.5.cssRem)
        .letterSpacing(0.06.cssRem)

val TypographyTitleSmall =
    Modifier
        .fontWeight(FontWeight.Medium)
        .fontSize(0.88.cssRem)
        .lineHeight(1.25.cssRem)
        .letterSpacing(0.06.cssRem)

val TypographyLabelLarge =
    Modifier
        .fontWeight(FontWeight.Medium)
        .fontSize(0.88.cssRem)
        .lineHeight(1.25.cssRem)
        .letterSpacing(0.06.cssRem)

val TypographyBodyLarge =
    Modifier
        .fontWeight(FontWeight.Normal)
        .fontSize(1.cssRem)
        .lineHeight(1.5.cssRem)
        .letterSpacing(0.031.cssRem)

val TypographyBodyMedium =
    Modifier
        .fontWeight(FontWeight.Normal)
        .fontSize(0.88.cssRem)
        .lineHeight(1.25.cssRem)
        .letterSpacing(0.015.cssRem)

val TypographyBodySmall =
    Modifier
        .fontWeight(FontWeight.Normal)
        .fontSize(0.75.cssRem)
        .lineHeight(1.cssRem)
        .letterSpacing(0.031.cssRem)

val TypographyLabelMedium =
    Modifier
        .fontWeight(FontWeight.Medium)
        .fontSize(0.75.cssRem)
        .lineHeight(1.cssRem)
        .letterSpacing(0.031.cssRem)

val TypographyLabelSmall =
    Modifier
        .fontWeight(FontWeight.Medium)
        .fontSize(0.69.cssRem)
        .lineHeight(1.cssRem)
        .letterSpacing(0.031.cssRem)