package app.cometes.shared.frontend.feature.auth.domain

import androidx.compose.runtime.Composable
import app.cash.molecule.RecompositionMode
import app.cash.molecule.moleculeFlow
import app.cometes.shared.frontend.feature.auth.data.isUserLoggedIn
import app.cometes.shared.frontend.feature.organization.data.currentOrganizationResource
import app.cometes.shared.frontend.feature.person.data.currentPerson
import com.rickclephas.kmp.nativecoroutines.NativeCoroutines

enum class AuthState {
    LoggedOut, PersonCreation, OrganizationSelection, Valid
}

@Composable
fun authStateBlock(): AuthState {
    val isLoggedIn = isUserLoggedIn()
    val person = currentPerson()
    val organization = currentOrganizationResource().data

    return when {
        !isLoggedIn -> AuthState.LoggedOut
        person == null -> AuthState.PersonCreation
        organization == null -> AuthState.OrganizationSelection
        else -> AuthState.Valid
    }
}

@NativeCoroutines
val authStateFlow = moleculeFlow(RecompositionMode.Immediate) { authStateBlock() }