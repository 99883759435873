package app.cometes.shared.frontend.feature.person.data

import androidx.compose.runtime.Composable
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.mapToEmpty
import app.cometes.shared.frontend.base.resource.EmptyMutation
import app.cometes.shared.frontend.base.resource.alsoOnSuccess
import app.cometes.shared.frontend.base.resource.rememberMutation
import app.cometes.shared.frontend.feature.auth.domain.LogOutUseCase
import app.cometes.shared.frontend.feature.person.data.source.PersonLocalSource
import app.cometes.shared.frontend.feature.person.data.source.PersonRemoteSource
import app.cometes.shared.frontend.util.rememberKoinDependency
import app.cometes.shared.frontend.util.withUseCaseContext

@Composable
fun registerMutation(firstName: String, lastName: String): EmptyMutation {
    val personRemoteSource = rememberKoinDependency<PersonRemoteSource>()
    val personLocalSource = rememberKoinDependency<PersonLocalSource>()

    return rememberMutation(firstName, lastName) {
        withUseCaseContext { personRemoteSource.registerPerson(firstName, lastName) }
            .alsoOnSuccess { personDto -> personLocalSource.person = personDto }
            .mapToEmpty()
    }
}

@Composable
fun removeUserDataAndLogOutMutation(): EmptyMutation {
    val personRemoteSource = rememberKoinDependency<PersonRemoteSource>()
    val logOut = rememberKoinDependency<LogOutUseCase>()

    return rememberMutation {
        val result = withUseCaseContext { personRemoteSource.removeUserData() }
        if (result is Result.Success) withUseCaseContext { logOut() }

        Result.Success(Unit)
    }
}