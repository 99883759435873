import app.cometes.shared.frontend.feature.auth.data.FirebaseSource
import dev.bitspittle.firebase.app.FirebaseApp
import dev.bitspittle.firebase.app.FirebaseOptions
import infrastructure.source.FirebaseSourceImpl
import org.koin.core.module.dsl.singleOf
import org.koin.dsl.bind
import org.koin.dsl.module

val webModule = module {
    singleOf(::FirebaseSourceImpl) bind FirebaseSource::class
    single {
        FirebaseApp.initialize(
            FirebaseOptions(
                apiKey = "AIzaSyD2MgKTA_dtkuP0UjnO94XWrTCZvsIvhrI",
                authDomain = "cometes-app.firebaseapp.com",
                projectId = "cometes-app",
                storageBucket = "cometes-app.appspot.com",
                messagingSenderId = "373956404221",
                appId = "1:373956404221:web:04c5ee604c8c99b4546f94",
                measurementId = "G-0V3TG39FLQ",
                databaseURL = "",
            )
        )
    }
}