package cometes.components.feature.auth

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import app.cometes.shared.frontend.feature.auth.presentation.UserInfoEvent
import app.cometes.shared.frontend.feature.auth.presentation.UserInfoIntent
import app.cometes.shared.frontend.feature.auth.presentation.UserInfoPresenter
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.text.DivText
import com.varabyte.kobweb.silk.components.text.SpanText
import cometes.components.common.AnimatedVisibility
import cometes.components.common.DelayedProgressIndicator
import cometes.components.common.ProgressIndicator
import cometes.components.common.VerticalSpacer
import cometes.components.layout.AuthPageLayout
import cometes.style.CometesTheme
import cometes.style.SpaceLarge
import cometes.style.SpaceMedium
import cometes.style.TypographyTitleLarge
import cometes.style.system.CometesSurface
import cometes.style.system.SurfaceBackgroundColorVariant
import cometes.style.system.animation.fadeInTransition
import cometes.style.system.input.CometesTextField

@Composable
fun AuthUserInfoContent() {
    val presenter = remember {
        UserInfoPresenter { event ->
            when (event) {
                is UserInfoEvent.Error -> {} // TODO
            }
        }
    }
    val state = presenter.presenterState()


    AuthPageLayout(
        title = "User information",
        onLogoutClick = { state.onIntent(UserInfoIntent.LogOut) }
    ) {
        Box(Modifier.fillMaxSize()) {
            if (state.isLoading) {
                DelayedProgressIndicator(Modifier.align(Alignment.Center))
            } else {
                CometesSurface(
                    variant = SurfaceBackgroundColorVariant,
                    modifier = Modifier.align(Alignment.Center).fadeInTransition()
                ) {
                    Column(
                        horizontalAlignment = Alignment.CenterHorizontally,
                        verticalArrangement = Arrangement.Center,
                        modifier = Modifier.fillMaxSize()
                    ) {
                        CometesTextField(
                            value = state.firstName,
                            onValueChange = { state.onIntent(UserInfoIntent.SetFirstName(it)) },
                            placeholder = "First name",
                            modifier = TypographyTitleLarge
                        )

                        VerticalSpacer(SpaceMedium)

                        CometesTextField(
                            value = state.lastName,
                            onValueChange = { state.onIntent(UserInfoIntent.SetLastName(it)) },
                            placeholder = "Last name",
                            modifier = TypographyTitleLarge
                        )

                        val error = state.error
                        if (error != null) {
                            VerticalSpacer(SpaceMedium)
                            DivText(
                                text = error.message ?: "Unknown error",
                                modifier = Modifier
                                    .color(CometesTheme.color.error)
                                    .align(Alignment.Start)
                            )
                        }

                        VerticalSpacer(SpaceLarge)

                        Button(
                            onClick = { state.onIntent(UserInfoIntent.Register) },
                            modifier = Modifier.align(Alignment.End)
                        ) {
                            SpanText(
                                text = "Save",
                                modifier = Modifier.thenIf(state.isLoading, Modifier.opacity(0.3))
                            )

                            AnimatedVisibility(state.isLoading) {
                                ProgressIndicator(Modifier.align(Alignment.Center))
                            }
                        }
                    }
                }
            }
        }
    }
}