package infrastructure.source

import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.error.AuthError
import app.cometes.shared.frontend.feature.auth.data.AuthListenerHandle
import app.cometes.shared.frontend.feature.auth.data.EmailSignInSettings
import app.cometes.shared.frontend.feature.auth.data.FirebaseSource
import co.touchlab.kermit.Logger
import dev.bitspittle.firebase.app.FirebaseApp
import dev.bitspittle.firebase.auth.ActionCodeSettings

class FirebaseSourceImpl(
    firebaseApp: FirebaseApp
) : FirebaseSource {
    private val auth = firebaseApp.getAuth()

    override val isLoggedIn: Boolean
        get() = auth.currentUser != null

    override suspend fun signInUserUsingEmailAndPassword(
        email: String,
        password: String
    ): Result<Unit> {
        try {
            auth.signInWithEmailAndPassword(email, password)
        } catch (e: dev.bitspittle.firebase.auth.AuthError) {
            return Result.Error(AuthError.InvalidCredentials(e))
        }

        return Result.Success(Unit)
    }

    override suspend fun sendSignInEmail(
        email: String,
        settings: EmailSignInSettings
    ): Result<Unit> {
        try {
            auth.sendSignInLinkToEmail(
                email,
                ActionCodeSettings(
                    url = settings.url,
                    handleCodeInApp = true,
                    android = ActionCodeSettings.AndroidConfig(settings.androidPackageName, true),
                    iOs = ActionCodeSettings.IosConfig(settings.iosBundle),
                )
            )
        } catch (e: dev.bitspittle.firebase.auth.AuthError) {
            Logger.e(e) { "Error while sending sign-in email" }
            return Result.Error(AuthError.InvalidCredentials(e))
        }

        return Result.Success(Unit)
    }

    override suspend fun confirmLogin(email: String, emailLink: String): Result<Unit> {
        try {
            auth.signInWithEmailLink(email, emailLink)
        } catch (e: dev.bitspittle.firebase.auth.AuthError) {
            Logger.e(e) { "Error while confirming sign-in link" }
            return Result.Error(AuthError.InvalidCredentials(e))
        }

        return Result.Success(Unit)
    }

    override suspend fun getToken(): Result<String> {
        val token = try {
            auth.currentUser?.getIdToken(false)
        } catch (t: Throwable) {
            Logger.e(tag = "Auth", throwable = t) { "Exception while retrieving the token" }
            null
        }

        return if (token != null) Result.Success(token)
        else Result.Error(AuthError.NoUserLoggedIn(AuthError.NoUserLoggedIn.Cause.FirebaseAuth))
    }

    override suspend fun logOutUser(): Result<Unit> {
        if (auth.currentUser == null)
            return Result.Error(AuthError.NoUserLoggedIn(AuthError.NoUserLoggedIn.Cause.FirebaseAuth))

        auth.signOut()
        return Result.Success(Unit)
    }

    override fun addAuthListener(onAuthChange: (isUserLoggedIn: Boolean) -> Unit): AuthListenerHandle {
        auth.onAuthStateChanged { user -> onAuthChange(user != null) }
        return AuthListenerHandle { } // TODO
    }
}