package cometes.components.common

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Transition
import com.varabyte.kobweb.compose.dom.ref
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.compose.ui.modifiers.transition
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnitTime
import org.jetbrains.compose.web.css.s

private val DefaultAnimationLength = 3.s

@Composable
fun AnimatedVisibility(
    isVisible: Boolean,
    modifier: Modifier = Modifier,
    transitionDuration: CSSSizeValue<out CSSUnitTime> = DefaultAnimationLength,
    content: @Composable () -> Unit
) {
//    var contentVisible by remember { mutableStateOf(isVisible) }
//    fun onTransitionEnd(element: HTMLElement, event: Event) {
//        contentVisible = element.style.opacity != "0"
//    }
//
//    DisposableEffect(isVisible) {
//        if (isVisible) contentVisible = true
//        onDispose {  }
//    }

    Box(
        ref = ref { element ->
//            element.addEventListener("transitionend", { onTransitionEnd(element, it) })
        },
        modifier = Modifier
            .transition(
//                CSSTransition("width", transitionDuration),
//                CSSTransition("height", transitionDuration),
                Transition.of("opacity", transitionDuration)
            )
            .opacity(if (isVisible) 1f else 0f)
//            .thenIf(!isVisible, Modifier.height(Height.Initial))
//            .thenIf(!isVisible, Modifier.width(0.px))
            .then(modifier),
    ) {
//        if (contentVisible) {
        content()
//        }
    }
}