package cometes.components.feature.organization

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import app.cometes.shared.frontend.feature.organization.presentation.NewOrganizationEvent
import app.cometes.shared.frontend.feature.organization.presentation.NewOrganizationIntent
import app.cometes.shared.frontend.feature.organization.presentation.NewOrganizationPresenter
import app.cometes.shared.frontend.network.readAsUrl
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.mdi.IconStyle
import com.varabyte.kobweb.silk.components.icons.mdi.MdiAdd
import com.varabyte.kobweb.silk.components.text.SpanText
import cometes.components.common.CometesDialog
import cometes.components.common.DivText
import cometes.components.common.HorizontalSpacer
import cometes.components.common.VerticalSpacer
import cometes.components.feature.organization.component.ImageUploadInput
import cometes.style.RadiusLarge
import cometes.style.SpaceMedium
import cometes.style.SpaceXSmall
import cometes.style.TypographyBodyMedium
import cometes.style.TypographyLabelMedium
import cometes.style.TypographyTitleMedium
import cometes.style.system.ButtonPlainVariant
import cometes.style.system.CometesButton
import cometes.style.system.CometesSurface
import cometes.style.system.SurfaceBackgroundColorVariant
import cometes.style.system.input.CometesTextArea
import cometes.style.system.input.CometesTextField
import org.jetbrains.compose.web.css.minus
import org.jetbrains.compose.web.css.px

@Composable
fun NewOrganizationDialog(
    onDismiss: () -> Unit,
    modifier: Modifier = Modifier
) {
    val presenter = remember {
        NewOrganizationPresenter { event ->
            when (event) {
                is NewOrganizationEvent.Error -> {
                    // TODO :(
                }
            }
        }
    }
    val state = presenter.presenterState()

    var orgImageUrl: String? by remember { mutableStateOf(null) }
    LaunchedEffect(state.organizationImage) {
        val file = state.organizationImage ?: return@LaunchedEffect
        orgImageUrl = file.readAsUrl()
    }

    CometesDialog(
        title = {
            Column(
                horizontalAlignment = Alignment.CenterHorizontally,
                modifier = Modifier.fillMaxWidth()
            ) {
                SpanText(
                    text = "Create new organization",
                    modifier = Modifier.then(TypographyTitleMedium)
                )
                VerticalSpacer(SpaceXSmall)
                SpanText(
                    text = "Create a new organization to manage desks and reservations.",
                    modifier = Modifier
                        .then(TypographyBodyMedium)
                        .fontWeight(FontWeight.Light)
                        .opacity(0.9f)
                )
            }
        },
        onDismiss = onDismiss,
        submitButtons = {
            CometesButton(
                enabled = !state.isLoading,
                onClick = { onDismiss() },
                variant = ButtonPlainVariant,
            ) { SpanText("Cancel") }

            HorizontalSpacer(SpaceMedium)

            CometesButton(
                enabled = !state.isLoading,
                onClick = { state.onIntent(NewOrganizationIntent.Confirm) },
            ) { SpanText("Confirm") }
        },
        modifier = modifier
    ) {
        Box(
            contentAlignment = Alignment.Center,
            modifier = Modifier.fillMaxSize()
        ) {
            Column {
                CometesTextField(
                    value = state.organizationName,
                    onValueChange = { state.onIntent(NewOrganizationIntent.SetName(it)) },
                    label = "Organization name",
                    placeholder = "Space Inc.",
                    modifier = Modifier.fillMaxWidth()
                )

                VerticalSpacer(SpaceMedium)

                CometesTextArea(
                    value = state.organizationDescription,
                    onValueChange = { state.onIntent(NewOrganizationIntent.SetDescription(it)) },
                    label = "Description",
                    placeholder = "Riding comets since 2023",
                    modifier = Modifier.fillMaxWidth()
                )

                VerticalSpacer(SpaceMedium)

                DivText("Organization logo", TypographyLabelMedium)
                VerticalSpacer(SpaceXSmall)
                Row(verticalAlignment = Alignment.CenterVertically) {
                    ImageUploadInput(
                        accept = "image/png, image/jpeg, image/jpg",
                        onInput = { file -> state.onIntent(NewOrganizationIntent.SetFile(file)) },
                    ) {
                        val size = 64.px
                        CometesSurface(
                            variant = SurfaceBackgroundColorVariant,
                            modifier = Modifier
                                .size(size)
                                .borderRadius(RadiusLarge)
                                .padding(SpaceXSmall)
                        ) {
                            val url = orgImageUrl
                            if (url != null) {
                                Image(
                                    src = url,
                                    modifier = Modifier
                                        .borderRadius(RadiusLarge.minus(SpaceXSmall))
                                        .fillMaxSize(),
                                )
                            } else {
                                MdiAdd(
                                    style = IconStyle.ROUNDED,
                                    modifier = Modifier
                                        .align(Alignment.Center)
                                        .fontSize(32.px)
                                        .opacity(0.6f)
                                )
                            }
                        }
                    }

                    HorizontalSpacer(SpaceMedium)

                    Column {
                        DivText(
                            text = "Upload a logo for your organization",
                            modifier = TypographyBodyMedium.fontWeight(FontWeight.Light)
                        )
                        DivText(
                            text = "PNG or JPG, max 3MB",
                            modifier = TypographyBodyMedium.fontWeight(FontWeight.Light)
                        )
                    }
                }
            }
        }
    }
}