package app.cometes.shared.frontend.base.error

import app.cometes.shared.frontend.base.ErrorResult

sealed class AuthError(
    message: String?,
    innerException: Exception?
) : ErrorResult(message, innerException) {

    class NoUserLoggedIn(
        val cause: Cause,
        exception: Exception?
    ) : AuthError("No user logged in", exception) {
        enum class Cause { FirebaseAuth, PersonDoesNotExit }

        constructor(cause: Cause) : this(cause, null)
    }

    class InvalidCredentials(
        innerException: Exception?
    ) : AuthError("Invalid credentials", innerException) {
        constructor() : this(null)
    }

    class PersonDoesNotExist(
        message: String?,
        innerException: Exception?
    ) : AuthError(message, innerException) {
        constructor(message: String?) : this(message, null)
    }

    object MagicEmailLoginNotStarted : AuthError(
        "No email cached when calling AuthRepository::confirmLogin",
        null
    )

    class EmailLinkNotSent(
        message: String?,
        innerException: Exception?
    ) : AuthError(message, innerException) {
        constructor(message: String?) : this(message, null)
    }

    class TimedOut(exception: Exception?) : AuthError(
        message = "Timed out due too many Firebase requests",
        innerException = exception
    )
}

fun NoUserLoggedInPersonError() =
    AuthError.NoUserLoggedIn(AuthError.NoUserLoggedIn.Cause.PersonDoesNotExit)