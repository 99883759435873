package app.cometes.shared.frontend.feature.desk

import app.cometes.shared.frontend.feature.desk.data.DeskRemoteSource
import app.cometes.shared.frontend.feature.desk.infrastructure.DeskRemoteSourceImpl
import org.koin.core.module.dsl.singleOf
import org.koin.dsl.bind
import org.koin.dsl.module

val featureDesk = module {
    singleOf(::DeskRemoteSourceImpl) bind DeskRemoteSource::class
}