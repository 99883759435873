package cometes.pages.organization

import androidx.compose.runtime.Composable
import app.cometes.shared.frontend.base.resource.Resource
import app.cometes.shared.frontend.base.resource.loadResourceIfEmpty
import app.cometes.shared.frontend.feature.organization.data.currentOrganization
import app.cometes.shared.frontend.feature.organization.data.currentOrganizationDetailResource
import app.cometes.shared.frontend.feature.organization.data.currentOrganizationMembership
import app.cometes.shared.frontend.feature.organization.domain.model.Organization
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationDetail
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationMember
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.icons.mdi.IconStyle
import com.varabyte.kobweb.silk.components.icons.mdi.MdiDesk
import com.varabyte.kobweb.silk.components.icons.mdi.MdiEdit
import com.varabyte.kobweb.silk.components.icons.mdi.MdiGroup
import com.varabyte.kobweb.silk.components.icons.mdi.MdiLocationPin
import com.varabyte.kobweb.silk.components.icons.mdi.MdiMeetingRoom
import com.varabyte.kobweb.silk.components.icons.mdi.MdiWarningAmber
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.breakpoint.ResponsiveValues
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import cometes.components.Authenticated
import cometes.components.common.CometesCard
import cometes.components.common.DivText
import cometes.components.common.HorizontalSpacer
import cometes.components.common.ProgressIndicator
import cometes.components.common.RoundedImage
import cometes.components.common.ThemeToggleButton
import cometes.components.common.VerticalSpacer
import cometes.components.layout.MainPageLayout
import cometes.style.RadiusPill
import cometes.style.SpaceMedium
import cometes.style.SpaceMediumSmall
import cometes.style.SpaceSmall
import cometes.style.TypographyBodyMedium
import cometes.style.TypographyTitleLarge
import cometes.style.TypographyTitleMedium
import cometes.style.system.ButtonPrimaryVariant
import cometes.style.system.ButtonTextVariant
import cometes.style.system.CometesButton
import cometes.style.system.CometesImageButton
import cometes.style.system.CometesSurface
import cometes.style.system.SurfacePrimaryVariant
import cometes.util.NavigationPath
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px

@Page
@Composable
fun OrganizationDetailPage() {
    Authenticated {
        val organization = currentOrganization() ?: return DivText("No org :(")
        val membership = currentOrganizationMembership() ?: return DivText("No membership :(")
        val organizationDetailResource = loadResourceIfEmpty { currentOrganizationDetailResource() }

//        val subscriptionStatus = loadResourceIfEmpty { subscriptionResource() }

        OrganizationDetailContent(
            organization = organization,
            membership = membership,
            detailResource = organizationDetailResource
        )
    }
}

@Composable
fun OrganizationDetailContent(
    organization: Organization,
    membership: OrganizationMember,
    detailResource: Resource<OrganizationDetail>
) {
    val pageContext = rememberPageContext()

    MainPageLayout(
        title = "Organization overview",
        utilRow = { ThemeToggleButton() }
    ) {
        when (detailResource) {
            is Resource.Value -> {
                Column(Modifier.fillMaxSize()) {
                    OrganizationHeader(
                        organizationDetail = detailResource.data,
                        onEditClick = { },
                        modifier = Modifier.fillMaxWidth()
                    )

                    VerticalSpacer(SpaceMedium)

                    SimpleGrid(
                        numColumns = ResponsiveValues(1, 1, 2, 2, 4),
                        modifier = Modifier.fillMaxWidth().styleModifier { property("grid-gap", SpaceMedium) }
                    ) {
                        ResourceCard(
                            icon = { MdiLocationPin(Modifier.fontSize(1.5.cssRem)) },
                            count = detailResource.data.locations.size,
                            label = "Locations",
                            onViewAllClick = { pageContext.router.navigateTo(NavigationPath.Locations) },
                            modifier = Modifier.fillMaxSize()
                        )

                        ResourceCard(
                            icon = { MdiDesk(Modifier.fontSize(1.5.cssRem)) },
                            count = detailResource.data.desks.size,
                            label = "Desks",
                            onViewAllClick = { pageContext.router.navigateTo(NavigationPath.Desks) },
                            modifier = Modifier.fillMaxSize()
                        )

                        ResourceCard(
                            icon = { MdiMeetingRoom(Modifier.fontSize(1.5.cssRem)) },
                            count = detailResource.data.rooms.size,
                            label = "Rooms",
                            onViewAllClick = { pageContext.router.navigateTo(NavigationPath.Rooms) },
                            modifier = Modifier.fillMaxSize()
                        )

                        ResourceCard(
                            icon = { MdiGroup(Modifier.fontSize(1.5.cssRem)) },
                            count = detailResource.data.members.size,
                            label = "Members",
                            onViewAllClick = { pageContext.router.navigateTo(NavigationPath.Members) },
                            modifier = Modifier.fillMaxSize()
                        )
                    }

                    VerticalSpacer(SpaceMedium)
                }
            }

            is Resource.Loading -> {
                LoadingPage()
            }

            is Resource.Error -> MdiWarningAmber(Modifier.align(Alignment.Center), style = IconStyle.ROUNDED)
        }
    }
}

@Composable
private fun OrganizationHeader(
    organizationDetail: OrganizationDetail,
    onEditClick: () -> Unit,
    modifier: Modifier = Modifier,
) {
    val breakpoint = rememberBreakpoint()
    val membership = currentOrganizationMembership() ?: return

    CometesCard(modifier) {
        Row(Modifier.fillMaxWidth()) {
            RoundedImage(organizationDetail.organization.imageUrl, Modifier.size(48.px))
            HorizontalSpacer(SpaceMedium)
            Column {
                DivText(organizationDetail.organization.name, TypographyTitleMedium)
                DivText(organizationDetail.organization.description, TypographyBodyMedium.opacity(0.8))
            }
            Spacer()
            if (membership.isAdmin) {
                when (breakpoint) {
                    Breakpoint.ZERO, Breakpoint.SM -> {
                        CometesImageButton(
                            onClick = { onEditClick() },
                            variant = ButtonPrimaryVariant,
                            content = { MdiEdit(style = IconStyle.ROUNDED) }
                        )
                    }

                    Breakpoint.MD -> {
                        CometesButton(onClick = { onEditClick() }) {
                            MdiEdit(style = IconStyle.ROUNDED)
                            HorizontalSpacer(SpaceMedium)
                            DivText("Edit")
                        }
                    }

                    Breakpoint.LG, Breakpoint.XL -> {
                        CometesButton(onClick = { onEditClick() }) {
                            MdiEdit(style = IconStyle.ROUNDED)
                            HorizontalSpacer(SpaceMedium)
                            DivText("Edit organization")
                        }
                    }
                }
            }
        }
    }
}

@Composable
private fun ResourceCard(
    icon: @Composable () -> Unit,
    count: Int,
    label: String,
    onViewAllClick: () -> Unit,
    modifier: Modifier = Modifier,
) {
    CometesCard(modifier) {
        Column(
            horizontalAlignment = Alignment.CenterHorizontally,
            modifier = Modifier.fillMaxSize()
        ) {
            Row(verticalAlignment = Alignment.CenterVertically) {
                CometesSurface(
                    variant = SurfacePrimaryVariant,
                    modifier = Modifier
                        .borderRadius(RadiusPill)
                        .padding(SpaceMediumSmall)
                ) {
                    icon()
                }

                HorizontalSpacer(SpaceMedium)

                Column(horizontalAlignment = Alignment.CenterHorizontally) {
                    DivText(text = count.toString(), modifier = TypographyTitleLarge)
                    DivText(label, TypographyBodyMedium.opacity(0.6))
                }
            }

            VerticalSpacer(SpaceSmall)

            CometesButton(
                onClick = { onViewAllClick() },
                variant = ButtonTextVariant
            ) {
                DivText("View All")
            }
        }
    }
}

@Composable
private fun LoadingPage() {
    Box(Modifier.fillMaxSize()) {
        ProgressIndicator(Modifier.align(Alignment.Center))
    }
}