package cometes.components.feature.organization

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import app.cometes.shared.frontend.feature.conferenceRoom.domain.createConferenceRoomMutation
import app.cometes.shared.frontend.feature.conferenceRoom.domain.entity.ConferenceRoom
import app.cometes.shared.frontend.feature.conferenceRoom.domain.removeConferenceRoomMutation
import app.cometes.shared.frontend.feature.conferenceRoom.domain.updateConferenceRoomMutation
import app.cometes.shared.frontend.feature.reservation.domain.model.Location
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.silk.components.icons.mdi.MdiDelete
import com.varabyte.kobweb.silk.components.icons.mdi.MdiInfo
import com.varabyte.kobweb.silk.components.text.SpanText
import cometes.components.common.CometesDialog
import cometes.components.common.CometesDropDown
import cometes.components.common.CometesLoadingButton
import cometes.components.common.DivText
import cometes.components.common.PopoverMenuItem
import cometes.components.common.VerticalSpacer
import cometes.style.BorderSmall
import cometes.style.SpaceMedium
import cometes.style.SpaceMediumLarge
import cometes.style.TextSecondaryOpacity
import cometes.style.TypographyBodySmall
import cometes.style.TypographyTitleSmall
import cometes.style.system.ButtonAlertVariant
import cometes.style.system.ButtonTextVariant
import cometes.style.system.CometesButton
import cometes.style.system.input.CometesNumberField
import cometes.style.system.input.CometesTextField
import cometes.style.system.input.CometesTextFieldSurfaceVariant
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.percent

@Composable
fun EditRoomDialog(
    room: ConferenceRoom?,
    locations: List<Location>,
    onDismiss: () -> Unit,
) {
    val actionCoroutineScope = rememberCoroutineScope()
    var isLoading by remember { mutableStateOf(false) }

    var roomName by remember(room) { mutableStateOf(room?.name ?: "") }
    var roomDescription by remember(room) { mutableStateOf(room?.description ?: "") }
    var roomCapacity by remember(room) { mutableStateOf(room?.capacity ?: 1) }
    var selectedLocation by remember(room) { mutableStateOf(room?.location ?: locations.first()) }

    val createRoom = if (room == null) {
        createConferenceRoomMutation(
            locationId = selectedLocation.id,
            name = roomName,
            description = roomDescription,
            capacity = roomCapacity
        )
    } else null

    val updateRoom = if (room != null) {
        updateConferenceRoomMutation(
            originalRoom = room,
            name = roomName,
            description = roomDescription,
            capacity = roomCapacity
        )
    } else null

    val removeRoom = if (room != null) {
        removeConferenceRoomMutation(room.id)
    } else null

    fun save() {
        isLoading = true
        actionCoroutineScope.launch {
            try {
                createRoom?.execute()
                updateRoom?.execute()
            } finally {
                isLoading = false
                onDismiss()
            }
        }
    }

    fun removeRoom() {
        isLoading = true
        actionCoroutineScope.launch {
            try {
                removeRoom?.execute()
            } finally {
                isLoading = false
                onDismiss()
            }
        }
    }

    CometesDialog(
        onDismiss = onDismiss,
        title = if (room == null) "Add new conference room" else "Edit conference room ${room.name}",
        subTitle = if (room == null) "Add a new conference room to a location" else null,
        submitButtons = {
            CometesButton(
                onClick = { onDismiss() },
                variant = ButtonTextVariant,
            ) { SpanText("Cancel") }

            CometesLoadingButton(
                isLoading = isLoading,
                onClick = { save() },
            ) {
                if (room == null) SpanText("Create")
                else SpanText("Save")
            }
        },
        modifier = Modifier
            .fillMaxWidth(80.percent)
            .maxWidth(32.cssRem),
    ) {
        Column(
            modifier = Modifier.fillMaxSize()
        ) {
            CometesTextField(
                value = roomName,
                onValueChange = { roomName = it },
                label = "Room name",
                variant = CometesTextFieldSurfaceVariant,
                modifier = Modifier.fillMaxWidth()
            )

            VerticalSpacer(SpaceMedium)

            CometesTextField(
                value = roomDescription,
                onValueChange = { roomDescription = it },
                label = "Description",
                variant = CometesTextFieldSurfaceVariant,
                modifier = Modifier.fillMaxWidth()
            )

            VerticalSpacer(SpaceMedium)

            CometesNumberField(
                value = roomCapacity,
                onValueChange = { roomCapacity = it.toInt().coerceAtLeast(1) },
                label = "Capacity",
                variant = CometesTextFieldSurfaceVariant,
                modifier = Modifier.fillMaxWidth()
            )

            if (room == null) {
                VerticalSpacer(SpaceMedium)
                CometesDropDown(
                    mainContent = { DivText(text = selectedLocation.name, TypographyTitleSmall) },
                    label = "Location",
                    enabled = locations.size > 1,
                    modifier = Modifier.fillMaxWidth(),
                ) {
                    for (location in locations)
                        PopoverMenuItem(
                            title = location.name,
                            onClick = { selectedLocation = location }
                        )
                }
            }

            if (room != null) {
                VerticalSpacer(SpaceMediumLarge)
                Row(
                    horizontalArrangement = Arrangement.spacedBy(SpaceMedium),
                    modifier = Modifier.fillMaxWidth().border(BorderSmall)
                ) {
                    MdiInfo(Modifier.opacity(TextSecondaryOpacity))
                    SpanText(
                        text = "Removing this conference room will remove all related resources, including reservations. " +
                                "This action is permanent and cannot be undone.",
                        modifier = TypographyBodySmall.opacity(TextSecondaryOpacity)
                    )
                    CometesLoadingButton(
                        isLoading = isLoading,
                        onClick = { removeRoom() },
                        variant = ButtonAlertVariant,
                        modifier = Modifier.align(Alignment.CenterVertically)
                    ) {
                        MdiDelete()
                        SpanText("Remove")
                    }
                }
                VerticalSpacer(SpaceMediumLarge)
            }
        }
    }
}