package app.cometes.shared.feature.organization.infrastructure.model

import kotlinx.serialization.Serializable

@Serializable
data class NewOrganizationBody(
    val name: String,
    val description: String,
)

@Serializable
data class UpdateOrganizationBody(
    val name: String,
    val description: String,
)

@Serializable
data class CreateInvitationBody(val targetEmail: String)

@Serializable
data class ClaimInvitationBody(val token: String)

@Serializable
data class NewLocationBody(
    val name: String,
    val address: String?
)

@Serializable
data class UpdateLocationBody(
    val name: String,
    val address: String?
)