
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import app.cometes.shared.frontend.initKoin
import app.cometes.shared.frontend.presentation.CometesColor
import com.varabyte.kobweb.compose.css.BoxSizing
import com.varabyte.kobweb.compose.css.FontVariationSettings
import com.varabyte.kobweb.compose.css.UserSelect
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.bottom
import com.varabyte.kobweb.compose.ui.modifiers.boxSizing
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontVariationSettings
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.left
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.right
import com.varabyte.kobweb.compose.ui.modifiers.top
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.compose.ui.modifiers.userSelect
import com.varabyte.kobweb.core.App
import com.varabyte.kobweb.core.init.InitKobweb
import com.varabyte.kobweb.core.init.InitKobwebContext
import com.varabyte.kobweb.silk.SilkApp
import com.varabyte.kobweb.silk.init.InitSilk
import com.varabyte.kobweb.silk.init.InitSilkContext
import com.varabyte.kobweb.silk.init.registerStyleBase
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.colors.palette.background
import com.varabyte.kobweb.silk.theme.colors.palette.color
import com.varabyte.kobweb.silk.theme.colors.palette.overlay
import cometes.components.layout.PageNotFoundContent
import cometes.style.TypographyBodyMedium
import cometes.style.system.animation.CometesTransitionOf
import dev.gitlive.firebase.FirebaseApp
import kotlinx.browser.localStorage
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div
import org.koin.mp.KoinPlatformTools
import org.w3c.dom.get

@InitKobweb
fun initKobweb(ctx: InitKobwebContext) {
    initKoin { modules(webModule) }
    KoinPlatformTools.defaultContext().get().get<FirebaseApp>() // Init firebase

    ctx.router.setErrorHandler {
        PageNotFoundContent(Modifier.fillMaxSize())
    }
}

@Suppress("unused")
@App
@Composable
fun CometesApp(content: @Composable () -> Unit) {
    SilkApp {
        val colorMode = ColorMode.current
        LaunchedEffect(colorMode) { // Relaunched every time the color mode changes
            localStorage.setItem(ColorModeKey, colorMode.name)
        }

        Div(attrs = { id("root") }) {
            content()
        }
    }
}


@InitSilk
fun initSiteStyles(ctx: InitSilkContext) {
    val colorMode = ColorMode.valueOf(
        localStorage[ColorModeKey] ?: ColorMode.DARK.name
    )

    ctx.config.initialColorMode = colorMode

    with(ctx.stylesheet) {
        registerStyleBase("html, body") {
            Modifier
                .margin(0.px)
                .padding(0.px)
                .height(100.percent)
        }

        registerStyleBase("*") { Modifier.boxSizing(BoxSizing.BorderBox) }

        registerStyleBase("#root") {
            Modifier
                .position(Position.Absolute)
                .top(0.px).bottom(0.px).left(0.px).right(0.px)
                .then(TypographyBodyMedium)
                .transition(CometesTransitionOf("background-color"))
        }

        registerStyleBase(".silk-colors_light #root") {
            Modifier
                .backgroundColor(Color.argb(CometesColor.light_background))
                .color(Color.argb(CometesColor.light_onBackground))
        }

        registerStyleBase(".silk-colors_dark #root") {
            Modifier
                .backgroundColor(Color.argb(CometesColor.dark_background))
                .color(Color.argb(CometesColor.dark_onBackground))
        }

        registerStyleBase("body, input, textarea") {
            Modifier.fontFamily("Inter var", "sans-serif")
        }

        registerStyleBase(".material-icons") {
            Modifier
                .fontVariationSettings(
                    FontVariationSettings.Axis("FILL", 0),
                    FontVariationSettings.Axis("wght", 400),
                    FontVariationSettings.Axis("GRAD", 0),
                    FontVariationSettings.Axis("opsz", 18),
                )
                .fontSize(1.2.cssRem)
                .userSelect(UserSelect.None)
        }
        registerStyleBase(".material-icons-round") {
            Modifier
                .fontVariationSettings(
                    FontVariationSettings.Axis("FILL", 0),
                    FontVariationSettings.Axis("wght", 400),
                    FontVariationSettings.Axis("GRAD", 0),
                    FontVariationSettings.Axis("opsz", 18),
                )
                .fontSize(1.2.cssRem)
                .userSelect(UserSelect.None)
        }
    }

    with(ctx.theme.palettes) {
        light.overlay = Color.argb(CometesColor.light_onBackground).copyf(alpha = 0.3f)
        dark.overlay = Color.argb(CometesColor.dark_onBackground).copyf(alpha = 0.2f)

        light.color = Color.argb(CometesColor.light_onBackground)
        dark.color = Color.argb(CometesColor.dark_onBackground)

        light.background = Color.argb(CometesColor.light_background)
        dark.background = Color.argb(CometesColor.dark_background)
    }
}

private const val ColorModeKey = "ColorModeKey"