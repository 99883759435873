package cometes.pages.organization

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import app.cometes.shared.frontend.base.resource.Resource
import app.cometes.shared.frontend.base.resource.loadResourceIfEmpty
import app.cometes.shared.frontend.feature.organization.data.currentOrganizationLocationListResource
import app.cometes.shared.frontend.feature.organization.data.currentOrganizationMembership
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationMember
import app.cometes.shared.frontend.feature.reservation.domain.model.Location
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.icons.mdi.MdiAdd
import com.varabyte.kobweb.silk.components.icons.mdi.MdiEdit
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import cometes.components.Authenticated
import cometes.components.common.DivText
import cometes.components.common.HorizontalSpacer
import cometes.components.common.ProgressIndicator
import cometes.components.common.ThemeToggleButton
import cometes.components.common.VerticalSpacer
import cometes.components.feature.organization.EditLocationDialog
import cometes.components.feature.organization.component.ResourceList
import cometes.components.layout.MainPageLayout
import cometes.style.SpaceLarge
import cometes.style.SpaceSmall
import cometes.style.TypographyBodyMedium
import cometes.style.TypographyBodySmall
import cometes.style.system.CometesButton
import cometes.style.system.CometesImageButton

@Page
@Composable
fun LocationsPage() {
    Authenticated {
        val userMembership = currentOrganizationMembership() ?: return@Authenticated
        val locationResource = loadResourceIfEmpty { currentOrganizationLocationListResource() }

        MainPageLayout(
            title = "Locations",
            utilRow = { ThemeToggleButton() },
            modifier = Modifier.fillMaxSize()
        ) {
            when (locationResource) {
                is Resource.Value -> {
                    LocationsPageContent(
                        userMembership = userMembership,
                        locations = locationResource.data,
                        modifier = Modifier.fillMaxWidth(),
                    )
                }

                is Resource.Loading -> ProgressIndicator(Modifier.align(Alignment.Center))
                is Resource.Error -> DivText("Error :(")
            }
        }
    }
}

@Composable
private fun LocationsPageContent(
    userMembership: OrganizationMember,
    locations: List<Location>,
    modifier: Modifier = Modifier
) {
    var newLocationDialogVisible by remember { mutableStateOf(false) }
    var editedLocation by remember { mutableStateOf<Location?>(null) }

    Column(
        modifier = Modifier.fillMaxSize()
    ) {
        if (userMembership.isAdmin) {
            CometesButton(
                onClick = { newLocationDialogVisible = true },
                modifier = Modifier.align(Alignment.End)
            ) {
                MdiAdd()
                HorizontalSpacer(SpaceSmall)
                DivText("New location")
            }
        }

        VerticalSpacer(SpaceLarge)

        ResourceList(
            items = locations,
            modifier = modifier,
            gridBreakpoint = Breakpoint.MD,
            gridColumns = 3
        ) { location ->
            LocationItem(
                location = location,
                isEditable = userMembership.isAdmin,
                onEditClick = { editedLocation = location },
                modifier = modifier.fillMaxSize()
            )
        }
    }

    if (editedLocation != null || newLocationDialogVisible) {
        EditLocationDialog(
            location = editedLocation,
            onDismiss = {
                editedLocation = null
                newLocationDialogVisible = false
            }
        )
    }
}

@Composable
private fun LocationItem(
    location: Location,
    isEditable: Boolean,
    onEditClick: () -> Unit,
    modifier: Modifier = Modifier
) {
    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = modifier
    ) {
        Column {
            DivText(location.name, TypographyBodyMedium)
            location.address?.let { address ->
                DivText(address, TypographyBodySmall.opacity(0.6))
            }
        }
        Spacer()
        if (isEditable) {
            CometesImageButton(
                onClick = { onEditClick() },
                modifier = Modifier.align(Alignment.Top)
            ) { MdiEdit() }
        }
    }
}
