package app.cometes.shared.frontend.feature.organization.domain.usecase

import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.usecase.UseCaseResult
import app.cometes.shared.frontend.feature.organization.domain.repository.OrganizationInvitationRepository

interface SetPendingInvitationUseCase : UseCaseResult<String, Unit>

internal class SetPendingInvitationUseCaseImpl(
    private val organizationInvitationRepository: OrganizationInvitationRepository
) : SetPendingInvitationUseCase {

    override suspend fun invoke(params: String): Result<Unit> {
        organizationInvitationRepository.setPendingInvitation(params)
        return Result.Success(Unit)
    }
}