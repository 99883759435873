package app.cometes.shared.feature.room.infrastructure.model

import app.cometes.shared.feature.reservation.infrastructure.model.LocationDto
import kotlinx.serialization.Serializable

@Serializable
data class ConferenceRoomDto(
    val id: Long,
    val location: LocationDto,
    val conferenceRoomName: String,
    val conferenceRoomDescription: String,
    val conferenceRoomCapacity: Int,
)