package app.cometes.shared.frontend.util

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import org.koin.core.annotation.KoinInternalApi
import org.koin.core.parameter.ParametersDefinition
import org.koin.core.qualifier.Qualifier
import org.koin.core.scope.Scope
import org.koin.mp.KoinPlatformTools

@OptIn(KoinInternalApi::class)
@Composable
internal inline fun <reified T> rememberKoinDependency(
    qualifier: Qualifier? = null,
    scope: Scope = KoinPlatformTools.defaultContext().get().scopeRegistry.rootScope,
    noinline parameters: ParametersDefinition? = null,
): T = remember(qualifier, parameters) {
    scope.get(qualifier, parameters)
}