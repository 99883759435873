package app.cometes.shared.frontend.base

/**
 * ```
 * val result = Result.Success("Hello World!")
 * val error = Result.Error(ErrorResult("Some error text"))
```
 */

typealias EmptyResult = Result<Unit>

sealed class Result<out T> {

    data class Success<out T>(val data: T) : Result<T>()

    data class Error<out T>(val error: ErrorResult, val data: T? = null) : Result<T>()

    override fun toString(): String {
        return when (this) {
            is Success -> "Success[data=$data]"
            is Error -> "Error[exception=${error.throwable}"
        }
    }

    /**
     * Returns the encapsulated value if this instance represents [Success] or cached data when available in [Error] state
     */
    fun getOrNull(): T? = when (this) {
        is Success -> data
        is Error -> data
    }

    /**
     * Returns the encapsulated error if this instance represents [Error]
     */
    fun getErrorOrNull(): ErrorResult? = when (this) {
        is Success -> null
        is Error -> error
    }

    fun unwrap(): T = when (this) {
        is Success -> data
        is Error -> throw ErrorResultException(error)
    }

}

abstract class ErrorResult(
    var message: String? = null,
    open val throwable: Throwable? = null
)

class ErrorResultException(val error: ErrorResult) : Exception(error.message, error.throwable)
class UnhandledExceptionError(throwable: Throwable) : ErrorResult(throwable = throwable)


// === HELPER FUNCTIONS

/** Transform Result data object */
inline fun <T : Any, R : Any> Result<T>.map(transform: (T) -> R) =
    when (this) {
        is Result.Success -> Result.Success(transform(data))
        is Result.Error -> Result.Error(error, data?.let(transform))
    }

fun <T : Any> Result<T>.mapToEmpty() =
    when (this) {
        is Result.Success -> Result.Success(Unit)
        is Result.Error -> Result.Error(error, Unit)
    }


/**
 * Unwrap success result data or return first error
 */
fun <T : Any> List<Result<T>>.unwrapItems(): Result<List<T>> {
    // If everything is success, return data
    if (all { it is Result.Success })
        return Result.Success(map { (it as Result.Success).data })

    // Otherwise return first error
    val error = asSequence().filterIsInstance<Result.Error<T>>().first()
    return Result.Error(error.error)
}

