package app.cometes.shared.frontend.feature.reservation.infrastructure.source

import app.cometes.shared.feature.reservation.infrastructure.model.CreateReservationBody
import app.cometes.shared.feature.reservation.infrastructure.model.ReservationDayDto
import app.cometes.shared.feature.reservation.infrastructure.model.ReservationDto
import app.cometes.shared.feature.reservation.infrastructure.model.ReservationSelectionBody
import app.cometes.shared.frontend.base.Result
import app.cometes.shared.frontend.base.error.BackendException
import app.cometes.shared.frontend.base.error.runCatchingNetworkExceptions
import app.cometes.shared.frontend.feature.reservation.data.source.ReservationRemoteSource
import app.cometes.shared.frontend.feature.reservation.domain.model.ReservationError.MaximumReached
import app.cometes.shared.frontend.feature.reservation.domain.model.ReservationError.NotFound
import app.cometes.shared.frontend.feature.reservation.domain.model.ReservationError.ReservationAlreadyExists
import app.cometes.shared.frontend.feature.reservation.domain.model.ReservationError.UnauthorizedModification
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.delete
import io.ktor.client.request.get
import io.ktor.client.request.parameter
import io.ktor.client.request.post
import io.ktor.client.request.setBody

internal class ReservationRemoteSourceImpl(
    private val client: HttpClient
) : ReservationRemoteSource {

    override suspend fun fetchHome(organizationId: Long): Result<List<ReservationDayDto>> =
        runCatchingNetworkExceptions {
            val res = client.get("/organization/${organizationId}/reservation/home")
            val resBody = res.body<List<ReservationDayDto>>()
            Result.Success(resBody)
        }

    override suspend fun getReservations(
        organizationId: Long,
        params: ReservationSelectionBody
    ): Result<List<ReservationDto>> = runCatchingNetworkExceptions {
        val res = client.get("/organization/${organizationId}/reservation") {
            parameter(ReservationSelectionBody.ParamNameStartDate, params.startDate)
            parameter(ReservationSelectionBody.ParamNameEndDate, params.endDate)
            parameter(ReservationSelectionBody.ParamNameDeskIds, params.deskIds?.asArrayParam())
            parameter(ReservationSelectionBody.ParamNameDays, params.days?.asArrayParam())
        }
        val resBody = res.body<List<ReservationDto>>()

        Result.Success(resBody)
    }

    override suspend fun createReservation(
        organizationId: Long,
        reservation: CreateReservationBody
    ): Result<ReservationDto> = try {
        runCatchingNetworkExceptions {
            val res = client.post("/organization/${organizationId}/reservation") {
                setBody(reservation)
            }
            val resBody = res.body<ReservationDto>()

            Result.Success(resBody)
        }
    } catch (e: BackendException) {
        when (e.error.type) {
            MaximumReached.type -> Result.Error(MaximumReached(e))
            ReservationAlreadyExists.type -> Result.Error(ReservationAlreadyExists(e))
            else -> throw e
        }
    }

    override suspend fun cancelReservation(
        organizationId: Long,
        reservationId: Long
    ): Result<Unit> = try {
        runCatchingNetworkExceptions {
            client.delete("/organization/${organizationId}/reservation/${reservationId}")
            Result.Success(Unit)
        }
    } catch (e: BackendException) {
        when (e.error.type) {
            NotFound.type -> Result.Error(NotFound(e))
            UnauthorizedModification.type -> Result.Error(UnauthorizedModification(e))
            else -> throw e
        }
    }
}


private fun <T> List<T>.asArrayParam() = joinToString(",")