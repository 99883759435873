package cometes.components.feature.organization.subscription

import androidx.compose.runtime.Composable
import app.cometes.shared.frontend.base.resource.isLoading
import app.cometes.shared.frontend.base.resource.loadResourceIfEmpty
import app.cometes.shared.frontend.feature.billing.data.customerPortalLinkResource
import app.cometes.shared.frontend.feature.billing.domain.model.SubscriptionStatus
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.icons.fa.FaPenToSquare
import com.varabyte.kobweb.silk.components.text.SpanText
import cometes.components.common.CometesLoadingButton
import cometes.components.common.HorizontalSpacer
import cometes.components.common.ItemList
import cometes.components.common.LabeledTextRow
import cometes.components.common.VerticalSpacer
import cometes.style.SpaceLarge
import cometes.style.SpaceSmall
import kotlinx.datetime.toJSDate

@Composable
fun ActiveSubscriptionContent(
    subscriptionState: SubscriptionStatus,
    modifier: Modifier = Modifier
) {
    Column(modifier = modifier) {
        val renewDate = subscriptionState.renewAt
            ?.toJSDate()
            ?.toLocaleDateString()

        VerticalSpacer(SpaceLarge)

        ItemList(
            buildList {
                if (subscriptionState.wilCancelAtPeriodEnd && renewDate != null)
                    add("End of subscription:" to renewDate)
                else if (renewDate != null)
                    add("Next payment:" to renewDate)

                add("Number of user slots:" to subscriptionState.quantity.toString())
            }
        ) { (label, value) -> LabeledTextRow(label, value, Modifier.fillMaxWidth()) }

        VerticalSpacer(SpaceLarge)

        CustomerPortalManageButton("Manage", Modifier.align(Alignment.End))
    }
}

@Composable
private fun CustomerPortalManageButton(
    text: String,
    modifier: Modifier = Modifier,
) {
    val pageContext = rememberPageContext()
    val customerPortalLink = loadResourceIfEmpty { customerPortalLinkResource() }

    fun navigateToCustomerPortal() {
        val link = customerPortalLink.data
        if (link != null) {
            pageContext.router.navigateTo(
                pathQueryAndFragment = link,
                openExternalLinksStrategy = OpenLinkStrategy.IN_PLACE
            )
        }
    }

    CometesLoadingButton(
        isLoading = customerPortalLink.isLoading,
        onClick = { navigateToCustomerPortal() },
        enabled = !customerPortalLink.isLoading,
        modifier = modifier
    ) {
        SpanText(text)
        HorizontalSpacer(SpaceSmall)
        FaPenToSquare()
    }
}