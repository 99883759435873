package cometes.pages.organization

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import app.cometes.shared.frontend.base.resource.Resource
import app.cometes.shared.frontend.base.resource.combine
import app.cometes.shared.frontend.base.resource.loadResourceIfEmpty
import app.cometes.shared.frontend.feature.desk.data.desksResource
import app.cometes.shared.frontend.feature.organization.data.currentOrganizationLocationListResource
import app.cometes.shared.frontend.feature.organization.data.currentOrganizationMembership
import app.cometes.shared.frontend.feature.organization.domain.model.OrganizationMember
import app.cometes.shared.frontend.feature.reservation.domain.model.Desk
import app.cometes.shared.frontend.feature.reservation.domain.model.Location
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.icons.mdi.MdiAdd
import com.varabyte.kobweb.silk.components.icons.mdi.MdiEdit
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import cometes.components.Authenticated
import cometes.components.common.DivText
import cometes.components.common.HorizontalSpacer
import cometes.components.common.ProgressIndicator
import cometes.components.common.ThemeToggleButton
import cometes.components.common.VerticalSpacer
import cometes.components.feature.organization.EditDeskDialog
import cometes.components.feature.organization.component.ResourceList
import cometes.components.layout.MainPageLayout
import cometes.style.SpaceLarge
import cometes.style.SpaceSmall
import cometes.style.TypographyBodyMedium
import cometes.style.TypographyBodySmall
import cometes.style.system.CometesButton
import cometes.style.system.CometesImageButton

@Page
@Composable
fun DesksPage() {
    Authenticated {
        val membership = currentOrganizationMembership() ?: return@Authenticated
        val desksResource = loadResourceIfEmpty { desksResource() }
        val locationsResource = loadResourceIfEmpty { currentOrganizationLocationListResource() }

        val dataResource = desksResource.combine(locationsResource)

        MainPageLayout(
            title = "Desks",
            utilRow = { ThemeToggleButton() },
            modifier = Modifier.fillMaxSize()
        ) {
            when (dataResource) {
                is Resource.Value -> {
                    val (desks, locations) = dataResource.data
                    DesksPageContent(
                        userMembership = membership,
                        desks = desks,
                        locations = locations,
                        modifier = Modifier.fillMaxWidth(),
                    )
                }

                is Resource.Loading -> ProgressIndicator(Modifier.align(Alignment.Center))
                is Resource.Error -> DivText("Error :(")
            }
        }
    }
}

@Composable
private fun DesksPageContent(
    userMembership: OrganizationMember,
    desks: List<Desk>,
    locations: List<Location>,
    modifier: Modifier = Modifier
) {
    var newDeskDialogVisible by remember { mutableStateOf(false) }
    var editedDesk by remember { mutableStateOf<Desk?>(null) }

    Column(
        modifier = Modifier.fillMaxSize()
    ) {
        if (userMembership.isAdmin) {
            CometesButton(
                onClick = { newDeskDialogVisible = true },
                modifier = Modifier.align(Alignment.End)
            ) {
                MdiAdd()
                HorizontalSpacer(SpaceSmall)
                DivText("New desk")
            }

            VerticalSpacer(SpaceLarge)
        }

        ResourceList(
            items = desks,
            modifier = modifier,
            gridBreakpoint = Breakpoint.MD,
            gridColumns = 3
        ) { desk ->
            DeskItem(
                desk = desk,
                isEditable = userMembership.isAdmin,
                onEditClick = { editedDesk = desk },
                modifier = modifier.fillMaxSize()
            )
        }
    }

    if (editedDesk != null || newDeskDialogVisible) {
        EditDeskDialog(
            desk = editedDesk,
            locations = locations,
            onDismiss = {
                editedDesk = null
                newDeskDialogVisible = false
            }
        )
    }
}

@Composable
private fun DeskItem(
    desk: Desk,
    isEditable: Boolean,
    onEditClick: () -> Unit,
    modifier: Modifier = Modifier
) {
    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = modifier
    ) {
        Column {
            DivText(desk.name, TypographyBodyMedium)
            DivText(desk.location.name, TypographyBodySmall.opacity(0.6))
        }
        Spacer()
        if (isEditable) {
            CometesImageButton(
                onClick = { onEditClick() },
                modifier = Modifier.align(Alignment.Top)
            ) { MdiEdit() }
        }
    }
}