package app.cometes.shared.frontend

import app.cometes.shared.frontend.configuration.data.source.AppInfoProvider
import app.cometes.shared.frontend.configuration.domain.Configuration
import kotlinx.browser.window
import org.koin.core.module.Module
import org.koin.core.module.dsl.singleOf
import org.koin.dsl.bind
import org.koin.dsl.module

actual val platformModule: Module = module {
    singleOf(::AppInfoProviderImpl) bind AppInfoProvider::class
}

private class AppInfoProviderImpl : AppInfoProvider {
    override val config: Configuration
        get() = when (window.location.host) {
            "cometes.app" -> Configuration.Release
            "preprod.cometes.app" -> Configuration.PreProd
            else -> Configuration.Debug
        }
    override val version: String = "0.0.2"
    override val appIdentification: String
        get() {
            val isPreProd = window.location.href.contains("pre-prod")
            return if (isPreProd) "web-pre-prod" else "web-prod"
        }
}