package app.cometes.shared.feature.reservation.infrastructure.model

import app.cometes.shared.feature.organization.infrastructure.model.OrganizationDto
import app.cometes.shared.feature.room.infrastructure.model.ConferenceRoomReservationDto
import kotlinx.serialization.Serializable

@Suppress("unused")
@Serializable
class HomeOverviewDto(
    val organization: OrganizationDto,
    val locations: List<LocationDto>,
    val deskReservations: List<ReservationDto>,
    val personsConferenceRoomReservations: List<ConferenceRoomReservationDto>,
)